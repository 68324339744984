<template>
    <b-row class="component-rules-link">
        <b-col>
            <a @click="redirectInformation('casto-kladene-otazky')">Často kladené otázky</a>
            <a @click="redirectInformation('pravidla')">Pravidlá portálu</a>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "RulesLink",
    methods: {
        redirectInformation(slug) {
            let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style lang="scss" scoped>
.component-rules-link::v-deep {
    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        a {
            margin-right: 10px;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}
</style>