<template>
    <div class="page-404">
        <MainMenu />

        <div class="container">
            <h1>404</h1>
            <h2>Stránka sa nenašla</h2>

            <b-button @click="$router.replace({ name: 'Home' })" class="mt-5" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="home"></font-awesome-icon>
                Domov
            </b-button>
        </div>

        <Footer />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";

export default {
    name: "Page404",
    components: {
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: '404 | ',
        }
    },
}
</script>

<style lang="scss" scoped>
$text-shadow-color: $small-color;

.page-404::v-deep {
    .container {
        min-height: 20vh;

        h1 {
            color: $primary;
            font-size: 100px !important;
            text-shadow: 5px 4px 4px rgba($primary, 0.4);
        }

        .text-3d {
            font-size: 100px !important;
            text-transform: uppercase;
            font-weight: 700;
            color: $primary;
            text-shadow: 1px 1px 1px $text-shadow-color,
            1px 2px 1px $text-shadow-color,
            1px 3px 1px $text-shadow-color,
            1px 4px 1px $text-shadow-color,
            1px 5px 1px $text-shadow-color,
            1px 6px 1px $text-shadow-color,
            1px 7px 1px $text-shadow-color,
            1px 8px 1px $text-shadow-color,
            1px 9px 1px $text-shadow-color,
            1px 10px 1px $text-shadow-color,
            1px 18px 6px rgba(16, 16, 16, 0.4),
            1px 22px 10px rgba(16, 16, 16, 0.2),
            1px 25px 35px rgba(16, 16, 16, 0.2),
            1px 30px 60px rgba(16, 16, 16, 0.4);
        }
    }
}
</style>