<template>
    <div class="search-supplier-card">
        <b-row @click="$router.push({ name: 'PageProfileAbout', params: { slug: supplier.slug } })" no-gutters>
            <b-col cols="12" lg="4">
                <span class="circle">
                    <font-awesome-icon class="absolute-left" icon="home" color="$secondary"></font-awesome-icon>
                    <span class="top">TOP</span>
                </span>
            </b-col>
            <b-col cols="12" lg="8">
                <h3>{{ supplier.name }}</h3>
                <div class="work-info">
<!--                    <span v-for="(profession, index) in supplier.professions" :key="index">-->
<!--                        <small>-->
<!--                            {{ profession.name }}<span v-if="index < supplier.professions.length - 1">,</span>-->
<!--                        </small>-->
<!--                    </span>-->
                    <div v-if="supplier.professions[0]">{{ supplier.professions[0].name }}</div>
                    <div v-if="supplier.professions[1]">{{ supplier.professions[1].name }}</div>
                    <div v-if="supplier.professions[3]">{{ supplier.professions[3].name }}</div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        props: {
            supplier: {
                default: function () {
                    return {}
                }
            },
        },
        
    }
</script>

<style lang="scss" scoped>
.search-supplier-card::v-deep {
    .row {
        cursor: pointer;
        > :nth-child(1) {
            @media (max-width: 991px) {
                margin-bottom: 20px;
            }
        }
        > :nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (min-width: 992px) {
                align-items: flex-start;
            }
        }
    }

    .circle {
        border: 1px solid $primary;
        width: 85px;
        height: 85px;
        display: block;
        margin: 0 auto;
        border-radius: 50%;

        svg {
            transform: scale(4);
            margin-top: 32px;
        }

        .top {
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 1px 14px;

            background-color: $secondary;
            border-radius: 22px;
            color: white;
            font-size: 11px;

        }
    }

    h3 {
        font-size: 18px !important;
        text-align: left;

        @media (max-width: 991px) {
            text-align: center;
        }
    }

    .work-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
            font-size: 12px;
            text-align: left;

            @media (min-width: 1080px) {
                margin-right: 5px;
            }
        }

        //.first-type::after {
        //    content: '';
        //    display: block;
        //    width: 2px;
        //    height: 15px;
        //    background-color: $primary;
        //    float: right;
        //    margin: 5px 7px 0;
        //
        //    @media (max-width: 1200px) {
        //        display: none;
        //    }
        //}

        @media (max-width: 1200px) {
            justify-content: center;
            flex-direction: column;
        }
    }

}
</style>