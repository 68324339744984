<template>
    <div class="component-profile-nav-swiper">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <a :class="{ active: $route.name === 'PageProfileAbout'}" @click="goProfileAbout()">O mne</a>
                </div>
                <div v-if="
                    $store.getters.user.slug
                    && ( $store.getters.user.slug === $store.getters.customer.slug
                        ? $store.getters.permissions('manage own demands') : $store.getters.permissions('view demands') )"
                     class="swiper-slide"
                >
                    <a :class="{ active: $route.name === 'PageProfileDemands'}" @click="goProfileDemands()">Dopyty</a>
                </div>
                <div class="swiper-slide">
                    <a :class="{ active: $route.name === 'PageProfileBlogs'}" @click="goProfileBlogs()">Blog</a>
                </div>
                <div class="swiper-slide">
                    <a :class="{ active: $route.name === 'PageProfileForum'}" @click="goProfileForums()">Fórum</a>
                </div>
                <div v-if="$route.params.slug === $store.getters.user.slug" class="swiper-slide">
                    <a :class="{ active: $route.name === 'PageProfileSubscriptions'}" @click="goProfileSubscriptions()">Moje predplatné</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

export default {
    name: "ProfileNav",
    data() {
        return {
            user: '',
            activeItem: ''
        }
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper('.component-profile-nav-swiper .swiper-container', {
            direction: 'horizontal',
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 0,
            initialSlide: 1,
            centeredSlides: true,
            breakpoints: {
                768: {
                    centeredSlides: false,
                    slidesPerView: 4,
                    initialSlide: 0,
                }
            }
        });
    },
    methods: {
        isActive: function (menuItem) {
            return this.activeItem === menuItem
        },
        setActive: function (menuItem) {
            this.activeItem = menuItem
        },
        goProfileAbout() {
            this.$router.push({ name: 'PageProfileAbout' }, () => {})
        },
        goProfileDemands() {
            this.$router.push({ name: 'PageProfileDemands' }, () => {})
        },
        goProfileBlogs() {
            this.$router.push({ name: 'PageProfileBlogs' }, () => {})
        },
        goProfileForums() {
            this.$router.push({ name: 'PageProfileForum' }, () => {})
        },
        goProfileSubscriptions() {
            this.$router.push({ name: 'PageProfileSubscriptions' }, () => {})
        },
    },
}
</script>

<style lang="scss" scoped>
.component-profile-nav-swiper {

    .swiper-container {
        //width: 100%;
        overflow: visible;
        padding: 30px 0;
        margin-left: -50px;

        .swiper-slide {
            width: auto;
            cursor: pointer !important;
            margin-right: 0;
            max-width: 140px;

            a {
                color: #8695A5;
                font-size: 15px;
                font-weight: 800;

                &:hover {
                    color: $primary;
                }

                &.active {
                    color: $primary;
                    position: relative;

                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        background: $primary;
                        height: 2px;
                        width: 40px;
                    }
                }
            }

            @media (max-width: 770px) {
                margin: 0 10px;
            }
        }
    }
}
</style>