<template>
    <div class="page-blog-main">

        <div class="container">
            <b-row class="row-buttons">
                <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
                    <b-row v-if="$store.getters.user.slug">
                        <b-col class="mb-4 mb-md-0" cols="12" md="6">
                            <b-button
                                @click="$router.push({ name: 'PageProfileBlogs', params: { slug: $store.getters.user.slug } }, () => {})"
                                variant="outline-secondary"
                                pill
                            >
                                Moje články
                            </b-button>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-button @click="$router.push({ name: 'PageBlogCreate' }, () => {})" pill variant="primary">Pridať článok</b-button>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col cols="12" md="6" offset-md="3">
                            <b-button @click="$router.push({ name: 'PageBlogCreate' }, () => {})" pill variant="primary">Pridať článok</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>

        <BenefitsSlider :banner_slug="'benefits-slider'" />

        <SearchPost :search_type="'blog'"/>

        <TopPostSlider />

        <div class="choose-category">
            <div class="container">
                <h2 class="title text-left">Vyberte si kategóriu</h2>
            </div>

            <CategoryCollapse :menu_id="1" :category="category" @update-category="updateCategory" />
        </div>

        <div id="blog-main" class="main-content">
            <div class="container">
                <b-row>
                    <b-col class="left" cols="12" lg="8">
                        <div class="top-wrapper">
                            <h2 class="title text-left">Články</h2>

                            <!--                            <p class="breadcumb">Pred stavbou > Projekty</p>-->
                        </div>

                        <div class="sort">
                            <button @click="setFilter('publication_date')" :class="{ sorting_color: filter === 'publication_date' }">
                                Najnovšie
                                <font-awesome-icon v-show="filter === 'publication_date' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'publication_date' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button> |
                            <button @click="setFilter('blog_likes_count')" :class="{ sorting_color: filter === 'blog_likes_count' }">
                                Najčítanejšie
                                <font-awesome-icon v-show="filter === 'blog_likes_count' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'blog_likes_count' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button> |
                            <button @click="setFilter('comments_count')" :class="{ sorting_color: filter === 'comments_count' }">
                                Najkomentovanejšie
                                <font-awesome-icon v-show="filter === 'comments_count' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'comments_count' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button>
                        </div>

                        <b-row v-if="all_blogs.length > 0" class="list" cols="1" cols-md="2">
                            <b-col v-for="blog in all_blogs" :key="blog.id">
                                <Post v-if="blog" :blog="blog" />
                            </b-col>
                        </b-row>
                        <div v-else-if="all_blogs.length === 0 && spinner.blog" class="mt-5 mb-5">
                            <b-spinner type="grow" label="Spinning"></b-spinner>
                        </div>
                        <div v-else class="blogs-not-found">
                            <p>Nenašli sa žiadne relevantné články</p>
                            <b-button @click="$router.push({ name: 'PageBlogCreate' }, () => {})" variant="primary" pill>
                                Pridať článok
                            </b-button>
                        </div>

                        <div v-if="!spinner.blog" class="paginator">
                            <pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :records="records"
                                :options="{
                                    theme: 'bootstrap4',
                                    edgeNavigation: false,
                                    texts: {
                                        count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                                        first: 'Prvá',
                                        last: 'Posledná'
                                    }
                                }"
                                @paginate="handlePageChange"
                            ></pagination>
                        </div>

                    </b-col>
                    <b-col class="sidebar" cols="12" lg="4" xl="3" offset-xl="1">
                        <BannerAdvertisingVertical :banner_slug="'TPagFbT03nrHAhO'" />
                        <TopPostContributor v-if="topBlogUsers.length > 0" :topUsers="topBlogUsers" :title="'Top prispievatelia'" />
                    </b-col>
                </b-row>

                <img class="geometric-5" :src="geometric_4" alt="svg">
            </div>
        </div>

        <ForumList />
        <Newsletter />
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "@/config";

import geometric_4 from "@/assets/geometric_4.svg"

import BenefitsSlider from "@/components/home/BenefitsSlider";
import TopPostSlider from "@/components/blog/TopPostSlider";

import Post from "@/components/blog/single/Post";
import CategoryCollapse from "@/components/CategoryCollapse";
import TopPostContributor from "@/components/TopPostContributor";
import SearchPost from "@/components/SearchPost";
import BannerAdvertisingVertical from "@/components/BannerAdvertisingVertical";

import ForumList from "@/components/forum/ForumList";
import Newsletter from "@/components/home/Newsletter";

export default {
    name: 'PageBlogMain',
    components: {
        BenefitsSlider,
        TopPostSlider,
        Post,
        ForumList,
        BannerAdvertisingVertical,
        Newsletter,
        SearchPost,
        CategoryCollapse,
        TopPostContributor,
    },
    metaInfo: {
        title: 'Blog | ',
        meta: [
            // { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_description_blog },
            // { vmid: 'og:description', name: 'og:description', content: this.$store.getters.settings.meta_description_blog },
            { vmid: 'og:title', name: 'og:title', content: 'eBrick Blog' },
            { vmid: 'og:type', name:'og:type', content: 'blog' },
        ]
    },
    data() {
        return {
            geometric_4: geometric_4,

            allowed_query: ['filter', 'currentPage', 'sort', 'category'],
            allowed_filters: ['publication_date', 'blog_likes_count', 'comments_count'],
            default_filter: 'publication_date',
            filter: 'publication_date',
            sort: 'DESC',
            search_term: '',
            category: [],
            perPage: 6,
            currentPage: 1,
            all_blogs: [],
            records: 0,
            topBlogs: [],
            topBlogUsersCount: 10,
            topBlogUsers: [],

            spinner: {
                blog: false,
            }
        }
    },
    watch: {
        '$route.query': {
            handler: function (query) {
                if (this.checkQuery(query)) {
                    // console.log('QUERY Y')
                    if (query.sort) {
                        query.sort === 'ASC' ? this.sort = 'ASC' : this.sort = 'DESC'
                    } else {
                        this.sort = 'DESC'
                    }
                    if (query.currentPage) {
                        if (Number.isInteger(Number(query.currentPage))) this.currentPage = Number(query.currentPage)
                    } else {
                        this.currentPage = 1
                    }
                    query.category ? this.category = JSON.parse(query.category) : this.category = []

                    this.getBlogs()
                } else {
                    this.resetFilter(this.default_filter)
                }
            },
            immediate: true
        },
    },
    mounted() {
        if (this.$route.params.blog) {
            this.$scrollTo('#blog-main')
        }

        this.getTopBlogUsers()
    },
    methods: {
        checkQuery(query) {
            let querySuccess = true
            let queryKeys = Object.keys(query)
            if (queryKeys.length > 0) {
                queryKeys.forEach(key => {
                    if (!this.allowed_query.includes(key)) querySuccess = false
                })
            }
            if (querySuccess) {
                if (query.filter) {
                    if (this.allowed_filters.includes(String(query.filter))) {
                        this.filter = String(query.filter)
                    } else {
                        querySuccess = false
                    }
                } else {
                    this.filter = this.default_filter
                    this.currentPage = 1
                    this.sort = 'DESC'
                }
            }
            return querySuccess
        },
        handlePageChange(page) {
            console.log('handlePageChange', page)
            let query = Object.assign({}, this.$route.query);
            if (page === 1) {
                this.currentPage = 1
                delete query.currentPage
            } else {
                if (Number(this.$route.query.currentPage) !== page) query.currentPage = this.currentPage
            }
            this.$router.push({ 'query': query }, () => {});
            this.$scrollTo('#blog-main')
        },
        changeSort(actualFilter) {
            let query = Object.assign({}, this.$route.query);
            if (actualFilter === this.filter) {
                if (this.sort === 'ASC') {
                    delete query.sort
                    this.sort = 'DESC'
                } else {
                    query.sort = 'ASC'
                    this.sort = 'ASC'
                }
            } else {
                if (this.sort !== 'DESC') {
                    delete query.sort
                    this.sort = 'DESC'
                }
            }
            this.currentPage = 1
            delete query.currentPage
            this.$router.push({ 'query': query }, () => {});
        },
        setFilter(filterItem) {
            this.changeSort(filterItem)
            if (filterItem === 'publication_date') {
                if (this.filter !== 'publication_date') this.$router.replace({ 'query': null })
            } else {
                let query = Object.assign({}, this.$route.query);
                query.filter = filterItem
                if (this.filter !== filterItem) {
                    delete query.currentPage
                    this.currentPage = 1
                }
                this.$router.push({ 'query': query }, () => {});
            }
            this.filter = filterItem
            this.$scrollTo('#blog-main')
        },
        updateCategory(category) {
            this.category = category
            this.currentPage = 1
            let query = Object.assign({}, this.$route.query);
            delete query.currentPage
            category.length > 0 ? query.category = JSON.stringify(category) : delete query.category
            this.$router.push({ 'query': query }, () => {});
        },
        getBlogs() {
            this.spinner.blog = true
            this.all_blogs.splice(0)
            console.log('URL', this.currentPage, this.filter, this.sort, this.category)
            axios.get(config.API_URL + '/blog'
                + '?page=' + this.currentPage
                + '&perpage=' + this.perPage
                + '&category_ids=' + '[' + this.category + ']'
                + '&orderBy=' + this.filter
                + '&ordering=' + this.sort, config.HEADERS)
                .then((response) => {
                    // console.log('bloggg:', response);
                    this.records = response.data.success.total;
                    this.all_blogs = response.data.success.data;
                    this.spinner.blog = false
                })
                .catch(error => {
                    console.log(error.response)
                    this.spinner.blog = false
                });
        },
        searchTerm() {
            this.$router.push({ name: 'Search', query: { search: 'blog', filter: 'created_at', sort: 'desc', search_term: this.search_term }}, () => {})
        },
        getTopBlogUsers() {
            axios.get(config.API_URL + '/user/getTopBlogUsers'
                + '?quantity=' + this.topBlogUsersCount, config.HEADERS)
                .then((response) => {
                    console.log('getTopBlogUsers:', response.data.success);
                    this.topBlogUsers = response.data.success
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
        resetFilter(filter) {
            this.filter = filter
            this.sort = 'DESC'
            this.currentPage = 1
            this.category = []
            this.$router.push({ 'query': null }, () => {});
        },
    },
}
</script>

<style scoped lang="scss">
.page-blog-main::v-deep {
    .row-buttons {
        margin-bottom: 30px;

        .btn {
            width: 100%;
            //width: 48%;
            //
            //&:first-child {
            //	margin-right: 4%;
            //}
        }
    }

    .choose-category {
        .title {
            color: $secondary;
            margin: 50px 0 30px;
        }
    }

    .main-content {
        position: relative;
        padding: 50px 0;

        .geometric-5 {
            position: absolute;
            right: -550px;
            bottom: -270px;
            z-index: -1;
        }

        .left {
            .top-wrapper {
                display: flex;
                flex-direction: row;

                .title {
                    color: $secondary;
                    margin-bottom: 15px;
                }

                .breadcumb {
                    color: $secondary;
                    line-height: 33px;
                    margin-bottom: 0;
                    margin-left: auto;
                }
            }

            .sort {
                text-align: left;

                button {
                    outline: none;
                    border: none;
                    background: transparent;

                    @media (max-width: 450px) {
                        font-size: 13px !important;
                    }
                }

                svg {
                    color: $primary;
                    transform: scale(0.9);
                }

                .sorting_color {
                    font-weight: bold;
                    color: $secondary;

                    svg {
                        color: $secondary;
                    }
                }
            }

            .list {
                margin-top: 30px;

                .single-post {
                    margin-bottom: 30px;
                }
            }

            .blogs-not-found {
                margin-top: 100px;
                margin-bottom: 100px;

                svg {
                    transform: scale(3);
                    margin-bottom: 20px;
                }
            }

            .paginator {
                display: flex;
                justify-content: center;
                width: 100%;

                .VuePagination {
                    //width: inherit;
                    overflow-x: auto;
                }

                .page-link {
                    user-select: none;
                }

                .VuePagination__pagination-item-prev-chunk .page-link {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }

                .VuePagination__pagination-item-next-chunk .page-link {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }

        .sidebar {
            >.title {
                font-size: 16px !important;
                text-align: center;
                margin-bottom: 40px;
            }
        }
    }

}

</style>
