<template>
    <div class="single-category-collapse">
        <b-collapse id="collapse-categories" v-model="categoriesCollapse">
            <div class="container">
                <b-row>
                    <b-col cols="12" lg="8" offset-lg="2">
                        <span class="blank-span"></span>
                        <b-spinner v-if="!categories" type="grow" label="Spinning"></b-spinner>
                        <treeselect
                            v-else
                            :value="category"
                            :multiple="true"
                            :options="categories"
                            :show-count="true"
                            :alwaysOpen="true"
                            :maxHeight="-1"
                            :default-expand-level="0"
                            @input="changeCategory"
                        />
                        <button @click="changeCategory([])" class="reset-category">Reset kategórií</button>
                    </b-col>
                </b-row>
            </div>
        </b-collapse>

        <div class="category-toggle" v-b-toggle="'collapse-categories'" >
            <span>
                {{ categoriesCollapse ? 'Skryť kategórie' : 'Zobraziť kategórie' }}
                <font-awesome-icon icon="arrow-down" size="xs"></font-awesome-icon>
            </span>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "CategoryCollapse",
    props: [ 'menu_id', 'category' ],
    components: {
        Treeselect,
    },
    data() {
        return {
            categoriesCollapse: false,
            categories: [],
            category_new: [],
        }
    },
    mounted() {
        // this.getCategory()
    },
    watch: {
        menu_id: {
            handler: function () {
                this.getCategory()
            },
            immediate: true
        }
    },
    methods: {
        loopCategory(response) {
            let categories = response
            let categoriesLength = Object.keys(categories).length
            let categoriesList = []

            for (let i = 0; i < categoriesLength; i++) {
                let newCategory = {}
                newCategory.label = categories[i].name
                newCategory.id = categories[i].id

                if (categories[i].children.length > 0) {
                    newCategory.children = this.loopCategory(categories[i].children)
                }
                categoriesList.push(newCategory)
            }
            return categoriesList
        },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=' + this.menu_id, config.HEADERS)
                .then((response) => {
                    this.categories = this.loopCategory(response.data.success)
                }, (error) => {
                    console.warn(error)
                });
        },
        changeCategory(category) {
            this.$emit('update-category', category)
        },
    },
}
</script>

<style lang="scss" scoped>
.single-category-collapse::v-deep {
    padding-top: 30px;

    #collapse-categories {
        background: $bg-gray;

        .blank-span {
            display: block;
            background-color: transparent;
            height: 30px;
            width: 1px;
        }

        .reset-category {
            color: $small-color;
            outline: none;
            border: none;
            background-color: transparent;
        }

        .vue-treeselect {

            .vue-treeselect__control {
                display: none;
            }

            .vue-treeselect__menu-container {
                position: relative;
                border: 0;
                font-size: 15px !important;

                .vue-treeselect__menu {
                    border: 0;
                    position: relative;
                    box-shadow: none;
                    background-color: $bg-gray;

                    > .vue-treeselect__list {

                        // .vue-treeselect__list-item
                        > div {
                            margin-bottom: 5px;

                            // .vue-treeselect__option
                            > div:first-child {
                                color: white;
                                border-radius: 5px;
                                padding: 5px 0;
                                background-color: $primary;
                                margin-bottom: 5px;

                                .vue-treeselect__count {
                                    margin-right: 15px;
                                }
                            }

                            .vue-treeselect__option-arrow-container {
                                width: 40px;

                                svg {
                                    color: white;

                                    &:hover {
                                        color: $secondary;
                                    }
                                }
                            }
                            .vue-treeselect__option-arrow-placeholder {
                                width: 40px;
                            }
                            .vue-treeselect__checkbox-container {
                                .vue-treeselect__checkbox {
                                    border-radius: 50%;
                                    background-color: $secondary;

                                    .vue-treeselect__check-mark {
                                        background-color: $secondary;
                                        border-color: $secondary;
                                    }
                                    .vue-treeselect__minus-mark {
                                        background-color: $secondary;
                                        border-color: $secondary;
                                    }
                                }

                                .vue-treeselect__checkbox--checked {
                                    border-color: $secondary;
                                    background-color: $secondary;
                                }
                                .vue-treeselect__checkbox--unchecked {
                                    border-color: white;
                                    background-color: white;
                                }
                                .vue-treeselect__checkbox--indeterminate {
                                    border-color: $secondary;
                                }
                            }
                            .vue-treeselect__count {
                                float: right;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .category-toggle {
        margin-bottom: 50px;
        padding: 30px 0;

        background: $bg-gray;
        outline: none;

        svg {
            transition: transform .4s ease-in-out;
        }

        &.not-collapsed {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
</style>