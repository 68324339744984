<template>
    <div class="component-video-link">
        <b-form-group :label="'Odkaz na video ' + (index + 1)" label-for="link_1" description="Odkaz na video.">
            <b-form-input id="link_1" v-model="actual_link" @input="setLink()" type="text"></b-form-input>
<!--            <b-form-group label="Popis" label-for="link_1_info">-->
<!--                <b-form-input id="link_1_info" v-model="link_1_info" type="text"></b-form-input>-->
<!--            </b-form-group>-->
            <div class="error-message-l" v-show="$v.link.$error">
                <div v-if="!$v.link.youtubeURLCheck">Prosím vložte iba odkazy na youtube video vo formáte https://www.youtube.com.</div>
            </div>
        </b-form-group>
    </div>
</template>

<script>
const youtubeURLCheck = (value, vm) => {
    if (value) {
        return !!value.match(vm.regExp);
    } else {
        return true
    }
}

export default {
    name: "DemandVideoLink",
    props: [ 'link', 'index' ],
    data() {
        return {
            actual_link: '',
            link_error_1: false,
            regExp: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
        }
    },
    watch: {
        link: {
            handler: function () {
                this.actual_link = this.link;
            },
            immediate: true
        },
    },
    methods: {
        setLink() {
            this.$emit('update:link', this.actual_link)
        },
    },
    validations: {
        link: {
            youtubeURLCheck: youtubeURLCheck
        }
    }
}
</script>

<style lang="scss" scoped>
.component-video-link::v-deep {
    //
}
</style>