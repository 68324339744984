<template>
    <div class="search-wrapper container">
        <div class="container">
            <b-row>
                <b-col cols="12" md="6" offset-md="3">
                    <b-form @submit.prevent="searchTerm(search_type)" class="mt-5 mb-5">
                        <b-form-group :label="search_label" label-for="post_search">
                            <b-form-input id="post_search" v-model="search_term" type="search" :placeholder="placeholder"></b-form-input>
                            <template slot="description">
                                <a @click="redirectToSearch()">Rozšírené vyhľadávanie</a>
                            </template>
                        </b-form-group>
                    </b-form>

                    <p class="mb-0">Niečo o tom, ako sa správať.</p>
                    <p class="mb-0">Chcete vedieť čo môžete, musíte, mali by ste a mohli by ste?</p>
                    <p>Prejdite si naše pravidlá.</p>

                    <b-button
                        @click="redirectInformation('pravidla')"
                        class="btn-terms"
                        variant="primary"
                        pill
                    >
                        Pravidlá portálu
                    </b-button>
                </b-col>
            </b-row>

            <img class="geometric-3" :src="geometric_3" alt="svg">
            <span class="palicka-right"></span>
            <span class="palicka-center"></span>
            <span class="palicka-center-2"></span>
            <span class="palicka-left"></span>
        </div>
    </div>
</template>

<script>
import geometric_3 from "@/assets/geometric_3.svg"
export default {
    name: "SearchPost",
    props: {
        search_type: {
            type: String,
            default: 'all'
        },
    },
    data() {
        return {
            geometric_3: geometric_3,
            search_term: '',
            search_label: '',
            placeholder: '',
        }
    },
    mounted() {
        if (this.search_type === 'blog') {
            this.search_label = 'Vyhľadajte v článkoch'
            this.placeholder = 'Názov článku...'
        } else if (this.search_type === 'forum') {
            this.search_label = 'Vyhľadajte vo fóre'
            this.placeholder = 'Názov fóra...'
        }
    },
    methods: {
        searchTerm(type) {
            if (type !== 'all') {
                console.log(this.search_term, this.search_type)
                this.$router.push({ name: 'Search', query: { search: type, filter: 'created_at', sort: 'desc', search_term: this.search_term }}, () => {})
            } else {
                this.redirectToSearch()
            }
        },
        redirectToSearch() {
            this.$router.push({ name: 'Search', query: { search: 'all' }}, () => {})
        },
        redirectInformation(slug) {
            let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
            window.open(routeData.href, '_blank');
        },
    }
}
</script>

<style lang="scss" scoped>
.search-wrapper::v-deep {
    padding: 40px 0;

    .container {
        position: relative;
    }

    a {
        cursor: pointer;
    }

    //form {
    //    margin-bottom: 20px;
    //
    //    label {
    //        text-align: left;
    //        padding-left: 20px;
    //        font-size: 14px;
    //        font-weight: 600;
    //    }
    //
    //    input {
    //        color: $primary;
    //        border-radius: 30px;
    //        background: #DCDEE6;
    //        border: none;
    //        padding: 30px 20px;
    //        box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
    //
    //        &::placeholder {
    //            color: $primary;
    //        }
    //    }
    //}

    .btn-terms {
        padding: 10px 50px;
    }

    .geometric-3 {
        position: absolute;
        left: -520px;
        bottom: -250px;
        height: calc(100% + 400px);
        //z-index: 5;
        z-index: -1;
    }

    .palicka-left {
        position: absolute;
        top: -100px;
        left: 150px;
        width: 2px;
        height: 4000px;
        background: $palicka-color;
        //z-index: 2;
        z-index: -1;
    }

    .palicka-right {
        position: absolute;
        top: 100%;
        right: 150px;
        width: 2px;
        height: 4000px;
        background: $palicka-color;
        //z-index: 1;
        z-index: -1;
    }

    .palicka-center {
        position: absolute;
        top: -20px;
        right: 51.3%;
        width: 2px;
        height: 1500px;
        background: $palicka-color;
        //z-index: 1;
        z-index: -1;
    }

    .palicka-center-2 {
        position: absolute;
        top: 40%;
        right: 48.5%;
        width: 2px;
        height: 1500px;
        background: $palicka-color;
        //z-index: 1;
        z-index: -1;
    }

    @media (max-width: 768px) {
        .palicka-left, .palicka-right, .palicka-center, .palicka-center-2 {
            display: none;
        }
    }
}
</style>