<template>
    <div class="menu-information">
        <h1 class="title mb-4">Všeobecné informácie</h1>

        <div class="info-navigation">
            <div class="accordion" role="tablist">
                <div v-for="(menu_item, index) in menuItems" :key="index" class="info-navigation-item">
                    <b-button
                        @click="setPage(menu_item)"
                        v-b-toggle="menu_item.slug"
                        :class="{ active: $route.params.slug === menu_item.slug && menu_item.children.length === 0 }"
                        variant="link"
                    >
                         <span :class="{ 'with-opener': menu_item.children.length > 0 }">{{ menu_item.title }}</span>
                    </b-button>

                    <b-collapse
                        :id="menu_item.slug"
                        :visible="$route.params.slug === menu_item.slug"
                        accordion="info-nav"
                    >
                        <div v-for="(children, index) in menu_item.children" :key="index" class="collapse-body">
                            <b-button
                                @click="setPage(children)"
                                :class="{ active: $route.params.slug === children.slug }"
                                variant="link"
                            >
                                {{ children.title }}
                            </b-button>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "MenuInformation",
    props: [ 'menuItems' ],
    mounted() {
        let items = []
        this.menuItems.forEach(el => {
            items.push(el.slug)
        })
        if (!items.includes(String(this.$route.params.slug))) {
            this.$router.replace({ name: 'Informations', params: { slug: 'pravidla' }}, () => {});
        }
    },
    methods: {
        setPage(value) {
            if (value.children.length === 0) {
                this.$router.push({ name: 'Informations', params: { slug: value.slug }}, () => {})
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-information::v-deep {
    margin-bottom: 30px;

    .title {
        color: $secondary;
        font-size: 20px !important;
    }

    .info-navigation {
        .accordion {

            .info-navigation-item {
                position: relative;

                &:first-child:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;

                    background-image: linear-gradient(to right, #0e2b4c 0%, rgba(255, 255, 255, 0) 30%);
                    background-position: bottom;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;

                    background-image: linear-gradient(to right, #0e2b4c 0%, rgba(255, 255, 255, 0) 30%);
                    background-position: bottom;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                }

                .btn-link {
                    position: relative;
                    width: 100%;
                    text-align: left;
                    outline: none;
                    box-shadow: none;
                    text-decoration: none;
                    padding: 5px 0;
                }

                > .btn-link {
                    padding: 10px 0;

                    span {
                        font-size: 16px;
                        white-space: break-spaces;

                        &.with-opener {
                            &:after {
                                display: inline-block;
                                margin-left: 0.255em;
                                //vertical-align: 0.255em;
                                vertical-align: 2.5px;
                                content: "";
                                border-top: 0.3em solid;
                                border-right: 0.3em solid transparent;
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;

                                transition: transform .2s ease-in-out;
                            }
                        }
                    }

                    &.not-collapsed {
                        span {
                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .active {
                    color: $secondary;
                    font-weight: bold;
                    white-space: nowrap;
                }

                .collapse-body {
                    //padding: 10px 10px 20px 20px;
                    padding-left: 20px;

                    .custom-checkbox {
                        .custom-control-label {
                            &:before {
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>