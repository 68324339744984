<template>
    <div class="demand-rating">
        <MainMenu />

        <div class="top-wrapper">
            <div class="container">
                <b-button class="btn-back" @click="$router.push({ name: 'DemandDetail', params: { slug: $route.query.demand } })" variant="link">
                    <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                    Späť na dopyty
                </b-button>

                <h1 class="title">Hodnotenie dopytu</h1>
            </div>
            <hr>
        </div>

        <div class="main-wrapper">
            <div class="container">
                <b-form>
                    <b-row>
                        <b-col cols="10" offset="1" md="4" offset-md="4" v-for="rate in defaultRole" :key="rate.id" class="one-rate-item">
                            <label :for="'rating-' + rate.id">
                                <span>{{ rate.title }}</span>
                            </label>
                            <star-rating
                                :id="'rating-' + rate.id"
                                :rating="defaultRole[rate.id].value"
                                v-model="defaultRole[rate.id].value"
                                :fixed-points="2"
                                :round-start-rating="false"
                                :show-rating="false"
                                :star-size="25"
                                :padding="10"
                                :active-color="'#ffa600'"
                                :border-width="1"
                            />
<!--                                <b-form-rating :id="'rating-' + rate.id" v-model="defaultRole[rate.id].value" stars="5"></b-form-rating>-->
                            <p class="description">popis hodnotenia</p>
                        </b-col>

                        <b-col class="send-button" cols="10" offset="1" md="6" offset-md="3">
                            <div v-show="!spinner.save">
                                <div class="error-message-c">
                                    <div v-for="(err, index) in errors" :key="index">{{ err }}</div>
                                </div>
                                <b-button @click="sendRate()" variant="primary" pill>
                                    <font-awesome-icon class="btn-icon" icon="share"></font-awesome-icon>
                                    Odoslať hodnotenie práce
                                </b-button>
                            </div>
                            <b-spinner v-show="spinner.save" class="mt-4 mb-4" type="grow" label="Spinning"></b-spinner>
                        </b-col>
                    </b-row>

                </b-form>
            </div>
        </div>

        <img class="geometric-1" :src="geometric_1" alt="svg">

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import geometric_1 from "@/assets/geometric_1.svg";
import MainMenu from "@/components/MainMenu";
import StarRating from 'vue-star-rating';
import Footer from "@/components/Footer";

    export default {
        components: {
            MainMenu,
            StarRating,
            Footer,
        },
        props: {
            demand_id: {
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                geometric_1: geometric_1,

                defaultRole: null,
                user_1: [
                    { id: 0, value: 1, name: 'quality', title: 'Kvalita práce'},
                    { id: 1, value: 1, name: 'summ_quality', title: 'Pomer cena / výkon'},
                    { id: 2, value: 1, name: 'comunication', title: 'Komunikácia'},
                    { id: 3, value: 1, name: 'cleanliness', title: 'Čistota'},
                    { id: 4, value: 1, name: 'deliver_speed', title: 'Rýchlosť dodania'},
                ],
                user_2: [
                    { id: 0, value: 1, name: 'pay_summ', title: 'Uhradenie dohodnutej sumy'},
                    { id: 1, value: 1, name: 'pay_speed', title: 'Rýchlosť platby'},
                    { id: 2, value: 1, name: 'comunication', title: 'Komunikatívnosť'},
                    { id: 3, value: 1, name: 'friendliness', title: 'Ústretovosť'},
                ],
                spinner: {
                    save: false,
                },
                errors: {},
            }
        },
        mounted() {
            if (!this.$route.query.demand || !this.$route.query.user) this.$router.push({ name: 'Demands' })
            let user_slug = this.$route.query.user
            if (user_slug === this.$store.getters.user.slug) {
                this.$set(this, 'defaultRole', this.user_1)
            } else {
                this.$set(this, 'defaultRole', this.user_2);
            }
        },
        methods: {
            sendRate() {
                this.spinner.save = true
                this.errors = {}

                const myData = new FormData();
                myData.set('slug', this.$route.query.demand);
                this.defaultRole.forEach(rating => {
                    myData.set(rating.name, rating.value);
                })

                // for (let pair of myData.entries()) {
                //     console.log(pair[0]+ ' = ' + pair[1]);
                // }

                axios.post(config.API_URL + '/demand/user/mainRaitController', myData, config.HEADERS)
                    .then((response) => {
                        console.log('statuss my', response);
                        this.spinner.save = false
                        this.$router.push({ name: 'DemandDetail', params: { slug: this.$route.query.demand } }, () => {})
                    }, (error) => {
                        this.spinner.save = false
                        this.errors = this.$errorMessage(error.response)
                    });
            },
        }
    }
</script>

<style lang="scss" scoped>
.demand-rating::v-deep {
    .top-wrapper {
		.container {
			display: flex;
			flex-direction: row;

			.title {
				margin-bottom: 0;
				line-height: 46px;
				font-size: 20px !important;
				color: $secondary;
				display: block;
				
				@media (min-width: 1199px) {
					margin: 0 41% 0 auto;
				}
			}

            @media (max-width: 1199px) {
                flex-direction: column;
                align-items: center;
            }
		}
		
		hr {
			border-color: $secondary;
		}
	}

    .main-wrapper {
        margin-top: 50px;

        form {
            .row {
                > :nth-child(2n) {
                    background-color: $bg-gray;

                    &:before {
                        content: '';
                        display: block;
                        left: -100vw;
                        width: 200vw;
                        height: calc(100% + 20px);
                        top: -10px;
                        background-color: $bg-gray;
                        position: absolute;
                        z-index: -1;
                    }
                }
            }

            .one-rate-item {
                display: flex;
                flex-direction: column;

                margin-top: 10px;
                margin-bottom: 10px;
                padding-top: 10px;
                padding-bottom: 10px;

                label {
                    float: left;
                    margin-bottom: 0;
                    font-weight: bold;
                    font-size: 14px !important;
                }

                .vue-star-rating {
                    display: flex;
                    justify-content: center;
                    margin: 5px 0;
                }

                .description {
                    opacity: 0.7;
                    font-size: 12px !important;
                    float: left;
                    margin-bottom: 0;
                }
            }

            .send-button {
                margin: 30px auto;

                button {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    padding: 10px 0;
                    width: 90%;
                    max-width: 300px;
                }
            }
        }
    }
    
    .geometric-1 {
        position: absolute;
        top: 400px;
        left: 20px;
        transform: scaleX(-1);
        width: 760px;
        z-index: -1;
        opacity: 0.5;
    }

    .component-footer {
        margin-top: 50px;
    }
}
</style>