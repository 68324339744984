<template>
    <div class="page-update-demand">
        <MainMenu />

        <CreateUpdateHead
            :title="'Upraviť dopyt'"
            :page="'DemandDetail'"
            :slug="$route.params.slug"
            :link_text="'Späť na dopyt'"
        />

        <div class="main-wrapper" >
            <NotLoginUser v-if="$store.getters.user === ''"  :text="'Ak chcete zadávať dopyty, musíte sa najprv prihlásiť.'" />

            <div class="container">
                <div v-if="!demand_id" class="no-data">
                    <b-spinner class="mt-5" type="grow" label="Spinning"></b-spinner>
                </div>
                <b-form v-else>
                    <b-row no-gutters>
                        <b-col cols="12" md="6" offset-md="3">

                            <b-form-group v-if="demand_state !== 3" label="Nadpis projektu*" label-for="title" description="Zadajte názov projektu." :class="{ 'input--error': $v.title.$error}">
                                <b-form-input id="title" v-model="title" type="text"></b-form-input>
                                <div class="error-message-l" v-show="$v.title.$error">
                                    <div v-if="!$v.title.required">Prosím, vyplňte názov projektu.</div>
                                    <div v-else-if="!$v.title.minLength">Zadajte minimálne {{ $v.title.$params.minLength.min }} znakov.</div>
                                    <div v-else-if="!$v.title.maxLength">Zadajte maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                                </div>
                            </b-form-group>

                            <div v-if="demand_state !== 3" class="select-wrapper" :class="{ 'input--error': $v.place.$error}">
                                <div class="multiselect-title">Miesto realizácie projektu*</div>
                                <treeselect
                                    v-if="placeLoaded"
                                    id="multiselect_place"
                                    :multiple="false"
                                    :async="true"
                                    v-model="place"
                                    :defaultOptions="options"
                                    :load-options="placeAutoComplete"
                                    placeholder="Mesto.."
                                    searchPromptText="Zadajte mesto..."
                                    loadingText="Načítavanie..."
                                    noResultsText="Žiadna zhoda"
                                />
<!--                                    noOptionsText="Žiadna zhoda"-->
<!--                                    noResultsText="Nenašli sa žiadne položky."-->
<!--                                    retryText="Chcete obnoviť vyhľadávanie?"-->
<!--                                    retryTitle="Nenašli sa žiadne položky."-->

                                <div class="error-message-l" v-show="$v.place.$error">
                                    <div v-if="!$v.place.required">Prosím, vyplňte miesto realizácie projektu.</div>
                                </div>
                                <span class="description">Zadajte miesto realizácie projektu.</span>
                            </div>

                            <div v-if="demand_state !== 3" class="select-wrapper" :class="{ 'input--error': $v.category.$error}">
                                <div class="multiselect-title">Kategória*</div>
                                <treeselect
                                    id="multiselect_category"
                                    v-model="category"
                                    :multiple="false"
                                    :options="categories"
                                    :show-count="true"
                                    :default-expand-level="1"
                                    placeholder="Vyberte kategóriu"
                                />
                                <div class="error-message-l" v-show="$v.category.$error">
                                    <div v-if="!$v.category.required">Prosím, zvoľte kategóriu.</div>
                                </div>
                                <span class="description">Zadajte kategóriu projektu.</span>
                            </div>

                            <b-row v-if="demand_state !== 3 && parameters.length > 0" class="category-parameters">
                                <b-col cols="10" offset="1" class="parameter-info">
                                    <strong>Doplnkové parametre dopytu:</strong>
                                    <hr>
                                </b-col>
                                <b-col cols="12" v-for="param in parameters" :key="param.id">
                                    <b-row class="parameter">
                                        <b-col cols="4" class="label">
                                            {{ param.label }}
                                            <span v-if="param.required === 1">(povinný parameter)</span>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-form-input
                                                :id="'input-parameter-' + param.id"
                                                :value="param.value"
                                                @input="setParameter($event, param.id)"
                                                type="text"
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12">
                                    <b-spinner v-if="spinner_parameter" type="grow" label="Spinning"></b-spinner>
                                </b-col>
                            </b-row>

                            <b-form-group v-if="demand_state !== 3" label="Realizovať do*" label-for="date" description="Termín dokončenia projektu." :class="{ 'input--error': $v.expected_date.$error}">
                                <b-form-input id="date" v-model="expected_date" type="date"></b-form-input>
                                <div class="error-message-l" v-show="$v.expected_date.$error">
                                    <div v-if="!$v.expected_date.required">Prosím zadajte očakávaný dátum realizácie projektu.</div>
                                </div>
                            </b-form-group>

                            <b-form-group v-if="demand_state !== 3" label="Cena" label-for="price" description="Celková odhadovaná cena projektu v €." :class="{ 'input--error': $v.amount.$error}">
                                <b-form-input id="price" v-model="amount" type="number"></b-form-input>
                                <div class="error-message-l" v-show="$v.amount.$error">
                                    <div v-if="!$v.amount.decimal">Prosím zadajte prosim iba číslice.</div>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <b-form-group label="Podrobný popis projektu" label-for="blog_text" :class="{ 'input--error': $v.project_description.$error}">
                                <editor
                                    api-key="jjefnn9guq2zzqkbuveerq7szs3epzfaps5ptm6i1lcf5vog"
                                    v-model="project_description"
                                    :init="{
									height: 500,
									language: 'sk',
									menubar: true,
									plugins: [
                                        'fullscreen lists charmap print preview wordcount help'
									],
									toolbar:
										'undo redo | formatselect | bold italic | \
										alignleft aligncenter alignright alignjustify | \
										bullist numlist outdent indent | removeformat | help'
								}" />

                                <div class="error-message-l" v-show="$v.project_description.$error">
                                    <div v-if="!$v.project_description.required">Prosím, vyplňte popis projektu.</div>
                                    <div v-else-if="!$v.project_description.minLength">Zadajte minimálne {{ $v.project_description.$params.minLength.min }} znakov.</div>
                                    <div v-else-if="!$v.project_description.maxLength">Zadajte maximálne {{ $v.project_description.$params.maxLength.max }} znakov.</div>
                                </div>

                                <template slot="description">
                                    Detailný popis Vášho projektu.
                                </template>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <ImagesUploader
                                :old_images="old_images"
                                :images="images"
                                @delete-image="deleteImage($event)"
                                @delete-new-image="deleteNewImage($event)"
                                @upload-images="onImagesChange($event)"
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" md="6" offset-md="3">
<!--                            <div class="file-name">-->
<!--                                <span v-for="(file, index) in files" :key="index">-->
<!--                                    <font-awesome-icon icon="trash-alt" @click="deleteAttachments(index)"></font-awesome-icon>-->
<!--                                    {{ file.name }}-->
<!--                                </span>-->
<!--                            </div>-->
                            <b-form-group
                                label="Prílohy"
                                label-for="input_files"
                                description='Maximálna povolená veľkosť súborov je 5MB.'
                            >
                                <b-input-group>
                                    <b-form-file
                                        id="input_files"
                                        class="with-append"
                                        placeholder=""
                                        drop-placeholder="Nahrať súbor"
                                        browse-text="Nahrať súbor"
                                        multiple
                                        accept=".pdf"
                                        @change="onFileChange"
                                    ></b-form-file>
                                </b-input-group>
                            </b-form-group>

                            <div v-if="additions.length > 0" class="attachments-list">
                                <p><strong>Uložené prílohy:</strong></p>
                                <div class="attachment" v-for="(file, index) in additions" :key="index">
                                    <font-awesome-icon class="attachment-paperclip" icon="paperclip"></font-awesome-icon>
                                    <a :href="asset_url + '/' + file.path" target="_blank" class="attachment-name">
                                        <span class="attachment-name">{{ file.attachment_name }}</span>
                                    </a>
                                    <font-awesome-icon class="attachments-delete" @click="deleteAttachment(file.id)" icon="trash-alt"></font-awesome-icon>
                                </div>
                            </div>
                            <div v-if="files.length > 0" class="attachments-list">
                                <p><strong>Novo pridané prílohy:</strong></p>
                                <div class="attachment" v-for="(file, index) in files" :key="index">
                                    <font-awesome-icon class="attachment-paperclip" icon="paperclip"></font-awesome-icon>
                                    <span class="attachment-name">{{ file.name }}</span>
                                    <font-awesome-icon class="attachments-delete" @click="deleteNewAttachment(index)" icon="trash-alt"></font-awesome-icon>
                                </div>
                                <div v-if="files.length > maxFileLength" class="error-message-l">Maximálny počet príloh: <strong>maxFileLength</strong>.</div>
                            </div>
                        </b-col>

                        <b-col cols="12" md="6" offset-md="3" class="video-links">
                            <div v-for="(link, index) in video_links" :key="index">
                                <DemandVideoLink :link.sync="link.link" :index="index" :ref="'input_link_' + index" />
                                <font-awesome-icon v-if="index < 2" @click="addVideoLink()" class="add-button" icon="plus-circle"></font-awesome-icon>
                                <font-awesome-icon v-if="index > 0" @click="deleteVideoLink(index)" icon="minus-circle"></font-awesome-icon>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row v-if="demand_state !== 3" class="send-demand-buttons">
                        <b-col cols="10" offset="1" lg="4" offset-lg="2">
                            <b-button @click="postUpdateDemand(1)" variant="primary" pill>
                                <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                                Uložiť dopyt
                            </b-button>
                        </b-col>
                        <b-col cols="10" offset="1" lg="4" offset-lg="0">
                            <b-button v-b-modal.modal-1 variant="secondary" pill>
                                <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                Zverejniť dopyt
                            </b-button>

                            <b-modal id="modal-1" title="Zverejniť dopyt?">
                                <b-container fluid>
                                    <p class="my-4">Chystáte sa zverejniť dopyt. Po jeho zverejnení, nebude možné meniť niektoré polia. Pokračovať?</p>
                                </b-container>
                                <template #modal-footer>
                                    <b-button variant="primary" @click="hideConfirmModal()" pill>
                                        <font-awesome-icon class="btn-icon" icon="marker"></font-awesome-icon>
                                        Zrušiť
                                    </b-button>
                                    <b-button variant="secondary" @click="postUpdateDemand(3)" pill>
                                        <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                        Zverejniť dopyt
                                    </b-button>
                                </template>
                            </b-modal>
                        </b-col>
                        <b-col cols="12" v-if="errors" class="mt-2 error-back">
                            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
                        </b-col>
                    </b-row>
                    <b-row v-else class="send-demand-buttons">
                        <b-col cols="10" offset="1" md="4" offset-md="4">
                            <b-button @click="postUpdateDemand(3)" variant="secondary" pill>
                                <font-awesome-icon icon="save"></font-awesome-icon>
                                Uložiť dopyt
                            </b-button>
                        </b-col>
                    </b-row>

                </b-form>
            </div>
        </div>


        <img class="geometric-1" :src="geometric_1" alt="svg">

        <Footer />

    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import Editor from '@tinymce/tinymce-vue';

import MainMenu from "@/components/MainMenu";
import NotLoginUser from "@/components/NotLoginUser";
import DemandVideoLink from "@/components/demands/single/DemandVideoLink";
import Footer from "@/components/Footer";

import geometric_1 from "@/assets/geometric_1.svg";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import { required, minLength, maxLength, decimal } from 'vuelidate/lib/validators'
import CreateUpdateHead from "../../components/form_components/CreateUpdateHead";
import ImagesUploader from "../../components/ImagesUploader";

export default {
    components: {
        ImagesUploader,
        CreateUpdateHead,
        MainMenu,
        NotLoginUser,
        Footer,
        Editor,
        Treeselect,
        DemandVideoLink
    },
    data() {
        return {
            geometric_1: geometric_1,
            asset_url: null,

            demand_id: '',
            demand_state: '',
            demand_slug: '',
            title: '',
            placeLoaded: false,
            place: null,
            options: [],

            categoryLoaded: false,
            categories: [],
            category: [],

            parameters: [],
            parameter: [],
            parameterID: [],
            spinner_parameter: false,
            expected_date: '',
            amount: '',
            project_description: '',

            old_images: null,
            images: [],
            new_images: [],

            files: [],
            maxFileLength: 8,
            additions: [],
            deleteAdditionsId: [],

            video_links: [
                { link: '' }
            ],
            errors: {},
        }
    },
    watch: {
        category: function () {
            if (this.categoryLoaded) this.getParameters()
        },
        old_images: function () {
            this.loadAllImagesLength()
        },
        images: function () {
            this.loadAllImagesLength()
        },
        new_images: function () {
            this.loadAllImagesLength()
        },
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        this.getDemand();
        this.getCategory();
    },
    methods: {
        loadAllImagesLength() {
            if (this.old_images !== null) {
                this.images_length = this.old_images.length + this.images.length
            } else {
                this.images_length = this.images.length
            }
        },
        hideConfirmModal() {
            this.$bvModal.hide('modal-1')
        },
        setOldParameters(params) {
            let paramsLength = Object.keys(params).length
            let paramsID = Object.keys(params)
            let parametersNew = []
            let paramsIDs = []

            for (let i = 0; i < paramsLength; i++) {
                console.log(params[parseInt(paramsID[i])])
                paramsIDs.push(params[parseInt(paramsID[i])].id)
                let newParam = {}
                newParam.id = params[parseInt(paramsID[i])].id
                newParam.label = params[parseInt(paramsID[i])].param
                newParam.required = params[parseInt(paramsID[i])].required
                if (params[parseInt(paramsID[i])].value) newParam.value = params[parseInt(paramsID[i])].value

                parametersNew.push(newParam)
            }
            this.parameterID = paramsIDs
            return parametersNew
        },
        getDemand() {
            axios.get(config.API_URL
                + '/demand/show/' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('demand update', response.data.success);
                    let demand = response.data.success;

                    this.demand_id = demand.id
                    this.demand_state = demand.state_id
                    this.demand_slug = demand.slug
                    this.title = demand.title
                    this.expected_date = demand.expected_date.slice(0, 10)
                    this.amount = demand.amount
                    this.project_description = demand.project_description

                    if (demand.video_links.length > 0) {
                        let newLinks = []
                        demand.video_links.forEach(el => {
                            let newLink = {}
                            newLink.link = el.link
                            newLinks.push(newLink)
                        })
                        this.video_links = newLinks
                    }

                    this.additions = demand.additions

                    let oldPlace = {}
                    oldPlace.id = demand.place_id
                    oldPlace.label = demand.place
                    oldPlace.children = null
                    this.options.push(oldPlace)
                    this.place = oldPlace.id

                    this.parameters = this.setOldParameters(demand.params)
                    demand.params.forEach(el => {
                        if (el.value) this.setParameter(el.value, el.id)
                    })

                    let categories = []
                    demand.categories.forEach(el => {
                        categories.push(el.category_id)
                    })
                    this.category = categories
                    this.placeLoaded = true

                    if (demand.images) {
                        this.old_images = demand.images
                    }

                    this.$nextTick(() => {
                        this.categoryLoaded = true
                        this.options = []
                    })
                }, (error) => {
                    console.warn(error);
                })
        },
        addVideoLink() {
            this.video_links.push({ link: '' })
        },
        deleteVideoLink(index) {
            this.video_links.splice(index, 1)
        },
        loopCategory(response) {
            let categories = response
            let categoriesLength = Object.keys(categories).length
            let categoriesList = []

            for (let i = 0; i < categoriesLength; i++) {
                let newCategory = {}
                newCategory.label = categories[i].name
                newCategory.id = categories[i].id

                if (categories[i].children.length > 0) {
                    newCategory.children = this.loopCategory(categories[i].children)
                }
                categoriesList.push(newCategory)
            }
            return categoriesList
        },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=3', config.HEADERS)
                .then((response) => {
                    this.categories = this.loopCategory(response.data.success)
                    this.category_spinner = true
                }, (error) => {
                    console.warn(error)
                });
        },
        getParameters() {
            if (this.category == null || this.category.length === 0) {
                this.parameters = []
                this.parameter = []
            } else {
                this.spinner_parameter = true;
                this.parameters = []
                axios.get(config.API_URL + '/demand/getParamList'
                    + '?category_id=' + '[' + this.category + ']', config.HEADERS)
                    .then((response) => {
                        console.log('getParameters', response.data.success)
                        this.spinner_parameter = false;
                        this.parameters = this.setOldParameters(response.data.success)
                    }, (error) => {
                        console.warn(error)
                        this.spinner_parameter = false;
                    });
            }
        },
        setParameter($event, id) {
            this.parameter['value_' + id] = $event
        },
        onFileChange(event) {
            console.log('onFileChange', event)
            let newFiles = event.target.files
            newFiles.forEach(file => {
                this.files.push(file);
            })
        },
        placeAutoComplete({ searchQuery, callback  }) {
            axios.get(config.API_URL + '/placeAutoComplite'
                + '?input=' + searchQuery, config.HEADERS)
                .then((response) => {
                    console.log('towns', response.data.predictions)
                    let towns = response.data.predictions
                    let options = []
                    towns.forEach(el => {
                        let newTown = {}
                        newTown.id = el.place_id
                        newTown.label = el.description
                        options.push(newTown)
                    })
                    callback(null, options)
                }, (error) => {
                    console.warn(error)
                });
        },
        postUpdateDemand(status) {
            this.hideConfirmModal()
            let video_link_error = false
            this.error = {}
            if (this.files.length > this.maxFileLength) return false

            this.video_links.forEach((el, index) => {
                let ref = 'input_link_' + index
                this.$refs[ref][0].$v.$touch()
                if (this.$refs[ref][0].$v.$invalid) {
                    video_link_error = true
                }
            })

            if (this.demand_state !== 3) {
                this.$v.$touch()
            } else {
                this.$v.project_description.$touch()
            }
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }
            if (video_link_error) return false

            const myData = new FormData();

            if (this.demand_state !== 3) {
                myData.set('title', this.title);
                myData.set('place_id', this.place);
                myData.set('state_id', status);
                myData.set('category', '[' + this.category + ']');
                myData.set('expected_date', this.expected_date);
                if(this.amount) myData.set('amount', this.amount);
                let paramLength = Object.keys(this.parameter).length
                let paramKeys = Object.keys(this.parameter)
                this.parameterID.forEach(el => {
                    let newPar = 'value_' + el
                    for (let i = 0; i < paramLength; i++) {
                        if (newPar === paramKeys[i] && this.parameter[paramKeys[i]] !== '') {
                            myData.set(paramKeys[i], this.parameter[paramKeys[i]]);
                        }
                    }
                })
            }

            myData.set('project_description', this.project_description);
            if(this.deleteAdditionsId.length > 0) myData.set('file_ids', '[' +  this.deleteAdditionsId + ']');
            console.log('deleteAdditionsId', this.deleteAdditionsId)
            this.files.forEach((el, index) => {
                myData.set('file[' + [index] + ']', el);
                myData.set('attachment_name[' + [index] + ']', el.name);
            })

            this.video_links.forEach((el, index) => {
                if (el.link.length > 0) myData.set('link[' + [index] + ']', el.link);
            })

            for (let pair of myData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            let demandConfig = config.HEADERS;
            demandConfig.HEADERS = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${this.$store.getters.user.access_token.token}`
                }
            };

            axios.post(config.API_URL + '/demand/update/' + this.demand_slug, myData, demandConfig)
                .then((response) => {
                    console.log('update demand', response);
                    this.$router.push({ name: 'DemandDetail', params: { slug: response.data.success.slug }})
                }, (error) => {
                    console.log(error.response.data.error)
                    this.errors = this.$errorMessage(error.response)
                });
        },
        deleteNewAttachment(index) {
            this.files.splice(index, 1)
        },
        deleteAttachment(attachment_id) {
            console.log(attachment_id)
            let removeIndex = this.additions.findIndex(item => item.id === attachment_id);
            this.deleteAdditionsId.push(this.additions[removeIndex].id)
            this.additions.splice(removeIndex, 1);
        },
        onImagesChange(event) {
            let files = event.target.files
            let self = this;
            files.forEach(img => {
                self.new_images.push(img)
                this.setImage(img, (data) => { self.images.push(data) })
            })
        },
        deleteImage(image_id) {
            this.old_images = this.old_images.filter(item => item.id !== image_id)
        },
        deleteNewImage(index) {
            this.images.splice(index, 1)
            this.new_images.splice(index, 1)
        },
    },
    validations: {
        title: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(255)
        },
        place: {
            required,
        },
        category: {
            required,
        },
        amount: {
            decimal
        },
        expected_date: {
            required,
        },
        project_description: {
            required,
            minLength: minLength(20),
            maxLength: maxLength(20000)
        }
    },
}
</script>

<style lang="scss" scoped>
.page-update-demand::v-deep {
    margin: 0 auto;

    .main-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .no-data {
            height: 100vh;
        }

        .select-wrapper {
            margin-bottom: 45px;

            .multiselect-title {
                float: left;
                margin-left: 20px;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 8px;
            }

            #multiselect_place {
                .vue-treeselect__control-arrow-container {
                    display: none;
                }
            }

            .vue-treeselect__control {
                background-color: #DCDEE6;
                padding: 15px;
                border-radius: 30px;
                outline: none;
                border: 0;
                box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
            }

            .vue-treeselect__input {
                font-size: 16px !important;
            }

            .vue-treeselect__placeholder, .vue-treeselect__single-value {
                line-height: inherit;
                //display: flex;
                //align-items: center;
            }

            .vue-treeselect__multi-value {
                margin-bottom: 0px;
            }

            .vue-treeselect__multi-value-item {
                background-color: $primary;
                border-radius: 10px;
                color: white;
                padding: 2px 0px 2px 5px;

                .vue-treeselect__icon {
                    svg {
                        position: relative;
                        top: 0;
                        right: 0;
                        transform: translate(0, 0);
                        color: white;

                        &:hover {
                            color: $secondary;
                        }
                    }

                }
            }

            .vue-treeselect__x-container {
                svg  {
                    color: $primary;

                    &:hover {
                        color: $secondary;
                    }
                }
            }

            .vue-treeselect__control-arrow-container {
                svg {
                    color: $primary
                }
            }

            span.description {
                color: #6c757d !important;
                float: left;
                padding-left: 20px;
                font-size: 13px;
                margin-top: 4px;
            }
        }

        .category-parameters {
            margin-bottom: 20px;

            .parameter-info {
                text-align: left;
                strong {
                    font-size: 14px;
                }

                hr {
                    margin-top: 0;
                }
            }

            .parameter {
                margin-bottom: 10px;

                .label {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    span {
                        position: absolute;
                        display: block;
                        color: red;
                        font-size: 12px;
                        margin-top: 31px;
                    }
                }

                input {
                    border-radius: 15px;
                }
            }
        }

        //.file-name {
        //    display: flex;
        //    flex-direction: column;
        //    align-items: flex-start;
        //    border-bottom: 1px solid $primary;
        //
        //    span {
        //        margin: -1px 10px 0 20px;
        //    }
        //}

        form {
            margin-top: 40px;
            color: $primary;

            .form-group {
                margin-bottom: 30px;
                text-align: left;

                label {
                    text-align: left;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 600;
                }

                input {
                    color: $primary;
                    border-radius: 30px;
                    background: $input-bg;
                    border: none;
                    padding: 30px 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                    &::placeholder {
                        color: $primary;
                    }

                    &.with-append {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                small {
                    text-align: left;
                    padding-left: 20px;
                }

                textarea {
                    position: relative;
                    font-size: 14px;
                    border-radius: 20px;
                    background: $input-bg;
                    border: none;
                    padding: 15px 15px;
                    box-shadow: 0 7px 20px rgba(0,0,0,.2);
                    min-height: 200px;
                    max-height: 500px;

                    &::placeholder {
                        color: $primary;
                    }

                    &:focus {
                        &::placeholder {
                            opacity: 0;
                        }
                    }
                }

                .b-form-file {
                    height: auto;

                    label {
                        border-radius: 50px;
                        background-color: #dcdee6;
                        border: none;
                        padding: 20px 20px;
                        height: auto;
                        box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                        .form-file-text {
                            min-height: 21px;
                            white-space: nowrap;
                        }

                        &:after {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            height: 100%;
                            line-height: unset;
                            background: $primary;
                            color: white;
                            font-weight: 400;
                            padding: 10px 30px;
                        }
                    }
                }
            }

            .attachments-list {
                display: flex;
                flex-direction: column;
                text-align: left;
                margin-bottom: 30px;
                position: relative;
                border-bottom: 1px solid $primary;

                .attachment {
                    display: flex;

                    .attachment-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                    }

                    .attachment-paperclip {
                        margin-right: 5px;
                        margin-top: 2px;
                        float: left;
                    }

                    .attachments-delete {
                        margin-top: 2px;
                        float: right;
                        cursor: pointer;
                    }
                }
            }

            .video-links {
                > :nth-child(2) {
                    .add-button {
                        margin-right: 10px;
                    }
                }
                .form-group {
                    margin-bottom: 0;
                }

                svg {
                    cursor: pointer;
                }
            }

            .send-demand-buttons {
                margin-top: 50px;

                button {
                    margin-top: 20px;
                    width: 100%;
                }
            }
        }
    }

    .geometric-1 {
        position: absolute;
        top: 400px;
        left: 20px;
        transform: scaleX(-1);
        width: 760px;
        max-width: 90vw;
        z-index: -1;
        opacity: 0.5;
    }
}
</style>