<template>
    <div class="page-forum-update">
        <MainMenu />

        <div class="top-wrapper">

            <div class="container">
                <b-button class="btn-back" @click="$router.push({ name: 'ForumDetail', params: { slug: $route.params.slug}}, () => {})" variant="link">
                    <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                    Späť na fórum
                </b-button>

                <h1 class="title">Upraviť otázku</h1>
            </div>

            <hr>
        </div>

        <div class="main-wrapper">
            <NotLoginUser v-if="$store.getters.user === ''" :text="'Ak chcete pridať otázku, musíte sa prihlásiť.'" />

            <div class="container">

                <b-form>
                    <b-row no-gutters>
                        <b-col cols="12" md="6" offset-md="3">

                            <b-form-group label="Nadpis*" label-for="title" description="Stručný popis témy.">
                                <b-form-input id="title" v-model="title" type="text"></b-form-input>
                                <div class="error-message-l" v-show="$v.title.$error">
                                    <div v-if="!$v.title.required">Prosím, vyplňte nadpis príspevku.</div>
                                    <div v-else-if="!$v.title.minLength">Nadpis musí mať aspoň {{ $v.title.$params.minLength.min }} znakov.</div>
                                    <div v-else-if="!$v.title.maxLength">Nadpis môže obsahovať maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                                </div>
                            </b-form-group>

                            <div class="select-wrapper">
                                <div class="category-title">Kategória*</div>
                                <treeselect
                                    v-model="category"
                                    :multiple="true"
                                    :options="categories"
                                    :show-count="true"
                                    :default-expand-level="1"
                                    placeholder="Vyberte kategóriu"
                                />
                                <div class="error-message-l" v-show="$v.category.$error">
                                    <div v-if="!$v.category.required">Prosím, zvoľte kategóriu.</div>
                                </div>
                                <span class="description">Ak pre danú kategóriu a podkategóriu existujú</span>
                            </div>

                            <!--                            <b-form-group-->
                            <!--                                label="Hlavný obrázok"-->
                            <!--                                label-for="main_image"-->
                            <!--                                description='Maximálna povolená veľkosť súborov je 10MB. Súbory s príponami exe, js, php, sql, vbs, html sú blokované.'-->
                            <!--                            >-->
                            <!--                                <b-input-group>-->
                            <!--                                    <b-form-file-->
                            <!--                                        id="main_image"-->
                            <!--                                        class="with-append"-->
                            <!--                                        accept="images/jpeg, images/png"-->
                            <!--                                        placeholder=""-->
                            <!--                                        drop-placeholder="Nahrať obrázok"-->
                            <!--                                        browse-text="Nahrať obrázok"-->
                            <!--                                        @change="onMainImageChange"-->
                            <!--                                    ></b-form-file>-->
                            <!--                                </b-input-group>-->
                            <!--                            </b-form-group>-->

                        </b-col>
                    </b-row>

                    <!--                    <b-row>-->
                    <!--                        <b-col cols="12" md="6" offset-md="3">-->
                    <!--                            <b-form-group-->
                    <!--                                label="Obrázky"-->
                    <!--                                label-for="images"-->
                    <!--                                description='Maximálna povolená veľkosť súborov je 10MB. Súbory s príponami exe, js, php, sql, vbs, html sú blokované.'-->
                    <!--                            >-->
                    <!--                                <b-input-group>-->
                    <!--                                    <b-form-file-->
                    <!--                                        id="images"-->
                    <!--                                        class="with-append"-->
                    <!--                                        accept="images/jpeg, images/png"-->
                    <!--                                        placeholder=""-->
                    <!--                                        drop-placeholder="Nahrať obrázky"-->
                    <!--                                        browse-text="Nahrať obrázky"-->
                    <!--                                        multiple-->
                    <!--                                        @change="onImagesChange"-->
                    <!--                                    ></b-form-file>-->
                    <!--                                </b-input-group>-->
                    <!--                            </b-form-group>-->
                    <!--                        </b-col>-->
                    <!--                    </b-row>-->

                    <!-- <b-row>
                        <b-col cols="12" md="8" offset-md="2">
                            <b-form-group label="Podrobný popis" label-for="detailed_description" description="popíšte detailne váš projekt, správny popis pomôže nájsť správneho dodávateľa">
                                <b-form-textarea
                                    id="detailed_description"
                                    v-model="text"
                                    placeholder="..."
                                    size="lg"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row> -->

                    <b-row>
                        <b-col cols="12" md="10" offset-md="1">
                            <b-form-group label="Text*" label-for="blog_text">
                                <editor
                                    api-key="jjefnn9guq2zzqkbuveerq7szs3epzfaps5ptm6i1lcf5vog"
                                    v-model="text"
                                    :init="{
									height: 500,
									language: 'sk',
									menubar: true,
									plugins: [
                                        'fullscreen lists charmap print preview wordcount help'
									],
									toolbar:
										'undo redo | formatselect | bold italic | \
										alignleft aligncenter alignright alignjustify | \
										bullist numlist outdent indent | removeformat | help'
								}" />
                                <div class="error-message-l" v-show="$v.text.$error">
                                    <div v-if="!$v.text.required"> Prosím, vyplňte text príspevku.</div>
                                    <div v-if="!$v.text.minLength">Nadpis musí mať aspoň {{ $v.text.$params.minLength.min }} znakov.</div>
                                    <div v-if="!$v.text.maxLength">Text nemôže obsahovať viac ako {{ $v.text.$params.maxLength.max }} znakov.</div>
                                </div>
                                <template slot="description">
                                    Sem vložte text pre Váš článok
                                </template>
                            </b-form-group>


                            <!--                            <b-form-group id="checkbox_vop_wrapper" v-slot="{ ariaDescribedby }">-->
                            <!--								<b-form-checkbox-group-->
                            <!--									id="checkbox_vop"-->
                            <!--									:aria-describedby="ariaDescribedby"-->
                            <!--								>-->
                            <!--									<b-form-checkbox>Súhlasím s <a href="#">Všeobecnými obchodnými podmienkami</a></b-form-checkbox>-->
                            <!--								</b-form-checkbox-group>-->
                            <!--							</b-form-group>-->
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <div class="image-gallery">
                                <div v-for="image in old_images" :key="image.id" @click="deleteImage(image.id)">
                                    <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                                    <img :src="image.url" />
                                </div>

                                <div v-for="(image, index) in images" :key="index" @click="deleteNewImage(index)">
                                    <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                                    <img :src="image" />
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div v-show="images_length > 5">
                                <span class="gallery-warn">Nemôžte pridať viac ako 5 obrázkov.</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" lg="6" offset-lg="3" v-if="images_length < 5">
                            <b-form-group label="Obrázky v galérii" label-for="blog_gallery_images">

                                <b-input-group>
                                    <b-form-file
                                        id="blog_gallery_images"
                                        class="with-append"
                                        accept="images/jpeg, images/png"
                                        placeholder=""
                                        drop-placeholder="Nahrať súbor"
                                        browse-text="Nahrať súbor"
                                        multiple
                                        @change="onImagesChange"
                                    ></b-form-file>
                                </b-input-group>
                                <template slot="description">
                                    Podporované súbory: jpg, png v max. velkosti 5 MB
                                </template>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="send-forum-buttons">
                        <b-col v-if="!loader_forumnew" cols="8" offset="2" lg="4" offset-lg="2" class="text-right mt-3">
                            <b-button pill @click="postForum(0)" variant="primary">Uložiť otázku</b-button>
                        </b-col>
                        <b-col v-if="!loader_forumnew" cols="8" offset="2" lg="4" offset-lg="0" class="text-left mt-3">
                            <b-button pill @click="postForum(1)" variant="primary">Publikovať otázku</b-button>
                        </b-col>
                        <b-col cols="12"><b-spinner v-if="loader_forumnew" type="grow" label="Spinning" class="mt-2"></b-spinner></b-col>
                    </b-row>

                </b-form>
            </div>
        </div>


        <img class="geometric-1" :src="geometric_1" alt="svg">

        <Footer />

    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import NotLoginUser from "@/components/NotLoginUser";
import Footer from "@/components/Footer";

import Editor from '@tinymce/tinymce-vue';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import geometric_1 from "@/assets/geometric_1.svg";
import {required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    components: {
        MainMenu,
        NotLoginUser,
        Editor,
        Footer,
        Treeselect,
    },
    metaInfo() {
        return {
            title: 'Upraviť otázky | ',
        }
    },
    data() {
        return {
            geometric_1: geometric_1,

            title: '',
            categories: [],
            category: null,
            text: '',

            images: [],
            old_images: null,
            images_length: null,

            loader_forumnew: false,
        }
    },
    watch: {
        old_images: function () {
            this.images_length = this.old_images.length + this.images.length
        },
        images: function () {
            this.images_length = this.old_images.length + this.images.length
        },
    },
    mounted() {
        this.getForum()
        this.getCategory()
    },
    methods: {
        getJobSelectParent() {
            return document.querySelector('.sign-form');
        },
        handleImages(files) {
            this.images = files
        },
        setImage(image, func) {
            const reader = new FileReader();
            reader.onload = () => {
                func(reader.result)
            }
            reader.readAsDataURL(image);
        },
        onImagesChange(event) {
            let files = event.target.files
            let self = this;
            files.forEach(img => {
                this.setImage(img, (data) => { self.images.push(data) })
            })
        },
        getForum() {
            axios.get(config.API_URL
                + '/forum/' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('toto je upravit forum:', response.data.success);
                    let data = response.data.success

                    if (data[1] !== 1) {
                        let newImages = []
                        data[1].forEach((img, index) => {
                            let imageItem = {}
                            imageItem.id = data[0].image[index]
                            imageItem.url = data[1][index]
                            newImages.push(imageItem)
                        })
                        this.old_images = newImages
                    }

                    this.title = data[0].title
                    const myArr = data[0].category
                    let filtered = myArr.replace(/\D+/g, ' ').trim().split(' ').map(e => parseInt(e));
                    this.category = filtered
                    this.text = data[0].text
                }, (error) => {
                    console.warn(error);
                });
        },

        onMainImageChange(event) {
            this.main_picture = event.target.files[0];
        },
        // onImagesChange(event) {
        //     this.images = event.target.files;
        // },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=2', config.HEADERS)
                .then((response) => {
                    // console.log('cate', response.data.success)

                    let categories = response.data.success
                    let categoriesLength = Object.keys(categories).length
                    let categoriesList = []

                    for (let i = 0; i < categoriesLength; i++) {
                        const newCategory = {}
                        newCategory.label = categories[i].name
                        newCategory.id = categories[i].id

                        if (categories[i].children[0]) {
                            let categoriesChildren = categories[i].children
                            let categoriesChildrenLength = Object.keys(categoriesChildren).length
                            let categoriesChildList = []

                            for (let i = 0; i < categoriesChildrenLength; i++) {
                                const newCategoryChild = {}
                                newCategoryChild.id = categoriesChildren[i].id
                                newCategoryChild.label = categoriesChildren[i].name

                                categoriesChildList.push(newCategoryChild)
                            }
                            newCategory.children = categoriesChildList
                        }
                        categoriesList.push(newCategory)
                    }
                    // console.log('cateLISTI', categoriesList)
                    this.categories = categoriesList
                }, (error) => {
                    console.warn(error);
                });
        },
        postForum(status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            // this.loader_forumnew = true

            const myData = new FormData();
            myData.set('text', this.text);
            myData.set('title', this.title);
            myData.set('category', '[' + this.category + ']');
            myData.set('status', status);

            if (this.old_images.length > 0) {
                let oldImagesID = []
                this.old_images.forEach(element => {
                    oldImagesID.push(element.id)
                });
                // myData.set('image' + '[' + index + ']', element)
                myData.set('image', oldImagesID)
            }
            if (this.images.length > 0) {
                this.images.forEach((element, index) => {
                    myData.set('image' + '[' + index + ']', element)
                    // console.log('new', element.id, index)
                });
            }

            let forumConfig = config.HEADERS;
            forumConfig.HEADERS = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${this.$store.getters.user.access_token.token}`
                }
            };
            for (let pair of myData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            axios.post(config.API_URL + '/forum', myData, forumConfig)
                .then((response) => {
                    console.log(response.data.success);
                    this.loader_forumnew = false
                    this.$router.push({ name: 'ForumDetail', params: { slug: response.data.success.data[1].id }})
                }, (error) => {
                    console.warn(error);
                });

        },
        deleteImage(index) {
            this.old_images = this.old_images.filter(item => item.id !== index)
        },
        deleteNewImage(index) {
            this.images.splice(index, 1)
        },
    },
    validations: {
        title: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(160)
        },
        category: {
            required,
        },
        text: {
            required,
            minLength: minLength(20),
            maxLength: maxLength(1800)
        },
    },
}
</script>

<style lang="scss" scoped>
.page-forum-update::v-deep {
    margin: 0 auto;

    .top-wrapper {
        .container {
            display: flex;
            flex-direction: row;

            .btn-link {
                box-shadow: none;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            .title {
                margin-bottom: 0;
                line-height: 46px;
                font-size: 20px !important;
                color: $secondary;
                display: block;

                @media (min-width: 768px) {
                    margin: 0 44% 0 auto;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        hr {
            border-color: $secondary;
        }
    }

    .main-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .select-wrapper {
            margin-bottom: 45px;
            font-weight: normal !important;

            .category-title {
                float: left;
                margin-left: 20px;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 8px;
            }

            label {
                padding-left: 0;
                font-weight: normal;
            }

            .vue-treeselect__control {
                background-color: #DCDEE6;
                padding: 15px;
                min-height: 60px;
                border-radius: 30px;
                outline: none;
                border: 0;
                box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
            }

            .vue-treeselect__input {
                font-size: 16px !important;
            }

            .vue-treeselect__placeholder {
                margin-top: -3px;
            }

            .vue-treeselect__multi-value {
                margin-bottom: 0px;
            }

            .vue-treeselect__multi-value-item {
                background-color: $primary;
                border-radius: 10px;
                color: white;
                padding: 2px 0px 2px 5px;

                .vue-treeselect__icon {
                    svg {
                        position: relative;
                        top: 0;
                        right: 0;
                        transform: translate(0, 0);
                        color: white;

                        &:hover {
                            color: $secondary;
                        }
                    }

                }
            }

            .vue-treeselect__x-container {
                svg  {
                    color: $primary;

                    &:hover {
                        color: $secondary;
                    }
                }
            }

            .vue-treeselect__control-arrow-container {
                svg {
                    color: $primary
                }
            }

            span.description {
                color: #6c757d !important;
                float: left;
                padding-left: 20px;
                font-size: 13px;
                margin-top: 4px;
            }
        }

        form {
            margin-top: 40px;
            color: $primary;

            .form-group {
                margin-bottom: 30px;
                text-align: left;

                label {
                    text-align: left;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 600;
                }

                input {
                    color: $primary;
                    border-radius: 30px;
                    background: $input-bg;
                    border: none;
                    padding: 30px 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                    &::placeholder {
                        color: $primary;
                    }

                    &.with-append {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                small {
                    text-align: left;
                    padding-left: 20px;
                }

                textarea {
                    position: relative;
                    font-size: 14px;
                    border-radius: 20px;
                    background: $input-bg;
                    border: none;
                    padding: 15px 15px;
                    box-shadow: 0 7px 20px rgba(0,0,0,.2);
                    min-height: 200px;
                    max-height: 500px;

                    &::placeholder {
                        color: $primary;
                    }

                    &:focus {
                        &::placeholder {
                            opacity: 0;
                        }
                    }
                }

                .tox-tinymce {
                    border-radius: 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
                }

                .b-form-file {
                    height: auto;

                    label {
                        border-radius: 50px;
                        background-color: #dcdee6;
                        border: none;
                        padding: 20px 20px;
                        height: auto;
                        box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                        .form-file-text {
                            min-height: 21px;
                        }

                        &:after {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            height: 100%;
                            line-height: unset;
                            background: $primary;
                            color: white;
                            font-weight: 400;
                            padding: 10px 30px;
                        }
                    }
                }
            }

            #checkbox_vop {
                text-align: left;
                padding-left: 20px;

                label {
                    padding-left: 0;
                    font-weight: 400;

                    &:before {
                        top: 2px;
                        border-radius: 0;
                    }

                    &:after {
                        top: 2px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }

            .send-forum-buttons {
                margin-top: 20px;
                margin-bottom: 100px;

                button {
                    width: 100%;
                }
            }
        }

        .image-gallery {
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;

            div {
                flex-grow: 1;
                margin: 0 7px;
                position: relative;

                .image-trash {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0) scale(1.2);
                    z-index: 1;
                    opacity: 0;
                }

                &:hover {
                    img {
                        filter: grayscale(100%);
                    }

                    .image-trash {
                        opacity: 1;
                        cursor: pointer;

                        svg {
                            filter: grayscale(0);
                            color: $secondary;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 250px;
                    object-fit: cover;
                    max-width: 300px;
                    border-radius: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
        .gallery-warn {
            color: red;
            font-weight: bold;
        }
    }

    .geometric-1 {
        position: absolute;
        top: 400px;
        left: 20px;
        transform: scaleX(-1);
        width: 760px;
        z-index: -1;
        opacity: 0.5;
    }
}
</style>