<template>
    <div class="top-vendor-list">
        <h2 class="top-vendor-list-title">{{ title }}</h2>

        <div v-if="topUsers.length > 0">
            <VendorVertical v-for="(user, index) in topUsers" :key="index" :user="user" />
        </div>
    </div>
</template>

<script>
import VendorVertical from '@/components/single/VendorVertical'

export default {
    name: "TopPostContributor",
    components: {
        VendorVertical
    },
    props: {
        title: {
            type: String,
            default: 'Top používatelia',
        },
        topUsers: {
            default: function () {
                return {}
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.top-vendor-list::v-deep {
    margin-top: 50px;
    margin-bottom: 50px;

    .top-vendor-list-title {
        margin-bottom: 40px;
    }

    .single-vendor-vertical {
        margin-bottom: 30px;
    }

    > div {
        @media (max-width: 992px) {
            display: flex;
            flex-direction: row;
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
            overflow-x: auto;

            .single-vendor-vertical {
                margin-right: 15px;
                min-width: 290px;
            }
        }

    }

}
</style>