<template>
	<div class="single-forum-post-light">
        <a @click="$router.push({ name: 'ForumDetail', params: { slug: forum.slug } }, () => {})">
			<div class="post-author">
				<span class="vendor-img-overlay">
					<img :src="vendor" alt="dodavatel">
				</span>
			</div>

			<div class="post-content">
				<p class="post-info mb-2">
                    <small class="d-flex mr-auto" v-if="forum.user">{{ forum.user.name }} | {{ moment(forum.created_at).format("LL") }}</small>
<!--                    <small class="d-flex ml-auto">Posledný príspevok: 1.12.2020</small>-->
                </p>

				<h3 class="text-left text-truncate">{{ forum.title }}</h3>

				<div class="categories">
					<p class="text-left" v-for="category in forum.categories" :key="category.id">
						<small>{{ category.menu_item.name }} </small>
					</p>
				</div>

				<div class="bottom-bar">
					<div class="left">
						<font-awesome-icon icon="thumbs-up"></font-awesome-icon>
						<small>{{ forum.forum_likes_count }} |</small>
						<font-awesome-icon icon="thumbs-down"></font-awesome-icon>
						<small>{{ forum.dislikes_count }}</small>
					</div>
					<div class="right">
						<small>{{ forum.comments_count }}</small>
						<font-awesome-icon icon="comments"></font-awesome-icon>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import vendor from "@/assets/vendor.svg"

export default {
	props: {
		'forum': {
			default: function () {
				return {}
			}
		}
	},
	data() {
		return {
			vendor: vendor
		}
	},
}
</script>

<style scoped lang="scss">
.single-forum-post-light::v-deep {

	position: relative;
	//border-radius: 15px;
	background: white;
	box-shadow: 0 5px 10px rgba(8, 25, 45, .25);
	margin-top: 20px;

	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	//overflow: hidden;

    a {
        cursor: pointer;
    }

	img.post-image {
		height: 200px;
		width: 100%;
		object-fit: cover;
	}

	.post-author {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.vendor-img-overlay {
			background: $secondary;
			position: relative;
			display: flex;
			width: 40px;
			height: 40px;
			margin-top: -20px;
			margin-left: auto;
			margin-right: auto;
			padding: 15px;
			border-radius: 50%;
			z-index: 2;

			img {
				transform: scale(1.3);
				margin-left: -1px;
			}
		}

		&:before {
			content: "";
			background: $secondary;
			position: absolute;
			top: 0;
			left: 0;
			height: 10px;
			width: 100%;
		}
	}

	.post-content {
		padding: 20px;

		h3 {
			color: $primary;

			font-weight: 400 !important;
			font-size: 22px !important;

			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}


		}

		.post-info {
		display: flex;
		flex-direction: row;
		}

		.categories {
			display: flex;

			p {
				margin-bottom: 0;
	
				> small {
					color: $small-color;
					margin-right: 5px;
				}
			}
		}

		.bottom-bar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.left {
				display: flex;
				flex-direction: row;
				align-items: center;
				svg {
					color: $primary;
					margin-right: 5px;
					font-size: 13px;
				}
				small {
					color: $small-color;
					margin-right: 5px;
				}
			}

			.right {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
					color: $primary;
					font-size: 13px;
				}

				small {
					color: $small-color;
					margin-right: 5px;
				}
			}
		}
	}
}
</style>