import { render, staticRenderFns } from "./AfterPay.vue?vue&type=template&id=940a6336&scoped=true&"
import script from "./AfterPay.vue?vue&type=script&lang=js&"
export * from "./AfterPay.vue?vue&type=script&lang=js&"
import style0 from "./AfterPay.vue?vue&type=style&index=0&id=940a6336&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "940a6336",
  null
  
)

export default component.exports