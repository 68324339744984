<template>
    <div class="page-profile-blogs">
        <b-row v-if="$route.params.slug === $store.getters.user.slug" class="blog-sort">
            <b-col cols="12">
                <div class="blog-sort-desktop">
                    <button @click="setActive('approved')" :class="{ active: isActive('approved') }">Zverejnené články</button> |
                    <button @click="setActive('written')" :class="{ active: isActive('written') }">Rozpísané články</button> |
                    <button @click="setActive('pending')" :class="{ active: isActive('pending') }">Čakajúce na schválenie</button> |
                    <button @click="setActive('refusal_change')" :class="{ active: isActive('refusal_change') }">Na opravu</button> |
                    <button @click="setActive('refusal_definitive')" :class="{ active: isActive('refusal_definitive') }">Neschválené</button>
                </div>

                <b-nav-item-dropdown id="blog-sort-mobile">
                    <template #button-content>
                        <span v-if="active_item === 'approved'">Zverejnené články</span>
                        <span v-else-if="active_item === 'written'">Rozpísané články</span>
                        <span v-else-if="active_item === 'pending'">Čakajúce na schválenie</span>
                        <span v-else-if="active_item === 'refusal_change'">Na opravu</span>
                        <span v-else-if="active_item === 'refusal_definitive'">Neschválené</span>
                    </template>
                    <b-dropdown-item @click="setActive('approved')" :class="{ active: isActive('approved') }">Zverejnené články</b-dropdown-item>
                    <b-dropdown-item @click="setActive('written')" :class="{ active: isActive('written') }">Rozpísané články</b-dropdown-item>
                    <b-dropdown-item @click="setActive('pending')" :class="{ active: isActive('pending') }">Čakajúce na schválenie</b-dropdown-item>
                    <b-dropdown-item @click="setActive('refusal_change')" :class="{ active: isActive('refusal_change') }">Na opravu</b-dropdown-item>
                    <b-dropdown-item @click="setActive('refusal_definitive')" :class="{ active: isActive('refusal_definitive') }">Neschválené</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-col>
        </b-row>

        <p v-if="!spinners.load_data && blogs_data && blogs_data.length === 0" class="mt-5">Žiadne príspevky</p>
        <b-row class="mt-5 blog-list" cols="1" cols-md="2">
            <b-col v-for="blog in blogs_data" :key="blog.id">
                <Post v-if="blog" :blog="blog" />
            </b-col>
        </b-row>
        <VuePagination
            :per_page="pagination.perPage"
            :current_page="pagination.currentPage"
            :records="pagination.records"
            :load_data="spinners.load_data"
            @handle-current-page="handlePageChange($event)"
        />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import Post from "@/components/blog/single/Post";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageProfileBlogs",
    components: {
        VuePagination,
        Post,
    },
    data() {
        return {

            pagination: {
                perPage: 6,
                currentPage: 1,
                records: 0,
            },

            active_item: 'approved',
            blogs_data: null,

            spinners: {
                load_data: false,
            }
        }
    },
    watch: {
        '$route.query': function () {
            this.checkRoute()
        }
    },
    mounted() {
        this.checkRoute()
    },
    methods: {
        checkRoute() {
            let query = Object.assign({}, this.$route.query);
            if (this.$route.params.slug === this.$store.getters.user.slug) {
                if (query.status) {
                    this.active_item = String(query.status)
                } else {
                    this.active_item = 'approved'
                }
            } else {
                delete query.status
                this.activeItem = 'approved'
            }
            if (query.page) {
                this.pagination.currentPage = Number(query.page)
            } else {
                this.pagination.currentPage = 1
            }

            this.$router.push({ 'query': query }, () => {});
            this.getUserBlogs()
        },
        setActive: function (menu_item) {
            if (this.spinners.load_data) return false
            this.active_item = menu_item
            this.pagination.currentPage = 1
            let query = Object.assign({}, this.$route.query);
            if (menu_item === 'approved') {
                delete query.status
            } else {
                query.status = menu_item
            }
            delete query.page
            this.$router.push({ 'query': query }, () => {});
            this.getUserBlogs()
        },
        isActive: function (menu_item) {
            return this.active_item === menu_item
        },
        handlePageChange(page) {
            this.pagination.currentPage = page
            let query = Object.assign({}, this.$route.query);
            if (page !== 1) {
                if (Number(this.$route.query.page) !== page) query.page = page
            } else {
                delete query.page
            }
            this.$router.push({ 'query': query }, () => {});
            this.getUserBlogs();
            this.$scrollTo('.profile-container')
        },
        getUserBlogs() {
            this.spinners.load_data = true
            // this.activeItem === 'delete' ? status = 'admin_refusal_definitive,refusal_definitive' : status = this.activeItem
            axios.get(config.API_URL + '/blog/user/created'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&status=' + this.active_item
                + '&slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let data = response.data.success
                    console.log('profil blogs', data);
                    // this.currentPage = data.current_page
                    this.pagination.records = data.total;
                    this.blogs_data = data.data;
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-blogs::v-deep {
    .blog-sort {
        font-size: 14px;

        button {
            outline: 0;
            background-color: transparent;
            border: 0;
        }

        .active {
            font-weight: bold;
            color: $secondary;
        }

        .blog-sort-desktop {
            text-align: left;

            @media (max-width: 992px) {
                display: none;
            }
        }

        #blog-sort-mobile {
            outline: none;
            list-style-type: none;
            text-align: left;

            > * {
                outline: none;
            }

            @media (min-width: 992px) {
                display: none;
            }

            ul {
                transform: none !important;
                top: 30px !important;
                left: 20px !important;

                li {
                    margin: 0 10px;
                    border-radius: 5px;

                    &:hover, &:focus {
                        background: #9999;
                    }

                    .dropdown-item {
                        width: auto !important;

                        &:hover, &:focus {
                            background-color: transparent;
                        }

                    }
                }

                .active {
                    background-color: $primary;

                    a {
                        color: white;
                    }
                }
            }
        }
    }

    .blog-list {
        > div {
            margin-bottom: 20px;
        }
    }
}
</style>