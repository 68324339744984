<template>
    <div class="component-blog-status-controller">
        <b-row class="blog_status mt-3 mb-3">

            <b-col cols="12" v-if="blog.status === 'refusal_change'">
                <h2>Váš blog bol odmietnutý adminom</h2>
                <p>Môžete upraviť váš blog podľa pripomienok admina a znova ho odoslať na schválenie.</p>

                <div v-if="blog.blog_history[blog.blog_history.length - 1].comment">
                    <p class="mb-0"><em>Poznámka admina:</em></p>
                    <p><strong>{{ blog.blog_history[blog.blog_history.length - 1].comment }}</strong></p>
                </div>
            </b-col>

            <b-col v-if="blog.status === 'written' || blog.status === 'refusal_change'" cols="12" sm="6">
                <b-button @click="changeBlogStatusConfirm('pending')" class="mt-2 mb-1" variant="secondary" pill>
                    <font-awesome-icon class="btn-icon" icon="check"></font-awesome-icon>
                    Odoslať na schválenie
                </b-button>
            </b-col>

            <b-col v-if="blog.status === 'written' || blog.status === 'refusal_change'" cols="6" sm="3">
                <b-button
                    v-if="blog.user_id === $store.getters.user.user_id && blog.status !== 'pending'"
                    @click="$router.push({ name: 'PageBlogUpdate', params: { blog_slug: blog.slug } })"
                    class="mt-2 mb-1"
                    variant="primary"
                    pill
                >
                    <font-awesome-icon class="btn-icon" icon="edit"></font-awesome-icon>
                    Upraviť
                </b-button>
            </b-col>

            <b-col v-if="blog.status === 'written' || blog.status === 'refusal_change'" cols="6" sm="3">
                <b-button v-b-modal.modal-confirm-blog-delete class="mt-2 mb-1" variant="primary" pill>
                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                </b-button>
            </b-col>

            <b-col v-if="blog.status === 'pending'">
                <h2 class="title-wait-pending">Článok čaká na schválenie...</h2>
            </b-col>

            <b-col v-if="blog.status === 'approved'" cols="10" offset="1" lg="8" offset-lg="2">
                <b-button @click="changeBlogStatusCancel('written')" class="mt-2 mb-1" variant="secondary" pill>
                    <font-awesome-icon class="btn-icon" icon="ban"></font-awesome-icon>
                    Zrušiť zverejnenie článku
                </b-button>
            </b-col>

            <b-col v-if="blog.status === 'approved'" cols="12">
                <p class="description"><em>Ak chcete upraviť článok, najprv musíte zrušiť jeho zverejnenie</em></p>
            </b-col>
        </b-row>

        <ModalBlogDelete @delete-blog="deleteBlog(blog.slug)" />
        <ModalBlogPending @pending-blog="changeBlogStatus()" />
    </div>
</template>

<script>
import ModalBlogDelete from "@/components/blog/modal/ModalBlogDelete";
import ModalBlogPending from "@/components/blog/modal/ModalBlogPending";

export default {
    name: "BlogStatusController",
    components: {
        ModalBlogDelete,
        ModalBlogPending,
    },
    props: {
        blog: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            new_blog_status: ''
        }
    },
    methods: {
        deleteBlog() {
            this.$emit('delete-blog', this.blog.slug)
        },
        changeBlogStatusConfirm(blog_status) {
            this.new_blog_status = blog_status
            this.$bvModal.show('modal-confirm-blog-pending')
        },
        changeBlogStatus() {
            this.$emit('change-blog-status', this.new_blog_status)
        },
        changeBlogStatusCancel(blog_status) {
            this.new_blog_status = blog_status
            this.changeBlogStatus()
        },
    }
}
</script>

<style lang="scss" scoped>
.component-blog-status-controller::v-deep {
    .blog_status {
        position: relative;
        background-color: $bg-gray;
        text-align: center;
        padding: 20px 0;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -100vw;
            background: $bg-gray;
            height: 100%;
            width: 200vw;
            z-index: -1;
        }

        .title-wait-pending{
            margin-bottom: 0;
        }

        button {
            width: 100%;
            text-align: center;
            padding: 10px 0;
        }

        .description {
            font-size: 15px;
            color: $red;
            margin-bottom: 0;
        }
    }
}
</style>