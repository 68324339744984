<template>
    <div class="subscription-invoice-info">
        <MainMenu />

        <div class="container">
            <h1 class="summary-title">Zhrnutie objednávky</h1>
        </div>

        <span class="rectangle"></span>

        <div class="container mb-5">
            <b-row>
                <b-col cols="12" order="2" lg="8" order-lg="1">

                    <b-spinner v-if="!select_package.id" class="mt-5" type="grow" label="Spinning"></b-spinner>
                    <b-container v-else class="info-detail">
                        <b-row class="package">
                            <b-col class="package-type">Balík kategórie <strong v-html="select_package.frekvence_name"></strong></b-col>
                            <b-col class="package-price">{{ select_package.price_dph }} €</b-col>
                        </b-row>

                        <b-row v-for="service in extra_service" :key="service.id" class="dotted">
                            <b-col class="service" v-html="service.text"></b-col>
                            <b-col class="service service-price">{{ service.price }} €</b-col>
                        </b-row>

                        <b-row class="first-line">
                            <b-col><span class="rectangle-line"></span></b-col>
                        </b-row>

                        <b-row class="dotted">
                            <b-col class="service">Medzisúčet</b-col>
                            <b-col class="service service-price">{{ total_price }} €</b-col>
                        </b-row>

                        <b-row class="second-line">
                            <b-col><span class="rectangle-line"></span></b-col>
                        </b-row>

                        <b-row class="dotted final-price">
                            <b-col>Celková suma s DPH:</b-col>
                            <b-col>{{ total_price }} €</b-col>
                        </b-row>
                        <b-row class="dotted">
                            <b-col class="service">Celková bez s DPH:</b-col>
                            <b-col class="service">{{ Math.round((total_price * (1 - Number($store.getters.settings.tax) / 100) + Number.EPSILON) * 100) / 100 }} €</b-col>
                        </b-row>
                        <b-row class="dotted">
                            <b-col class="service">DPH 20%</b-col>
                            <b-col class="service">{{ Math.round((total_price * (Number($store.getters.settings.tax) / 100) + Number.EPSILON) * 100) / 100 }} €</b-col>
                        </b-row>
                    </b-container>

                    <b-container class="payment-information">
                        <h3>Fakturačné údaje</h3>

                        <b-row v-if="order.user_data && !$store.getters.user.slug" class="information mb-4">
                            <b-col cols="12" md="5"><strong>Používateľské meno:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.user_data.nick }}</b-col>
                            <b-col cols="12" md="5"><strong>Email:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.user_data.email }}</b-col>
                        </b-row>

                        <b-row v-if="order.order_data" class="information">
                            <b-col cols="12" md="5"><strong>Meno:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.name }}</b-col>
                            <b-col cols="12" md="5"><strong>Priezvisko:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.lastname }}</b-col>
                            <b-col cols="12" md="5"><strong>Názov spoločnosti:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.trade_name }}</b-col>
                            <b-col cols="12" md="5"><strong>IČO:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.ico }}</b-col>
                            <b-col cols="12" md="5"><strong>DIČ:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.dic }}</b-col>
                            <b-col cols="12" md="5"><strong>IČ DPH:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.ic_dph }}</b-col>
                            <b-col cols="12" md="5"><strong>Fakturačná adresa – ulica:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.billing_address_street }}</b-col>
                            <b-col cols="12" md="5"><strong>Fakturačná adresa – mesto:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.billing_address_town }}</b-col>
                            <b-col cols="12" md="5"><strong>Fakturačná adresa – PSČ:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.billing_address_psc }}</b-col>
                            <b-col cols="12" md="5"><strong>Fakturačná adresa – Krajina:</strong></b-col>
                            <b-col cols="12" md="7">{{ order.order_data.billing_address_country }}</b-col>
                        </b-row>
                    </b-container>

                    <b-button @click="$router.push({ name: 'SubscriptionInvoiceInfo', query: { package: $store.getters.order.package } }, () => {})" class="mb-3" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="arrow-left"></font-awesome-icon>
                        Upraviť údaje objednávky
                    </b-button>

                    <span class="rectangle rectangle-second"></span>

                    <b-row class="sending-box">
                        <b-col cols="12" v-if="error.note">
                            <div v-for="(error, index) in error.note" :key="index" class="error-message-c m-0">{{ error }}</div>
                        </b-col>
                        <b-col cols="12">
                            <b-button @click="createOrder()" variant="secondary" pill>
                                <font-awesome-icon class="btn-icon mr-1" icon="credit-card"></font-awesome-icon>
                                Prejsť na platbu
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="12" order="1" lg="4" order-lg="2">
                    <SubscriptionDetail
                        v-if="select_package.id"
                        :product="select_package"
                        :button_visible="false"
                        :recommended="select_package.frekvence_recommended"
                    />
                    <b-spinner v-else class="mt-5" type="grow" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
        </div>

        <ModalClearCart :next_path="next_path" @continue="($event)" ref="leaveModal" />

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import Footer from '@/components/Footer';
import SubscriptionDetail from '@/components/subscription/SubscriptionDetail';
import ModalClearCart from '@/components/subscription/ModalClearCart';

export default {
    components: {
        MainMenu,
        Footer,
        SubscriptionDetail,
        ModalClearCart
    },
    metaInfo() {
        return {
            title: 'Predplatné | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_subscription },
            ]
        }
    },
    data() {
        return {
            order: {},
            select_package: {},
            extra_service: {},
            next_path: '',

            error: {
                note: null,
            }
        }
    },
    mounted() {
        this.order = this.$store.getters.order
        this.getPackage()
        // this.getExtraPackages()
    },
    computed: {
        total_price() {
            let total_price = 0
            total_price += Number(this.select_package.price_dph)
            for (let i = 0; i < this.extra_service.length; i++) {
                total_price += Number(this.extra_service[i].price)
            }
            return total_price
        }
    },
    beforeRouteEnter(_, from, next) {
        if (from.name === 'SubscriptionInvoiceInfo' || from.name === 'SubscriptionSummary' || from.name === 'Subscription') {
            next()
        } else {
            next(vm => {
                if (vm.$store.getters.user.slug) {
                    vm.$router.push({ name: 'PageProfileSubscriptions', params: { slug: vm.$store.getters.user.slug }})
                } else {
                    vm.$router.push({ name: 'Sign_in' })
                }
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'Subscription' || to.name === 'SubscriptionInvoiceInfo' || to.name === 'ProfilePlan' || to.name === 'Sign_in') {
            next()
        } else {
            this.next_path = to
            this.$bvModal.show('modal-clear-cart')
            this.$nextTick(() => {
                this.$refs.leaveModal.$once('continue', () => {
                    next()
                })
            })
        }
    },
    methods: {
        getPackage() {
            axios.get(config.API_URL + '/products/getOneProductFrekvence'
                + '?slug=' + this.$store.getters.order.package, config.HEADERS)
                .then((response) => {
                    console.log('get one card', response.data)
                    this.select_package = response.data.success
                }, (error) => {
                    console.warn(error);
                });
        },
        getExtraPackages() {
            axios.get(config.API_URL + '/products/randomExtra?productType=2', config.HEADERS)
                .then((response) => {
                    console.log('getExtraPackages', response.data.success);

                    // [0].product_frekvences[0].product_frekvence_types

                    let extraPackages = response.data.success
                    let packagesLength = Object.keys(extraPackages).length
                    let newExtraPackages = []

                    for (let i = 0; i < packagesLength; i++) {
                        console.log('extraPackages[i]', extraPackages[i])
                        if (this.order.extra_services.includes(extraPackages[i].product_frekvences[0].product_frekvence_types[0].slug)) {
                            let newPackage = {}
                            newPackage.id = extraPackages[i].id
                            newPackage.text = extraPackages[i].name
                            newPackage.tooltip = 'cena je s DPH'
                            newPackage.slug = extraPackages[i].product_frekvences[0].product_frekvence_types[0].slug
                            newPackage.price = extraPackages[i].product_frekvences[0].product_frekvence_types[0].price_dph

                            newExtraPackages.push(newPackage)
                        }
                    }
                    this.extra_service = newExtraPackages
                }, (error) => {
                    console.warn(error);
                });
        },
        createOrder() {
            let order = this.$store.getters.order
            const orderData = new FormData();
            if (!this.$store.getters.user.slug) {
                orderData.set('nick', order.user_data.nick);
                orderData.set('email', order.user_data.email);
                orderData.set('password', order.user_data.password);
                orderData.set('password_confirmation', order.user_data.password_confirmation);
                orderData.set('conditions_shop', 1);
                orderData.set('conditions_data', 1);
            }

            orderData.set('name', order.order_data.name);
            orderData.set('lastname', order.order_data.lastname);
            orderData.set('trade_name', order.order_data.trade_name);
            orderData.set('ico', order.order_data.ico);
            orderData.set('dic', order.order_data.dic);
            if (order.order_data.ic_dph) {
                if (order.order_data.ic_dph.length > 0) orderData.set('ic_dph', order.order_data.ic_dph);
            }
            orderData.set('billing_address_street', order.order_data.billing_address_street);
            orderData.set('billing_address_town', order.order_data.billing_address_town);
            orderData.set('billing_address_psc', order.order_data.billing_address_psc);
            orderData.set('billing_address_country', order.order_data.billing_address_country);

            // orderData.set('phone', '0902030403');

            orderData.set('items[0]', order.package);
            // order.extra_services.forEach((el, index) => {
            //     orderData.set('items[' + [index + 1] + ']', el);
            // })

            for (let pair of orderData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            this.postCreatedOrder(orderData)
        },
        postCreatedOrder(postData) {
            axios.post(config.API_URL + '/chekout/doOrder', postData, config.HEADERS)
                .then((response) => {
                    console.log('CREATE ORDER', response.data)
                    window.open(response.data.access_token.pay_link, '_self'); //_self _blank
                }, (error) => {
                    console.warn(error);
                    this.error.note = this.$errorMessage(error.response)
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.subscription-invoice-info::v-deep {
    .summary-title {
        color: $secondary;
        text-align: left;
        font-size: 20px !important;
        margin: 30px 0;
    }
    
    .rectangle {
        display: block;
        width: 100%;
        height: 10px;
        background-color: $secondary;
        opacity: 0.2;
    }
    
    .rectangle-second {
        position: absolute;
        opacity: 1;
        width: 200vw;
        margin-left: -100vw;
        height: 1px;
    }

    .info-detail {
        margin-top: 50px;
        padding: 30px 10px 40px 10px;
        background-color: #9b413a17;
        border-radius: 10px;
        
        > .row {
            margin: 0 10px;
            padding-bottom: 1px;

            :nth-child(1) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                padding-left: 5px;
            }

            :nth-child(2) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }

            &.first-line {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            &.second-line {
                margin-top: 55px;
            }

            .rectangle-line {
                display: block;
                width: 100%;
                height: 1px;
                background-color: $primary;
            }

            &.dotted {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                    background-image: linear-gradient( 90deg, $secondary 0, rgba($secondary, 0) 30%);
                    background-position: bottom;
                }
            }

            &.package {
                font-size: 17px;
                margin-bottom: 20px;

                .package-price {
                    font-weight: 800;
                    color: $secondary;
                }
            }

            &.final-price {
                margin-top: 10px;
                font-size: 23px;
                font-weight: 800;
                color: $secondary;

                 @media (max-width: 991px) {
                    font-size: 19px;
                }
            }

            > div {
                padding: 5px 5px 0 0;

                &.service {
                    color: #8690a0;
                    font-weight: bold;
                    font-size: 12px;
                }

                &.service-price {
                    color: $secondary;
                    font-weight: 800;
                    font-size: 12px;
                }
            }
        }
    }

    .payment-information {
        margin-top: 50px;
        margin-bottom: 30px;

        h3 {
            margin-bottom: 20px;
            color: $secondary;
            text-align: left;
            font-size: 20px !important;
        }

        .information {
            text-align: left;

            @media (min-width: 690px) {
                > div {
                    margin-bottom: 10px;
                    box-shadow: 0 5px 5px -6px rgba(0, 0, 0, 0.3);
                }
            }
            @media (max-width: 690px) {
                > div:nth-child(2n) {
                    margin-bottom: 10px;
                    box-shadow: 0 5px 6px -6px rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    .sending-box {
        margin-top: 60px;
        text-align: left;
        font-size: 20px !important;

        #checkbox-consent {

            #checkbox-consent-1 {
    
                label {
                    font-size: 12px;
    
                    &:before {
                        top: 0;
                        border-radius: 0;
                    }
    
                    &:after {
                        top: 0;
                    }
    
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        .btn {
            display: block;
            margin: 30px auto;
            width: 50%;
            padding: 14px 20px;
            font-size: 18px;

            @media (max-width: 991px) {
                margin: 40px auto;
                font-size: 16px;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}
</style>