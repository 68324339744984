<template>
    <b-row class="user-react-note" no-gutters>
<!--        <b-col cols="10" order="1" order-lg="1" lg="2" class="my-note">-->
<!--            Poznámka k dopytu:-->
<!--        </b-col>-->
        <b-col cols="12" lg="10" order="2" order-lg="1" class="my-note-text">
            <div v-if="!note_update" class="update-note">
                <span>{{ demand_note_copy.note }}</span>
            </div>
            <b-form v-else @submit.prevent="postNote()">
                <b-form-input
                    type="text"
                    size="sm"
                    v-model.trim="new_demand_note"
                    placeholder="Napíšte si poznámku k dopytu..."
                ></b-form-input>
                <div class="error-message-l" v-show="$v.new_demand_note.$error">
                    <div v-if="!$v.new_demand_note.required || !$v.new_demand_note.minLength">Zadajte minimálne {{ $v.new_demand_note.$params.minLength.min }} znakov.</div>
                    <div v-if="!$v.new_demand_note.maxLength">Zadajte minimálne {{ $v.new_demand_note.$params.maxLength.max }} znakov.</div>
                </div>
            </b-form>
        </b-col>
        <b-col v-if="!note_update" cols="6" offset="3" lg="1" offset-lg="1" order="1" order-lg="2" class="icons">
            <font-awesome-icon @click="updateNote()" icon="pen"></font-awesome-icon>
            <font-awesome-icon @click="deleteNote()" icon="trash-alt"></font-awesome-icon>
        </b-col>
        <b-col v-if="note_update && note_length > 0" cols="6" offset="3" lg="1" offset-lg="1" order="1" order-lg="2" class="icons">
            <font-awesome-icon @click="postNote()" icon="check"></font-awesome-icon>
            <font-awesome-icon @click="cancelUpdateNote()" icon="times"></font-awesome-icon>
        </b-col>
    </b-row>
</template>

<script>
import axios from "axios";
import config from "@/config";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
    name: "DemandNote",
    props: {
        demand_note: {
            default() {
                return {}
            }
        },
        demand_id: {
            default() {
                return ''
            }
        },
    },
    data() {
        return {
            demand_note_copy: {},
            note_update: false,
            new_demand_note: '',
        }
    },
    computed: {
        note_length() {
            return this.new_demand_note.length
        }
    },
    mounted() {
        if (this.demand_note.note) {
            this.demand_note_copy = this.demand_note
            this.new_demand_note = this.demand_note.note
        } else {
            this.updateNote()
        }
    },
    methods: {
        updateNote() {
            this.note_update = !this.note_update
        },
        cancelUpdateNote() {
            if (Object.keys(this.demand_note_copy).length === 0) {
                this.new_demand_note = ''
            } else {
                this.note_update = false
                this.new_demand_note = this.demand_note_copy.note
            }
        },
        postNote() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            const myData = new FormData();
            myData.set('demand_id', this.demand_id);
            myData.set('note', this.new_demand_note);

            // for (let pair of myData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            let url = '/demand/note'
            if (this.demand_note_copy.id) url += '/' + this.demand_note_copy.id

            axios.post(config.API_URL + url, myData, config.HEADERS)
                .then((response) => {
                    console.log('react user note', response);
                    this.demand_note_copy = response.data.success
                    this.note_update = false
                }, (error) => {
                    console.warn(error);
                    this.cancelUpdateNote()
                });
        },
        deleteNote() {
            axios.post(config.API_URL + '/demand/note/delete/' + this.demand_note_copy.id, {
                token: this.$store.getters.user.access_token.token
            }, config.HEADERS)
                .then((response) => {
                    console.log('delete note ', response);
                    this.demand_note_copy = {}
                    this.new_demand_note = ''
                    this.$v.$reset()
                    this.note_update = true
                }, (error) => {
                    console.warn(error);
                });
        },
    },
    validations: {
        new_demand_note: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(150),
        },
    },
}
</script>

<style lang="scss" scoped>
.user-react-note::v-deep {
    display: flex;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;

    .my-note {
        font-size: 14px;
        color: $secondary;
        margin-top: 5px;
        text-align: left;
    }

    .my-note-text {
        .update-note {
            display: flex;
            align-items: center;
            background-color: rgba($secondary-lighter, 0.1);
            padding: 5px 10px;
            border-radius: 10px;
            text-align: left;
            margin-top: 10px;
        }

        form {
            margin-bottom: 0;

            input {
                border-radius: 10px;
            }
        }

    }

    .icons {
        display: flex;
        justify-content: space-between;

        svg {
            cursor: pointer;
            margin-top: -3px;
        }
    }



    form {
        //display: flex;
        //flex-direction: row;
        //align-items: center;

        @media (max-width: 991px) {
            margin-top: 10px;
        }

        input {
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;
            width: 100%;
            overflow: hidden;
            box-shadow: 3px 5px 10px rgba(14, 43, 76, 0.1);

            @media (max-width: 991px) {
                height: 35px;
            }
        }

        svg {
            margin-left: 10px;
            cursor: pointer;
        }
    }
}
</style>