<template>
    <div class="page-about-project">
        <MainMenu />

        <TopEbrickSlider :banner_slug="'about-top-banner'" />

        <div class="container p-0">
            <EbrickDescriptionSlider />
        </div>

        <PortalAdmin />

        <div id="wrote-about" class="wrote-about">
            <WroteAboutSlider />
        </div>

        <Footer />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import TopEbrickSlider from '@/components/TopEbrickSlider';
import WroteAboutSlider from '@/components/my_ebrick/about_project/WroteAboutSlider';
import EbrickDescriptionSlider from '@/components/my_ebrick/about_project/EbrickDescriptionSlider';
import Footer from '@/components/Footer';
import PortalAdmin from "@/components/my_ebrick/about_project/PortalAdmin";

export default {
    components: {
        PortalAdmin,
        MainMenu,
        TopEbrickSlider,
        WroteAboutSlider,
        EbrickDescriptionSlider,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Môj eBrick | ',
        }
    },
    mounted() {
        if (this.$route.params.wrote_about) {
            this.$scrollTo('.component-portal-admin')
        }
    },
}
</script>

<style lang="scss" scoped>
.page-about-project::v-deep {

    .top-banner {
        background-color: $bg-gray;
    }

    .wrote-about {
        display: none;
        padding-bottom: 50px;
    }
}
</style>