<template>
	<div class="single-post">
		<img class="post-image" src="https://llandscapes-10674.kxcdn.com/wp-content/uploads/2019/07/lighting.jpg" alt="obrazok">

		<div class="post-author">
			<span class="vendor-img-overlay">
				<img :src="vendor" alt="dodavatel">
			</span>
		</div>

		<div class="post-content">
			<p class="text-left mb-2 user">
                <small v-if="blog.user">
                    {{ blog.user.name }}
                    {{ moment(blog.created_at).format("LL") }}
                </small>
            </p>

			<h3 class="text-left text-truncate">{{ blog.title }}</h3>

            <div class="categories">
                <p class="text-left" v-for="category in blog.categories" :key="category.id">
                    <small>{{ category.category_name }}</small>
                </p>
            </div>

			<div class="bottom-bar">
				<div class="left">
					<font-awesome-icon icon="thumbs-up"></font-awesome-icon>
					<small>{{ blog.blog_likes_count }} |</small>
					<font-awesome-icon icon="thumbs-down"></font-awesome-icon>
					<small>{{ blog.dislikes_count }}</small>
				</div>
				<div class="right">
					<small>{{ blog.comments_count }}</small>
					<font-awesome-icon icon="comments"></font-awesome-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import vendor from "@/assets/vendor.svg"

export default {
    props: {
        'blog': {
            default: function () {
                return {}
            },
        },
    },
	data() {
		return {
			vendor: vendor
		}
	},
}
</script>

<style scoped lang="scss">
.single-post::v-deep {
	position: relative;
	border-radius: 15px;
	background: $primary-lighter;
	box-shadow: 0 6px 12px rgba(14, 43, 76, .08);
	overflow: hidden;
	z-index: 5;

	img.post-image {
		height: 200px;
		width: 100%;
		object-fit: cover;
	}

	.post-author {
		position: relative;

		.vendor-img-overlay {
			background: $secondary-lighter;
			position: relative;
			display: flex;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			margin-left: auto;
			margin-right: auto;
			padding: 15px;
			border-radius: 50%;
      box-shadow: 0 6px 14px rgba(155, 65, 58, .2);
			z-index: 2;
		}

		&:before {
			content: "";
			background: $secondary-lighter;
			position: absolute;
			top: 20px;
			left: 0;
			height: 7px;
			width: 100%;
		}
	}

	.post-content {
		padding: 0 20px 20px;

		h3 {
			font-weight: 400 !important;
			font-size: 22px !important;
            color: white;
            min-height: 52px;

			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

        .user {
            color: $small-color;
        }

        .categories {
            display: flex;

            p {
                margin-bottom: 0;

                > small {
                    color: $small-color;
                    margin-right: 5px;
                }
            }
        }

		.bottom-bar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.left {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
          color: $small-color;
					margin-right: 5px;
					font-size: 13px;
				}

				small {
					color: $small-color;
					margin-right: 5px;
				}
			}

			.right {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
          color: $small-color;
					font-size: 13px;
				}

				small {
					color: $small-color;
					margin-right: 5px;
				}
			}
		}
	}
}
</style>