<template>
	<div class="page-sign-up">
		<MainMenu />

		<div class="content-wrapper">
			<div class="container">
<!--				<img class="img-doors" :src="doors" alt="svg">-->
				<img class="img-logo" :src="logo" alt="svg">

				<b-row class="sign-form">
					<b-col cols="12" md="8" offset-md="2">
						<div class="sign-switcher">
							<div class="left">
								<a @click="$router.push({ name: 'Sign_in'})">Prihlásiť sa</a>
							</div>
							<div class="right">
								<a class="active" href="#">Registrovať</a>
							</div>
						</div>

						<b-form>
							<b-row>
								<b-col cols="12" md="8" offset-md="2">
									<b-form-group description="Meno, pod ktorým chcete vystupovať v systéme eBrick." label="Používateľské meno*" label-for="nick">
										<b-form-input id="nick" v-model="nick" type="text"></b-form-input>
                                        <div v-if="nick_exist.length > 0" class="error-message-l">{{ nick_exist }}</div>
                                        <div class="error-message-l" v-show="$v.nick.$error">
                                            <div v-if="!$v.nick.required">Prosím zadajte meno, pod ktorým chcete vystupovať v systéme eBrick.</div>
                                            <div v-else-if="!$v.nick.minLength">Meno musí mať aspoň {{$v.nick.$params.minLength.min}} znakov.</div>
                                            <div v-else-if="!$v.nick.alphaNum">Povolené znaky sú iba písmena a číslice.</div>
                                        </div>
									</b-form-group>
								</b-col>

								<b-col cols="12" md="8" offset-md="2">
									<b-form-group label="Email*" label-for="sing_up_email">
										<font-awesome-icon class="input-custom-prepend" icon="envelope"></font-awesome-icon>
										<b-form-input id="sing_up_email" v-model="email" type="email"></b-form-input>
                                        <div v-if="email_exist.length > 0" class="error-message-l">{{ email_exist }}</div>
                                        <div class="error-message-l" v-show="$v.email.$error">
                                            <div v-show="!$v.email.required || !$v.email.email">Prosím, zadajte Vašu platnú e-mailovú adresu.</div>
                                        </div>
									</b-form-group>
								</b-col>

                                <b-col cols="12" md="8" offset-md="2">
                                    <b-form-group label="Heslo*" label-for="sing_up_password">
                                        <b-form-input id="sing_up_password" v-model="password" type="password"></b-form-input>
                                        <div class="error-message-l" v-show="$v.password.$error">
                                            <div v-show="!$v.password.required || !$v.password.minLength">Meno musí mať aspoň {{$v.password.$params.minLength.min}} znakov.</div>
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="8" offset-md="2">
                                    <b-form-group label="Overenie hesla*" label-for="sing_up_password_confirm">
                                        <b-form-input id="sing_up_password_confirm" v-model="password_confirmation" type="password"></b-form-input>
                                        <div class="error-message-l" v-show="$v.password_confirmation.$error">
                                            <div v-show="!$v.password_confirmation.required || !$v.password_confirmation.sameAsPassword">Zadané heslá sa nezhodujú.</div>
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col class="text-left" cols="12" md="8" offset-md="2">
                                    <b-form-group id="checkbox_vop_wrapper_1">
                                        <b-form-checkbox v-model="select_1">
                                            Súhlasím so
                                            <a @click="redirectInformation('ochrana-osobnych-udajov')">
                                                Spracovaním osobných údajov</a>
                                            a
                                            <a @click="redirectInformation('vseobecne-obchodne-podmienky')">
                                                Všeobecnými obchodnými podmienkami*
                                            </a>
                                        </b-form-checkbox>
                                    <div class="error-message-l" v-show="$v.select_1.$error">
                                        <div>Bez súhlasu s podmienkami nie je registrácia možná.</div>
                                    </div>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="8" offset-md="2">
                                    <b-col class="error-message-c" cols="12" md="8" offset-md="2">
                                        <span v-for="(err, index) in error" :key="index">{{ err }}</span>
                                    </b-col>
                                    <b-spinner v-if="spinner.save" type="grow" label="Spinning"></b-spinner>
                                    <b-button v-else @click="signUp()" pill variant="primary">
                                        Registrovať sa
                                    </b-button>
                                </b-col>
                            </b-row>
						</b-form>


                        <SocialLogin />
                        <hr>
                        <RulesLink />
					</b-col>
				</b-row>
			</div>
		</div>

		<Footer />
	</div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import doors from "@/assets/doors.svg";
import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import ebrick_logo from "@/assets/ebrick_logo.svg"
import RulesLink from "@/components/login/RulesLink";
import SocialLogin from "@/components/login/SocialLogin";

import { required, minLength, email, alphaNum, sameAs } from 'vuelidate/lib/validators'

export default {
	components: {
		MainMenu,
        RulesLink,
        SocialLogin,
		Footer,
	},
    metaInfo() {
        return {
            title: 'Registrácia | ',
        }
    },
	data() {
		return {
			doors: doors,
            logo: ebrick_logo,

            nick_exist: '',
            email_exist: '',
            error: [],

            nick: '',
            email: '',
            password: '',
            password_confirmation: '',
            select_1: false,

            spinner: {
                save: false,
            },
		}
	},
    watch: {
        nick: function(value) {
            this.nick_exist = ''
            if (value.length > 4) this.checkUniqueItem(value, '')
        },
        email: function(value) {
            this.email_exist = ''
            if (value.length > 4) this.checkUniqueItem('', value)
        },
    },
    methods: {
        checkUniqueItem(nick, email) {
            axios.get(config.API_URL+ '/checkEmailAndNickValid'
                + '?nick=' + nick
                + '&email=' + email, config.HEADERS)
                .then(() => {
                    //
                }, (error) => {
                    if (error.response.data.nick) this.nick_exist = error.response.data.nick
                    if (error.response.data.email) this.email_exist = error.response.data.email
                });
        },
        signUp() {
            if (this.nick_exist.length > 0 || this.email_exist.length > 0) return false
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }
            this.spinner.save = true
            this.error = []

            const registerData = new FormData();
            registerData.set('nick', this.nick);
            registerData.set('email', this.email);
            registerData.set('password', this.password);
            registerData.set('password_confirmation', this.password_confirmation);
            if (this.select_1) {
                registerData.set('conditions_data', '1');
                registerData.set('conditions_shop', '1');
            } else {
                registerData.set('conditions_data', '0');
                registerData.set('conditions_shop', '0');
            }

            // for (let pair of registerData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            axios.post(config.API_URL+ '/auth/signup', registerData, config.HEADERS)
                .then((response) => {
                    console.log('register', response.data)
                    this.$store.commit('setUser', response.data)
                    config.HEADERS.headers['Authorization'] = `Bearer ${response.data.access_token.token}`;
                    this.$nextTick(() => {
                        this.$store.dispatch('getCustomer', response.data.slug)
                        this.$router.push({ name: 'Home' }, () => {})
                        this.spinner.save = false
                    });
                }, (error) => {
                    this.spinner.save = false
                    this.error = this.$errorMessage(error.response)
                });
        },
        redirectInformation(slug) {
            let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
            window.open(routeData.href, '_blank');
        },
    },
    validations: {
        nick: {
            required,
            alphaNum,
            minLength: minLength(5),
        },
        email: {
            required,
            email,
        },
        password: {
            required,
            minLength: minLength(6),
        },
        password_confirmation: {
            required,
            sameAsPassword: sameAs('password')
        },
        select_1: {
            required,
            sameAs: sameAs(() => true)
        },
    },
}
</script>

<style scoped lang="scss">
.page-sign-up::v-deep {
	.content-wrapper {
		background: $bg-gray;

		.container {
			position: relative;
		}

		.img-doors {
			position: absolute;
			left: 50px;
			bottom: -10px;
		}

        .img-logo {
            position: absolute;
            top: 70px;
            left: 0;
            width: 100%;
            opacity: 0.05;
            filter: blur(4px);

            @media (max-width: 500px) {
                display: none;
            }
        }

		.sign-form {
			padding-top: 100px;
			padding-bottom: 100px;
			margin-bottom: 70px;

			form {
				padding: 40px 0;

                .row > div {
                    margin-bottom: 20px;
                }

				label {
					text-align: left;
					padding-left: 20px;
					font-size: 14px;
					font-weight: 600;
				}

				.form-group {
					input {
						color: $primary;
						border-radius: 30px;
						background: $input-bg;
						border: none;
						padding: 30px 20px;
						box-shadow: 0 12px 17px rgba(0,0,0,.2);

						&::placeholder {
							color: $primary;
						}
					}

                    .text-muted {
                        text-align: left;
                        padding-left: 20px;
                    }
				}

                // ikonka pred emailom
				.input-custom-prepend {
                    display: none;
					position: absolute;
					left: -25px;
					top: 50%;
					font-size: 20px;
					transform: translate(-50%, -50%);
				}

                #checkbox_vop_wrapper_1 {
                    .custom-control-label::before {
                        margin-top: -2px;
                    }
                    .custom-control-label::after {
                        margin-top: -2px;
                    }

                    label {
                        font-weight: normal;
                        padding: 0;
                        margin-left: 10px;
                    }

                    .custom-checkbox {
                        cursor: pointer;

                        input, label {
                            cursor: pointer;
                        }
                    }

                    a {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

				.btn {
					margin: 0 auto;
					padding: 10px 100px;
				}
			}
		}

		.sign-switcher {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding-bottom: 4px;
			border-bottom: 1px solid #E6E6E6;

			a {
				color: $secondary;
				padding-bottom: 3px;
				font-weight: 800;
				font-size: 20px;
				letter-spacing: .3px;
				text-decoration: none;
				opacity: .5;
                cursor: pointer;

				&:hover {
					opacity: 1;
				}

				&.active {
					opacity: 1;
					border-bottom: 5px solid $secondary;
				}
			}
		}

		@media (max-width: 768px) {
			.img-doors {
				opacity: 0;
			}
		}
	}
}
</style>