<template>
    <div class="page-chat">
        <MainMenu />

        <div class="container">

            <div class="chat-body">
                <NotLoginUser v-if="!$store.getters.user" :text="'Ak chcete kontaktovať poskytovateľov, musíte sa najprv prihlásiť.'" />
                <b-spinner v-if="!roomsLoaded" class="mt-5 mb-5" type="grow" label="Spinning"></b-spinner>
                <chat-window
                    v-if="roomsLoaded && rooms.length > 0"
                    :rooms="rooms"
                    :messages="messages"
                    :current-user-id="currentUserId"
                    :rooms-loaded="roomsLoaded"
                    :messages-loaded="messagesLoaded"
                    :show-files="false"
                    :show-audio="false"
                    :show-add-room="false"
                    :show-reaction-emojis="false"
                    :messageActions=[]
                    :room-id="room_id"
                    no-room-selected="harpuna"
                    :text-messages="{
                      SEARCH: 'Vyhľadať',
                      TYPE_MESSAGE: 'Vaša správa...',
                      CONVERSATION_STARTED: 'Začiatok konverzácie',
                      NEW_MESSAGES: 'Nové správy',
                      MESSAGES_EMPTY: 'Žiadne správy',
                      ROOMS_EMPTY: 'Žiadne kontakty',
                      ROOM_EMPTY: 'Žiadna konverzácia',
                    }"
                    @send-message="sendMessage"
                    @fetch-messages="fetchMessages"
                />
                <div v-if="roomsLoaded && rooms.length === 0" class="mt-5">
                    <p class="mt-5 mb-0">Zatiaľ nemáte žiadne kontakty.</p>
                    <p>Prezrite si dopyty a nájdite vhodného zákazníka alebo dodávateľa pre svoj projekt.</p>
                    <b-button @click="$router.push({ name: 'Demands', params: { demands: 'demands' }})" variant="secondary" pill>
                        <font-awesome-icon class="btn-icon" icon="arrow-circle-right"></font-awesome-icon>
                        Prejsť na dopyty
                    </b-button>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>
<script>
import axios from "axios";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import ebrick_logo from "@/assets/ebrick_logo.svg";
import config from "@/config";
import moment from "moment";

import MainMenu from "@/components/MainMenu";
import NotLoginUser from "@/components/NotLoginUser";
import Footer from "@/components/Footer";
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';

export default {
    name: "Chat",
    components: {
        MainMenu,
        NotLoginUser,
        ChatWindow,
        Footer,
    },
    data() {
        return {
            user: '',
            logo: ebrick_logo,

            currentUserId: '',
            rooms: [],
            messages: [],
            room_id: 0,
            actual_room: '',
            roomsLoaded: false,
            messagesLoaded: false,
        };
    },
    watch: {
        '$store.getters.new_chat_message': {
            handler(e) {
                if (e) {
                    let roomsLength = this.rooms.length
                    for (let i = 0; i < roomsLength; i++) {
                        if (this.rooms[i].roomId === e.message.user_id) {
                            this.rooms[i].lastMessage.content = e.message.message
                            this.rooms[i].lastMessage.timestamp = moment(e.message.created_at).startOf().fromNow(),
                                this.rooms[i].unreadCount += 1
                            this.rooms[i].index = e.message.id
                        }
                    }
                    if (this.actual_room === e.message.user_id) this.messages.push(this.createMessages(e.message, 'echo'));
                }
            }, immediate: true
        }
    },
    mounted() {
        if (this.$store.getters.user.slug) {
            this.$store.commit('setChatMessagesCount', false)
            moment.locale('sk');
            this.$set(this, 'currentUserId', this.$store.getters.user.user_id)
            this.$route.params.room_id ? this.$set(this, 'room_id', this.$route.params.room_id) : this.$set(this, 'room_id', 0)
            this.$set(this, 'user', this.$store.getters.user)
            this.fetchUsers();
        }
    },
    methods: {
        sendMessage({ roomId, content }) {
            // console.log('room', roomId, 'mess', content)
            axios.post(config.API_URL + "/sendMessage", {
                user_id: roomId,
                message: content
            }, config.HEADERS)
            .then((response) => {
                // console.log('send mess', response.data.success)
                this.messages.push(this.createMessages(response.data.success.message, 'echo'));
            });
        },
        createMessages(message, func) {
            // console.log('message', message)
            let messLength = Object.keys(message).length
            let messList = []
            if (func !== 'echo') {
                for (let i = 0; i < messLength; i++) {
                    const newMess = {}
                    newMess._id = message[i].id
                    newMess.content = message[i].message
                    newMess.senderId = message[i].user.id
                    newMess.username = message[i].user.name
                    // newMess.avatar = message[i].user.customer.image_path === null ? this.logo : config.ASSET_URL + '/' + message[i].user.customer.image_path
                    newMess.timestamp = moment(message[i].created_at).format("LLL")
                    newMess.system = false
                    newMess.saved = true
                    newMess.distributed = false
                    newMess.seen = false
                    newMess.disableActions = false
                    newMess.disableReactions = false

                    messList.push(newMess)
                }
            } else {
                const newMess = {}
                newMess._id = message.id
                newMess.content = message.message
                newMess.senderId = message.user.id
                newMess.username = message.user.name
                // newMess.avatar = message.user.customer.image_path === null ? this.logo : config.ASSET_URL + '/' + message.user.customer.image_path
                newMess.timestamp = moment(message.created_at).format("LLL")
                newMess.system = false
                newMess.saved = true
                newMess.distributed = false
                newMess.seen = false
                newMess.disableActions = false
                newMess.disableReactions = false

                return newMess
            }

            return messList
        },
        fetchMessages({ room }) {
            let roomsLength = this.rooms.length
            for (let i = 0; i < roomsLength; i++) {
                if (this.rooms[i].roomId === room.roomId) {
                    this.rooms[i].unreadCount = 0
                }
            }
            this.actual_room = room.roomId
            this.messages.splice(0)

            axios.get(config.API_URL + '/user/messages?user_id=' + room.roomId, config.HEADERS)
                .then((response) => {
                    // console.log('messages', response.data.success)
                    this.messages = this.createMessages(response.data.success)
                    this.messagesLoaded = true
                }, (error) => {
                    console.warn('echo', error)
                });
        },
        fetchUsers() {
            axios.get(config.API_URL + '/users/friends', config.HEADERS)
                .then((response) => {
                    console.log('friends', response.data.success.friend_list)
                    let friends = response.data.success.friend_list
                    let friendsLength = Object.keys(friends).length
                    let friendsList = []

                    for (let i = 0; i < friendsLength; i++) {
                        const newFriend = {}
                        newFriend.roomId = friends[i].user.id
                        newFriend.roomName = friends[i].user.name
                        newFriend.avatar = friends[i].user.customer.image_path === null ? this.logo : config.ASSET_URL + '/' + friends[i].user.customer.image_path
                        newFriend.unreadCount = friends[i].unread_messages_count
                        if (friends[i].last_message) {
                            newFriend.index = friends[i].last_message.id
                            newFriend.lastMessage = {
                                content: friends[i].last_message.message,
                                senderId: friends[i].user.id,
                                username: friends[i].user.name,
                                // timestamp: moment(friends[i].last_message.created_at).format("LLL"),
                                timestamp: moment(friends[i].last_message.created_at).startOf().fromNow(),
                                saved: true,
                                distributed: false,
                                seen: false,
                                new: true
                            }
                        }
                        newFriend.users = [
                            { _id: this.$store.getters.user.user_id, username: this.$store.getters.user.name, avatar: '', status: { state: 'false', lastChanged: '' } },
                            { _id: friends[i].user.id, username: friends[i].user.name, avatar: '', status: { state: 'false', lastChanged: '' } }
                        ]
                        friendsList.push(newFriend)
                    }
                    this.rooms = friendsList
                    this.roomsLoaded = true
                }, (error) => {
                    console.warn(error)
                });
        },
    },
};
</script>

<style scoped lang="scss">
.page-chat::v-deep {
    .chat-body {
        position: relative;
        margin-top: 20px;
        margin-bottom: 100px;
        padding-bottom: 10px;
    }

    .vac-chat-container {
        .vac-rooms-container {
            min-width: 350px;

            .vac-room-item {
                .vac-avatar {
                    margin-right: 10px;
                }

                .vac-state-circle {
                    display: none;
                }

                .vac-room-name {
                    text-align: left;
                    font-weight: bold;
                }

                .vac-text-last {
                    text-align: left;
                }
            }
        }

        .vac-col-messages {
            .vac-room-header {
                z-index: 0;
            }

            .vac-message-container {
                .vac-format-message-wrapper {
                    text-align: left;
                }
            }
        }


    }
}
</style>