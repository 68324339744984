<template>
	<div class="component-top-ebrick-slider">
        <b-spinner v-if="active_banner === null" type="grow" label="Spinning"></b-spinner>
		<div v-else class="swiper-container">
			<div class="swiper-wrapper">
				<div v-for="slide in active_banner" :key="slide.id" class="swiper-slide">
					<div class="about-project-card">
						<div class="container">
							<b-row>
								<b-col cols="12" order-lg="2" lg="5" class="image">
                                    <img :src="asset_url + '/' + slide.banner" alt="obrazok">
								</b-col>
								<b-col cols="12" order-lg="1" lg="5" offset-lg="1" class="text">
									<h2 v-html="slide.title"></h2>
									<p v-html="slide.text"></p>
<!--                                    <b-button v-if="banner_slug === 'home-top-banner'" @click="$router.push({ name: 'AboutProject' }, () => {})" variant="primary" pill>-->
<!--                                        Viac o projekte-->
<!--                                    </b-button>-->
                                    <b-button v-if="!$store.getters.user.slug" @click="$router.push({ name: 'Sign_up' }, () => {})" variant="primary" pill>
                                        Chcem sa registrovať
                                    </b-button>
                                    <b-button v-if="$store.getters.user.slug" @click="$router.push({ name: 'Subscription' }, () => {})" variant="primary" pill>
                                        Predplatné a plány
                                    </b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</div>
			</div>
			<div class="swiper-pagination"></div>
		</div>
	</div>
</template>

<script>
import config from "@/config";
import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

export default {
    props: {
        banner_slug: {
            default() {
                return ''
            }
        },
    },
	data() {
		return {
            asset_url: null,
            active_banner: null,

            config_swiper: {
                init: false,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,

                direction: 'horizontal',
                loop: true,
                centeredSlides: true,
                initialSlide: 1,
                slidesPerView: 1,
                spaceBetween: 40,
                preloadImages: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            },
            main_swiper: '',
		}
	},
    watch: {
        '$store.getters.banner_area': {
            handler() {
                if (this.$store.getters.banner_area) {
                    this.$store.getters.banner_area.forEach(el => {
                        if (el.slug === this.banner_slug) {
                            this.active_banner = el.banners
                            this.$nextTick(() => {
                                this.main_swiper.init();
                            })
                        }
                    })
                }
            }, immediate: true
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
        if (this.banner_slug === 'home-top-banner') this.config_swiper.initialSlide = 0
        if (this.banner_slug === 'about-top-banner') this.config_swiper.initialSlide = 1
    },
    mounted() {
        this.$set(this, 'main_swiper', new Swiper('.component-top-ebrick-slider .swiper-container', this.config_swiper));
	},
}
</script>

<style scoped lang="scss">
.component-top-ebrick-slider::v-deep {
	padding-top: 70px;
	padding-bottom: 70px;
	background: $bg-gray;
    cursor: pointer;

	@media (max-width: 992px) {
		padding-top: 20px;
	}

	.title {
		color: $secondary;
		margin-bottom: 50px;
	}

	.swiper-container {
		overflow: visible;

		.swiper-slide {
			width: 290px;
            text-align: left;

			@media (max-width: 992px) {
				width: 550px;
                text-align: center;
			}
		}

		.swiper-pagination {
			bottom: -30px;

			&-bullet {
				background: none;
				border: 1px solid $secondary;
				width: 12px;
				height: 12px;
				opacity: 1;

				&-active {
					background: $secondary;
				}
			}
		}
	}

	.about-project-card {
        .row {
            padding: 20px 0;
        }

		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;

			h2 {
				color: $secondary;
				margin-bottom: 20px;
                font-size: 26px !important;

                p {
                    font-size: 26px !important;
                }
			}

			p {
				font-size: 16px;
				margin-bottom: 0;

                strong {
                    color: $secondary;
                }
			}

            button {
                margin-top: 15px;
                width: 100%;
                max-width: 250px;
            }

			@media (max-width: 992px) {
				margin-top: 25px;
                align-items: center;
			}
		}

		.image {
            img {
                display: block;
                width: auto;
                max-width: 100%;
                height: 100%;
                max-height: 200px;
                margin: 0 auto;
            }
		}
	}
}
</style>