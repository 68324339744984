<template>
    <span @click="readNewMessages()" class="chat-icon">
        <font-awesome-icon v-if="$store.getters.user.slug" icon="comment-dots"></font-awesome-icon>
        <font-awesome-icon v-else icon="comment-slash"></font-awesome-icon>

        <span v-if="chat_count > 0 && chat_count < 10" class="chat-count">{{ chat_count }}</span>
        <span v-if="chat_count > 10" class="chat-count">9+</span>
    </span>
</template>

<script>
export default {
    name: "NotificationCount",
    data() {
        return {
            chat_count: 0,
        }
    },
    watch: {
        '$store.getters.chat_messages_count': {
            handler: function (value) {
                this.chat_count = value
            },
            immediate: true
        },
    },
    methods: {
        readNewMessages() {
            this.$store.commit('setChatMessagesCount', false)
        }
    },
}
</script>

<style lang="scss" scoped>
.chat-icon::v-deep {
    .b-icon {
        outline: none;
    }

    .chat-count {
        position: absolute;
        left: 50%;
        background-color: #ef0000;
        color: white;
        font-size: 10px;
        padding: 0 3px;
        border-radius: 3px;
    }
}
</style>