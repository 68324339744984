<template>
	<div class="main-menu">
		<div class="container">
			<b-navbar toggleable="xl" type="light">

				<b-navbar-brand @click="$router.push({ name: 'Home'}, () => {})">
                    <img :src="logo" class="main-logo" alt="logo">
                </b-navbar-brand>

				<div class="mobile-menu-column">
					<b-navbar-nav class="mobile-nav d-xl-none">

                        <b-nav-item v-if="!$store.getters.user" class="user-wrapper login">
                            <span @click="toLogin">Prihlásiť sa</span>
                            <span> / </span>
                            <span @click="toRegister">Registrovať</span>
                            <b-icon @click="toLogin" icon="person-circle"></b-icon>
                        </b-nav-item>

                        <b-nav-item-dropdown id="mobile-second-menu" class="user-wrapper login menu-login">
                            <template #button-content>
                                <b-nav-item v-if="$store.getters.user" @click="mobile_second_menu = !mobile_second_menu" class="user-wrapper login menu-login">
                                    <span>{{ $store.getters.user.name }}</span>
                                    <b-icon icon="person-circle"></b-icon>
                                </b-nav-item>
                            </template>

                            <UnverifiedEmail v-if="$store.getters.user && $store.getters.user.email_verified_at === null" />
                            <div v-else>
                                <b-dropdown-item @click="$router.push({ name: 'PageProfileAbout', params: { slug: $store.getters.user.slug }}, () => {})">
                                    <b-button class="mt-3" pill variant="primary">Môj profil</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'PageProfileDemands', params: { slug: $store.getters.user.slug }}, () => {})">
                                    <b-button class="mt-3" pill variant="outline-secondary">Moje dopyty</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'PageProfileBlogs', params: { slug: $store.getters.user.slug } }, () => {})">
                                    <b-button class="mt-3" pill variant="outline-secondary">Moje články</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'PageProfileForum', params: { slug: $store.getters.user.slug } }, () => {})">
                                    <b-button class="mt-3" pill variant="outline-secondary">Moje otázky</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'NewDemand' }, () => {})">
                                    <b-button class="mt-3" pill variant="primary">Zadať dopyt</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'PageBlogCreate' }, () => {})">
                                    <b-button class="mt-3" pill variant="primary">Pridať článok</b-button>
                                </b-dropdown-item>
                                <b-dropdown-item @click="$router.push({ name: 'ForumNew' }, () => {})">
                                    <b-button class="mt-3 mb-2" pill variant="primary">Pridať otázku</b-button>
                                </b-dropdown-item>
                            </div>
                            <b-dropdown-item class="sign-out" @click="signOut()">
                                <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                                Odhlásiť sa
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

<!--						<b-nav-form :class="'search-form ' + (toggledSearch ? 'show' : '')">-->
<!--							<b-form-input size="sm" class="mr-sm-2" placeholder="Vyhľadajte..."></b-form-input>-->
<!--						</b-nav-form>-->

						<b-nav-item class="toggle-search" @click="$router.push({ name: 'Search', query: { search: 'all' }}, () => {})">
<!--						<b-nav-item class="toggle-search">-->
							<b-icon icon="search" @click="toggleSearch"></b-icon>
						</b-nav-item>

						<b-nav-item @click="$router.push({ name: 'Chat' }, () => {})" class="chat">
                            <ChatMessagesCount />
						</b-nav-item>

						<b-nav-item v-b-modal.modal-notification class="notifications">
                            <NotificationCount />
						</b-nav-item>

						<b-nav-item class="menu-toggle">
							<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
						</b-nav-item>
					</b-navbar-nav>
				</div>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<b-nav-item @click="$router.push({ name: 'Home' }, () => {})" :active='$route.name === "Home"'>Domov</b-nav-item>
						<b-nav-item @click="$router.push({ name: 'Demands' }, () => {})" :active='$route.name === "Demands" || $route.name === "DemandDetail"'>Dopyty</b-nav-item>
						<b-nav-item @click="$router.push({ name: 'PageBlogMain' }, () => {})" :active='$route.name === "PageBlogMain" || $route.name === "PageBlogDetail" || $route.name === "PageBlogUpdate" || $route.name === "PageBlogCreate"'>Blog</b-nav-item>
						<b-nav-item @click="$router.push({ name: 'Forum' }, () => {})" :active='$route.name === "Forum" || $route.name === "ForumDetail"'>Fórum</b-nav-item>

                        <div
                            class="my-ebrick-container"
                            @mouseover="mouseOver(true)"
                            @mouseleave="mouseOver(false)"
                        >
                            <b-nav-item-dropdown
                                id="my-ebrick-dropdown"
                                ref="dropdown_menu_second"
                                :toggle-class="$route.name === 'Informations' || $route.name === 'AboutProject' || $route.name === 'Subscription' || $route.name === 'SubscriptionInvoiceInfo' || $route.name === 'SubscriptionSummary' ? 'active' : ''"
                            >
                              <template #button-content>Môj eBrick</template>
                              <b-dropdown-item @click="$router.push({ name: 'AboutProject'}, () => {})" :active='$route.name === "AboutProject"'>O projekte</b-dropdown-item>
                              <b-dropdown-item @click="$router.push({ name: 'Subscription' }, () => {})" :active='$route.name === "Subscription"'>Cenník</b-dropdown-item>
                              <b-dropdown-item @click="$router.push({ name: 'Informations', params: { slug: 'pravidla' }}, () => {})" :active='$route.name === "Informations"'>Všeobecné informácie</b-dropdown-item>
                            </b-nav-item-dropdown>
                        </div>

						<b-nav-item @click="$router.push({ name: 'Contact'}, () => {})" :active='$route.name === "Contact"'>Kontakt</b-nav-item>
					</b-navbar-nav>

					<!-- Right aligned nav items -->
					<b-navbar-nav class="ml-auto d-none d-xl-flex">
						<b-nav-form v-if="$route.name !== 'Search'" @submit="searchTerm()" :class="'search-form ' + (toggledSearch ? 'show' : '')">
							<b-form-input v-model="search_term" size="sm" class="mr-sm-2" placeholder="Vyhľadajte..."></b-form-input>
						</b-nav-form>
<!--                        <b-nav-item class="toggle-search" @click="$router.push({ name: 'Search', query: { search: 'all' } }, () => {})">-->
                        <b-nav-item class="toggle-search">
<!--						<b-nav-item class="toggle-search" @click="toggleSearch">-->
							<b-icon icon="search" @click="toggleSearch"></b-icon>
						</b-nav-item>

						<b-nav-item @click="$router.push({ name: 'Chat' }, () => {})" class="chat">
                            <ChatMessagesCount />
						</b-nav-item>

						<b-nav-item v-b-modal.modal-notification class="notifications">
                            <NotificationCount />
						</b-nav-item>

						<b-nav-item v-if="$store.getters.user" class="user-wrapper login">
							<span>{{ $store.getters.user.name }}</span>
							<b-icon icon="person-circle"></b-icon>
						</b-nav-item>

						<b-nav-item v-else class="user-wrapper login">
							<span @click="toLogin">Prihlásiť sa</span>
							<span> / </span>
							<span @click="toRegister">Registrovať</span>
							<b-icon @click="toLogin" icon="person-circle"></b-icon>
						</b-nav-item>

						<b-nav-item-dropdown id="user-dropdown" v-if="$store.getters.user" :class="'user-wrapper ' + (toggledSearch ? 'show' : '')" right>
							<!-- &lt;!&ndash; Using 'button-content' slot &ndash;&gt; -->
							<b-dropdown-item @click="myProfil()" class="login-name">
								<div class="post-author">
									<span class="mb-2 vendor-img-overlay">
										<img :src="vendor" alt="dodavatel">
									</span>
								</div>
								<span>{{ $store.getters.user.name }}</span>
                                <p class="go-profile">
                                    Prejst na profil
                                    <font-awesome-icon icon="angle-double-right"></font-awesome-icon>
                                </p>
							</b-dropdown-item>

							<template #button-content>
								<em>{{ $store.getters.user.name }}</em>
							</template>

                            <UnverifiedEmail v-if="$store.getters.user && $store.getters.user.email_verified_at === null" />
							<b-row v-else>
								<b-col cols="5" offset="1">
									<!-- <b-dropdown-item @click="myProfil()">Profile</b-dropdown-item> -->
									<b-button @click="$router.push({ name: 'NewDemand'}, () => {})" class="mt-3" pill variant="primary">
                                        Zadať dopyt
                                    </b-button>
									<b-button @click="$router.push({ name: 'PageBlogCreate'}, () => {})" class="mt-3" pill variant="primary">
                                        Pridať článok
                                    </b-button>
									<b-button @click="$router.push({ name: 'ForumNew'}, () => {})" class="mt-3 mb-2" pill variant="primary">
                                        Pridať otázku
                                    </b-button>
								</b-col>
								<b-col cols="5">
									<b-button @click="$router.push({ name: 'PageProfileDemands', params: { slug: $store.getters.user.slug } }, () => {})" class="mt-3" pill variant="outline-secondary">
                                        Moje dopyty
                                    </b-button>
									<b-button @click="$router.push({ name: 'PageProfileBlogs', params: { slug: $store.getters.user.slug } }, () => {})" class="mt-3" pill variant="outline-secondary">
                                        Moje články
                                    </b-button>
									<b-button @click="$router.push({ name: 'PageProfileForum', params: { slug: $store.getters.user.slug } }, () => {})" class="mt-3" pill variant="outline-secondary">
                                        Moje otázky
                                    </b-button>
								</b-col>
							</b-row>
                            <b-row>
                                <b-col class="sign-out" @click="signOut()" cols="12">
                                    <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                                    Odhlásiť sa
                                </b-col>
                            </b-row>
						</b-nav-item-dropdown>

					</b-navbar-nav>
				</b-collapse>
			</b-navbar>

            <ModalNotifications />
        </div>
	</div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import ebrick_logo from "@/assets/ebrick_logo.svg"
import vendor from "@/assets/vendor.svg";
import NotificationCount from "@/components/NotificationCount";
import ChatMessagesCount from "@/components/ChatMessagesCount";
import UnverifiedEmail from "@/components/UnverifiedEmail";
import ModalNotifications from "@/components/ModalNotifications";

export default {
	name: "MainMenu",
    components: {
        UnverifiedEmail,
        NotificationCount,
        ChatMessagesCount,
        ModalNotifications,
    },
	data() {
		return {
			logo: ebrick_logo,
			vendor: vendor,
            dropdown_menu: false,

			toggledSearch: false,
            search_term: '',

            mobile_second_menu: false
		}
	},
	watch: {
        dropdown_menu() {
            console.log(this.dropdown_menu)
        }
	},
	mounted() {
		// console.warn(this, this.$router);
	},
	methods: {
        mouseOver(value) {
            // dropdown_menu_second
            // this.dropdown_menu =  value
            if (value) {
                this.$refs.dropdown_menu_second.visible = true;
            } else {
                this.$refs.dropdown_menu_second.visible = false;
            }
        },
		toggleSearch() {
			this.toggledSearch = !this.toggledSearch;
		},
		toLogin() {
			this.$router.push({ name: 'Sign_in'}, () => {})
			// this.$route.name !== 'Sign_in' ? this.$router.push({ name: 'Sign_in'}) : this.$router.go(this.$router.currentRoute);
		},
		toRegister() {
			this.$router.push({ name: 'Sign_up'}, () => {})
		},
		myProfil() {
			// this.$router.push({ name: 'PageProfileAbout', params: { slug: this.$store.getters.user.slug }}, () => {})
			this.$router.push({ name: 'PageProfileAbout', params: { slug: this.$store.getters.user.slug }}, () => {})
		},
		signOut() {
			axios.post(config.API_URL + '/auth/logout', {
                token: this.$store.getters.user.access_token.token
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                    this.$store.dispatch('logoutUser');
                }, (error) => {
                    console.log(error)
                    this.$store.dispatch('logoutUser');
                });
		},
        searchTerm() {
            this.$router.push({ name: 'Search', query: { search: 'all', search_term: this.search_term }}, () => {})
        },
	}
}
</script>

<style scoped lang="scss">
.main-menu::v-deep {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    z-index: 15;


    .main-logo {
        position: absolute;
        top: 0;
        left: -50px;
        margin: 0;
        transform: scale(1.3);

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 1200px) {
            left: 10px;
            max-width: 100px;
        }

    }

    .navbar {
        padding-bottom: 50px;
    }

    .menu-login {
        z-index: 1;
    }

    #mobile-second-menu {
        position: absolute;
        min-width: 250px;
        z-index: 5;

        a {
            &:after {
                display: none;
            }
        }

        ul {
            box-shadow: 0 6px 14px rgba($primary, 0.2);
            margin-left: 0;
            margin-top: 30px;

            li {
                padding: 0 20px;

                a {
                    padding: 0;

                    &:hover, &:focus  {
                        background-color: transparent;
                    }
                }
            }
        }

        button {
            width: 100%;
            margin-top: 0;
        }

        .sign-out {
            text-align: right;
            margin-top: 5px;
            margin-bottom: 10px;

            a {
                &:hover, &:focus {
                    color: $primary-lighter;
                }
            }
        }
    }

    img {
        //height: 100px;
        //width: 100%;
        object-fit: contain;

    }

    .navbar-nav {
        position: relative;

        .search-form {
            position: absolute;
            right: 100%;
            top: 30px;
            width: 0;
            transition: width .2s ease-in-out;
            overflow: hidden;
            margin-top: -5px;

            &.show {
                width: 160px;
            }
        }

        #user-dropdown {
            a::after {
                opacity: 0;
            }

            .sign-out {
                border: 0;
                outline: 0;
                text-align: right;
                padding-right: 50px;
                font-size: 14px;
                color: $primary;
                margin: 10px 0;
                cursor: pointer;
            }
        }

        .user-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            &.login {
                > a {
                    color: $secondary;
                    font-size: 14px;
                    font-weight: 800;
                    padding: 0;
                    white-space: nowrap;

                    svg {
                        font-size: 22px;
                        margin-left: 5px;
                    }
                }
            }

            &.dropdown {
                > a {
                    padding: 0;
                }
            }

            .login-name {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                color: $primary !important;

                &:after {
                    content: "";
                    height: 2px;
                    width: 90%;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                    background-image: linear-gradient( 90deg, #0e2b4c 0, rgba(255, 255, 255, 0) 30%);
                    background-position: bottom;
                }

                .dropdown-item:hover {
                    background-color: transparent;
                    color: $primary;
                }

                .post-author {
                    img {
                        margin: 0;
                        padding: 0;
                        //margin-top: -40px;
                        transform: scale(1);
                    }

                    .vendor-img-overlay {
                        background: $secondary;
                        position: relative;
                        display: flex;
                        width: 40px;
                        height: 40px;
                        margin: 0 auto;
                        padding: 10px;
                        border-radius: 50%;
                        box-shadow: 0 6px 14px rgba(155, 65, 58, .2);
                        z-index: 2;
                    }

                    span {
                        display: flex;
                        justify-content: center;
                    }
                }

                span {
                    font-weight: bold;
                    color: $primary;
                }

                .go-profile {
                    margin-bottom: 0;
                    color: $primary;
                }
            }

            em {
                opacity: 0;
                letter-spacing: 3px;

                &::after {
                    opacity: 0;
                }
            }

            .row {

                button {
                    width: 100%;
                }

                .dropdown-item {
                    text-align: right;

                    &:hover {
                        background-color: transparent;
                        color: $primary;
                    }
                }
            }
        }

        .nav-item {
            &:first-child {
                .nav-link {
                    padding-left: 0;

                    &.active:before {
                        width: calc(100% - 25px);
                    }
                }
            }

            &.toggle-search {
                svg {
                    width: 16px;
                }
                .nav-link {
                    padding: 30px 8px;
                    //padding-bottom: 0;
                }
            }

            &.chat, &.notifications {
                outline: none;
                border: 0;

                svg {
                    width: 16px;
                }

                .nav-link {
                    padding: 30px 5px;
                    //padding-bottom: 0;
                }
            }

            .nav-link {
                position: relative;
                padding: 30px 25px 30px;
                color: $primary;

                &.active:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    //left: 0;
                    height: 3px;
                    width: calc(100% - 50px);
                    background: $secondary;
                    margin-bottom: -1px;
                }

                &.disabled {
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            &.b-nav-dropdown {
                .dropdown-toggle, ul {
                    outline: none;
                }

                .dropdown-toggle {
                    &:after {
                        transition: transform .2s ease-in-out;
                    }
                }

                ul {
                    border-radius: 15px;
                    padding: 0;
                    margin-left: 25px;
                    //min-width: 300px;
                    overflow: hidden;
                    border: none;
                    box-shadow: 0 6px 14px rgba($primary, 0.2);

                    li {

                        a {
                            padding: 15px 25px;
                            font-size: 14px;
                            transition: background .2s ease-in-out, color .2s ease-in-out;
                            white-space: normal;

                            &:hover {
                                color: white;
                                background: $primary;
                            }
                        }
                    }
                }

                &.show {
                    .dropdown-toggle {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .navbar-collapse {
        //padding-bottom: 15px;
        border-bottom: 1px solid #E6E6E6;
        margin-left: 230px;
    }

    @media (min-width: 1200px) {
        img {
            //transform: scale(1.6);
            margin-right: 100px;
            margin-left: -50px;
        }

        .navbar-nav {
            .nav-item {
                &.b-nav-dropdown {
                    ul {
                        text-align: center;
                        margin-top: 5px;
                        min-width: 550px;
                    }
                }
            }

            #my-ebrick-dropdown {
                margin-left: 25px;

                ul {
                    box-shadow: 0 6px 14px rgba($primary, 0.2);
                    min-width: 350px;
                    margin-top: -30px;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        img {
            transform: scale(1.4);
        }

        .navbar {
            padding-bottom: 0;
            //overflow: hidden;
        }

        .navbar-brand {
            width: 100px;
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    text-align: left;
                    padding: 15px 25px;

                    &.active {
                        &:before {
                            left: -4px;
                            width: 5px;
                            height: 100%;
                        }
                    }
                }

                &:first-child {
                    .nav-link {
                        padding-left: 25px;
                        &.active {
                            &:before {
                                width: 5px;
                            }
                        }
                    }
                }
            }
        }

        .navbar-collapse {
            border-bottom: none;
            z-index: 1;
            overflow: hidden;
            margin-left: 0;

            .navbar-nav {
                //margin: 10px 0 20px;
                margin: 10px 0 0 20px;
                border-left: 3px solid #E6E6E6;
            }
        }

        .mobile-menu-column {
            position: relative;
            text-align: right;

            .navbar-toggler {
                border: none;
                padding-right: 2px;
                margin-bottom: 5px;

                font-size: 16px;

                &.collapsed {
                    .navbar-toggler-icon {
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2814, 43, 76, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                    }
                }

                &.not-collapsed {
                    .navbar-toggler-icon {
                        background-image: url("data:image/svg+xml,%3Csvg%20data-v-beffe230=%22%22%20aria-hidden=%22true%22%20focusable=%22false%22%20data-prefix=%22fas%22%20data-icon=%22times%22%20role=%22img%22%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%20352%20512%22%20class=%22svg-inline--fa%20fa-times%20fa-w-11%22%3E%3Cpath%20data-v-beffe230=%22%22%20fill=%22currentColor%22%20d=%22M242.72%20256l100.07-100.07c12.28-12.28%2012.28-32.19%200-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48%200L176%20189.28%2075.93%2089.21c-12.28-12.28-32.19-12.28-44.48%200L9.21%20111.45c-12.28%2012.28-12.28%2032.19%200%2044.48L109.28%20256%209.21%20356.07c-12.28%2012.28-12.28%2032.19%200%2044.48l22.24%2022.24c12.28%2012.28%2032.2%2012.28%2044.48%200L176%20322.72l100.07%20100.07c12.28%2012.28%2032.2%2012.28%2044.48%200l22.24-22.24c12.28-12.28%2012.28-32.19%200-44.48L242.72%20256z%22%20class=%22%22%3E%3C/path%3E%3C/svg%3E");
                    }
                }
            }

            .mobile-nav {
                flex-direction: row;

                .nav-item:not(.user-wrapper):not(.menu-toggle) {

                    .nav-link {
                        padding: 30px 5px;
                        padding-bottom: 0;

                        svg {
                            outline: none;
                            font-size: 20px;
                            margin-top: 0.3rem;
                        }
                    }
                }

                .user-wrapper {

                    &.login {
                        > a {
                            svg {
                                margin-right: 3px;
                            }
                        }
                    }
                }

                .menu-toggle {
                    .nav-link {
                        padding: 30px 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    @media (max-width: 380px) {
        .navbar-brand {
            width: 70px;
        }
    }
}
</style>