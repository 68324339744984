<template>
    <a @click="$router.push({ name: 'PageBlogDetail', params: { slug: blog.slug } }, () => {})">
        <div class="single-post">
            <div class="post-image-wrapper">
                <img v-if="blog.main_picture_path" class="post-image" :src="asset_url + blog.main_picture_path" alt="obrazok">
                <img v-else class="post-image" :src="logo" alt="obrazok">
            </div>

            <div class="post-author">
                <span class="vendor-img-overlay">
                    <img :src="vendor" alt="dodavatel">
                </span>
            </div>

            <div class="post-content">
                <p class="text-left mb-2">
                    <small v-if="blog.user">{{ blog.user.name }} | {{ moment(blog.publication_date).format("LL") }}</small>
                </p>

                <h3 class="text-left text-truncate">{{ blog.title }}</h3>

                <div class="categories">
                    <p class="text-left" v-for="category in blog.categories" :key="category.id">
                        <small>{{ category.menu_item.name }} </small>
                    </p>
                </div>

                <div class="bottom-bar">
                    <div class="left">
                        <font-awesome-icon icon="thumbs-up"></font-awesome-icon>
                        <small>{{ blog.blog_likes_count }} |</small>
                        <font-awesome-icon icon="thumbs-down"></font-awesome-icon>
                        <small>{{ blog.dislikes_count }}</small>
                    </div>
                    <div class="right">
                        <small>{{ blog.comments_count }}</small>
                        <font-awesome-icon icon="comments"></font-awesome-icon>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import config from "@/config";

import vendor from "@/assets/vendor.svg";
import ebrick_logo from "@/assets/ebrick_logo.svg";

export default {
    props: {
        'blog': {
            default: function() {
                return {}
            }
        }

    },
    data() {
        return {
            vendor: vendor,
            logo: ebrick_logo,
            asset_url: '',
        }
    },
    mounted() {
        this.$set(this, 'asset_url', config.ASSET_URL + '/');
    }
}
</script>

<style scoped lang="scss">
.single-post::v-deep {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 6px 12px rgba(14, 43, 76, .08);
    overflow: hidden;
    z-index: 5;
    max-height: 182px;

    .post-image-wrapper {
        display: flex;
        flex-basis: 45%;
        height: inherit;
        img.post-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .post-author {
        position: relative;

        .vendor-img-overlay {
            background: $secondary;
            position: relative;
            display: flex;
            width: 40px;
            height: 40px;
            margin-left: -25px;
            top: 50%;
            padding: 10px;
            border-radius: 50%;
            z-index: 2;
            transform: translate(0, -50%);
        }

        &:before {
            content: "";
            background: $secondary;
            position: absolute;
            left: -9px;
            top: 0;
            width: 9px;
            height: 100%;
        }
    }

    .post-content {
        display: flex;
        flex-direction: column;
        flex-basis: 55%;
        padding: 20px 15px 20px 10px;

        h3 {
            font-weight: 400 !important;
            font-size: 18px !important;
            margin: 5px 0 15px;
            min-height: 42px;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }


        }

        .categories {
            display: flex;
            flex-wrap: wrap;

            p {
                margin-bottom: 0;

                > small {
                    color: $small-color;
                    margin-right: 5px;
                }
            }
        }

        .bottom-bar {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 5px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;

                background-image: linear-gradient(to right, $primary 0%, rgba(255,255,255,0) 30%);
                background-position: bottom;
                background-size: 5px 1px;
                background-repeat: repeat-x;
            }

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: 5px;
                    font-size: 13px;
                }

                small {
                    color: $small-color;
                    margin-right: 5px;
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    font-size: 13px;
                }

                small {
                    color: $small-color;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>