<template>
    <b-row class="component-form-registration-user">
        <b-col cols="12" lg="10" offset-lg="1">
            <b-form-group label="Používateľské meno*" label-for="nick" :class="{ 'input--error': $v.user_data.nick.$error}">
                <b-form-input id="nick" v-model.trim="user_data.nick" type="text"></b-form-input>
                <div v-if="nick_exist" class="error-message-l">{{ nick_exist }}</div>
                <div class="error-message-l" v-show="$v.user_data.nick.$error">
                    <div v-if="!$v.user_data.nick.required">Prosím zadajte meno, pod ktorým chcete vystupovať v systéme eBrick.</div>
                    <div v-else-if="!$v.user_data.nick.minLength">Meno musí mať aspoň {{$v.user_data.nick.$params.minLength.min}} znakov.</div>
                    <div v-else-if="!$v.user_data.nick.alphaNum">Povolené znaky sú iba písmena a číslice.</div>
                </div>
            </b-form-group>

            <b-form-group label="Email*" label-for="sing_up_email" :class="{ 'input--error': $v.user_data.email.$error}">
                <b-form-input id="sing_up_email" v-model.trim="user_data.email" type="email"></b-form-input>
                <div v-if="email_exist" class="error-message-l">{{ email_exist }}</div>
                <div class="error-message-l" v-show="$v.user_data.email.$error">
                    <div v-show="!$v.user_data.email.required || !$v.user_data.email.email">Prosím, zadajte Vašu platnú e-mailovú adresu.</div>
                </div>
            </b-form-group>

            <b-form-group label="Heslo*" label-for="password" :class="{ 'input--error': $v.user_data.password.$error}">
                <b-form-input id="password" v-model.trim="user_data.password" type="password"></b-form-input>
                <div class="error-message-l" v-show="$v.user_data.password.$error">
                    <div v-show="!$v.user_data.password.required || !$v.user_data.password.minLength">Meno musí mať aspoň {{$v.user_data.password.$params.minLength.min}} znakov.</div>
                </div>
            </b-form-group>

            <b-form-group label="Potvrdenie hesla*" label-for="password_confirm" :class="{ 'input--error': $v.user_data.password_confirmation.$error}">
                <b-form-input id="password_confirm" v-model.trim="user_data.password_confirmation" type="password"></b-form-input>
                <div class="error-message-l" v-show="$v.user_data.password_confirmation.$error">
                    <div v-show="!$v.user_data.password_confirmation.required || !$v.user_data.password_confirmation.sameAsPassword">Zadané heslá sa nezhodujú.</div>
                </div>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import axios from "axios";
import config from "@/config";
import { alphaNum, email, minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
    name: "FormRegistrationUser",
    props: {
        user_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            nick_exist: null,
            email_exist: null,
        }
    },
    watch: {
        'user_data.nick': function(value) {
            this.nick_exist = null
            if (value && value.length > 4) this.checkUniqueItem(value, '')
            this.$v.user_data.nick.$touch()
        },
        'user_data.email': function(value) {
            this.email_exist = null
            if (value && value.length > 4) this.checkUniqueItem('', value)
            this.$v.user_data.email.$touch()
        },
    },
    methods: {
        checkUniqueItem(nick, email) {
            axios.get(config.API_URL + '/checkEmailAndNickValid'
                + '?nick=' + nick
                + '&email=' + email, config.HEADERS)
                .then(() => {
                    //
                }, (error) => {
                    if (error.response.data.nick) this.nick_exist = error.response.data.nick
                    if (error.response.data.email) this.email_exist = error.response.data.email
                });
        },
    },
    validations: {
        user_data: {
            nick: {
                required,
                alphaNum,
                minLength: minLength(5),
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(6),
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            },
        },
    },
}
</script>

<style lang="scss" scoped>
.component-form-registration-user::v-deep {
    //
}
</style>