<template>
    <div class="page-contact">
        <MainMenu />

        <p class="main-text mb-0">Niečo o tom, ako sa správať.</p>
        <p class="main-text mb-0">Chcete vedieť čo môžete, musíte, mali by ste a mohli by ste?</p>
        <p class="main-text">Prejdite si naše pravidlá.</p>

        <b-button @click="redirectInformation('pravidla')" class="btn-terms mb-5" pill variant="primary">
            Pravidlá portálu
        </b-button>
        <p class="mb-5">
            <a @click="redirectInformation('casto-kladene-otazky')" class="questions">Často kladené otázky</a>
        </p>

        <div class="container">
			<div class="contact-form">
                <div v-if="messageSend" class="message-send">
                    <h2>Vaša správa bola úspešne odoslaná.</h2>
                    <p @click="newMessage()">Odoslať novú správu.</p>
                </div>
                
                <b-form v-if="!messageSend">
                    <b-row>
                        <b-col cols="12">
                            <p class="form-text">Ak ste nenašli odpoveď na Vašu otázku, <br> neváhajte nás kontaktovať:</p>
                        </b-col>

                        <b-col cols="12" lg="5" offset-lg="1">
                            <b-form-group label="Meno*" label-for="name">
                                <b-form-input id="name" v-model="name" type="search" placeholder=""></b-form-input>
                                <div class="error-message-l" v-show="$v.name.$error">
                                    <div v-if="!$v.name.required">Prosím zadajte Vaše meno.</div>
                                </div>
                                <!-- <p class="input-desc">celé meno</p> -->
                            </b-form-group>

                            <b-form-group label="Mobil" label-for="phone">
                                <b-form-input id="phone" v-model="phone" type="search" placeholder=""></b-form-input>
                                <div class="error-message-l" v-show="$v.phone.$error">
                                    <div v-if="!$v.phone.required || !$v.phone.numeric || !$v.phone.minLength || !$v.phone.maxLength">
                                        Prosím zadajte Vaše platné telefónne číslo vo formáte 0912345678.
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="5">
                            <b-form-group label="Váš e-mail*" label-for="email">
                                <b-form-input id="email" v-model="email" type="search" placeholder=""></b-form-input>
                                <div class="error-message-l" v-show="$v.email.$error">
                                    <div v-if="!$v.email.required || !$v.email.email">Prosím zadajte Váš platný email.</div>
                                </div>
                            </b-form-group>

                            <b-form-group label="Predmet*" label-for="subject">
                                <b-form-input id="subject" v-model="subject" type="search" placeholder=""></b-form-input>
                                <div class="error-message-l" v-show="$v.subject.$error">
                                    <div v-if="!$v.subject.required">Prosím zadajte predmet správy.</div>
                                    <div v-if="!$v.subject.minLength">Predmet musí obsahovať viac ako {{$v.subject.$params.minLength.min}} znaky.</div>
                                </div>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" lg="10" offset-lg="1">
                            <b-form-group label="Vaša správa*" label-for="textarea">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="text"
                                    max-rows="6"
                                ></b-form-textarea>
                                <div class="error-message-l" v-show="$v.text.$error">
                                    <div v-if="!$v.text.required">Prosím zadajte text správy.</div>
                                    <div v-if="!$v.text.minLength">Text správy musí obsagovať aspoň {{$v.text.$params.minLength.min}} znakov.</div>
                                </div>
                            </b-form-group>

                            <b-form-group id="checkbox_vop_wrapper" class="text-left" v-slot="{ ariaDescribedby }">
                                <b-form-group
                                    id="checkbox_vop"
                                    :aria-describedby="ariaDescribedby"
                                >
                                    <b-form-checkbox id="checkbox-consent-1" class="custom-checkbox" v-model="conditions">
                                        Súhlasím so
                                        <a @click="$router.push({ name: 'Informations', params: { slug: 'ochrana-osobnych-udajov' }}, () => {})">
                                            Spracovaním osobných údajov</a>
                                        a
                                        <a @click="$router.push({ name: 'Informations', params: { slug: 'vseobecne-obchodne-podmienky' }}, () => {})">
                                            Všeobecnými obchodnými podmienkami
                                        </a>
                                    </b-form-checkbox>
                                    <div class="error-message-l" v-show="$v.conditions.$error">
                                        <div>Bez súhlasu so spracovaním osobných údajov odoslanie správy nie je možné.</div>
                                    </div>
                                </b-form-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-button @click="sendMessage()" class="btn-terms" pill variant="primary">Odoslať otázku</b-button>
                    <div class="loader"><b-spinner v-show="loader" type="grow" label="Spinning"></b-spinner></div>
                </b-form>
			</div>
		</div>

        <div class="contact-info">
            <b-row>
                <b-col cols="11" offset="1" lg="9" offset-lg="3">
                    <p class="form-text ml-0">Kontakt</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="mb-4" cols="10" offset="2" lg="2" offset-lg="3">
                    <ul>
                        <li class="first-li">
                            Infolinka:
                        </li>
                        <li class="text-left space-li">
                            <font-awesome-icon class="absolute-left" icon="envelope" color="$primary"></font-awesome-icon>
                            <a :href="`mailto:${$store.getters.settings.email}`">{{ $store.getters.settings.email }}</a>
                        </li>
                        <li class="text-left space-li">
                            <font-awesome-icon class="absolute-left" icon="phone" color="$primary"></font-awesome-icon>
                            <a :href="`tel:${$store.getters.settings.tel}`">{{ $store.getters.settings.tel }}</a>
                        </li>
                    </ul>
                    <br>
<!--                    <ul>-->
<!--                        <li class="space-li">Skyned s.r.o.</li>-->
<!--                        <li class="space-li">Tročany 12</li>-->
<!--                        <li class="space-li">086 41 Tročany</li>-->
<!--                    </ul>-->
                </b-col>

                <b-col class="mb-4" cols="10" offset="2" lg="2" offset-lg="0">
                    <ul class="address">
                        <li>
                            <font-awesome-icon class="absolute-left" icon="map-marker-alt" color="$primary"></font-awesome-icon>
                            {{ $store.getters.settings.adress }}
                        </li>
                        <li>{{ $store.getters.settings.psc }} {{ $store.getters.settings.city }}</li>
                        <li>{{ $store.getters.settings.company_name }}</li>
                    </ul>
                    <br>
                    <ul>
                        <li>IČO: {{ $store.getters.settings.ico }}</li>
<!--                        <li>DIČ: {{ $store.getters.settings.dic }}</li>-->
<!--                        <li>IČ DPH: {{ $store.getters.settings.ic_dph }}</li>-->
                    </ul>
                </b-col>

<!--                <b-col cols="10" offset="2" lg="2" offset-lg="0">-->
<!--                    <ul>-->
<!--                        <li class="first-li">Obchodné oddelenie (inzercia, PR)</li>-->
<!--                        <li>Meno: {{ $store.getters.settings.name }}</li>-->
<!--                        <li>Email: {{ $store.getters.settings.surname }}</li>-->
<!--                        <li>Telefón: {{ $store.getters.settings.tel }}</li>-->
<!--                    </ul>-->
<!--                </b-col>-->
            </b-row>

            <b-row>
                <b-col cols="11" offset="1" lg="9" offset-lg="3">
                    <p class="text-left mt-4">{{ $store.getters.settings.register }}</p>
                </b-col>
            </b-row>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from '@/components/Footer';

import { required, minLength, maxLength, email, sameAs, numeric } from 'vuelidate/lib/validators'

    export default {
        components: {
            MainMenu,
            Footer
        },
        metaInfo() {
            return {
                title: 'Kontakt | ',
            }
        },
        data() {
            return {

                name: '',
                email: '',
                phone: '',
                subject: '',
                text: '',
                conditions: false,

                messageSend: false,
                loader: false,
            }
        },
        methods: {
            sendMessage() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return false
                }

                this.loader = true

                const contactData = new FormData();
                contactData.set('name', this.name);
                contactData.set('email', this.email);
                contactData.set('phone', this.phone);
                contactData.set('subject', this.subject);
                contactData.set('text', this.text);
                contactData.set('conditions', this.conditions ? 1 : 0 );

                axios.post(config.API_URL+ '/contactUs', contactData, config.HEADERS)
                    .then((response) => {
                        console.log(response)
                        this.messageSend = true
                        this.loader = false
                    }, (error) => {
                        console.warn(error.response.data.error);
                    });
            },
            newMessage() {
                this.name = '';
                this.email = '';
                this.phone = '';
                this.subject = '';
                this.text = '';
                this.conditions = false;
                this.messageSend = false;
                this.loader = false;
                this.$v.$reset()
            },
            redirectInformation(slug) {
                let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
                window.open(routeData.href, '_blank');
            },
        },
        validations: {
            name: {
                required,
            },
            email: {
                required,
                email
            },
            phone: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            subject: {
                required,
                minLength: minLength(3)
            },
            text: {
                required,
                minLength: minLength(10)
            },
            conditions: {
                required,
                sameAs: sameAs(() => true)
            },
        }
    }
</script>

<style lang="scss" scoped>
.page-contact::v-deep {
    .questions {
        margin: 0;
        padding: 0;
        text-decoration: underline;
        cursor: pointer;

        .questions:hover {
            text-decoration-color: $primary;
        }
    }

    .form-text {
        color: $secondary;
        text-align: left;
        margin-left: 50px;
        margin-top: 70px;
        padding-bottom: 20px;
        font-weight: 800;
        font-size: 20px;
        text-decoration: none;

        @media only screen and (max-width: 767px) {
            & {
                margin-left: 10px;
            }
        }
    }

    .contact-form {
        .message-send {
            margin-top: 200px;
            margin-bottom: 200px;

            h2 {
                color: $secondary;
            }

            p {
                font-size: 14px !important;
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $small-color;
                }
            }
        }

		form {
			margin-bottom: 20px;

            > .row {
                margin-bottom: 20px;
            }

			label {
				text-align: left;
				font-size: 14px;
				font-weight: 600;
                margin-left: 20px;
			}

			input {
				color: $primary;
				border-radius: 30px;
				background: #DCDEE6;
				border: none;
				padding: 30px 20px;
				box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

				&::placeholder {
					color: $primary;
				}
			}

            textarea {
				color: $primary;
				border-radius: 30px;
				background: #DCDEE6;
				border: none;
				padding: 20px 20px;
				box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
                min-height: 120px;

				&::placeholder {
					color: $primary;
				}

                &::-webkit-scrollbar {
                    display: none;
                }
			}

            .custom-control-label {
                padding-top: 2px;
                font-weight: normal;
                cursor: pointer;

                a {
                    text-decoration: underline;
                }
            }
		}

		.btn-terms {
			padding: 10px 50px;
			margin-top: 20px;
		}

        .loader {
            margin-top: 20px;
            margin-bottom: 100px;
        }
	}

    .contact-info {
        background: $bg-gray;
        font-size: 15px;
        padding-bottom: 100px;

        .infolink {
            margin: 0;
            text-align: left;
        }

        .absolute-left {
            position: absolute;
            font-size: 12px;
            margin: 9px 5px 0 -20px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: left;

            li {
                line-height: 2;
                margin-bottom: -8px;
            }

            .first-li {
                font-weight: 600;
                line-height: 1;
                margin-bottom: 8px;
            }

            @media (min-width: 992px) {
                .space-li {
                    margin-left: 20px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .main-text {
            margin-left: 70px;
            margin-right: 70px;
            font-size: 14px;
        }

        .btn-terms {
            margin-top: 10px;
            margin-bottom: 40px;
            width: 80%;
        }

        .contact-info {
            padding-bottom: 30px;
        }

        .form-text {
            margin-top: 50px;
        }
    }
}
</style>