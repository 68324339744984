<template>
    <div class="page-forum-detail">
        <MainMenu />

        <div class="main-container container">

            <ArticleBackButton
                :button_text="'Späť na fórum'"
                :route_name="'Forum'"
            />

            <b-spinner v-if="spinner.load_forum" class="mt-5" type="grow" label="Spinning"></b-spinner>
            <b-row v-else class="forum-main-content">
                <b-col class="left" cols="12" lg="7">

                    <ArticleHead
                        :title="forum.title"
                        :publication_date="forum.publication_date"
                        :user="forum.user"
                        :categories="forum.categories"
                        :route_name="'Forum'"
                        :param="'forum'"
                    />

                    <ArticleContent
                        :main_text="forum.text"
                        :images="forumImages"
                    />

                    <ArticleRating
                        :type="'forum'"
                        :article="forum"
                        :like_count="forum.forum_likes_count"
                        :dislike_count="forum.dislikes_count"
                        :like_value="like"
                        :unlike_value="unlike"
                        :error_comment="error.comment"
                        :send_button="spinner.post_comment"
                        @article-like="forumLike()"
                        @article-unlike="forumUnlike()"
                        @post-article-comment="postForumComment($event)"
                        ref="article_rating_component"
                    />

                    <ArticleCommentsList
                        :type="'forum'"
                        :comments_list="forum_comments"
                        :pagination="pagination"
                        :load_data="spinner.load_forum_comments"
                        @handle-current-page="handlePageChange($event)"
                        @post-like="postForumCommentLike($event)"
                        @post-unlike="postForumCommentUnlike($event)"
                        @post-reply-comment="postCommentReply($event)"
                        @delete-comment="deleteForumComment($event)"
                    />
                </b-col>

                <b-col class="sidebar" cols="12" lg="5" xl="4" offset-xl="1">
                    <BannerAdvertisingVertical :banner_slug="'TPagFbT03nrHAhO'" />

                    <div class="top-forums">
                        <h3 class="title">Najčítanejšie tento mesiac</h3>
                        <div v-for="forum in topForums" :key="forum.id">
                            <ForumPostLight class="mb-5" :forum="forum" />
                        </div>
                    </div>

                    <TopPostContributor v-if="topForumUsers.length > 0" :topUsers="topForumUsers" :title="'Top prispievatelia'" />
                </b-col>
            </b-row>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import vendor from "@/assets/vendor.svg";

import MainMenu from "@/components/MainMenu";
import ForumPostLight from "@/components/forum/single/ForumPostLight";
import TopPostContributor from "@/components/TopPostContributor";
import Footer from "@/components/Footer";

import { required, minLength } from 'vuelidate/lib/validators'
import ArticleBackButton from "@/components/detail_components/ArticleBackButton";
import ArticleHead from "@/components/detail_components/ArticleHead";
import ArticleContent from "@/components/detail_components/ArticleContent";
import ArticleRating from "@/components/detail_components/ArticleRating";
import ArticleCommentsList from "@/components/detail_components/ArticleCommentsList";
import BannerAdvertisingVertical from "@/components/BannerAdvertisingVertical";

export default {
    name: 'ForumDetail',
    components: {
        ArticleContent,
        BannerAdvertisingVertical,
        ArticleHead,
        ArticleBackButton,
        ArticleRating,
        MainMenu,
        ArticleCommentsList,
        ForumPostLight,
        TopPostContributor,
        Footer,
    },
    metaInfo() {
        return {
            title: this.meta_data.title ? this.meta_data.title +  ' | '  : 'Fórum | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.meta_data.description },

                { vmid: 'og:url', name:'og:url', content: config.BASE_URL + this.$route.fullPath },
                { vmid: 'og:type', name:'og:type', content: 'forum' },
                { vmid: 'og:image', name:'og:image', content: this.$store.getters.settings.meta_logo },
                { vmid: 'og:title', name:'og:title', content: this.meta_data.title },
                { vmid: 'og:description', name:'og:description', content: this.meta_data.description },
            ]
        }
    },
    data() {
        return {
            vendor: vendor,
            asset_url: null,

            meta_data: {},

            forum: {},
            forumImages: [],
            indexImage: null,
            forumComments: [],

            like: 0,
            unlike: 0,


            pagination: {
                perPage: 30,
                currentPage: 1,
                records: 1,
            },
            forum_comments: [],

            forum_comment: '',

            topForums: [],
            topForumUsersCount: 10,
            topForumUsers: [],

            spinner: {
                load_forum: false,
                post_comment: false,
                load_forum_comments: false,
            },
            error: {
                note: null,
                comment: null,
            }
        }
    },
    watch: {
        '$route.query.currentPage': function(value) {
            this.$set(this, 'currentPage', Number(value) ? Number(value) : 1);
        },
        '$route.params.slug': function() {
            this.getForum();
        },
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        this.getForum();
        this.getForumComments();

        this.getTopForums();
        this.getTopForumUsers();
    },
    methods: {
        setMetaData(data) {
            let metaTags = {}
            let title = data.title.replace(/<[^>]+>/g, '')
            metaTags.title = title.substring(0, 150)
            let text = data.text.replace(/<[^>]+>/g, '')
            metaTags.description = text.substring(0, 150)
            this.meta_data = metaTags
        },
        getForum() {
            this.spinner.load_forum = true
            axios.get(config.API_URL + '/forum/' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('forum', response.data);
                    this.forum = response.data.success[0];

                    if (response.data.success[1].length > 0) {
                        let newImageUrl = []
                        response.data.success[1].forEach(img => {
                            let new_image_url = this.asset_url + img.folder + img.name
                            newImageUrl.push(new_image_url)
                        })
                        this.forumImages = newImageUrl
                    }

                    if (response.data.success[0].forum_likes[0]) {
                        response.data.success[0].forum_likes[0].like === 1 ? this.like = 1 : this.like = 0;
                        response.data.success[0].forum_likes[0].unlike === 1 ? this.unlike = 1 : this.unlike = 0;
                    }
                    this.setMetaData(response.data.success[0])
                    this.spinner.load_forum = false
                }, (error) => {
                    this.$errorResponse404(error.response)
                    this.spinner.load_forum = false
                });
        },
        handlePageChange(page) {
            console.log(page)
            this.pagination.currentPage = page;
            this.$scrollTo('.component-article-rating')
            this.getForumComments()
        },
        getForumComments() {
            this.spinner.load_forum_comments = true
            axios.get(config.API_URL + '/forumcomments'
                + '?slug=' + this.$route.params.slug
                + '&perpage=' + this.pagination.perPage
                + '&page=' + this.pagination.currentPage
                + '&orderBy=' + 'created_at'
                + '&ordering=' + 'DESC', config.HEADERS)
                .then((response) => {
                    console.log('toto su komenty:', response.data.success.data);
                    this.pagination.records = response.data.success.total
                    this.forum_comments = response.data.success.data;
                    this.spinner.load_forum_comments = false
                }, (error) => {
                    this.spinner.load_forum_comments = false
                    console.warn(error);
                });
        },
        postForumCommentLike(comment_id) {
            axios.post(config.API_URL + '/forum/comment/like/' + comment_id, {
                token: this.$store.getters.user.access_token.token,
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        postForumCommentUnlike(comment_id) {
            axios.post(config.API_URL + '/forum/comment/unlike/' + comment_id, {
                token: this.$store.getters.user.access_token.token,
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        postCommentReply(reply_data) {
            axios.post(config.API_URL + '/forumcomments', {
                text: reply_data.comment,
                forum_id: reply_data.forum_id,
                parent_id: reply_data.comment_id,
            }, config.HEADERS)
                .then((response) => {
                    console.log('reply comment send', response);
                    this.recoverForumComments()
                }, (error) => {
                    this.error.note = this.$errorMessage(error.response)
                    this.commentInput = 0
                });
        },
        recoverForumComments() {
            this.pagination.currentPage = 1
            this.getForumComments()
        },
        deleteForumComment(comment_id) {
            axios.delete(config.API_URL + '/forumcomments/' + comment_id, config.HEADERS)
                .then((response) => {
                    console.log('delete comment', response);
                    this.recoverForumComments()
                }, (error) => {
                    console.warn(error);
                });
        },
        postForumComment(forum_comment) {
            this.spinner.post_comment = true
            axios.post(config.API_URL + '/forumcomments', {
                forum_id: this.forum.id,
                text: forum_comment,
            }, config.HEADERS)
                .then((response) => {
                    console.log('post koment', response);
                    this.handlePageChange(1)
                    this.spinner.post_comment = false

                }, (error) => {
                    this.spinner.post_comment = false
                    console.log(error)
                });
        },
        forumLike() {
            if (this.$store.getters.user) {
                if (this.unlike === 1) {
                    this.unlike = 0;
                    this.forum.dislikes_count -= 1
                }
                if (this.like === 1) {
                    this.like = 0
                    this.forum.forum_likes_count -= 1
                } else {
                    this.like = 1;
                    this.forum.forum_likes_count += 1
                }
                this.postForumLike();
            }
        },
        forumUnlike() {
            if (this.$store.getters.user) {
                if (this.like === 1) {
                    this.like = 0;
                    this.forum.forum_likes_count -= 1
                }
                if (this.unlike === 1) {
                    this.unlike = 0
                    this.forum.dislikes_count -= 1
                } else {
                    this.unlike = 1;
                    this.forum.dislikes_count += 1
                }
                this.postForumLike();
            }
        },
        postForumLike() {
            console.log('like', this.like, this.unlike)
            axios.post(config.API_URL + '/forum/like', {
                forum_id: this.forum.id,
                like: this.like,
                unlike: this.unlike,
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        getTopForums() {
            axios.get(config.API_URL + '/forum/last/getLastPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    // console.log(response);
                    this.topForums = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
        getTopForumUsers() {
            axios.get(config.API_URL + '/user/getTopForumUsers'
                + '?quantity=' + this.topForumUsersCount, config.HEADERS)
                .then((response) => {
                    console.log('getTopForumUsers:', response.data.success);
                    this.topForumUsers = response.data.success
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
    },
    validations: {
        forum_comment: {
            required,
            minLength: minLength(5),
            // maxLength: maxLength(500)
        },
    },
}
</script>

<style scoped lang="scss">
.page-forum-detail::v-deep {
    .main-container {
        min-height: 50vh;
    }

    .forum-main-content {

        > .left {
            margin-bottom: 50px;

            hr {
                background: $secondary;
                height: 7px;
            }

            .forum_status {
                position: relative;
                background-color: $bg-gray;
                text-align: center;
                margin: 20px 0;
                padding: 20px 0;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -100vw;
                    background: $bg-gray;
                    height: 100%;
                    width: 200vw;
                    z-index: -1;
                }

                button {
                    width: 100%;
                }
            }

            .forum-content {
                background: white;
                border-radius: 15px;
                padding: 20px 30px;
                box-shadow: 0 6px 12px #ccc;
                margin-bottom: 20px;

                p {
                    text-align: justify;
                    line-height: 1.6;
                    margin-bottom: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                }

                .images {
                    box-sizing: border-box;
                    margin-top: 20px;

                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: space-around;
                    cursor: pointer;

                    img {
                        width: 48%;
                        border-radius: 10px;
                        margin-bottom: 15px;

                        max-height: 250px;
                        object-fit: cover;
                    }

                }
            }

            .rating {
                .title {
                    text-align: left;
                    margin-top: 50px;
                    color: $secondary;
                    margin-bottom: 0;
                    font-weight: bold;
                }

                .subtitle {
                    text-align: left;
                    font-size: 15px;
                    margin-top: 2px;
                }

                .buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    a {
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                    }

                    .forum_like {
                        color: $secondary;
                    }

                    .like, .dislike {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 24px;

                        span {
                            color: rgba($primary, 0.8);
                        }
                    }

                    .separate {
                        font-size: 26px;
                        margin: 0 15px;
                        color: $primary;

                        &:hover {
                            cursor: default;
                        }
                    }
                }
            }

            .no-login-info {
                text-align: left;
                color: $red;
            }

        }

        > .sidebar {
            .top-forums {
                margin-top: 70px;

                > .title {
                    font-size: 16px !important;
                    font-weight: 400 !important;
                    margin-bottom: 30px;
                }

                .single-forum-post {
                    margin-bottom: 30px;
                }
            }

            .top-forum-vendor-list {
                > .title {
                    font-size: 16px !important;
                    font-weight: 400 !important;
                    margin-top: 60px;
                    margin-bottom: 40px;
                }

                .single-vendor-vertical {
                    margin-bottom: 30px;
                }
            }
        }
    }
}
</style>