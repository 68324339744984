<template>
    <b-modal id="modal-distance" title="Chýba Vaša adresa">
        <p class="my-4">
            Momentálne nemáte zadanú v profile Vašu <strong>ADRESU</strong> pôsobenia.
            Pre zobrazenie dopytov, ktoré sa nachádzajú ku Vám <strong>najbližšie</strong>, zadajte prosím Vašu adresu.
        </p>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>
            <b-button @click="redirectToProfileUpdate()" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="map-marker-alt"></font-awesome-icon>
                Zadať ADRESU teraz
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalUpdateCity",
    data() {
        return {
            //
        }
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-distance')
        },
        redirectToProfileUpdate() {
            this.$router.push({ name: 'ProfileEdit', params: { slug: this.$store.getters.user.slug, address_input: 'address_input' }}, () => {})
        }
    }
}
</script>

<style lang="scss" scoped>
#modal-distance::v-deep {
    //
}
</style>