<template>
    <b-modal id="modal-confirm-user-select">
        <template #modal-title>
            Vybrať pre spoluprácu dodávateľa:<br>
            <strong @click="$router.push({ name: 'PageProfileAbout', params: { slug: user.slug }})">{{ user.name }}</strong> ?
        </template>

        <b-container >
            <p class="my-4">
                Chystáte sa vybrať dodávateľa pre svoj projekt.
                <strong>Po výbere už NEBUDE možné zmeniť dodávateľa.</strong>
                Pokračovať?
            </p>

        </b-container>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" size="sm" variant="primary">
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>

            <b-button @click="selectUser()" size="sm" variant="secondary">
                <font-awesome-icon icon="check"></font-awesome-icon>
                Potvrdiť výber
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalConfirmUserSelect",
    props: {
        user: {
            default: function () {
                return {}
            }
        },
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-confirm-user-select')
        },
        selectUser() {
            this.$emit('select-user', this.user.id)
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep #modal-confirm-user-select {
    color: $primary;

    .modal-header {
        strong {
            color: $secondary;
            cursor: pointer;
        }
    }

    .modal-footer {
        button {
            border-radius: 30px;

            svg {
                margin-top: -3px;
            }
        }
    }
}
</style>