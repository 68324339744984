import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home';

import Forum from "@/views/Forum";
import ForumDetail from "@/views/forum/ForumDetail";
import ForumNew from "@/views/forum/ForumNew";
import ForumUpdate from "@/views/forum/ForumUpdate";

import Demands from "@/views/Demands";
import DemandDetail from "@/views/demand/DemandDetail";
import NewDemand from "@/views/demand/NewDemand";
import DemandUpdate from "@/views/demand/DemandUpdate";
import DemandRating from "@/views/demand/DemandRating";

import Informations from "@/views/info/Informations";

import Contact from "@/views/Contact";

import Chat from "@/views/Chat";

import SignIn from "@/views/SignIn";
import SignUp from "@/views/SignUp";
import EmailVerify from "@/views/EmailVerify";
import ChangePassword from "@/views/password/ChangePassword";
import RequestChangePassword from "@/views/password/RequestChangePassword";

import Subscription from "@/views/Subscription";
import SubscriptionInvoiceInfo from "@/views/subscription/SubscriptionInvoiceInfo";
import SubscriptionSummary from "@/views/subscription/SubscriptionSummary";
import AfterPay from "@/views/subscription/AfterPay";

import AboutProject from "@/views/AboutProject";

import Search from "@/views/Search";
import Page404 from "@/views/Page404";



import PageBlog from '@/views/PageBlog';
import PageBlogMain from '@/views/blog/PageBlogMain';
import PageBlogDetail from "@/views/blog/PageBlogDetail";
import PageBlogUpdate from "@/views/blog/PageBlogUpdate";
import PageBlogCreate from "@/views/blog/PageBlogCreate";

import PageProfile from "@/views/PageProfile";
import PageProfileAbout from "@/views/profile/PageProfileAbout";
import PageProfileDemands from "@/views/profile/PageProfileDemands";
import PageProfileBlogs from "@/views/profile/PageProfileBlogs";
import PageProfileForum from "@/views/profile/PageProfileForum";
import PageProfileSubscriptions from "@/views/profile/PageProfileSubscriptions";
import ProfileEdit from "@/views/profile/ProfileEdit";
import ProfileEditDefault from "@/views/profile/ProfileEditDefault";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/blog',
        // name: 'PageBlog',
        component: PageBlog,
        children: [
            {
                path: '/blog',
                name: 'PageBlogMain',
                component: PageBlogMain,
            },
            {
                path: 'vytvorit-blog',
                name: 'PageBlogCreate',
                component: PageBlogCreate
            },
            {
                path: 'upravit-blog/:blog_slug',
                name: 'PageBlogUpdate',
                component: PageBlogUpdate
            },
            {
                path: 'detail-blog/:slug',
                name: 'PageBlogDetail',
                component: PageBlogDetail
            },
        ]
    },
    {
        path: '/profil/:slug',
        // name: 'PageProfile',
        component: PageProfile,
        children: [
            {
                path: '/profil/:slug',
                name: 'PageProfileAbout',
                component: PageProfileAbout,
            },
            {
                path: 'dopyty',
                name: 'PageProfileDemands',
                component: PageProfileDemands,
            },
            {
                path: 'blogy',
                name: 'PageProfileBlogs',
                component: PageProfileBlogs,
            },
            {
                path: 'forum',
                name: 'PageProfileForum',
                component: PageProfileForum,
            },
            {
                path: 'predplatne',
                name: 'PageProfileSubscriptions',
                component: PageProfileSubscriptions,
            },
        ],
    },
    {
        path: '/profil/:slug/upravit-firma',
        name: 'ProfileEdit',
        component: ProfileEdit
    },
    {
        path: '/profil/:slug/upravit-uzivatel',
        name: 'ProfileEditDefault',
        component: ProfileEditDefault
    },


    {
        path: '/forum',
        name: 'Forum',
        component: Forum
    },
    {
        path: '/forum/vytvorit',
        name: 'ForumNew',
        component: ForumNew
    },
    {
        path: '/forum/:slug',
        name: 'ForumDetail',
        component: ForumDetail
    },
    {
        path: '/forum/upravit/:slug',
        name: 'ForumUpdate',
        component: ForumUpdate
    },
    {
        path: '/dopyty',
        name: 'Demands',
        component: Demands
    },
    {
        path: '/dopyty/vytvorit',
        name: 'NewDemand',
        component: NewDemand
    },
    {
        path: '/dopyty/hodnotenie',
        name: 'DemandRating',
        component: DemandRating
    },
    {
        path: '/dopyty/:slug',
        name: 'DemandDetail',
        component: DemandDetail
    },
    {
        path: '/dopyty/upravit/:slug',
        name: 'DemandUpdate',
        component: DemandUpdate
    },
    {
        path: '/informacie/:slug',
        name: 'Informations',
        component: Informations
    },
    {
        path: '/kontakt',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/chat',
        name: 'Chat',
        component: Chat
    },
    {
        path: '/prihlasenie',
        name: 'Sign_in',
        component: SignIn
    },
    {
        path: '/registracia',
        name: 'Sign_up',
        component: SignUp
    },
    {
        path: '/email-verify',
        name: 'EmailVerify',
        component: EmailVerify
    },
    {
        path: '/ziadost-o-zmenu-hesla',
        name: 'RequestChangePassword',
        component: RequestChangePassword
    },
    {
        path: '/zmena-hesla',
        name: 'ChangePassword',
        component: ChangePassword
    },
    {
        path: '/predplatne/fakturacne',
        name: 'SubscriptionInvoiceInfo',
        component: SubscriptionInvoiceInfo,
    },
    {
        path: '/predplatne/zhrnutie',
        name: 'SubscriptionSummary',
        component: SubscriptionSummary,
    },
    {
        path: '/predplatne/po-zaplateni',
        name: 'AfterPay',
        component: AfterPay,
    },
    {
        path: '/predplatne',
        name: 'Subscription',
        component: Subscription,
    },
    {
        path: '/o-projekte',
        name: 'AboutProject',
        component: AboutProject
    },
    {
        path: '/vyhladavanie',
        name: 'Search',
        component: Search
    },
    {
        path: '/404',
        name: 'Page404',
        component: Page404
    },
    {
        path: '*',
        name: '404',
        beforeEnter: (to, from, next) => {
            next({ name: 'Page404' })
        }
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior() {
    //   return { x: 0, y: 0 };
    // },
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (to.name !== from.name) {
                return { x: 0, y: 0 }
            }
        }
    }
})

export default router
