<template>
    <div class="unverified-email">
        <div class="email-verification">
            <p>Aby ste mohli využívať portál eBrick naplno, musíte najprv overiť vašu emailovú adresu.</p>
            <b-button @click="sendVerificationEmail()" variant="light" pill>Overiť email</b-button>
            <p v-if="email_verified_send" class="email-send">
                Email bol úspešne odoslaný, skontrolujte prosím svoju emailovú schránku.
            </p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
    name: "UnverifiedEmail",
    data() {
        return {
            email_verified_send: false
        }
    },
    methods: {
        sendVerificationEmail() {
            axios.post(config.API_URL + '/auth/createVerifyEmail', {
                token: this.$store.getters.user.access_token.token
            }, config.HEADERS)
                .then((response) => {
                    console.log(response)
                    this.email_verified_send = true
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.unverified-email::v-deep {
    .email-verification {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        max-width: 600px;
        margin: 20px auto;
        padding: 10px;

        font-size: 12px;
        font-weight: 500;
        background-color: $secondary;
        border-radius: 10px;
        color: white;

        p {
            //margin-bottom: 0;
            text-align: center;
        }

        .email-send {
            position: relative;
            margin-bottom: 0;
        }

        button {
            margin: 0 auto 10px;
            color: $primary;
        }
    }
}
</style>