<template>
	<div class="single-vendor">
		<b-row>
			<b-col @click="$router.push({ name: 'PageProfileAbout', params: { slug: user.slug }})">
				<div class="vendor-overlay">
					<span class="vendor-img-overlay">
						<img :src="vendor" alt="dodavatel">
					</span>
					<div class="vendor">
                        <p><strong>{{ user.name }}</strong></p>
						<small class="profession">
                            <span v-for="(profession, index) in user.professions" :key="profession.id">
                                {{ profession.name }}<span v-if="index < user.professions.length - 1"> | </span>
                            </span>
                        </small>
					</div>
                    <font-awesome-icon v-if="index in [0, 1, 2]" class="medal" :class="'medal-' + index" icon="medal"></font-awesome-icon>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import vendor from "@/assets/vendor.svg"

export default {
    props: {
        user: {
            default: function () {
                return ''
            }
        },
        index: {
            default: function () {
                return ''
            }
        },
    },
	data() {
		return {
			vendor: vendor,
		}
	},
}
</script>

<style scoped lang="scss">
.single-vendor::v-deep {
    cursor: pointer;
    transition: all 0.1s ease-out;

    &:hover {
        color: $secondary;
    }

	.vendor-overlay {
		display: flex;
		flex-direction: row;

		.vendor-img-overlay {
			background: $secondary;
			display: flex;
			width: 40px;
			height: 40px;
			padding: 10px;
			border-radius: 50%;
		}

		.vendor {
			display: flex;
			flex-direction: column;
			text-align: left;
			margin-left: 20px;

			p {
				margin-bottom: 0;
			}

			small {
				font-size: 10px;
                color: $small-color;
			}

            .profession {
                @supports (-webkit-line-clamp: 1) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
		}

        .medal {
            position: absolute;
            right: 20px;
            top: 7px;

            &-0 {
                color: #f6d000;
                transform: scale(1.5);
            }
            &-1 {
                color: #7b7b7b;
                transform: scale(1.2);
            }
            &-2 {
                color: #814c04;
            }
        }
	}
}
</style>