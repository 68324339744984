<template>
    <div class="page-subscription">
        <MainMenu />

        <div class="subscription-container container">
            <h1 class="subscription-title">Predplatné a plány</h1>

            <b-row v-show="description" class="package-info" no-gutters>
                <b-col cols="12" md="2" class="box-icon">
                    <font-awesome-icon icon="box-open"></font-awesome-icon>
                </b-col>
                <b-col cols="12" md="10">
                    <p class="description" v-html="description"></p>
                </b-col>
            </b-row>

<!--            <h2>Vyberte si dobu platnosti a typ balíka:</h2>-->
<!--            <b-row class="select-package-duration" no-gutters>-->
<!--                <b-col cols="6" md="3">-->
<!--                    <div @click="setPackageDuration(0)" class="item" :class="{'active-item': package_duration === 0}">1 mesiac</div>-->
<!--                </b-col>-->
<!--                <b-col cols="6" md="3">-->
<!--                    <div @click="setPackageDuration(1)" class="item" :class="{'active-item': package_duration === 1}">3 mesiace</div>-->
<!--                </b-col>-->
<!--                <b-col cols="6" md="3">-->
<!--                    <div @click="setPackageDuration(2)" class="item" :class="{'active-item': package_duration === 2}">6 mesiacov</div>-->
<!--                </b-col>-->
<!--                <b-col cols="6" md="3">-->
<!--                    <div @click="setPackageDuration(3)" class="item" :class="{'active-item': package_duration === 3}">12 mesiacov</div>-->
<!--                </b-col>-->
<!--            </b-row>-->
        </div>
<!--        <span class="rectangle"></span>-->

        <b-spinner v-if="spinners.load_packages" class="mt-5 mb-5" type="grow" label="Spinning"></b-spinner>
        <div v-else class="subscription-packages-list container">
            <b-row class="package-items">
<!--                <b-col cols="12" sm="8" offset-sm="2" lg="3" offset-lg="0" v-for="(product, index) in products" :key="product.id">-->
<!--                    <SubscriptionDetail-->
<!--                        :product="product.product_frekvence_types[index]"-->
<!--                        :button_visible="true"-->
<!--                        :recommended="product.recommended"-->
<!--                        @package_select="goNextStep($event)"-->
<!--                    />-->
<!--                </b-col>-->
                <b-col cols="12" sm="8" offset-sm="2" lg="3" offset-lg="0" v-for="product in products" :key="product.id">
                    <SubscriptionDetail
                        :product="product.product_frekvence_types[0]"
                        :button_visible="true"
                        :recommended="product.recommended"
                        @package_select="goNextStep($event)"
                    />
                </b-col>
            </b-row>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import SubscriptionDetail from "@/components/subscription/SubscriptionDetail";
import Footer from '@/components/Footer';

export default {
    name: "Subscription",
    components: {
        MainMenu,
        SubscriptionDetail,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Predplatné | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_subscription },
            ]
        }
    },
    data() {
        return {
            description: '',
            package: 'provider_package',
            products: [],

            spinners: {
                load_packages: false,
            }
        }
    },
    created() {
        this.getTextArea()
    },
    mounted() {
        this.getCartItems(this.package)
    },
    methods: {
        getTextArea() {
            axios.get(config.API_URL + '/textArea/get/subscription-description', config.HEADERS)
                .then((response) => {
                    console.log(response)
                    this.description = response.data.success.text
                }, (error) => {
                    console.warn(error);
                });
        },
        getCartItems(slug) {
            this.spinners.load_packages = true
            // axios.get(config.API_URL + '/products/list', config.HEADERS)
            axios.get(config.API_URL + '/products/getOneProduct?slug=' + slug, config.HEADERS)
                .then((response) => {
                    console.log('subs card', response.data.success)
                    this.products = response.data.success.product_frekvences
                    this.spinners.load_packages = false
                }, (error) => {
                    this.spinners.load_packages = false
                    console.warn(error);
                });
        },
        goNextStep(selected_card) {
            this.$router.push({ name: 'SubscriptionInvoiceInfo', query: { package: selected_card } }, () => {})
        },
    }
}
</script>

<style lang="scss" scoped>
.page-subscription::v-deep {
    .subscription-container {
        .subscription-title {
            text-align: left;
            color: $primary;
            margin-bottom: 30px;
        }

        .package-info {
            position: relative;
            color: $secondary;
            margin-bottom: 50px;
            padding: 20px;

            .box-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 60px;

                svg {
                    transform: scale(4.5);
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .description {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;

                p {
                    margin-bottom: 0;
                }

                strong {
                    font-weight: 800;
                    text-decoration: underline;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -100vw;
                background: rgba($secondary, 0.1);
                height: 100%;
                width: 200vw;
            }
        }
    }

    .subscription-packages-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;

        .package-items {
            width: 100%;
        }
    }
}
</style>