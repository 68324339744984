<template>
    <div class="component-demand-detail-info-panel">
        <b-row class="demand-detail-info-panel">
            <b-col cols="12" lg="4" v-if="$view_demand_info">
                <font-awesome-icon class="demand-detail-info-panel__svg" icon="user"></font-awesome-icon>
                Pridal:
            </b-col>
            <b-col cols="12" lg="8" v-if="$view_demand_info">
                <a v-if="demand.user" @click="$router.push({ name: 'PageProfileAbout', params: { slug: demand.user.slug }})" class="user_name">
                    <strong>{{ demand.user.name }}</strong>
                </a>
            </b-col>
            <b-col v-if="$view_demand_info" cols="12" lg="4">
                <font-awesome-icon class="demand-detail-info-panel__svg locality-svg" icon="map-marker-alt"></font-awesome-icon>
                Lokalita:
            </b-col>
            <b-col v-if="$view_demand_info" cols="12" lg="8">
                {{ demand.place }}
            </b-col>


            <b-col cols="12" lg="4">
                <font-awesome-icon class="demand-detail-info-panel__svg" icon="calendar-check"></font-awesome-icon>
                Termín dokončenia:
            </b-col>
            <b-col cols="12" lg="8">
                {{ moment(demand.expected_date).locale('sk').format("LL") }}
            </b-col>

            <b-col cols="12" lg="4">
                <font-awesome-icon class="demand-detail-info-panel__svg" icon="layer-group"></font-awesome-icon>
                Kategória:
            </b-col>
            <b-col cols="12" lg="8">
                <span
                    v-for="(category, index) in demand.categories"
                    :key="category.id"
                    class="category-link"
                    @click="$router.push({ name: 'Demands', params: { demands: 'demands' }, query: { category: '[' + category.menu_item.id + ']' }})"
                >
                    <strong>{{ category.menu_item.name }}</strong><span v-if="index < demand.categories.length - 1">,</span>
                </span>
            </b-col>

            <b-col v-if="demand.amount" cols="12" lg="4">
                <font-awesome-icon class="demand-detail-info-panel__svg" icon="tag"></font-awesome-icon>
                Cena:
            </b-col>
            <b-col v-if="demand.amount" cols="12" lg="8">
                {{ demand.amount }} €
            </b-col>

            <b-col v-if="demand.updated_at" cols="12" class="last-update">
                <hr class="last-update__hr">
                <small>
                    <em>Posledná aktualizácia: {{ moment(demand.updated_at).locale('sk').format("LL") }}</em>
                </small>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "DemandDetailInfoPanel",
    props: {
        demand: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        $view_demand_info() {
            let permission = false
            if (this.$store.getters.user) {
                if (this.demand.user_id === this.$store.getters.user.user_id) {
                    permission = this.$store.getters.permissions('view own demands')
                } else {
                    permission = this.$store.getters.permissions('view demands')
                }
            }
            return permission
        }
    }
}
</script>

<style lang="scss" scoped>
.component-demand-detail-info-panel::v-deep {
    margin: 30px 10px;
    text-align: left;

    .demand-detail-info-panel {
        margin-left: 0;

        > div {
            position: relative;
            margin-bottom: 5px;
        }

        .user_name {
            cursor: pointer;
        }

        .category-link {
            cursor: pointer;
        }

        &__svg {
            color: $secondary;
            position: absolute;
            left: -9px;
            top: 3px;
        }

        &__svg.locality-svg {
            left: -8px;
        }

        .last-update {
            text-align: center;
            margin-bottom: 0;

            &__hr {
                margin: 0;
            }
        }

        @media (max-width: 991px) {
            margin: 0;
        }
    }
}
</style>