<template>
    <div class="component-portal-admin">
        <div class="container">
            <b-row>
                <b-col cols="12" lg="6" class="founder-image">
                    <img class="image" :src="logo" alt="">
                </b-col>
                <b-col cols="12" lg="6" class="founder-info">
                    <b-row>
                        <!--                            <b-col cols="12" class="founder-type">Zakladateľ portálu</b-col>-->
                        <b-col cols="12" class="founder-name">
                            Tím eBrick
                            <span class="rectangle"></span>
                        </b-col>
                        <b-col cols="12" class="founder-text">
                            „Vytvorením projektu <span>eBrick</span> chceme na jednej strane poskytnúť zákazníkom jednoduchší spôsob realizácie ich stavebných projektov
                            a na strane druhej uľahčiť stavebným firmám prísun nových pracovných príležitostí.
                        </b-col>
                        <b-col cols="12" class="founder-desc">
                            <span>Rýchle, Relevantné, Jasné, Transparentné a Efektívne</span>
                            spojenie medzi zákazníkom a dodávateľom je to čo nám chýba v stavebníctve,
                            a čo je našou hlavnou motiváciou pri tvorbe tohto portálu".
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import ebrick_logo from "@/assets/ebrick_logo.svg";

export default {
    name: "PortalAdmin",
    data() {
        return {
            logo: ebrick_logo,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-portal-admin::v-deep {
    padding: 80px;
    background-color: $bg-gray;
    margin-top: 100px;
    margin-bottom: 50px;

    .container .row {
        align-items: center;
    }

    .founder-image {
        display: flex;
        justify-content: flex-end;

        .image {
            margin-right: 15px;
            width: 100%;
            height: 100%;

            //@media (max-width: 991px) {
            //    margin-right: 0;
            //}
        }

        //@media (max-width: 991px) {
        //    justify-content: center;
        //}
    }

    .founder-info {
        .row {
            text-align: left;

            > div {
                margin: 0;
                padding: 0;
            }

            .founder-type {
                font-size: 15px;
                font-weight: bold;

                //@media (max-width: 992px) {
                //    text-align: center;
                //    margin-top: 30px;
                //}
            }

            .founder-name {
                color: $secondary;
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 35px;
                margin-top: -2px;

                .rectangle {
                    margin-top: 10px;
                    display: block;
                    width: 67px;
                    height: 9px;
                    background-color: $secondary;

                    //@media (max-width: 992px) {
                    //    position: absolute;
                    //    left: 50%;
                    //    transform: translate(-50%, 0);
                    //}
                }

                //@media (max-width: 992px) {
                //    margin-bottom: 60px;
                //    text-align: center;
                //}
            }

            .founder-text {
                margin-bottom: 15px;

                span {
                    font-weight: 800;
                    color: $secondary;
                }

            }

            .founder-desc {
                font-weight: bold;

                span {
                    font-size: 19px;
                    color: $secondary;
                    font-weight: 800;
                }
            }
        }
    }
}
</style>