/**
 * Base init
 */
import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import './pwa/registerServiceWorker'
import router from './router'
import store from './store'

/**
 * Bootstrap init
 */
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/scss/base.scss'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

/**
 * Vue select2 init
 */

import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);

/**
 * Font Awesome init
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/**
 * Vue scroll init
 */
import VueScrollTo from 'vue-scrollto';

Vue.use(VueScrollTo, {
	container: "body",
	duration: 500,
	easing: "ease",
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
});

/**
 * Vue pagination init
 */
import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

/**
 * Vue moment
 */
import moment from 'moment';
moment.locale('sk');
Vue.prototype.moment = moment;

/**
 * Vue Vuelidate
 */
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

/**
 * Vue Gallery
 */
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)

/**
 * Notifications
 */
import Notifications from 'vue-notification'
// import {identity} from "@riophae/vue-treeselect/src/utils";
Vue.use(Notifications)

/**
 * Globally Functions
 */
Vue.prototype.$errorMessage = function(error) {
	if (Number(error.status) === 401) {
		store.dispatch('logoutUser');
	} else {
		let errors = error.data.error
		let errorsLength = Object.keys(errors).length
		let newErrors = []

		for (let i = 1; i <= errorsLength; i++) {
			newErrors.push(errors[i].message)
		}
		return newErrors
	}
}
Vue.prototype.$errorResponse404 = function(error) {
	if (Number(error.status) === 404) {
		this.$router.replace({ name: 'Page404' })
	}
}

/**
 * Vue Meta
 */
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

/**
 * Vue Social Sharing
 */
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

/**
 * Google oauth2
 */
// import GAuth from 'vue-google-oauth2'
// const gauthOption = {
// 	clientId: '468169474330-netmc7obhfjuicv4nv2muduqdt2b1nb5.apps.googleusercontent.com',
// 	scope: 'profile email',
// 	prompt: 'select_account',
// 	fetch_basic_profile: true
// }
// https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?
// client_id=468169474330-netmc7obhfjuicv4nv2muduqdt2b1nb5.apps.googleusercontent.com
// &redirect_uri=https%3A%2F%2Fapp.ebrick.wame.sk%2F
// &scope=openid%20profile%20email
// &response_type=code
// &state=XL7vWlqdM4lcG2Ds8sMTTIBUwdIXpVxRAGUHeTGw
// &flowName=GeneralOAuthFlow

// prompt: 'select_account'
// Vue.use(GAuth, gauthOption)

/**
 * Production mode
 * @type {boolean}
 */
Vue.config.productionTip = false

/**
 * Vue app init
 */
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
