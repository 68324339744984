<template>
	<div class="single-post">
        <a @click="$router.push({ name: 'PageBlogDetail', params: { slug: blog.slug } }, () => {})">
			<img v-if="blog.main_picture_path" class="post-image" :src="asset_url + blog.main_picture_path" alt="obrazok">
			<img v-else class="post-image" :src="logo" alt="obrazok">

			<div class="post-author">
				<span class="vendor-img-overlay">
					<img :src="vendor" alt="dodavatel">
				</span>
			</div>

			<div class="post-content">
				<p class="text-left mb-2">
					<small v-if="blog.user">
						{{ blog.user.name }}
                        <span v-if="blog.publication_date"> | {{ moment(blog.publication_date).format("LL") }}</span>
					</small>
				</p>

				<h3 class="text-left text-truncate">{{ blog.title }}</h3>

				<div class="categories">
					<p class="text-left" v-for="category in blog.categories" :key="category.id">
						<small>{{ category.menu_item.name }} </small>
					</p>
				</div>


				<div class="bottom-bar">
					<div class="left">
						<font-awesome-icon icon="thumbs-up"></font-awesome-icon>
						<small>{{ blog.blog_likes_count }} |</small>
						<font-awesome-icon icon="thumbs-down"></font-awesome-icon>
						<small>{{ blog.dislikes_count }}</small>
					</div>
					<div class="right">
						<small>{{ blog.comments_count }}</small>
						<font-awesome-icon icon="comments"></font-awesome-icon>
					</div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import vendor from "@/assets/vendor.svg";
import config from "@/config";
import ebrick_logo from "@/assets/ebrick_logo.svg"

export default {
	props: {
		'blog': {
			default: function () {
				return {}
			}
		}
	},
	data() {
		return {
            logo: ebrick_logo,
			vendor: vendor,
            asset_url: null,
		}
	},
    mounted() {
        this.$set(this, 'asset_url', config.ASSET_URL + '/');
    }
}
</script>

<style scoped lang="scss">
.single-post::v-deep {
	position: relative;
	border-radius: 15px;
	background: #fff;
	box-shadow: 0 6px 12px rgba(14, 43, 76, .08);
	overflow: hidden;
	z-index: 5;

    a {
        cursor: pointer;
    }

	img.post-image {
		height: 200px;
		width: 100%;
		object-fit: cover;
	}

	.post-author {
		position: relative;

		.vendor-img-overlay {
			background: $secondary;
			position: relative;
			display: flex;
			width: 40px;
			height: 40px;
			margin-top: -25px;
			margin-left: auto;
			margin-right: auto;
			padding: 10px;
			border-radius: 50%;
			box-shadow: 0 6px 14px rgba(155, 65, 58, .2);
			z-index: 2;

			img {
				transform: scale(0.8);
				margin-left: -1px;
			}
		}

		&:before {
			content: "";
			background: $secondary;
			position: absolute;
			top: 20px;
			left: 0;
			height: 9px;
			width: 100%;
		}
	}

	.post-content {
		padding: 0 20px 20px;

		h3 {
			font-weight: 400 !important;
			font-size: 22px !important;
			min-height: 52px;
			height: auto;

			@supports (-webkit-line-clamp: 2) {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: initial;
				display: -webkit-box !important;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		.categories {
			display: flex;
            flex-wrap: wrap;

			p {
				margin-bottom: 0;
                white-space: nowrap;
	
				> small {
					color: $small-color;
					margin-right: 5px;
				}
			}
		}


		.bottom-bar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.left {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
					margin-right: 5px;
					font-size: 13px;
				}

				small {
					color: $small-color;
					margin-right: 5px;
				}
			}

			.right {
				display: flex;
				flex-direction: row;
				align-items: center;

				svg {
					font-size: 13px;
				}

				small {
					color: $small-color;
					margin-right: 5px;
				}
			}
		}
	}
}
</style>