import { render, staticRenderFns } from "./NotificationCount.vue?vue&type=template&id=2e42fa83&scoped=true&"
import script from "./NotificationCount.vue?vue&type=script&lang=js&"
export * from "./NotificationCount.vue?vue&type=script&lang=js&"
import style0 from "./NotificationCount.vue?vue&type=style&index=0&id=2e42fa83&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e42fa83",
  null
  
)

export default component.exports