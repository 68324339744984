<template>
    <div class="component-demand-user-react">
        <div :class="{ 'user-reacted': react_user.user_reacted_id === user.id }">
            <b-row class="user-info">
                <b-col cols="12" class="user">
                    <font-awesome-icon icon="home"></font-awesome-icon>
                    <span @click="$router.push({ name: 'PageProfileAbout', params: { slug: user.slug }})">
                        {{ user.name }}
                    </span>
                </b-col>
                <b-col class="select-buttons" cols="12">
                    <b-button @click="$router.push({ name: 'Chat', params: { room_id: user.id } }, () => {})" class="btn-terms primary open-chat" pill variant="primary">
                        <font-awesome-icon class="go-chat" icon="comments"></font-awesome-icon>
                        Otvoriť chat
                    </b-button>
                    <b-button v-if="!react_user.user_reacted_id" v-b-modal.modal-confirm-user-select class="primary select-user" pill>
                        Vybrať tohto dodávateľa
                    </b-button>

                    <ModalConfirmUserSelect :user="user" @select-user="chooseUser" />
                </b-col>
            </b-row>

            <b-row class="user-react-note" no-gutters>
                <b-col cols="12" lg="2" class="my-note">
                    Poznámka k dodávateľovi:
                </b-col>
                <b-col cols="12" lg="9" class="my-note-text">
                    <div v-if="!note_update" class="update-note">
                        <span>{{ demand_note }}</span>
                    </div>
                    <b-form v-else @submit.prevent="postUserNote()">
                        <b-form-input
                            type="text"
                            v-model="demand_note"
                            placeholder="Text..."
                        ></b-form-input>
                        <div class="error-message-l" v-show="$v.demand_note.$error">
                            <div v-if="!$v.demand_note.required || !$v.demand_note.minLength">Zadajte minimálne {{ $v.demand_note.$params.minLength.min }} znakov.</div>
                            <div v-if="!$v.demand_note.maxLength">Zadajte minimálne {{ $v.demand_note.$params.maxLength.max }} znakov.</div>
                        </div>
                    </b-form>
                </b-col>
                <b-col v-if="!note_update" cols="4" offset="4" lg="1" offset-lg="0" class="icons">
                    <font-awesome-icon @click="updateNote()" icon="pen"></font-awesome-icon>
                    <font-awesome-icon @click="deleteUserNote()" icon="trash-alt"></font-awesome-icon>
                </b-col>
                <b-col v-else cols="4" offset="4" lg="1" offset-lg="0" class="icons">
                    <font-awesome-icon @click="postUserNote()" icon="check"></font-awesome-icon>
                    <font-awesome-icon @click="cancelUpdate()" icon="times"></font-awesome-icon>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import ModalConfirmUserSelect from '@/components/demands/modal/ModalConfirmUserSelect.vue'
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
    name: "DemandUserReact",
    components: {
        ModalConfirmUserSelect,
    },
    props: {
        user: {
            default: function () {
                return {}
            }
        },
        react_user: {
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            note_update: false,
            demand_note: '',
        }
    },
    mounted() {
        if (this.user.user_reacted_note[0] && this.user.user_reacted_note[0].note) {
            this.demand_note = this.user.user_reacted_note[0].note
        } else {
            this.note_update = true
        }
    },
    methods: {
        updateNote() {
            this.note_update = !this.note_update
        },
        cancelUpdate() {
            this.note_update = false
            this.demand_note = this.user.user_reacted_note[0].note
        },
        chooseUser(user_id) {
            const myData = new FormData();
            myData.set('user_id', user_id);
            myData.set('demand_id', this.user.pivot.demand_id);

            axios.post(config.API_URL + '/demand/user/chooseUser', myData, config.HEADERS)
                .then((response) => {
                    console.log('select user', response);
                    this.$router.push({ name: 'Chat', params: { room_id: response.data.success.user_reacted_id } }, () => {})
                }, (error) => {
                    console.warn(error);
                });
        },
        postUserNote() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            const myData = new FormData();
            myData.set('user_id', this.user.id);
            myData.set('note', this.demand_note);

            // for (let pair of myData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            let url = '/demand/noteForUserReactedDemand'
            if (this.user.user_reacted_note[0] && this.user.user_reacted_note[0].note) {
                url += '/' + this.user.user_reacted_note[0].id
            }

            axios.post(config.API_URL + url, myData, config.HEADERS)
                .then((response) => {
                    console.log('react user note', response);
                    this.user.user_reacted_note[0] = response.data.success
                    this.note_update = false
                }, (error) => {
                    console.warn(error);
                });
        },
        deleteUserNote() {
            axios.post(config.API_URL + '/demand/noteForUserReactedDemand/delete/' + this.user.user_reacted_note[0].id, {
                token: this.$store.getters.user.access_token.token
            }, config.HEADERS)
                .then((response) => {
                    console.log('delete note ', response);
                    this.user.user_reacted_note = []
                    this.demand_note = ''
                    this.note_update = true
                    this.$v.$reset()
                }, (error) => {
                    console.warn(error);
                });
        },
    },
    validations: {
        demand_note: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(150),
        },
    },
}
</script>

<style lang="scss" scoped>
.component-demand-user-react::v-deep {
    margin-top: 20px;
    margin-bottom: 20px;

    > div {
        margin-bottom: 10px;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
        height: 2px;
        width: 96%;
        background-size: 8px 2px;
        background-repeat: repeat-x;
        background-image: linear-gradient( 90deg, #0e2b4c 0, rgba(255, 255, 255, 0) 30%);
        background-position: bottom;
    }

    .user-reacted {
        background-color: rgba($secondary, 0.1);
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 20px;
    }

    .user-info {
        margin: 10px 0;

        .user {
            text-align: left;

            span {
                font-weight: bold;
                font-size: 17px !important;
                cursor: pointer;
            }

            svg {
                margin-top: -5px;
                margin-right: 10px;
            }
        }

        .select-buttons {
            text-align: left;

            .go-chat {
                margin-top: -3px;
            }

            button.open-chat, button.select-user {
                padding: 0 20px;
                margin-bottom: 0;
                float: none;
            }
            button.select-user {
                margin-left: 20px;
            }
        }

        @media (max-width: 991px) {
            .user {
                text-align: center;
            }

            .select-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    margin-top: 5px;
                }

                button.select-user {
                    margin-left: 0;
                }
            }
        }
    }
    
    .row {
        margin: 0;
    }

    .user-react-note {
        display: flex;
        align-items: center;

        margin: 10px 20px 0 20px;

        .my-note {
            font-size: 14px;
            color: $secondary;
            margin-top: 5px;
            text-align: left;
        }

        .my-note-text {
            padding: 0 20px;

            .update-note {
                display: flex;
                align-items: center;
                background-color: rgba($secondary, 0.5);
                padding: 5px 10px;
                border-radius: 10px;
            }

            @media (max-width: 992px) {
                padding: 0;
            }
        }

        .icons {
            display: flex;
            justify-content: space-between;

            svg {
                cursor: pointer;
                margin-top: -3px;
            }

            @media (max-width: 992px) {
                margin-top: 10px;
                justify-content: space-around;

                svg {
                    margin-top: 0;
                }
            }
        }



        form {
            //display: flex;
            //flex-direction: row;
            //align-items: center;

            input {
                border-radius: 5px;
                padding: 5px 15px;
                height: 30px;
                width: 100%;
                overflow: hidden;
                box-shadow: 3px 5px 10px rgba(14, 43, 76, 0.1);

                @media (max-width: 991px) {
                    height: 35px;
                }
            }

            svg {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
}
</style>