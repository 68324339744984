<template>
    <div class="component-demands-list container">
<!--        <div v-if="$store.getters.user.slug && $store.getters.permissions('view demands')">-->
<!--            <div v-if="$store.getters.user.slug && $store.getters.user.email_verified_at !== null" id="demands">-->

                <div>
                    <div class="demand-cards" v-for="demand in demands" :key="demand.id">
                        <DemandCard :demand="demand" />
                    </div>

                    <VuePagination
                        :per_page="pagination.perPage"
                        :current_page="pagination.currentPage"
                        :records="pagination.records"
                        :load_data="load_data"
                        @handle-current-page="handlePageChange($event)"
                    />
                        <!-- <a href="#demands" class=""> -->
<!--                        <pagination-->
<!--                            v-model="currentPage"-->
<!--                            :per-page="perPage"-->
<!--                            :records="records"-->
<!--                            :options="{-->
<!--                                        chunk: 5,-->
<!--                                        theme: 'bootstrap4',-->
<!--                                        edgeNavigation: false,-->
<!--                                        texts: {-->
<!--                                            count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',-->
<!--                                            //count: 'Stránka {page} z {pages}',-->
<!--                                            first: 'Prvá',-->
<!--                                            last: 'Posledná'-->
<!--                                        }-->
<!--                                    }"-->
<!--                            @paginate="handlePageChange"-->
<!--                        ></pagination>-->
                        <!-- </a> -->
                </div>

<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import DemandCard from '@/components/demands/single/DemandCard';
import VuePagination from "@/components/VuePagination";

export default {
    name: "DemandsList",
    components: {
        VuePagination,
        DemandCard,
    },
    props: {
        demands: {
            type: Array,
            default() {
                return []
            }
        },
        pagination: {
            type: Object,
            default() {
                return {}
            }
        },
        load_data: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        handlePageChange(page) {
            this.$emit('handle-current-page', page)
        },
    }
}
</script>

<style lang="scss" scoped>
.component-demands-list::v-deep {
    //
}
</style>