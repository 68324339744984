<template>
    <div class="demand-card">
        <div :class="{'demand-reaction': reaction}">

            <div v-if="$store.getters.user" @click.prevent="addToFavorite()" class="demand-bookmark">
                <button :class="favorite ? 'favorite-demand' : 'non-favorite-demand'">
                    <font-awesome-icon v-if="favorite" id="bookmark_favorite" class="absolute-left" icon="bookmark" color="white"></font-awesome-icon>
                    <font-awesome-icon v-else id="bookmark" class="absolute-left" icon="bookmark" color="#9B413A"></font-awesome-icon>
                </button>
                <span v-if="favorite" class="favorite-text">Pridané do obľúbených</span>
                <span v-else class="favorite-text">Pridať do obľúbených</span>
            </div>

            <a @click="goDemandDetail(demand.slug)">
                <span class="top-frame"></span>

                <div v-if="$store.getters.user && reaction" class="demand-reaction-status">
                    <span>reagoval/a som</span>
                </div>

                <b-row class="demand-text">
                    <b-col cols="10" offset="1">
                        <h3 class="title">{{ demand.title }}</h3>
                    </b-col>
                    <b-col v-if="$store.getters.user.slug === demand.user.slug" cols="10" offset="1" class="status-reaction">
                        <div class="status">
                            <span v-if="demand.state_id === 1" class="no-activ">Neaktívny</span>
                            <span v-if="demand.state_id === 2" class="deactiv">Zrušený</span>
                            <span v-if="demand.state_id === 3" class="activ">Aktívny</span>
                            <span v-if="demand.state_id === 4" class="finished-yes">Ukončený, dodaný</span>
                            <span v-if="demand.state_id === 5" class="finished-no">Ukončený, nedodaný</span>
                            <span v-if="demand.state_id === 6" class="in-process">V procese</span>
                        </div>

                        <div v-if="demand.demand_in_procces_user !== null && demand.demand_in_procces_user.reacted_user.name">
                            Dodávateľ: <strong>{{ demand.demand_in_procces_user.reacted_user.name }}</strong>
                        </div>
                        <div v-else>
                            Počet reakcií: <strong>{{ demand.demand_reacteds_count }}</strong>
                        </div>
                    </b-col>
                    <b-col v-if="$store.getters.user.slug === demand.user.slug" cols="10" offset="1">
                        <p v-if="demand.publication_date" class="date">{{ moment(demand.publication_date).format("LL") }}</p>
                        <p v-else class="no-publication-demand"><em>Dopyt ešte nebol zverejnený</em></p>
                    </b-col>
                    <b-col cols="10" offset="1">
                        <p v-html="demand.project_description" class="main-text"></p>
                    </b-col>
                </b-row>

                <b-row class="demand-info">
                    <b-col cols="10" offset="1" lg="auto" offset-lg="1">
                        <font-awesome-icon icon="user" color="$secondary"></font-awesome-icon>
                        <span>{{ demand.user.name }}</span>
                    </b-col>
                    <b-col cols="10" offset="1" lg="auto" offset-lg="0">
                        <font-awesome-icon icon="map-marker-alt" color="$secondary"></font-awesome-icon>
                        <span>{{ demand.place }}</span>
                    </b-col>
                    <b-col cols="10" offset="1" lg="auto" offset-lg="0">
                        <font-awesome-icon icon="calendar-check" color="$secondary"></font-awesome-icon>
                        <span class="expected-date">{{ moment(demand.expected_date).format("L") }}</span>
                    </b-col>
                </b-row>
            </a>

            <b-row v-if="demand.demand_notes" class="demand-note">
                <b-col cols="10" offset="1">
                    <DemandNote
                        v-if="spinner.loader"
                        :demand_id="demand.id"
                        :demand_note="demand.demand_notes[0]"
                    />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import DemandNote from "@/components/demands/single/DemandNote";

export default {
    name: 'DemandCard',
    components: {
        DemandNote
    },
    props: {
		'demand': {
			default: function () {
				return {}
			}
		}
	},
    data() {
        return {
            reaction: false,
            favorite: false,

            demand_note: '',

            spinner: {
                loader: true,
            }
        }
    },
    mounted() {
        if (this.demand.demand_reacteds) this.reaction = !!this.demand.demand_reacteds[0];
        this.favorite = !!this.demand.demand_favourites[0];
    },
    methods: {
        addToFavorite() {
            axios.post(config.API_URL + '/demand/addFavourite/' + this.demand.id, {
                token: this.$store.getters.user.access_token,
            }, config.HEADERS)
            .then((response) => {
                console.log(response);
                this.favorite = !this.favorite;
            }, (error) => {
                console.warn(error);
            });
        },
        goDemandDetail(demand_slug) {
            this.$router.push({ name: 'DemandDetail', params: { slug: demand_slug } })
        },
    },
}
</script>

<style lang="scss" scoped>
.demand-card::v-deep {
    position: relative;
    margin-top: 50px;
    width: 100%;
    max-width: 900px;

    background-color: white;
    box-shadow: 0 12px 17px rgba(14, 43, 76, .16);
    border-radius: 0  0 20px 20px;

    a:hover {
        cursor: pointer;
    }

    .demand-reaction {
        border: 1px solid $secondary;
        border-top: 0;
        border-radius: 0 0 20px 20px;
        background-color: rgba($secondary, 0.1);
    }

    .demand-bookmark {
        position: absolute;
        right: 65px;

        display: flex;
        flex-direction: column;
        align-items: center;

        width: 180px;
        margin-top: -20px;
        color: $secondary;

        @media (max-width: 991px) {
            right: 0
        }

        .favorite-demand,
        .non-favorite-demand {
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            box-shadow: 2px 3px 10px 3px rgba($secondary, .3);
            outline: none;
            border: none;

            svg {
                transform: scale(1.5);
            }
        }

        .favorite-demand {
            background-color: $secondary;
        }

        .non-favorite-demand {
            background-color: white;
        }

        .favorite-text {
            margin-top: 5px;
            font-size: 11px;
            font-weight: lighter;
        }
    }

    .top-frame {
        display: block;
        width: 100%;
        height: 10px;
        background-color: $secondary;
    }

    .row {
        margin: 0;
    }

    .demand-reaction-status {
        position: absolute;
        left: 0;

        margin-top: -10px;
        margin-left: 30px;
        width: 130px;

        background-color: $secondary;
        color: white;
        border-radius: 0 0 15px 15px;

        span {
            font-size: 12px;
        }

        @media (min-width: 991px) {
            margin-left: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    .demand-text {
        text-align: left;
        margin-top: 30px;

        @media (max-width: 991px) {
            margin-top: 50px;
        }

        .title {
            font-size: 19px !important;
            color: $secondary;
            margin-top: 15px;

            @media (max-width: 991px) {
                margin-top: 0;
            }
        }

        .status-reaction {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $secondary;

            @media (max-width: 480px) {
                align-items: flex-start;
                flex-direction: column;
            }
        }

        .status {
            padding: 5px 0;

            span {
                padding: 5px 20px;
                border-radius: 30px;
                margin: 5px 0 5px 0;
                color: white;
                font-size: 14px;
            }
            .no-activ {
                background-color: $secondary;
            }
            .deactiv {
                //background-color: $small-color;
                background-color: #888888;
            }
            .activ {
                background-color: $primary;
            }
            .finished-yes {
                background-color: $green;
            }
            .finished-no {
                background-color: $red;
            }
            .in-process {
                background-color: $primary;
            }
        }

        .date {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
        }

        .no-publication-demand {
            font-size: 12px;
            margin-bottom: 0;
        }

        .main-text {
            line-height: 1.6;
            font-size: 15px;
            margin: 0;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            p {
                margin: 0;
            }

            @supports (-webkit-line-clamp: 3) {
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    .demand-info {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;

        @media (max-width: 991px) {
            font-size: 14px !important;
        }

        > div {
            padding-right: 0px;

            @media (max-width: 991px) {
                margin-bottom: 5px;
            }

            > span {
                padding-left: 25px;
            }

            &:not(:last-child):after {
                content: " ";
                display: block;
                float: right;
                margin-left: 10px;

                width: 2px;
                height: 90%;
                background-color: $secondary;
                border-radius: 10px;

                @media (max-width: 991px) {
                    visibility: hidden;
                }
            }

            svg {
                position: absolute;
                color: $secondary;
                transform: scale(1.2);

                margin-top: 2px;
            }

            &:nth-child(1) {
                svg {
                    margin-left: -2px;
                }
            }
            &:nth-child(2) {
                svg {
                    margin-left: 1px;
                }
            }
        }
    }

    .demand-note {
        margin-bottom: 10px;

        form {
            input {
                padding: 10px 15px;
            }
        }
    }

    .user-react-note {
        margin-bottom: 15px;
    }
}
</style>