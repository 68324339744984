<template>
    <div class="page-blog-detail">
        <div class="blog-detail-container container">

            <ArticleBackButton
                :button_text="'Späť na blog'"
                :route_name="'PageBlogMain'"
            />

            <b-spinner v-if="spinner.load_blog" class="mt-5 mb-5" type="grow" label="Spinning"></b-spinner>
            <b-row v-else>
                <b-col class="left-col" cols="12" lg="7">

                    <ArticleHead
                        :title="blog.title"
                        :publication_date="blog.publication_date"
                        :user="blog.user"
                        :categories="blog.categories"
                        :route_name="'PageBlogMain'"
                        :param="'blog'"
                    />

                    <BlogStatusController
                        v-if="blog.user_id === $store.getters.user.user_id && !blog.publication_date"
                        :blog="blog"
                        @delete-blog="deleteBlog($event)"
                        @change-blog-status="changeBlogStatus($event)"
                    />

                    <ArticleContent
                        :main_image="blog.main_picture_path"
                        :main_text="blog.text"
                        :images="images"
                    />

                    <ArticleRating
                        :type="'blog'"
                        v-if="blog.publication_date"
                        :article="blog"
                        :like_count="blog.blog_likes_count"
                        :dislike_count="blog.dislikes_count"
                        :like_value="like"
                        :unlike_value="unlike"
                        :error_comment="error.comment"
                        :send_button="spinner.post_comment"
                        @article-like="blogLike()"
                        @article-unlike="blogUnlike()"
                        @post-article-comment="addBlogComment($event)"
                        ref="article_rating_component"
                    />

                    <ArticleCommentsList
                        v-if="blog.publication_date"
                        :type="'blog'"
                        :comments_list="blog_comments"
                        :pagination="pagination"
                        :load_data="spinner.load_blog_comments"
                        @handle-current-page="handlePageChange($event)"
                        @post-like="postBlogCommentLike($event)"
                        @post-unlike="postBlogCommentUnlike($event)"
                        @post-reply-comment="postCommentReply($event)"
                        @delete-comment="deleteBlogComment($event)"
                    />
                </b-col>

                <b-col class="sidebar" cols="12" lg="5" xl="4" offset-xl="1">
                    <BannerAdvertisingVertical :banner_slug="'TPagFbT03nrHAhO'" />

                    <div class="top-blogs">
                        <h3 class="title">Najčítanejšie tento mesiac</h3>
                        <b-col v-for="blog in topBlogs" :key="blog.id">
                            <Post :blog="blog" />
                        </b-col>
                    </div>

                    <TopPostContributor v-if="topBlogUsers.length > 0" :topUsers="topBlogUsers" :title="'Top prispievatelia'" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import vendor from "@/assets/vendor.svg";
import Post from "@/components/blog/single/Post";
import TopPostContributor from "@/components/TopPostContributor";
import {required, minLength } from 'vuelidate/lib/validators'

import ArticleRating from "@/components/detail_components/ArticleRating";
import ArticleCommentsList from "@/components/detail_components/ArticleCommentsList";
import BlogStatusController from "@/components/blog/BlogStatusController";
import ArticleBackButton from "@/components/detail_components/ArticleBackButton";
import ArticleHead from "@/components/detail_components/ArticleHead";
import ArticleContent from "@/components/detail_components/ArticleContent";
import BannerAdvertisingVertical from "@/components/BannerAdvertisingVertical";

export default {
    name: 'PageBlogMain',
    components: {
        BannerAdvertisingVertical,
        ArticleContent,
        ArticleHead,
        ArticleBackButton,
        BlogStatusController,
        ArticleCommentsList,
        ArticleRating,
        Post,
        TopPostContributor,
    },
    metaInfo() {
        return {
            title: this.meta_data.title ? this.meta_data.title +  ' | '  : 'Blog | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.meta_data.description },

                { vmid: 'og:url', name:'og:url', content: config.BASE_URL + this.$route.fullPath },
                { vmid: 'og:type', name:'og:type', content: 'blog' },
                { vmid: 'og:image', name:'og:image', content: this.meta_data.main_image ? config.ASSET_URL + '/' + this.meta_data.main_image : this.$store.getters.settings.meta_logo },
                { vmid: 'og:title', name:'og:title', content: this.meta_data.title },
                { vmid: 'og:description', name:'og:description', content: this.meta_data.description },
            ]
        }
    },
    data() {
        return {
            vendor: vendor,
            asset_url: null,

            meta_data: {},

            pagination: {
                perPage: 30,
                currentPage: 1,
                records: 1,
            },
            blog_comments: [],

            blog: {},
            images: [],
            indexImage: null,
            blogComments: [],

            like: 0,
            unlike: 0,

            topBlogs: [],

            topBlogUsersCount: 10,
            topBlogUsers: [],

            spinner: {
                load_blog: false,
                load_blog_comments: false,
                topBlogUsers: false,
                post_comment: false,
            },
            error: {
                note: '',
                comment: null,
            },
        }
    },
    watch: {
        '$route.query.currentPage': function(value) {
            this.$set(this, 'currentPage', Number(value) ? Number(value) : 1);
        },
        '$route.params.slug': function() {
            this.getBlog();
        },
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        this.getBlog();
        this.getBlogComments();

        this.getTopBlogs();
        this.getTopBlogUsers();
    },
    methods: {
        setMetaData(data) {
            let metaTags = {}
            let title = data.title.replace(/<[^>]+>/g, '')
            metaTags.title = title.substring(0, 150)
            let text = data.text.replace(/<[^>]+>/g, '')
            metaTags.description = text.substring(0, 150)
            if (data.main_picture_path) metaTags.main_image = data.main_picture_path
            this.meta_data = metaTags
        },
        getBlog() {
            this.spinner.load_blog = true
            axios.get(config.API_URL + '/blog/' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    let data = response.data.success
                    this.blog = data[0];
                    console.log('BLOG:', data);
                    if (data[1].length > 0) {
                        let newImageUrl = []
                        response.data.success[1].forEach(img => {
                            let new_image_url = this.asset_url + img.folder + img.name
                            newImageUrl.push(new_image_url)
                        })
                        this.images = newImageUrl
                    }
                    if (data[0].blog_likes[0]) {
                        data[0].blog_likes[0].like === 1 ? this.like = 1 : this.like = 0;
                        data[0].blog_likes[0].unlike === 1 ? this.unlike = 1 : this.unlike = 0;
                    }
                    this.setMetaData(data[0])
                    this.spinner.load_blog = false
                }, (error) => {
                    this.$errorResponse404(error.response)
                    this.spinner.load_blog = false
                });
        },
        getBlogComments() {
            this.spinner.load_blog_comments = true
            axios.get(config.API_URL + '/blogcomments'
                + '?slug=' + this.$route.params.slug
                + '&perpage=' + this.pagination.perPage
                + '&page=' + this.pagination.currentPage
                + '&orderBy=' + 'created_at'
                + '&ordering=' + 'DESC', config.HEADERS)
                .then((response) => {
                    console.log('toto su komenty:', response.data.success)
                    this.pagination.records = response.data.success.total
                    this.blog_comments = response.data.success.data
                    this.spinner.load_blog_comments = false
                }, (error) => {
                    console.warn(error);
                    this.spinner.load_blog_comments = false
                });
        },
        handlePageChange(page) {
            console.log(page)
            this.pagination.currentPage = page;
            this.$scrollTo('.component-article-rating')
            this.getBlogComments()
        },
        postBlogCommentLike(comment_id) {
            axios.post(config.API_URL + '/blog/comment/like/' + comment_id, {
                token: this.$store.getters.user.access_token,
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        postBlogCommentUnlike(comment_id) {
            axios.post(config.API_URL + '/blog/comment/unlike/' + comment_id, {
                token: this.$store.getters.user.access_token,
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        postCommentReply(reply_data) {
            axios.post(config.API_URL + '/blogcomments', {
                text: reply_data.comment,
                blog_id: reply_data.blog_id,
                parent_id: reply_data.comment_id,
            }, config.HEADERS)
                .then((response) => {
                    console.log('reply comment send', response);
                    this.recoverBlogComments()
                }, (error) => {
                    this.error.note = this.$errorMessage(error.response)
                    this.commentInput = 0
                });
        },
        recoverBlogComments() {
            this.pagination.currentPage = 1
            this.getBlogComments()
        },
        deleteBlogComment(comment_id) {
            axios.delete(config.API_URL + '/blogcomments/' + comment_id, config.HEADERS)
                .then((response) => {
                    console.log('delete comment', response);
                    this.recoverBlogComments()
                }, (error) => {
                    console.warn(error);
                });
        },
        addBlogComment(blog_comment) {
            // this.$refs.article_rating_component.$v.$touch()
            // if (this.$refs.article_rating_component.$v.$invalid) {
            //     this.$nextTick(() => {
            //         this.$refs.article_rating_component.$scrollTo('.input--error')
            //     })
            //     return false
            // } else {
            //     this.$refs.article_rating_component.$v.$reset()
            // }
            this.postBlogComment(blog_comment)
        },
        postBlogComment(blog_comment) {
            this.spinner.post_comment = true
            axios.post(config.API_URL + '/blogcomments', {
                blog_id: this.blog.id,
                text: blog_comment,
            }, config.HEADERS)
                .then((response) => {
                    console.log('post koment', response);
                    this.handlePageChange(1)
                    // this.getBlogComments();
                    this.spinner.post_comment = false

                }, (error) => {
                    console.log(error)
                });
        },
        blogLike() {
            if (this.$store.getters.user) {
                if (this.unlike === 1) {
                    this.unlike = 0;
                    this.blog.dislikes_count -= 1
                }
                if (this.like === 1) {
                    this.like = 0
                    this.blog.blog_likes_count -= 1
                } else {
                    this.like = 1;
                    this.blog.blog_likes_count += 1
                }
                this.postBlogLike();
            }
        },
        blogUnlike() {
            if (this.$store.getters.user) {
                if (this.like === 1) {
                    this.like = 0;
                    this.blog.blog_likes_count -= 1
                }
                if (this.unlike === 1) {
                    this.unlike = 0
                    this.blog.dislikes_count -= 1
                } else {
                    this.unlike = 1;
                    this.blog.dislikes_count += 1
                }
                this.postBlogLike();
            }
        },
        postBlogLike() {
            axios.post(config.API_URL + '/blog/like', {
                blog_id: this.blog.id,
                like: this.like,
                unlike: this.unlike,
            }, config.HEADERS)
                .then((response) => {
                    console.log('df', response)
                    console.log(response);
                }, (error) => {
                    console.warn(error);
                });
        },
        getTopBlogs() {
            axios.get(config.API_URL + '/blog/top/getTopPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    this.topBlogs = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
        changeBlogStatus(status) {
            axios.post(config.API_URL + '/blog/mainStatus', {
                blog_id: this.blog.id,
                status: status
            }, config.HEADERS)
                .then((response) => {
                    // console.log(response);
                    this.blog.status = response.data.success.status
                }, (error) => {
                    console.warn(error);
                });
        },
        deleteBlog(slug) {
            axios.delete(config.API_URL + '/blog/' + slug, config.HEADERS)
                .then((response) => {
                    console.log('delete blog', response);
                }, (error) => {
                    console.warn(error);
                });
            this.$router.replace({ name: 'PageBlogMain' })
            // this.$router.push({ name: 'PageProfileBlogs', params: { slug: this.$store.getters.user.slug }, query: { posts: 'approved', currentPage: '1' } }, () => {})
        },

        getTopBlogUsers() {
            axios.get(config.API_URL + '/user/getTopBlogUsers'
                + '?quantity=' + this.topBlogUsersCount, config.HEADERS)
                .then((response) => {
                    console.log('getTopBlogUsers:', response.data.success);
                    this.topBlogUsers = response.data.success
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
    },
    validations: {
        blog_comment: {
            required,
            minLength: minLength(20),
            // maxLength: maxLength(160)
        },
    },
}
</script>

<style scoped lang="scss">
.page-blog-detail::v-deep {

    .blog-detail-container {
        min-height: 50vh;
    }

    .row {
        > .left-col {
            margin-bottom: 50px;

            .blog-content {
                background: white;
                border-radius: 15px;
                padding: 20px 30px;
                box-shadow: 0 6px 12px #ccc;
                margin-bottom: 20px;

                &__main-image {
                    max-height: 400px;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                    margin-bottom: 20px;
                }

                &__text {
                    text-align: justify;

                    .blog-text {
                        ol {
                            padding-left: 17px;
                        }

                        span {
                            color: inherit;
                            font-family: inherit;
                            font-size: inherit;
                            background-color: inherit;
                    }
                }
            }

            &__add-files {
                margin-top: 30px;

                .add-files-title {
                    text-align: left;
                    color: $secondary;
                }
            }
        }



        .rating {
            .title {
                text-align: left;
                margin-top: 50px;
                color: $secondary;
                margin-bottom: 0;
                font-weight: bold;
            }

            .subtitle {
                text-align: left;
                font-size: 15px;
                margin-top: 2px;
            }

            .like-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;

                a {
                    &:hover {
                        cursor: pointer;
                    }
                }

                svg {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }

                .blog_like {
                    color: $secondary;
                }

                .like, .dislike {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 24px;

                    span {
                        color: rgba($primary, 0.8);
                    }
                }

                .separate {
                    font-size: 26px;
                    margin: 0 15px;
                    color: $primary;

                    &:hover {
                        cursor: default;
                    }
                }
            }
        }

        .no-login-text {
            text-align: left;
            color: $red;
        }

        .add-comment-form {

            textarea {
                position: relative;
                font-size: 16px;
                color: $primary;
                border-radius: 10px;
                background: #efefef;
                border: none;
                padding: 15px 20px;
                //box-shadow: 0 12px 17px rgba(0,0,0,.2);
                max-height: 300px;

                &::placeholder {
                    color: $primary;
                }
            }

            .no-login {
                button {
                    width: 100%;
                }
            }
        }

        .comments {
            .title {
                text-align: left;
                margin-top: 50px;
                margin-bottom: 15px;
                color: $secondary;
                font-size: 20px !important;

                span {
                    font-size: 13px;
                    font-weight: normal;
                    margin-left: 10px;
                    color: $primary;
                }
            }

            .single-comment {
                margin-bottom: 50px;

                &.is-reply {
                    margin-bottom: 60px;
                }
            }

            .paginator {
                display: flex;
                justify-content: center;
                width: 100%;

                .VuePagination {
                    //width: inherit;
                    overflow-x: auto;
                }

                .page-link {
                    user-select: none;
                }

                .VuePagination__pagination-item-prev-chunk .page-link {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }

                .VuePagination__pagination-item-next-chunk .page-link {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }

    }

    > .sidebar {
        z-index: 0;

        .top-blogs {
            > .title {
                font-size: 16px !important;
                font-weight: 400 !important;
                margin-bottom: 30px;
            }

            .single-post {
                margin-bottom: 30px;
            }
        }
    }
}

}
</style>