<template>
	<div class="page-home">
		<MainMenu />

<!--        home-benefits-slider-->
        <BenefitsSlider :banner_slug="'benefits-slider'" class="mb-5" />

        <TopEbrickSlider :banner_slug="'home-top-banner'" />

		<Search :page="'home'" />

		<Stats />

		<div class="home-wrapper">
			<div class="container">
				<div v-show="topProviders.length > 0" class="top-lists">
					<b-row>
<!--						<b-col v-if="topSuppliers" cols="12" md="6">-->
<!--							<TopVendorList :title="'dodávatelia materiálu'" :topUsers="topSuppliers" />-->
<!--						</b-col>-->
						<b-col v-if="topProviders" cols="12" lg="10" offset-lg="1">
							<TopVendorList :title="'stavebné firmy'" :topUsers="topProviders" />
						</b-col>
					</b-row>

					<img class="geometric-4" :src="geometric_1" alt="svg">
				</div>

				<p @click="$router.push({ name: 'Informations', params: { slug: 'casto-kladene-otazky' } })" class="rating-link">
                    Čo predstavuje portál <span>eBrick.sk?</span>
<!--                    Ako funguje eBrick hodnotenie dodávateľov?-->
                </p>

				<h2 class="text-left text-secondary mb-4">Články od vás</h2>
			</div>

			<div class="post-list-wrapper">
				<div class="container">
					<b-row class="post-list">
						<b-col cols="4" v-for="blog in topBlogs" :key="blog.id">
							<Post :blog="blog" />
						</b-col>
					</b-row>
				</div>
			</div>

			<div class="container">
				<b-button @click="$router.push({ name: 'PageBlogMain' })" class="mt-2 all-blog" pill variant="primary">
                    <font-awesome-icon class="btn-icon" icon="align-left"></font-awesome-icon>
                    Všetky články
                </b-button>
				<img class="geometric-5" :src="geometric_4" alt="svg">
			</div>
		</div>

		<ForumList />

		<Newsletter id="newsletter_id" />

		<Footer />
	</div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
// import HomeSlider from "@/components/home/HomeSlider";
import Search from "@/components/toMarket";
import Stats from "@/components/home/Stats";
import TopVendorList from "@/components/home/TopVendorList";
import Post from "@/components/blog/single/Post";
import ForumList from "@/components/forum/ForumList";
import Newsletter from "@/components/home/Newsletter";
import Footer from "@/components/Footer";

import geometric_1 from "@/assets/geometric_1.svg"
import geometric_4 from "@/assets/geometric_4.svg"
import TopEbrickSlider from "@/components/TopEbrickSlider";
import BenefitsSlider from "../components/home/BenefitsSlider";

export default {
	name: 'Home',
	components: {
        BenefitsSlider,
		MainMenu,
        // HomeSlider,
        TopEbrickSlider,
		Search,
		Stats,
		TopVendorList,
		Post,
		ForumList,
		Newsletter,
		Footer
	},
    metaInfo() {
        return {
            title: 'Stavebné dopyty pre firmy i remeselníkov | ',
        }
    },
	data() {
		return {
			geometric_1: geometric_1,
			geometric_4: geometric_4,

            topBlogs: [],

            topSuppliers: [],
            topProviders: [],
		}
	},
    mounted() {
        this.loginWithGoogle()

        this.getTopBlogs();
        // this.getTopSuppliers();
        this.getTopProviders();

        if (this.$route.params.newsletter) {
            this.$scrollTo('.component-footer', { force: false, duration: 1000 })
        }
    },
    methods: {
        loginWithGoogle() {
            console.log('login route', this.$route)
            let authData = this.$route.query
            if (authData.state) {
                if (authData.prompt) {
                    axios.get(config.API_URL + '/auth/socialCallback/google'
                        + '?state=' + authData.state
                        + '&code=' + authData.code
                        + '&scope=' + authData.scope
                        + '&authuser=' + authData.authuser
                        + '&prompt=' + authData.prompt, config.HEADERS)
                        .then((response) => {
                            console.log('google google login', response)
                            this.$store.dispatch('loginUser', response.data)
                        }, (error) => {
                            console.warn(error);
                        });
                } else {
                    axios.get(config.API_URL + '/auth/socialCallback/facebook'
                        + '?state=' + authData.state
                        + '&code=' + authData.code, config.HEADERS)
                        .then((response) => {
                            console.log('google facebook login', response)
                            this.$store.dispatch('loginUser', response.data)
                        }, (error) => {
                            console.warn(error);
                        });
                }
            }
        },
        getTopBlogs() {
            axios.get(config.API_URL + '/blog/top/getTopPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    console.log(response)
                    this.topBlogs = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
        getTopSuppliers() {
            axios.get(config.API_URL
                + '/user/getTopSupplierUser'
                + '?page=1'
                + '&perpage=5', config.HEADERS)
                .then((response) => {
                    // console.log('sup', response.data.success.data)
                    this.topSuppliers = response.data.success.data
                }, (error) => {
                    console.warn(error);
                });
        },
        getTopProviders() {
            axios.get(config.API_URL
                + '/user/getTopProviderUser'
                + '?page=1'
                + '&perpage=5', config.HEADERS)
                .then((response) => {
                    // console.log('prov', response.data.success.data)
                    this.topProviders = response.data.success.data
                }, (error) => {
                    console.warn(error);
                });
        },
    },
}
</script>

<style scoped lang="scss">
.page-home::v-deep {
	.home-wrapper {
		padding-top: 80px;
		padding-bottom: 50px;

		.container {
			position: relative;

			> h2, > button {
				position: relative;
				z-index: 10;
			}

			.geometric-5 {
				position: absolute;
				right: -700px;
				bottom: -270px;
			}
		}

		.top-lists {
			position: relative;
			.geometric-4 {
				position: absolute;
				left: -400px;
				bottom: -270px;
				transform: rotateY(180deg) scale(1.3);
				opacity: .7;
				z-index: 2;
			}
		}

		.rating-link {
			position: relative;
			margin-top: 70px;
			margin-bottom: 70px;
			font-weight: 600;
			color: rgba(14, 43, 76, .5);
			z-index: 10;
            cursor: pointer;

			span {
				color: $primary;
			}
		}

		.post-list-wrapper {
			overflow-y: auto;
			padding-bottom: 20px;

			.post-list {
				min-width: 960px;
                min-height: 370px;
			}
		}

        .all-blog {
            width: 100%;
            max-width: 300px;
        }

		.all-posts {
			margin-top: 70px;
			padding: 10px 50px;
		}

		@media (max-width: 768px) {
			padding-top: 30px;

			.rating-link {
				margin: 20px 0;
			}

			.all-posts {
				margin-top: 20px;
				width: 100%;
			}
		}
	}

    .component-footer {
        margin-top: 100px;
    }
}
</style>
