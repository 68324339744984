<template>
    <div class="page-profile">
        <MainMenu />
        <BenefitsSlider :banner_slug="'benefits-slider'" />

        <div class="profile-container container">
            <b-row>
                <b-col cols="10" sm="5" md="4" lg="3">
                    <b-spinner v-if="!spinners.load_customer" class="mt-5" type="grow" label="Spinning"></b-spinner>
                    <ProfilSlidebar
                        v-else
                        :customer_load="spinners.load_customer"
                    />
                </b-col>
                <b-col class="profile-details" cols="10" offset="1" lg="8" offset-xl="1">
                    <div v-if="$route.params.slug === $store.getters.user.slug" class="profile-menu-buttons">
                        <b-button @click="$router.push({ name: 'Demands', params: { demands: 'demands' }})" class="to-my-market" variant="primary" pill>
                            Dopyty pre mňa
                        </b-button>
<!--                        <b-button @click="$router.push({ name: 'Subscription' })" variant="secondary" pill>-->
<!--                            <font-awesome-icon class="btn-icon" icon="angle-double-right"></font-awesome-icon>-->
<!--                            Predplatné a plány-->
<!--                        </b-button>-->
                        <b-button @click="$router.push({ name: 'ChangePassword' })" class="settings" variant="primary" pill>
                            <font-awesome-icon class="btn-icon" icon="key"></font-awesome-icon>
                            Zmena hesla
                        </b-button>
                    </div>

                    <ProfileNav />

                    <div v-if="verified_user">
                        <div v-if="$store.getters.customer.slug === $store.getters.user.slug" class="customer-email">
                            <p class="my-email">
                                <span><strong>Môj email:</strong> {{ $store.getters.user.email }}</span>
                                <font-awesome-icon @click="changeEmail()" icon="edit"></font-awesome-icon>
                            </p>
                            <p v-if="email_changed" class="email-success-changed">{{ email_changed }}</p>
                            <div v-if="email_edit">
                                <b-form class="change-email">
                                    <b-form-group label="Email" label-for="sing_in_email">
                                        <b-form-input id="sing_in_email" v-model.trim="email" type="email"></b-form-input>
        <!--                                <div class="error-message-l" v-show="$v.email.$error">-->
        <!--                                    <div v-show="!$v.email.required || !$v.email.email">Prosím, zadajte Vašu platnú e-mailovú adresu.</div>-->
        <!--                                </div>-->
                                    </b-form-group>
                                </b-form>

                                <div class="error-message-c" v-if="errors">
                                    <span v-for="(err, index) in errors" :key="index">{{ err }}</span>
                                </div>

                                <b-button @click="sendChangeEmail()" variant="primary" pill>Zmeniť email</b-button>
                            </div>
                        </div>

                        <UnverifiedEmail />
                    </div>
                    <div v-else>
                        <b-spinner v-if="!spinners.load_customer" class="mt-5" type="grow" label="Spinning"></b-spinner>
                        <div v-else class="profile-content">
                            <router-view></router-view>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <BannerAdvertisingHorizontal :banner_slug="'banner-reklama-pozdlzny'" />

        <Footer />
    </div>
</template>

<script>
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import ProfilSlidebar from "@/components/profil/ProfilSlidebar";
import BenefitsSlider from "@/components/home/BenefitsSlider";
import ProfileNav from "@/components/profil/ProfileNav";
import UnverifiedEmail from "@/components/UnverifiedEmail";
import BannerAdvertisingHorizontal from "@/components/BannerAdvertisingHorizontal";
import axios from "axios";

export default {
    name: "PageProfile",
    components: {
        BannerAdvertisingHorizontal,
        UnverifiedEmail,
        ProfileNav,
        BenefitsSlider,
        ProfilSlidebar,
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: this.meta_data.title ? this.meta_data.title +  ' | '  : 'Profil | ',
            meta: [
                { vmid: 'og:title', name:'og:title', content: this.meta_data.title },
                // { vmid: 'og:description', name:'og:description', content: this.meta_og_description },
                { vmid: 'og:type', name:'og:type', content: 'profil' },
                { vmid: 'og:image', name:'og:image', content: this.meta_data.image },
            ]
        }
    },
    data() {
        return {
            verified_user: false,

            meta_data: {
                title: 'Profil',
            },
            email_edit: false,
            email: '',
            email_changed: '',

            spinners: {
                load_customer: false,
            },
            errors: {}
        }
    },
    watch: {
        '$route.params.slug': function () {
            this.setCustomer()
        },
    },
    mounted() {
        this.setCustomer()
        if (this.$store.getters.user.slug) {
            this.verified_user = !this.$store.getters.user.email_verified_at;
        } else {
            this.verified_user = false
        }
    },
    methods: {
        setCustomer() {
            this.spinners.load_customer = false
            this.$store.dispatch("getCustomer", this.$route.params.slug)
                .then(response => {
                    let customer = this.$store.getters.customer
                    this.setMetaData(customer)
                    if (response) this.spinners.load_customer = response
                }, error => {
                    console.error(error)
                })
        },
        setMetaData(data) {
            let new_meta_data = {}
            new_meta_data.title = data.name.replace(/<[^>]+>/g, '')
            new_meta_data.image = config.ASSET_URL + '/' + data.customer.image_path
            this.meta_data = new_meta_data
        },
        changeEmail() {
            this.email = this.$store.getters.customer.email
            this.email_edit = true
        },
        sendChangeEmail() {
            let myData = new FormData();
            myData.set('email', this.email);
            axios.post(config.API_URL + '/user/customer/update', myData, config.HEADERS)
                .then(() => {
                    this.$store.commit('setUserEmail', this.email)
                    this.email_changed = 'Váš email bol úspešne zmenený'
                    this.email_edit = false
                }, (error) => {
                    console.warn(error);
                    this.errors = this.$errorMessage(error.response)
                    this.spinner.post_data = false
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.page-profile::v-deep {
    .profile-container {
        margin-top: 100px;

        .profile-details {
            position: relative;

            .profile-menu-buttons {
                display: flex;
                justify-content: space-between;
                position: relative;
                margin-top: -20px;

                .to-my-market {
                    box-shadow: 0 0 0 3px white;
                    padding: 10px 50px;

                }
                @media (max-width: 767px) {
                    flex-direction: column;
                    margin-top: 20px;

                    button {
                        margin-top: 10px;
                        width: 100%;
                    }
                }
            }
        }

        .customer-email {
            .my-email {
                svg {
                    cursor: pointer;

                    @media (min-width: 600px) {
                        margin-left: 30px;
                    }
                }

                @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .email-success-changed {
                color: $green;
            }

            .change-email {
                max-width: 500px;
                margin: 0 auto;
            }
        }


        @media (max-width: 767px) {
            margin-top: 0;
        }
    }
}
</style>