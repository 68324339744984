<template>
    <div class="subscription-invoice-info">
        <MainMenu />

        <div class="invoice-info-container container">
            <h1 class="invoice-info-title">Fakturačné údaje</h1>
        </div>

        <span class="rectangle"></span>

        <div class="invoice-info-container container mb-5">
            <b-row>
                <b-col cols="12" lg="8" order="2" order-lg="1">
                    <div v-if="!$store.getters.user.slug" class="no-login-user">
                        <div><strong>Ak už máte vytvorený účet, prihláste sa.</strong></div>
                        <b-button @click="$router.push({ name: 'Sign_in'}, () => {})" class="mt-2 mb-2" variant="primary" pill>
                            <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                            Prihlásiť sa
                        </b-button>
                        <span class="rectangle rectangle-second"></span>
                    </div>

                    <b-row>
                        <b-col cols="12" lg="10" offset-lg="1">
                            <h2 class="company-data-title">Vaše údaje</h2>
                        </b-col>
                    </b-row>

                    <b-spinner v-if="spinners.load_customer" class="mt-5 mb-5" type="grow" label="Spinning"></b-spinner>
                    <b-form v-else>
                        <FormRegistrationUser
                            v-if="!$store.getters.user.slug"
                            :user_data.sync="user_data"
                            ref="form_registration_user_component"
                        />

                        <FormCompanyData
                            :order_data.sync="order_data"
                            @next-step="goNextStep()"
                            ref="form_company_data_component"
                        />
                    </b-form>
                    <span class="rectangle rectangle-three"></span>
                </b-col>

                <b-col cols="12" order="1" lg="4" order-lg="2">
                    <SubscriptionDetail
                        v-if="select_package.id"
                        :product="select_package"
                        :button_visible="false"
                        :recommended="select_package.frekvence_recommended"
                    />
                    <b-spinner v-else class="mt-5" type="grow" label="Spinning"></b-spinner>
                </b-col>

            </b-row>
        </div>
        <ModalClearCart :next_path="next_path" @continue="($event)" ref="leaveModal" />
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import FormRegistrationUser from "@/components/subscription/FormRegistrationUser";
import FormCompanyData from "@/components/subscription/FormCompanyData";
import ModalClearCart from "@/components/subscription/ModalClearCart";
import SubscriptionDetail from '@/components/subscription/SubscriptionDetail';
import Footer from '@/components/Footer';

export default {
    name: 'SubscriptionInvoiceInfo',
    components: {
        MainMenu,
        FormCompanyData,
        FormRegistrationUser,
        ModalClearCart,
        SubscriptionDetail,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Predplatné | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_subscription },
            ]
        }
    },
    data() {
        return {
            select_package: {},

            user_data: {
                nick: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            order_data: {
                name: '',
                lastname: '',
                trade_name: '',
                ico: '',
                dic: '',
                ic_dph: '',
                billing_address_street: '',
                billing_address_town: '',
                billing_address_psc: '',
                billing_address_country: '',
                select_1: false,
            },
            spinners: {
                load_customer: true,
            },
            next_path: '',
        }
    },
    mounted() {
        if (!this.$route.query.package) this.$router.replace({ name: 'Subscription' })
        if (this.$store.getters.order.order_data) {
            this.order_data = this.$store.getters.order.order_data
            this.spinners.load_customer = false
        } else {
            if (this.$store.getters.user.slug) {
                this.setCustomer()
            } else {
                this.spinners.load_customer = false
            }
        }
        this.getPackageFrequency()
    },
    beforeRouteEnter(_, from, next) {
        if (from.name === 'Subscription' || from.name === 'SubscriptionInvoiceInfo' || from.name === 'SubscriptionSummary') {
            next()
        } else {
            next({ name: 'Subscription' })
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'Subscription' || to.name === 'SubscriptionSummary' || to.name === 'Sign_in' || to.name === 'Sign_up') {
            next()
        } else {
            let confirm_next = false
            let emptyValues = Object.values(this.order_data)
            emptyValues.forEach(el => {
                if (el) confirm_next = true
            })
            if (confirm_next) {
                this.next_path = to
                this.$bvModal.show('modal-clear-cart')
                this.$nextTick(() => {
                    this.$refs.leaveModal.$once('continue', (bvEvt) => {
                        if (bvEvt === 'continue') {
                            console.log('CONTINUE')
                            this.$store.commit('setOrderData', this.storeData())
                            next()
                        } else if (bvEvt === 'delete') {
                            console.log('DELETE')
                            next()
                        } else {
                            next(false)
                        }
                    })
                })
            } else {
                next()
            }
        }
    },
    methods: {
        getPackageFrequency() {
            axios.get(config.API_URL + '/products/getOneProductFrekvence'
                + '?slug=' + this.$route.query.package, config.HEADERS)
                .then((response) => {
                    console.log('get one  card', response.data.success)
                    this.select_package = response.data.success
                }, (error) => {
                    console.warn(error);
                });
        },
        storeData() {
            let allData = {}
            allData.package = this.$route.query.package
            if (!this.$store.getters.user.slug) allData.user_data = this.user_data
            allData.order_data = this.order_data
            console.log('ALL DATA', allData)
            return allData
        },
        setCustomer() {
            this.$store.dispatch("getCustomer", this.$store.getters.user.slug)
                .then(response => {
                    if (response) {
                        console.log('user response', this.$store.getters.customer.customer)
                        let loginUser = this.$store.getters.customer.customer
                        let newPriceList = {}
                        newPriceList.name = loginUser.name
                        newPriceList.lastname = loginUser.lastname
                        newPriceList.trade_name = loginUser.trade_name
                        newPriceList.ico = loginUser.ico
                        newPriceList.dic = loginUser.dic
                        newPriceList.ic_dph = loginUser.ic_dph
                        newPriceList.billing_address_street = loginUser.address
                        newPriceList.billing_address_town = loginUser.city
                        newPriceList.billing_address_psc = loginUser.psc
                        newPriceList.billing_address_country = loginUser.country
                        newPriceList.select_1 = false
                        this.order_data = newPriceList
                        this.spinners.load_customer = false
                    }
                }, error => {
                    console.error(error)
                })
        },
        goNextStep() {
            console.log('idem')
            if (!this.checkValidationData()) return false
            this.$store.commit('setOrderData', this.storeData())
            this.$router.push({ name: 'SubscriptionSummary' }, () => {})
        },
        checkValidationData() {
            console.log('idem 1')
            let success_validation = true
            if (!this.$store.getters.user.slug) {
                this.$refs.form_registration_user_component.$v.$touch()
                if (this.$refs.form_registration_user_component.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.form_registration_user_component.$scrollTo('.input--error')
                    })
                    success_validation = false
                }
            }
            this.$refs.form_company_data_component.$v.$touch()
            if (this.$refs.form_company_data_component.$v.$invalid) {
                this.$nextTick(() => {
                    if (success_validation) this.$refs.form_company_data_component.$scrollTo('.input--error')
                })
                success_validation = false
            }
            console.log('success_validation', success_validation)
            return success_validation
        },
    },

}
</script>

<style lang="scss" scoped>
.subscription-invoice-info::v-deep {
    .rectangle {
        display: block;
        width: 100%;
        height: 10px;
        background-color: $secondary;
        opacity: 0.2;
    }

    .invoice-info-container {
        .invoice-info-title {
            color: $secondary;
            text-align: left;
            font-size: 20px !important;
            margin: 30px 0;
        }

        .no-login-user {
            margin: 30px 0;

            .rectangle-second {
                opacity: 1;
                width: 100%;
                margin-top: 15px;
            }

            .company-data-title {
                color: $secondary;
                font-size: 18px !important;
                text-align: left;
                margin: 30px 0;
            }
        }

        .form-group {
            margin-bottom: 20px;
        }

        .rectangle-three {
            position: absolute;
            margin-top: 15px;
            opacity: 1;
            width: 200vw;
            margin-left: -100vw;
            height: 1px;
        }
    }
}
</style>