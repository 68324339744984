<template>
    <div class="component-article-back-button">
        <b-button class="btn-back" @click="goRouteName()" variant="link">
            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
            {{ button_text }}
        </b-button>
        <hr class="top-back-hr">
    </div>
</template>

<script>
export default {
    name: "ArticlelBackButton",
    props: {
        button_text: {
            type: String,
            default: ''
        },
        route_name: {
            type: String,
            default: ''
        },
    },
    methods: {
        goRouteName() {
            this.$router.push({ name: this.route_name })
        },
    }
}
</script>

<style lang="scss" scoped>
.component-article-back-button::v-deep {
    position: relative;

    .btn-back {
        padding: 0;
    }

    .top-back-hr {
        margin-top: 0;
        margin-bottom: 30px;
        opacity: 0.3;
        border-bottom: 1px solid $primary;
    }
}
</style>