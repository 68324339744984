var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-chat"},[_c('MainMenu'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"chat-body"},[(!_vm.$store.getters.user)?_c('NotLoginUser',{attrs:{"text":'Ak chcete kontaktovať poskytovateľov, musíte sa najprv prihlásiť.'}}):_vm._e(),(!_vm.roomsLoaded)?_c('b-spinner',{staticClass:"mt-5 mb-5",attrs:{"type":"grow","label":"Spinning"}}):_vm._e(),(_vm.roomsLoaded && _vm.rooms.length > 0)?_c('chat-window',{attrs:{"rooms":_vm.rooms,"messages":_vm.messages,"current-user-id":_vm.currentUserId,"rooms-loaded":_vm.roomsLoaded,"messages-loaded":_vm.messagesLoaded,"show-files":false,"show-audio":false,"show-add-room":false,"show-reaction-emojis":false,"messageActions":[],"room-id":_vm.room_id,"no-room-selected":"harpuna","text-messages":{
                  SEARCH: 'Vyhľadať',
                  TYPE_MESSAGE: 'Vaša správa...',
                  CONVERSATION_STARTED: 'Začiatok konverzácie',
                  NEW_MESSAGES: 'Nové správy',
                  MESSAGES_EMPTY: 'Žiadne správy',
                  ROOMS_EMPTY: 'Žiadne kontakty',
                  ROOM_EMPTY: 'Žiadna konverzácia',
                }},on:{"send-message":_vm.sendMessage,"fetch-messages":_vm.fetchMessages}}):_vm._e(),(_vm.roomsLoaded && _vm.rooms.length === 0)?_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"mt-5 mb-0"},[_vm._v("Zatiaľ nemáte žiadne kontakty.")]),_c('p',[_vm._v("Prezrite si dopyty a nájdite vhodného zákazníka alebo dodávateľa pre svoj projekt.")]),_c('b-button',{attrs:{"variant":"secondary","pill":""},on:{"click":function($event){return _vm.$router.push({ name: 'Demands', params: { demands: 'demands' }})}}},[_c('font-awesome-icon',{staticClass:"btn-icon",attrs:{"icon":"arrow-circle-right"}}),_vm._v(" Prejsť na dopyty ")],1)],1):_vm._e()],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }