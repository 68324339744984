<template>
    <div class="page-profile-forum">
        <p v-if="!spinners.load_data && forum_data && forum_data.length === 0" class="mt-5">Žiadne príspevky</p>
        <ForumPostLight v-for="forum in forum_data" :key="forum.id" :forum="forum" class="mb-5" />
        <VuePagination
            :per_page="pagination.perPage"
            :current_page="pagination.currentPage"
            :records="pagination.records"
            :load_data="spinners.load_data"
            @handle-current-page="handlePageChange($event)"
        />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import ForumPostLight from "@/components/forum/single/ForumPostLight";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageProfileForum",
    components: {
        VuePagination,
        ForumPostLight,
    },
    data() {
        return {
            pagination: {
                perPage: 6,
                currentPage: 1,
                records: 0,
            },

            active_item: 1,
            forum_data: null,

            spinners: {
                load_data: false
            },
        }
    },
    watch: {
        '$route.query': function () {
            this.checkUrl()
        }
    },
    mounted() {
        this.checkUrl()
    },
    methods: {
        checkUrl() {
            let query = Object.assign({}, this.$route.query);
            if (query.page) {
                this.pagination.currentPage = Number(query.page)
            } else {
                this.pagination.currentPage = 1
            }
            this.$router.push({ 'query': query }, () => {});
            this.getUserForums()
        },
        getUserForums() {
            this.spinners.load_data = true
            // this.forum_data = null
            axios.get(config.API_URL + '/forum/user/created'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&status=' + '1'
                + '&slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('user forums', response.data.success);
                    let data = response.data.success
                    this.pagination.records = data.total;
                    this.forum_data = data.data;
                    // this.currentPage = data.current_page;
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        handlePageChange(page) {
            if (this.pagination.currentPage === page) this.getUserForums();
            this.pagination.currentPage = page
            let query = Object.assign({}, this.$route.query);
            if (page !== 1) {
                if (Number(this.$route.query.page) !== page) query.page = page
            } else {
                delete query.page
            }
            this.$router.push({ 'query': query }, () => {});
            this.$scrollTo('.profile-container')
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-forum::v-deep {
    //
}
</style>