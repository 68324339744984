<template>
    <div class="component-not-login-user">
<!--        <div class="blur-div"></div>-->
        <div class="no-login">
            <b-row>
                <b-col cols="12"><h2>{{ text }}</h2></b-col>
                <b-col cols="12" lg="4" offset-lg="2">
                    <b-button class="login-button" @click="$router.push({ name: 'Sign_in' },() => {})" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                        Prihlásiť sa
                    </b-button>
                </b-col>
                <b-col cols="12" lg="4" offset-lg="0">
                    <b-button class="login-button" @click="$router.push({ name: 'Sign_up' },() => {})" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                        Registrovať
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotLoginUser",
    props: {
        text: {
            default: function () {
                return 'Pre pokračovanie sa musíte prihlásiť.'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.component-not-login-user::v-deep {
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    background-color: rgba(white, 0.2);
    backdrop-filter: blur(5px);

    .no-login {
        background-color: $secondary;
        padding: 20px 0;

        .row {
            margin: 0 auto;
            max-width: 50%;

            @media (max-width: 730px) {
                max-width: 100%;
            }

            h2 {
                color: white;
                font-size: 20px !important;
                margin-bottom: 20px;
            }

            .login-button {
                width: 100%;

                @media (max-width: 991px) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>