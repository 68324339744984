<template>
    <div class="single-wrote-about">
        <b-row class="wrote-about-card">
            <b-col cols="11" class="date">{{ moment(blog.publication_date).format("LL") }} | {{ blog.user.name }}</b-col>
            <b-col cols="11" class="title" v-html="blog.title"></b-col>
            <b-col cols="11"><span class="rectangle"></span></b-col>
            <b-col cols="11" class="text" v-html="blog.text"></b-col>
            <b-col>
                <b-button @click="$router.push({ name: 'PageBlogDetail', params: { slug: blog.slug } }, () => {})" class="btn-terms" variant="primary" pill>
                    Prejsť na článok
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        props: {
            blog: {
                default() {
                    return {}
                }
            },
        },
        
    }
</script>

<style lang="scss" scoped>
.single-wrote-about::v-deep {
    font-size: 15px;
    cursor: pointer;

    .wrote-about-card {
        text-align: left;
        margin: 0;

        > div {
            margin: 0;
            padding: 0;
        }

        .date {
            font-weight: bold;
            font-size: 13px;
            margin-bottom: 3px;
        }

        .title {
            color: $secondary;
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 10px;
            margin-top: -2px;
            min-height: 54px;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .rectangle {
            display: block;
            width: 67px;
            height: 9px;
            background-color: $secondary;
        }

        .text {
            margin: 10px 0 20px 0;
            line-height: 1.5;
            min-height: 88px;

            @supports (-webkit-line-clamp: 4) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box !important;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }

            br {
                @media (min-width: 700px) {
                    display: none;
                }
            }
        }

        .btn-terms {
            box-shadow: 6px 5px 7px rgba(14, 43, 76, 0.2);
            padding: 9px 70px;
        }
    }
}
</style>