<template>
    <div class="demand-detail">
        <MainMenu />

        <div class="container">
            <b-row>
                <b-col v-if="!loader" cols="12" lg="7" offset-lg="1" class="mt-5 mb-5">
                    <b-spinner class="mt-5 mb-5" type="grow" label="Spinning"></b-spinner>
                </b-col>
                <b-col v-else cols="12" lg="7" offset-lg="1">
                    <hr>
                    <div @click.prevent="addToFavorite()" class="favorite">
                        <span class="bookmark-added" v-if="demand_favourites">Pridané do obľúbených</span>
                        <span class="bookmark-add" v-else>Pridať do obľúbených</span>
                        <font-awesome-icon id="bookmark-added" v-if="demand_favourites" class="absolute-left bookmark-added" icon="bookmark"></font-awesome-icon>
                        <font-awesome-icon id="bookmark-add" v-else class="absolute-left bookmark-add" icon="bookmark"></font-awesome-icon>
                    </div>

                    <div class="demand-detail-info">
                        <h1 class="demand-head-title mb-4" v-html="demand.title"></h1>

                        <DemandControlPanel
                            v-if="demand.user && demand.user.slug === $store.getters.user.slug"
                            :demand="demand"
                            :demandStatusActive.sync="demandStatusActive"
                            @refresh-demand="getDemand()"
                        />

                        <DemandDetailInfoPanel
                            :demand="demand"
                        />

                        <div v-if="demand.user && $store.getters.user.user_id === demand.user.id" class="demand-update-btn">
                            <b-button
                                v-if="demandStatusActive === 1 || demandStatusActive === 3"
                                @click="$router.push({ name: 'DemandUpdate', params: { slug: demand.slug } })"
                                class="mb-2"
                                variant="secondary"
                                pill
                            >
                                <font-awesome-icon class="btn-icon" icon="edit"></font-awesome-icon>
                                Upraviť dopyt
                            </b-button>
                        </div>
                    </div>

                    <span class="rectangle"></span>

                    <DemandNote
                        v-if="loader && demand.user"
                        :demand_id="demand.id"
                        :demand_note="demand.demand_notes[0]"
                    />

                    <p v-html="demand.project_description" class="main-text"></p>

                    <div class="params">
                        <div v-for="param in demand.params" :key="param.id">
                            <b-row v-if="param.value">
                                <b-col cols="4" class="parameter">{{ param.param }}:</b-col>
                                <b-col cols="8">{{ param.value }}</b-col>
                            </b-row>
                        </div>
                    </div>

                    <span class="rectangle"></span>

                    <div class="demand-add-files">
                        <h2 class="demand-add-files__title">Priložené súbory</h2>

                        <Gallery
                            v-if="images.length > 0"
                            :images="images"
                        />

                        <h3 v-if="demand.additions && demand.additions.length > 0" class="demand-add-files__title-second">Dokumenty</h3>
                        <div v-if="demand.additions && demand.additions.length > 0" class="add-files-list">
                            <div class="add-files-list-card" v-for="(file, index) in demand.additions" :key="index">
                                <a :href="asset_url + '/' + file.path" target="_blank" class="attachment-name">
                                    <font-awesome-icon class="list-card-svg" icon="paperclip"></font-awesome-icon>
                                    <span>{{ file.attachment_name }}</span>
                                </a>
                            </div>
                        </div>

                        <h3 v-if="demand.video_links && demand.video_links.length > 0" class="demand-add-files__title-second">Video linky</h3>
                        <div class="add-files-list">
                            <div class="add-files-list-card" v-for="(link, index) in demand.video_links" :key="index">
                                <a :href="link.link" target="_blank">
                                    <font-awesome-icon class="list-card-svg" icon="external-link-alt"></font-awesome-icon>
                                    <span>{{ link.link }}</span>
                                </a>
                            </div>
                        </div>
                    </div>


                    <div v-if="demand.user && $store.getters.user.user_id === demand.user.id" class="demand-providers-reactions">
                        <span class="rectangle"></span>
                        <div class="mt-3 mb-5">
                            <p><em>Počet remeselníkov, ktorí reagovali na tento dopyt:<strong>{{ reactUsers }}</strong></em></p>
                            <div v-for="user in demand.user_reacted" :key="user.id">
                                <DemandUserReact
                                    :user="user"
                                    :react_user="demand.demand_in_procces_user ? demand.demand_in_procces_user : false"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="demand.user && $store.getters.user.user_id !== demand.user.id" class="provider-reaction-on-demand">
                        <span class="mb-5 rectangle"></span>
                        <div v-if="demand.demand_reacteds.length > 0" class="provider-reaction-on-demand__react">
                            <p>
                                Na tento dopyt ste už reagovali dňa: <br>
                                <strong>{{ moment(demand.demand_reacteds[0].created_at).format('LL') }}</strong>
                            </p>
                            <b-button
                                class="btn-terms btn-reaction mt-4"
                                @click="$router.push({ name: 'Chat', params: { room_id: demand.user_id } }, () => {})"
                                variant="primary"
                                pill
                            >
                                <font-awesome-icon class="btn-icon" icon="comments"></font-awesome-icon>
                                Prejsť na komunikáciu
                            </b-button>
                        </div>
                        <div v-else class="provider-reaction-on-demand__no-react">
                            <b-button
                                class="btn-terms btn-reaction mt-4"
                                @click="demandReact()"
                                variant="primary"
                                pill
                                :disabled="$store.getters.user && !$store.getters.permissions('react on demands')"
                            >
                                <font-awesome-icon class="btn-icon" icon="share"></font-awesome-icon>
                                Reagovať na dopyt
                            </b-button>
                            <div v-if="$store.getters.user && !$store.getters.permissions('react on demands')" class="no-provider">
                                <p><em>Na dopyty môžu reagovať iba poskytovatelia služieb.</em></p>
                                <p><em>Ak chcete reagovať na dopyty, najprv si musíte aktivovať predplatné.</em></p>
                                <b-button @click="$router.push({ name: 'Subscription' }, () => {})" variant="primary" pill>
                                    Prejsť na predplatné
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-col>

                <b-col cols="12" lg="3">
                    <BannerAdvertisingVertical :banner_slug="'TPagFbT03nrHAhO'" />
                    <TopPostContributor v-if="topBlogUsers.length > 0" :topUsers="topBlogUsers" :title="'Top stavebné firmy'" />
                </b-col>
            </b-row>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import DemandUserReact from "@/components/demands/single/DemandUserReact";
import Footer from "@/components/Footer";

import DemandNote from "@/components/demands/single/DemandNote";
import BannerAdvertisingVertical from "@/components/BannerAdvertisingVertical";
import TopPostContributor from "@/components/TopPostContributor";
import DemandControlPanel from "@/components/demands/demand_detail/DemandControlPanel";
import DemandDetailInfoPanel from "@/components/demands/demand_detail/DemandDetailInfoPanel";
import Gallery from "../../components/detail_components/Gallery";

export default {
    name: 'DemandDetail',
    components: {
        Gallery,
        DemandControlPanel,
        DemandNote,
        BannerAdvertisingVertical,
        TopPostContributor,
        DemandUserReact,
        MainMenu,
        Footer,
        DemandDetailInfoPanel,
    },
    metaInfo() {
        return {
            title: this.demand.title ? this.demand.title +  ' | '  : 'Dopyty | ',
            meta: [
                { vmid: 'og:title', name:'og:title', content: this.meta_data.title },
                // { vmid: 'og:image', name:'og:image', content: 'Wow' },
                { vmid: 'og:description', name:'og:description', content: this.meta_data.description },
            ]
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
		this.getDemand();
        this.getTopBlogUsers()
    },
    data() {
        return {
            asset_url: null,

            meta_data: {},

            categoriesCollapse: false,
            dropdown_value: 'all',
            demandStatusActive: null,
            demandStatus: 0,
            noFinished: 0,
            noFinishedReason: '',
            loader: false,

            demand: {},
            images: [],
            indexImage: null,
            demand_favourites: false,
            reactUsers: null,

            topBlogUsersCount: 10,
            topBlogUsers: [],

            modal_id: '',
        }
    },
    methods: {
        setMetaData(data) {
            let metaTags = {}
            let text = data.project_description.replace(/<[^>]+>/g, '')
            metaTags.description = text.substring(0, 50)
            let title = data.title.replace(/<[^>]+>/g, '')
            metaTags.title = title.substring(0, 50)
            this.meta_data = metaTags
        },
        downloadFile(url, name) {
            console.log(url)
            console.log(name)
            axios.request({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then(({ data }) => {
                console.log('asdfasdf', data)
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'file.pdf');
                // document.body.appendChild(link);
                // link.click();
            });
        },
        downloadFile2(url, name) {
            if (!url) {
                throw new Error("Resource URL not provided! You need to provide one");
            }
            // setFetching(true);

            let demandConfig = config.HEADERS;
            demandConfig.HEADERS = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "text/html",
                },
            };
            fetch(url)
                .then(response => response.blob(), demandConfig)
                .then(blob => {
                    // setFetching(false);
                    const blobURL = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = blobURL;
                    a.style = "display: none";

                    if (name && name.length) a.download = name;
                    document.body.appendChild(a);
                    a.click();
                })
                // .catch(() => setError(true));
        },
        getDemand() {
            this.loader = false
            axios.get(config.API_URL
                + '/demand/show/' + this.$route.params.slug, config.HEADERS)
            .then((response) => {
                console.log('demand', response.data.success);
                this.demand = response.data.success;
                this.demand_favourites = !!response.data.success.demand_favourites[0];
                this.demandStatusActive = response.data.success.state_id;
                if (response.data.success.user_reacted) this.reactUsers = response.data.success.user_reacted.length
                this.getDemandImages(response.data.success.id);
                this.setMetaData(response.data.success)
                this.loader = true
            }, (error) => {
                if (Number(error.response.status) === 404) {
                    this.$router.replace({ name: 'Page404' })
                } else if (Number(error.response.status) === 401) {
                    this.$store.dispatch('logoutUser');
                }
            });
        },
        getDemandImages(demand_id) {
            axios.get(config.API_URL
                + '/demand/images/' + demand_id, config.HEADERS)
                .then((response) => {
                    console.log('images', response.data.success);
                    if (response.data.success !== 1) {
                        let all_images = response.data.success
                        let images_url = []
                        all_images.forEach(image => {
                            images_url.push(image.url)
                        })
                        this.images = images_url;
                    }
                }, (error) => {
                    console.warn(error);
                });
        },
        demandReact() {
            axios.post(config.API_URL + '/demand/react/' + this.demand.id, {
                token: this.$store.getters.user.access_token.token,
            }, config.HEADERS)
            .then((response) => {
                console.log('reagoval som response', response);
                this.$router.push({ name: 'Chat', params: { room_id: this.demand.user_id } }, () => {})
            }, (error) => {
                console.warn(error);
            });
        },
        addToFavorite() {
            axios.post(config.API_URL + '/demand/addFavourite/' + this.demand.id, {
                token: this.$store.getters.user.access_token,
            }, config.HEADERS)
            .then((response) => {
                console.log(response);
                this.demand_favourites = !this.demand_favourites;
            }, (error) => {
                console.warn(error);
            });
        },

        getTopBlogUsers() {
            axios.get(config.API_URL + '/user/getTopBlogUsers'
                + '?quantity=' + this.topBlogUsersCount, config.HEADERS)
                .then((response) => {
                    console.log('getTopBlogUsers:', response.data.success);
                    this.topBlogUsers = response.data.success
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.demand-detail::v-deep {

    hr {
        margin-top: -1px;
    }

    .favorite {
        text-align: right;
        margin-top: -15px;
        font-size: 11px;
        color: $secondary;
        cursor: pointer;

        svg {
            margin-top: -5px;
            margin-left: 12px;
            margin-right: 6px;
            transform: scale(2.4);
        }

        .bookmark-add {
            color: $primary;
        }
        .bookmark-added {
            color: $secondary;
        }
    }

    .demand-detail-info {
        .demand-head-title {
            color: $secondary;
            margin-top: 20px;
            text-align: left;
        }

        .demand-update-btn {
            margin-bottom: 20px;

            .btn {
                width: 100%;
                max-width: 300px;
            }
        }
    }

    .rectangle {
        display: block;
        width: 100%;
        height: 8px;
        background-color: $secondary;
    }

    .main-text {
        margin: 20px 0;
        text-align: justify;
        line-height: 1.7;
    }

    .params {
        text-align: left;
        margin-bottom: 10px;

        .parameter {
            color: $secondary;
        }
    }

    .demand-add-files {
        text-align: left;

        &__title {
            font-size: 20px;
            margin-left: 0;
            margin-top: 20px;
            color: $secondary;
        }

        &__title-second {
            font-size: 18px !important;
            margin-top: 30px;
            margin-bottom: 0;
        }

        .add-files-gallery {
            .image-row {
                margin: 0;

                > div {
                    margin: 5px 0;
                    padding: 0 5px;
                    max-height: 200px;
                }

                .post-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                    cursor: pointer;
                }
            }

            .file-images {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;

                &:hover {
                    cursor: pointer;
                }

                .post-image {
                    width: 30%;
                    height: auto;
                    border-radius: 15px;
                    margin-bottom: 15px;
                    object-fit: cover;
                    max-height: 250px;
                }

                @media (max-width: 776px) {
                    img.post-image {
                        width: 100%;
                    }
                }
            }
        }

        .add-files-list {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 20px;
            position: relative;
            padding: 0 10px;

            .add-files-list-card {
                margin-bottom: 2px;

                .list-card-svg {
                    margin-right: 5px;
                    margin-top: 2px;
                    float: left;
                }

                @supports (-webkit-line-clamp: 1) {
                    overflow: hidden;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }

        &__images {
            //
        }
    }

    .no-provider {
        p {
            color: $red;
            margin-top: 20px;
            margin-bottom: 5px;
        }
    }

    .demand-providers-reactions {
        //
    }

    .provider-reaction-on-demand {
        .btn-reaction {
            width: 100%;
            font-size: 20px !important;
            font-weight: bold;
            margin-top: 0 !important;

            @media (min-width: 767px) {
                width: 70%;
            }

            svg {
                margin-top: -3px;
            }
        }

        &__no-react {
            p {
                margin: 0;
            }

            .btn {
                margin: 20px 0;
            }
        }
    }
}
</style>