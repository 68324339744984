<template>
    <b-modal id="modal-confirm-blog-delete">
        <template #modal-title>
            Odstránenie článku
        </template>

        <b-container >
            <p class="my-4">
                Chystáte sa odstrániť článok.
                <strong>Po odstránení už NEBUDE možné vrátiť tento krok späť.</strong>
                Pokračovať?
            </p>
        </b-container>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>

            <b-button @click="deleteBlog()" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="trash-alt"></font-awesome-icon>
                Odstrániť článok
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalBlogDelete",
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-confirm-blog-delete')
        },
        deleteBlog() {
            this.$emit('delete-blog')
            this.hideConfirmModal()
        },
    }
}
</script>

<style lang="scss" scoped>

</style>