<template>
    <div class="page-informations">
        <MainMenu />

        <div class="main-content">
            <div class="container">
                <b-row>
                    <b-col v-if="menuItems === null" cols="12" md="4" xl="3">
                        <b-spinner class="mt-5" type="grow" label="Spinning"></b-spinner>
                    </b-col>
                    <b-col v-else cols="12" md="4" xl="3">
                        <MenuInformation :menuItems="menuItems" />
                    </b-col>

                    <b-col class="info-content" cols="12" md="8" xl="7" offset-xl="1">
                        <AskedQuestion v-if="$route.params.slug === 'casto-kladene-otazky'" />

                        <div v-else>
                            <b-spinner v-if="spinner.mainText" class="mt-5" type="grow" label="Spinning"></b-spinner>
                            <div v-else class="main-text" v-html="mainText"></div>
                        </div>

                    </b-col>
                </b-row>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import MenuInformation from "@/components/my_ebrick/informations/MenuInformation";
import AskedQuestion from "@/components/my_ebrick/informations/single/AskedQuestion";

export default {
    name: 'Informations',
    components: {
        AskedQuestion,
        MenuInformation,
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Všeobecné informácie | ',
        }
    },
    data() {
        return {
            // { id: 3, text: 'Návody', slug: 'navody', children: [ {id: 10, text: 'navod1', slug: 'navod1' } ] },
            menuItems: null,
            mainText: '',

            spinner: {
                mainText: false
            }
        }
    },
    watch: {
        '$route.params.slug': {
            handler: function (value) {
                this.getTextArea(value)
            }, immediate: true
        },
        select_type_question() {
            console.log(this.select_type_question)
        }
    },
    mounted() {
        this.getInfoMenu()
        this.getTextArea(this.$route.params.slug)
    },
    methods: {
        getInfoMenu() {
            axios.get(config.API_URL + '/generalInfoMenu', config.HEADERS)
                .then((response) => {
                    this.menuItems = response.data.success
                }, (error) => {
                    console.warn(error);
                });
        },
        getTextArea(slug) {
            this.spinner.mainText = true

            axios.get(config.API_URL + '/textArea/get/' + slug, config.HEADERS)
                .then((response) => {
                    this.mainText = response.data.success.text
                    this.spinner.mainText = false
                }, (error) => {
                    console.warn(error);
                    this.spinner.mainText = false
                });
        },
    }
}
</script>

<style scoped lang="scss">
.page-informations::v-deep {
    .main-content {
        text-align: left;

        .spinner-grow {
            margin: 0 auto;
        }

        .info-content {
            margin-bottom: 40px;
            min-height: 100vh;
            text-align: center;
        }

        .main-text {
            text-align: left;

            h1, h2, h3, h4 {
                text-align: center;
            }

            h1 {
                font-size: 21px !important;
            }

            h2, h3, h4 {
                font-size: 18px !important;
            }

            li {
                text-align: justify;
            }

            a {
                color: $secondary;
            }

            ol {
                @media (max-width: 767px) {
                    padding-left: 10px;
                }
            }
        }

    }
}
</style>