<template>
    <div class="component-article-rating">
        <div v-if="type === 'blog'">
            <h2 class="article-rating-title">Hodnotenie článku</h2>
            <p class="article-rating-subtitle">Pomohol Vám tento článok?</p>
        </div>
        <div v-else>
            <h2 class="article-rating-title">Hodnotenie príspevku</h2>
            <p class="article-rating-subtitle">Pomohol Vám tento príspevok?</p>
        </div>

        <div class="like-buttons">
            <a class="like" @click="articleRatingLike()">
                <font-awesome-icon class="rating-icon" :class="{ 'article-like' : like_value }" icon="thumbs-up"></font-awesome-icon>
                <span>{{ like_count }}</span>
            </a>
            <div class="separate">|</div>
            <a class="dislike" @click="articleRatingUnlike()">
                <font-awesome-icon class="rating-icon" :class="{ 'article-like' : unlike_value }" icon="thumbs-down"></font-awesome-icon>
                <span>{{ dislike_count }}</span>
            </a>
        </div>

        <p v-if="!logged_user" class="no-login-text">
            Pridávať hodnotenie a komentáre môžu len prihlásení používatelia.
            Zaregistrujte sa a získajte viac informácií a benefitov s multifunkčnou zónou eBrick.sk
        </p>

        <b-form @submit.prevent="postArticleComment()" :class="{ 'input--error': $v.article_comment.$error}">
            <b-spinner v-if="send_button" type="grow" label="Spinning" class="mt-4 mb-5"></b-spinner>
            <b-form-group v-else>
                <b-form-textarea
                    id="article_comment"
                    v-model.trim="article_comment"
                    placeholder="Váš komentár..."
                    size="lg"
                    :disabled="!logged_user"
                ></b-form-textarea>
                <div class="error-message-l" v-show="$v.article_comment.$error">
                    <div v-if="!$v.article_comment.required || !$v.article_comment.minLength">Komentár musí obsahovať najmenej {{ $v.article_comment.$params.minLength.min }} znakov.</div>
                    <div v-if="!$v.article_comment.maxLength">Komentár môže obsahovať maximálne {{ $v.article_comment.$params.maxLength.max }} znakov.</div>
                </div>
                <div v-if="error_comment">
                    <div v-for="(error, index) in error_comment" :key="index" class="error-message-l">{{ error }}</div>
                </div>
            </b-form-group>

            <div v-if="logged_user">
                <b-button type="submit" variant="primary" pill>
                    Pridať komentár
                </b-button>
            </div>

            <b-row v-else class="no-login">
                <b-col cols="10" offset="1" lg="5" offset-lg="1" class="mb-2">
                    <b-button variant="primary" @click="$router.push({ name: 'Sign_in'}, () => {})" pill>
                        <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                        Prihlásiť sa
                    </b-button>
                </b-col>
                <b-col cols="10"  offset="1" lg="5" offset-lg="0">
                    <b-button variant="primary" @click="$router.push({ name: 'Sign_up'}, () => {})" pill>
                        <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                        Registrovať
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    name: "ArticleRating",
    props: {
        article: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: String,
            default: ''
        },
        like_count: {
            type: Number,
            default: 0
        },
        dislike_count: {
            type: Number,
            default: 0
        },
        like_value: {
            type: Number,
            default: 0
        },
        unlike_value: {
            type: Number,
            default: 0
        },
        error_comment: {
            type: Array,
            default() {
                return []
            }
        },
        send_button: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            logged_user: false,
            article_comment: '',
        }
    },
    mounted() {
        this.$store.getters.user.slug ? this.$set(this, 'logged_user', true) : this.$set(this, 'logged_user', false);
    },
    methods: {
        articleRatingLike() {
            this.$emit('article-like')
        },
        articleRatingUnlike() {
            this.$emit('article-unlike')
        },
        postArticleComment() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    // this.$scrollTo('.input--error')
                })
                return false
            } else {
                this.$emit('post-article-comment', this.article_comment)
                this.$v.$reset()
                this.article_comment = ''
            }
        },
    },
    validations: {
        article_comment: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(500)
        },
    },
}
</script>

<style lang="scss" scoped>
.component-article-rating::v-deep {
    .article-rating-title {
        text-align: left;
        color: $secondary;
        margin-bottom: 0;
    }

    .article-rating-subtitle {
        text-align: left;
        font-size: 15px;
        margin-top: 2px;
    }

    .like-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        a {
            &:hover {
                cursor: pointer;
            }
        }

        .rating-icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        .article-like {
            color: $secondary;
        }

        .like, .dislike {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 24px;

            span {
                color: rgba($primary, 0.8);
            }
        }

        .separate {
            font-size: 26px;
            margin: 0 15px;
            color: $primary;

            &:hover {
                cursor: default;
            }
        }
    }

    .no-login-text {
        text-align: left;
        color: $red;
        margin: 20px 0;
    }

    .form-group {
        margin-bottom: 20px;
    }

    textarea {
        position: relative;
        color: $primary;
        border-radius: 10px;
        background: #efefef;
        border: none;
        padding: 15px 20px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);

        &::placeholder {
            color: $primary;
        }
    }

    textarea.form-control-lg {
        font-size: 16px !important;
    }
}
</style>