<template>
    <div class="component-article-comments-list">
        <h3 class="list-title">
            Diskusia k článku
            <span>Počet odpovedí: <strong>{{ pagination.records }}</strong></span>
        </h3>

        <div class="list-comment">
            <ArticleCommentsListCard
                v-for="comment in comments_list"
                :key="comment.id"
                :comment="comment"
                :type="type"
                @post-like="postBlogCommentLike($event)"
                @post-unlike="postBlogCommentUnlike($event)"
                @post-reply-comment="postCommentReply($event)"
                @delete-comment="deleteBlogComment($event)"
            />

            <VuePagination
                :per_page="pagination.perPage"
                :current_page.sync="pagination.currentPage"
                :records="pagination.records"
                :load_data="load_data"
                @handle-current-page="handlePageChange($event)"
            />
        </div>
    </div>
</template>

<script>
import ArticleCommentsListCard from "@/components/detail_components/ArticleCommentsListCard";
import VuePagination from "@/components/VuePagination";

export default {
    name: "ArticleCommentsList",
    components: {
        VuePagination,
        ArticleCommentsListCard
    },
    props: {
        comments_list: {
            type: Array,
            default() {
                return []
            }
        },
        pagination: {
            type: Object,
            default() {
                return {}
            }
        },
        load_data: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            //
        }
    },
    mounted() {
        //
    },
    methods: {
        handlePageChange(page) {
            this.$emit('handle-current-page', page)
        },
        postBlogCommentLike(comment_id) {
            this.$emit('post-like', comment_id)
        },
        postBlogCommentUnlike(comment_id) {
            this.$emit('post-unlike', comment_id)
        },
        postCommentReply(postData) {
            this.$emit('post-reply-comment', postData)
        },
        deleteBlogComment(comment_id) {
            this.$emit('delete-comment', comment_id)
        },
    }
}
</script>

<style lang="scss" scoped>
.component-article-comments-list::v-deep {
    .list-title {
        text-align: left;
        margin-top: 50px;
        margin-bottom: 15px;
        color: $secondary;
        font-size: 20px !important;

        span {
            font-size: 13px;
            font-weight: normal;
            margin-left: 10px;
            color: $primary;
        }
    }
}
</style>