<template>
    <b-modal id="modal-order-detail" v-if="order.order_number">
        <template #modal-header>
            <h1>Objednávka č. {{ order.order_number }}</h1>
        </template>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <span class="description">Názov produktu:</span>
                    <span class="value">{{ order.order_product[0].product_name }}</span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Dátum vytvorenia:</span>
                    <span class="value">{{ moment(order.created_at).format("LLL") }}</span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Stav:</span>
                    <span class="value" :class="order.shipped === 1 ? 'paid' : 'unpaid'">
                        {{ order.shipped === 1 ? 'Uhradená' : 'Neuhradená' }}
                    </span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Cena s DPH:</span>
                    <span class="value">{{ order.summ_with_tax }}</span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Typ produktu:</span>
                    <span class="value">{{ order.order_product[0].product_type }}</span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Platnosť balíka:</span>
                    <span class="value">{{ order.order_product[0].frekvence_month }} mesiac/mesiace</span>
                </b-col>
                <b-col cols="12">
                    <span class="description">Popis balíka:</span>
                    <span class="value">{{ order.order_product[0].short_description }}</span>
                </b-col>
            </b-row>
        </b-container>
        <template #modal-footer>
            <div>
                <b-button @click="hideConfirmModal()" class="mr-2" variant="primary" pill>
                    <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                    Zavrieť
                </b-button>
                <b-button v-if="order.shipped === 0" @click="payOrder()" variant="secondary" pill>
                    <font-awesome-icon class="btn-icon mr-1" icon="credit-card"></font-awesome-icon>
                    Uhradiť
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
    name: "ModalOrderDetail",
    props: {
        order: {
            default: function () {
                return {}
            }
        },
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-order-detail')
        },
        payOrder() {
            const orderData = new FormData();
            orderData.set('order_id', this.order.id);

            axios.post(config.API_URL + '/chekout/doOrder', orderData, config.HEADERS)
                .then((response) => {
                    console.log('CREATE ORDER', response.data)
                    window.open(response.data.success, '_self');
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep #modal-order-detail {
    color: $primary;

    .modal-header {
        h1 {
            margin-bottom: 0;
            font-weight: bold !important;
        }
    }

    .modal-body {
        .row {
            > div {
                display: flex;
                justify-content: space-around;
                //align-items: center;
                box-shadow: 0px 1px 2px rgba(14, 43, 76, 0.1);
                margin-bottom: 10px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                span {
                    min-width: 50%;
                    padding: 3px 0;
                }

                .description {
                    font-weight: bold;
                }
            }

            .paid {
                color: $green;
                font-weight: bold;
            }
            .unpaid {
                color: $red;
                font-weight: bold;
            }
        }
    }

    .modal-footer div {
        text-align: right;
    }
}
</style>