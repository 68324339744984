<template>
    <div :class="'custom-sidebar' + (sidebarOpened ? ' opened' : '')">
        <div v-if="customer">
            <div class="mobile-only d-lg-none">
                <div class="photo-toggle" @click="sidebarOpened = !sidebarOpened">
                    <img class="default-image" :src="imagePreview" alt="obrazok">
                    <img class="toggle-bg" :src="sidebar_toggle" alt="obrazok">
                </div>
            </div>

            <div class="sidebar-main">
                <div class="close-sidebar d-lg-none" @click="sidebarOpened = !sidebarOpened">
                    Zavrieť okno<font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </div>

                <div class="profile-image-wrapper">
                    <img :src="imagePreview" alt="obrazok">
                </div>

                <h1 class="profile-name">{{ customer.name }}</h1>
                <p v-if="customer.role === 'provider'" class="type-customer">Poskytovateľ služieb</p>
                <p v-if="customer.role === 'supplier'" class="type-customer">Dodávateľ materiálu</p>

                <div v-if="customer.stats && customer.stats !== null" class="overall-rating">
                    Celkové hodnotenie
                    <star-rating
                        :rating="customer.stats.all / 20"
                        :fixed-points="2"
                        :round-start-rating="false"
                        :show-rating="false"
                        :read-only="true"
                        :star-size="30"
                        :active-color="'#ffa600'"
                        :inactive-color="'#fff'"
                        :padding="5"
                    />
                </div>
                <hr>

                <UnverifiedEmail v-if="customer.email_verified_at === null" />

                <div v-if="$route.params.slug === $store.getters.user.slug && customer.stats !== null" class="my-profile">
                    <div class="rank-icon"><font-awesome-icon icon="home"></font-awesome-icon></div>
                    <p class="rank-title">Môj profil</p>
                    <div class="rank-progress">
                        <b-progress :max="100">
                            <b-progress-bar :value="customer.stats.profile" variant="secondary"></b-progress-bar>
                        </b-progress>
                        <div class="separators"><span>|</span><span>|</span><span>|</span><span>|</span></div>
                    </div>
                </div>

                <div v-if="$route.params.slug === $store.getters.user.slug && customer.email_verified_at && $store.getters.permissions('manage own customers') && customer.role !== 'super-admin'" class="edit-profile">
                    <p>Doplňte profil a zvýšte úroveň svojej prezentácie.</p>
                    <b-button @click="profileEdit('provider')" variant="light" pill>Doplniť profil</b-button>
                    <p class="last-text">Dobre vyplnený profil zvyšuje prehľadávanie, čo smeruje k zvýšeniu počtu zákaziek.</p>
                </div>
                <div v-if="$route.params.slug === $store.getters.user.slug && customer.email_verified_at && !$store.getters.permissions('manage own customers') && customer.role !== 'super-admin'">
                    <b-button @click="profileEdit('default')" variant="light" pill>Upraviť profil</b-button>
                </div>
<!--                <hr v-if="$route.params.slug === $store.getters.user.slug && customer.email_verified_at !== null && $store.getters.permissions('manage own customers')">-->
                <hr v-if="$route.params.slug === $store.getters.user.slug">

                <div v-if="customer.email_verified_at !== null" class="rank">
                    <div v-if="customer.user_views_count || customer.demands_count" class="stats">
                        <b-row no-gutters>
                            <b-col class="number" cols="3">
                                {{ customer.user_views_count }}
                                <span class="separator">|</span>
                            </b-col>
                            <b-col class="text" cols="9">Počet zobrazení profilu</b-col>
                        </b-row>
                        <b-row v-if="customer.demands_count" no-gutters>
                            <b-col class="number" cols="3">
                                {{ customer.demands_count }}
                                <span class="separator">|</span>
                            </b-col>
                            <b-col class="text" cols="9">Počet dokončených projektov</b-col>
                        </b-row>
                    </div>
                    <hr v-if="customer.user_views_count || customer.demands_count">

                    <div class="stats">
                        <b-row no-gutters>
                            <b-col class="number" cols="3">
                                {{ customer.blogs_count + customer.forums_count}}
                                <span class="separator">|</span>
                            </b-col>
                            <b-col class="text" cols="9">Počet napísaných príspevkov</b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="number" cols="3">
                                {{ customer.blog_likes_count + customer.forum_likes_count }}
                                <span class="separator">|</span>
                            </b-col>
                            <b-col class="text" cols="9">Spolu hodnotení „pomohlo“</b-col>
                        </b-row>
                    </div>
                    <hr>

                    <div v-if="customer.professions && customer.professions.length > 0" class="stats">
                        <p class="text-left mb-2">Moje profesie:</p>
                        <b-row v-for="profession in customer.professions" :key="profession.id" no-gutters>
                            <b-col class="number" cols="3"><span class="separator">|</span></b-col>
                            <b-col class="text" cols="9">{{ profession.name }}</b-col>
                        </b-row>
                    </div>
                    <hr v-if="customer.professions && customer.professions.length > 0">

                    <div v-if="customer.customer && customer.customer.trade_name" class="stats">
                        <p class="text-left mb-2">Oficiálny názov mojej spoločnosti:</p>
                        <b-row v-if="customer.customer.trade_name" no-gutters>
                            <b-col class="number" cols="4"><font-awesome-icon icon="home"></font-awesome-icon><span class="separator">|</span></b-col>
                            <b-col class="text" cols="8">{{ customer.customer.trade_name }}</b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="number" cols="4"><font-awesome-icon icon="map-marker-alt"></font-awesome-icon><span class="separator">|</span></b-col>
                            <b-col class="text" cols="8">
                                {{ customer.customer.address }}
<!--                                {{ customer.customer.psc }}, {{ customer.customer.city }}<br />-->
<!--                                {{ customer.customer.country }}-->
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col class="number" cols="4">IČO<span class="separator">|</span></b-col>
                            <b-col class="text" cols="8">{{ customer.customer.ico }}</b-col>
                        </b-row>
                    </div>
                    <hr v-if="customer.customer && customer.customer.trade_name">

                    <div v-if="customer.shop_links" class="shop-link">
                        <p class="text-left">{{ customer.shop_links.name }}:</p>
                        <a :href="customer.shop_links.link" target="_blank">{{ customer.shop_links.link }}</a>
                        <p v-if="customer.shop_links.text !== null" class="text-left mt-2">Popis: <em>{{ customer.shop_links.text }}</em></p>
                    </div>
                    <hr v-if="customer.shop_links && customer.shop_links !== null">
                </div>
                <p class="registered">Registrovaný od:<br> {{ moment(customer.created_at).format("LL") }}</p>
            </div>

            <div class="geometric-wrapper">
                <img class="hammers" :src="hammers" alt="svg">
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config";
import banner from "@/assets/profile_banner.png";
import hammers from "@/assets/hammers.png";
import sidebar_toggle from "@/assets/sidebar_toggle.png";
import ebrick_logo from "@/assets/ebrick_logo.svg";
import StarRating from 'vue-star-rating'
import UnverifiedEmail from "@/components/UnverifiedEmail";

export default {
    name: "ProfilSlidebar",
    props: {
        customer_load: {
            type: Boolean,
            default: false
        },
    },
    components: {
        UnverifiedEmail,
        StarRating
    },
    data() {
        return {
            banner: banner,
            hammers: hammers,
            sidebar_toggle: sidebar_toggle,

            sidebarOpened: false,

            default_image: ebrick_logo,
            imagePreview: ebrick_logo,
            customer: null,

            email_verified_send: false,
        }
    },
    watch: {
        customer_load: {
            handler: function (value) {
                if (value) {
                    console.log('idem2', value)
                    this.customer = this.$store.getters.customer
                    if (this.customer.customer && this.customer.customer.image_path) {
                        this.imagePreview = config.ASSET_URL + '/' +  this.customer.customer.image_path
                    }
                }
            },
            immediate: true
        },
    },
    mounted() {
        this.setCustomerProfile()
    },
    methods: {
        setCustomerProfile() {
            this.customer = this.$store.getters.customer
            console.log('detail customer', this.$store.getters.customer)
        },
        profileEdit(customer_type) {
            if (customer_type === 'provider') {
                this.$router.push({ name: 'ProfileEdit', params: { slug: this.$store.getters.user.slug }}, () => {})
            } else if (customer_type === 'default') {
                this.$router.push({ name: 'ProfileEditDefault', params: { slug: this.$store.getters.user.slug }}, () => {})
            }
        },
    }
}
</script>

<style scoped lang="scss">
.custom-sidebar {
    position: relative;
    background: $primary;
    color: white;
    padding: 0 25px 10px 25px;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);

    min-height: 500px;
    margin-bottom: 40px;
    z-index: 5;

    hr {
        border-top: 8px solid $secondary-lighter;
    }

    p {
        font-size: 12px;
        font-weight: 500;
    }

    .profile-image-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        height: 75px;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: -100%;
            border-radius: 50%;
            border: 2px solid white;
            display: flex;
            width: 150px;
            height: 150px;
            object-fit: cover;
            box-shadow: 0 0 10px $primary;
            background-color: white;
        }
    }

    .profile-name {
        font-weight: 800 !important;
        font-size: 18px !important;
    }

    .type-customer {
        color: $secondary-lighter;
    }

    .overall-rating {
        margin-top: 20px;
        position: relative;

        .vue-star-rating {
            justify-content: center;
        }
    }

    .my-profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .rank-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $secondary-lighter;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            margin-bottom: 5px;

            svg {
                font-size: 50px;
                color: $secondary-lighter;
            }
        }

        .rank-title {
            color: $secondary-lighter;
            font-weight: 600;
            font-size: 18px;
            margin: 0;
        }

        .rank-progress {
            position: relative;
            width: 100%;
            margin: 20px;

            .progress {
                height: 5px;
                overflow: visible;

                .progress-bar {
                    position: relative;
                    background-color: $secondary-lighter !important;
                    height: 9px;
                    margin-top: -2px;
                    border-radius: 10px;
                    overflow: visible;

                    &:after {
                        content: "";
                        position: absolute;
                        top: -3px;
                        right: -8px;
                        height: 16px;
                        width: 16px;
                        background: $primary;
                        border: 2px solid $secondary-lighter;
                        border-radius: 50%;
                        z-index: 1;
                    }
                }
            }

            .separators {
                position: absolute;
                top: -7px;
                left: 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                span {
                    font-size: 22px;
                    font-weight: 600;
                    border-radius: 50%;
                    line-height: 19px;
                    overflow: hidden;
                }
            }
        }
    }

    .edit-profile {
        font-size: 12px;
        padding: 10px 5px;
        font-weight: 500;
        border-radius: 10px;

        p {
            margin-bottom: 0;
        }

        .email-send {
            position: relative;
            margin-bottom: 0;
        }

        button {
            margin: 15px auto;
        }
    }

    .rank {
        //display: flex;
        flex-direction: column;
        align-items: center;
        display: block;

        .stats {
            .row {
                margin-bottom: 10px;

                .number {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    text-align: right;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 20px;

                    .separator {
                        color: $secondary-lighter;
                        margin: 0 5px;
                        font-size: 20px;
                        font-weight: 400;
                        margin-top: -2px;
                    }

                    svg {
                        height: 18px;
                    }
                }

                .text {
                    display: flex;
                    text-align: left;
                    font-size: 12px;
                    padding-left: 0;
                    line-height: 20px;
                }
            }
        }

        .shop-link {
            p {
                margin-bottom: 10px;
            }

            a {
                color: white;

                &:hover {
                    color: $secondary-lighter;
                }
            }
        }
    }

    .registered {
        margin-bottom: 10px;
    }

    .geometric-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: -1;

        .hammers {
            position: relative;
            bottom: -80px;
            left: -40px;
        }
    }

    @media (max-width: 992px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        overflow: visible;
        transition: transform .2s ease-in-out;

        transform: translate(calc(-100% + 10px), 0);

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.opened {
            transform: translate(0, 0);
            box-shadow: 12px 0 24px rgba(10, 28, 51, .5);
            //transform: translate(calc(-100% + 10px), 0);
        }

        .sidebar-main {
            position: relative;
            width: 100%;
            max-height: 100vh;
            padding-top: 10px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 5;
        }

        .profile-image-wrapper {
            height: auto;

            img {
                position: relative;
                top: auto;
            }
        }

        .close-sidebar {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 80px;
            text-align: right;
            font-size: 13px;

            svg {
                font-size: 20px;
                margin-left: 5px;
            }
        }

        .mobile-only {
            .photo-toggle {
                position: absolute;
                right: -40px;
                //top: 100px;
                top: 35px;
                padding: 10px;
                border-radius: 50%;
                z-index: 99;

                img {
                    width: 90px;
                    height: 90px;
                    object-fit: cover;
                    border-radius: 50%;
                }

                .default-image {
                    background: white;

                }

                .toggle-bg {
                    position: absolute;
                    width: 147px;
                    height: 147px;
                    right: -47px;
                    top: -19px;
                    z-index: -1;
                }
            }
        }
    }
}
</style>