<template>
    <span @click="readNotifications()" class="notification-bell">
        <font-awesome-icon v-if="$store.getters.user.slug" icon="bell"></font-awesome-icon>
        <font-awesome-icon v-else icon="bell-slash"></font-awesome-icon>
        <span v-if="notification_count > 0 && notification_count < 10" class="notification-count">{{ notification_count }}</span>
        <span v-if="notification_count > 10" class="notification-count">9+</span>
    </span>
</template>

<script>
export default {
    name: "NotificationCount",
    data() {
        return {
            notification_count: 0,
        }
    },
    watch: {
        '$store.getters.notification_count': {
            handler: function (value) {
                this.notification_count = value
            },
            immediate: true
        },
    },
    methods: {
        readNotifications() {
            if (!this.$store.getters.user.slug) return false
            this.$store.commit('setNotificationCount', false)
            this.$store.dispatch('getNewNotification', false)
        },
    },
}
</script>

<style lang="scss" scoped>
.notification-bell::v-deep {

    .notification-count {
        position: absolute;
        left: 50%;
        background-color: #ef0000;
        color: white;
        font-size: 10px;
        padding: 0 3px;
        border-radius: 3px;
    }
}
</style>