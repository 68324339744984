<template>
    <div class="component-package-card">
        <div :class="(recommended ? 'recommended-package' : 'standard-package')">
            <span class="circle" :class="(recommended ? 'circle-recommended' : 'circle-standard')" v-html="product.frekvence_name"></span>
            <div class="wrapper" :class="(recommended ? 'wrapper-recommended' : 'wrapper-standard')">
                <span class="top-rectangle"></span>
                <div class="price">{{ product.price_dph }} €</div>

                <div class="toggle-summary" v-b-toggle="product.slug" @click.prevent="showSummary()">
                    <span class="mr-1">{{ toggle ? 'Skryť detaily' : 'Zobraziť detaily' }}</span>
                    <font-awesome-icon icon="arrow-down"></font-awesome-icon>
                </div>
            </div>

            <b-collapse :visible="visible" :id="product.slug" class="collapse-card">
                <b-container class="wrapper-card">
                    <b-row class="package-time-duration" no-gutters>
                        <b-col cols="12">
                            <p>Doba platnosti:</p>
                            <p class="package-time-duration__months">{{ product.month }}</p>
                            <p class="package-time-duration__description">počet mesiacov</p>
                        </b-col>
                    </b-row>
                    <b-row no-gutters>
                        <b-col cols="1">
                            <div class="item_info">
                                <font-awesome-icon class="info-svg" icon="info-circle"></font-awesome-icon>
                                <span class="tooltiptext">cena za mesiac</span>
                            </div>
                        </b-col>
                        <b-col cols="7">cena</b-col>
                        <b-col cols="4"><strong>{{ Math.round(product.price_per_month * 100) / 100 }} €</strong></b-col>
                    </b-row>
                    <b-row class="no-dotted" no-gutters>
                        <b-col cols="1"></b-col>
                        <b-col cols="7">cena bez DPH</b-col>
                        <b-col cols="4">{{ product.price }} €</b-col>
                    </b-row>

                    <b-button v-if="button_visible && recommended === 1" @click="selectPackage(product.slug)" class="button-select-package" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="angle-double-right"></font-awesome-icon>
                        Vybrať
                    </b-button>
                    <b-button v-if="!button_visible" @click="changePackage()" class="button-select-package" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="exchange-alt"></font-awesome-icon>
                        Zmeniť balík
                    </b-button>
                </b-container>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            product: {
                type: Object,
                default() {
                    return {}
                }
            },
            button_visible: {
                type: Boolean,
                default() {
                    return false
                }
            },
            recommended: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                toggle: true,
                visible: true, // false
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
        },
        mounted() {
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            showSummary() {
                this.toggle = !this.toggle
            },
            handleResize() {
                // kontrola sirky okna, podla toho nastavuje ci ma byt balik otvoreny alebo nie
                // this.visible = window.innerWidth > 992;
            },
            selectPackage(package_select) {
                this.$emit('package_select', package_select)
            },
            changePackage() {
                this.$router.push({ name: 'Subscription' }, () => {})
            },
        },
    }
</script>

<style lang="scss" scoped>
.component-package-card::v-deep {
    position: relative;
    background-color: white;
    margin-top: -10px;
    margin-bottom: 50px;
    border-radius: 0 0 15px 15px;

    .recommended-package {
        box-shadow: 0 3px 15px -3px $secondary;
        border-radius: 0 0 15px 15px;
    }

    .standard-package {
        filter: blur(2px);
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
        background-color: white;
        box-shadow: 0 2px 8px -3px rgba(black, 0.5);
        border-radius: 0 0 15px 15px;
    }

    .circle {
        position: absolute;
        margin-top: -25px;
        padding-top: 5px;
        left: 50%;
        transform: translate(-50%, 0);

        width: 55px;
        height: 55px;
        border-radius: 50%;
        box-shadow: 3px 2px 10px -1px rgba(0, 0, 0, .4);
        border: 1px solid #9B413A;

        z-index: 1;
        font-size: 30px;
        font-weight: bold;

        &-recommended {
            color: white;
            background-color: $secondary;
        }

        &-standard {
            color: $secondary;
            background-color: white;
        }
    }

    .wrapper {
        border-radius: 0 0 15px 15px;
        padding-top: 25px;
        border: 1px solid #9B413A;

        &-recommended {
            color: white;
            background-color: $secondary;
        }

        &-standard {
            color: $secondary;
            background-color: white;
        }

        .top-rectangle {
            position: absolute;
            display: block;
            width: calc(100% - 1px);
            height: 10px;
            background-color: $secondary;
            top: 0;
        }

        .price {
            margin-top: 15px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 20px;
        }

        .recommendation {
            font-size: 15px;
            font-weight: bold;
            margin-top: -5px;
            margin-bottom: 5px;
        }

        .toggle-summary {
            font-size: 12px;
            font-weight: lighter;
            opacity: 0.8;
            padding-bottom: 5px;

            svg {
                margin-top: -3px;
                transition: transform .4s ease-in-out;
            }

            &.not-collapsed {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    
    .collapse-card {
        background-color: white;
        color: $primary;

        &:last-child {
            border-radius: 0 0 15px 15px;
        }

        .wrapper-card {
            .item_info {
                color: $secondary;

                svg {
                    transform: scale(1.5);
                    cursor: pointer;
                }

                &:hover .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .package-time-duration {
                p {
                    margin: 0;
                }

                &__months {
                    font-size: 25px;
                    font-weight: 800;
                    color: $secondary;
                    margin-top: 10px;
                    background-color: #9b413a17;
                    border-radius: 10px;
                }

                &__description {
                    margin-top: -5px;
                    color: $secondary;
                    font-size: 14px;
                }
            }

            .item_info .tooltiptext {
                visibility: hidden;
                opacity: 0;
                //display: table;
                position: absolute;
                padding: 5px 20px;
                width: max-content;
                z-index: 1;
                transition: 0.3s;

                left: 0;
                top: 35px;

                font-size: 15px;
                border-radius: 0 30px 30px 30px;
                background-color: #888;
                color: #fff;
                text-align: left;
            }

            > .row {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                padding: 10px 0;
                font-size: 15px;

                &:not(.no-dotted):after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                    background-image: linear-gradient( 90deg, #0e2b4c 0, rgba(255, 255, 255, 0) 30%);
                    background-position: bottom;
                }

                > div {
                    padding: 10px 0;

                    &:nth-child(2) {
                        text-align: left;
                        padding-left: 15px;
                    }

                }
                    &:last-child {
                        padding-bottom: 15px;
                    }
            }

            .button-select-package {
                width: 90%;
                max-width: 300px;
                margin: 20px 0;
            }
        }
    }
}
</style>