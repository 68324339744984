<template>
    <div :class="'single-comment' + (comment.parent_id ? ' is-reply' : '')">
        <p class="comment-date">{{ moment(comment.created_at).format("LLL") }}</p>

        <div class="comment-body">
            <div v-if="comment.parent" class="reply-content">
                <div class="reply-title">
                    Reaguje na komentár uživateľa
                    <a @click="$router.push({ name: 'PageProfileAbout', params: { slug: comment.parent.user.slug }})">
                        <strong>{{ comment.parent.user.name }}</strong>
                    </a>
                </div>
                <div v-if="comment.parent.deleted_at" class="reply-message"><em>Tento komenár bol vymazaný.</em></div>
                <p v-else class="reply-message" v-html="comment.parent.text"></p>
            </div>

            <p v-if="comment.deleted_at"><em>Tento komenár bol vymazaný.</em></p>
            <p v-else v-html="comment.text"></p>

            <b-form-group v-if="commentInput">
                <b-form-textarea
                    id="comment_add"
                    v-if="commentInput"
                    v-model.trim="comment_add"
                    placeholder="Váš komentár..."
                    size="sm"
                ></b-form-textarea>

                <div class="error-message-l" v-show="$v.comment_add.$error">
                    <div v-if="!$v.comment_add.required">Najprv musíte napísať komentár.</div>
                    <div v-if="!$v.comment_add.minLength">Komentár musí obsahovať najmenej {{ $v.comment_add.$params.minLength.min }} znakov.</div>
                </div>
                <div v-if="error.note !== null">
                    <div v-for="(error, index) in error.note" :key="index" class="error-message-l">{{ error }}</div>
                </div>
            </b-form-group>
        </div>

        <hr class="separator-hr">

        <div class="bottom-bar">
            <div class="bottom-bar__left">
                <div v-if="!comment.deleted_at">
                    <font-awesome-icon icon="thumbs-up" :class="{ like : like }" @click="commentLike()"></font-awesome-icon>
                    <span>{{ comment.likes_count }}</span>

                    <span class="separate">|</span>
                    <font-awesome-icon icon="thumbs-down" :class="{ like : unlike }" @click="commentUnlike()"></font-awesome-icon>

                    <span>{{ comment.dislikes_count }}</span>
                    <span class="separate">|</span>
                </div>

                <div>
                    <a @click="$router.push({ name: 'PageProfileAbout', params: { slug: comment.user.slug }})" v-if="comment.user">
                        {{ comment.user.name }}
                    </a>
                </div>
            </div>

            <div v-if="!comment.deleted_at && $store.getters.user.user_id" class="bottom-bar__right">
                <button v-if="!commentInput" @click="addComment()">Odpovedať na komentár</button>
                <button v-if="!commentInput && $store.getters.user.user_id === comment.user_id" @click="deleteBlogComment(comment.id)">Vymazať</button>
                <button v-if="commentInput" @click="addReplyComment()">Odoslať odpoveď</button>

                <button v-if="commentInput" @click="cancelReplyComment()">Zrušiť</button>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'ArticleCommentsListCard',
    props: {
        reply: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: ''
        },
        comment: {
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            like: 0,
            unlike: 0,

            comment_add: '',
            commentInput: false,

            error: {
                note: null,
            }
        }
    },
    mounted() {
        if (this.type === 'blog') {
            if (this.comment.blog_comment_likes[0]) {
                this.comment.blog_comment_likes[0].like === 1 ? this.like = 1 : this.like = 0;
                this.comment.blog_comment_likes[0].unlike === 1 ? this.unlike = 1 : this.unlike = 0;
            }
        } else {
            if (this.comment.forum_comment_likes[0]) {
                this.comment.forum_comment_likes[0].like === 1 ? this.like = 1 : this.like = 0;
                this.comment.forum_comment_likes[0].unlike === 1 ? this.unlike = 1 : this.unlike = 0;
            }
        }
    },
    methods: {
        postBlogCommentLike() {
            this.$emit('post-like', this.comment.id)
        },
        postBlogCommentUnlike() {
            this.$emit('post-unlike', this.comment.id)
        },
        addComment() {
            this.commentInput = !this.commentInput;
        },
        addReplyComment() {
            this.$v.$touch()
            if (this.$v.$invalid) return false

            let postData = {}
            postData.comment = this.comment_add
            if (this.type === 'blog') {
                postData.blog_id = this.comment.blog_id
            } else {
                postData.forum_id = this.comment.forum_id
            }
            postData.comment_id = this.comment.id

            this.$emit('post-reply-comment', postData)

            this.comment_add = '';
            this.commentInput = false
            this.$v.$reset()
        },
        deleteBlogComment(comment_id) {
            this.$emit('delete-comment', comment_id)
        },



        commentLike() {
            if (this.$store.getters.user) {
                if (this.unlike === 1) {
                    this.unlike = 0;
                    this.comment.dislikes_count -= 1
                }
                if (this.like === 1) {
                    this.like = 0
                    this.comment.likes_count -= 1
                } else {
                    this.like = 1;
                    this.comment.likes_count += 1
                }
                this.postBlogCommentLike();
            }
        },
        commentUnlike() {
            if (this.$store.getters.user) {
                if (this.like === 1) {
                    this.like = 0;
                    this.comment.likes_count -= 1
                }
                if (this.unlike === 1) {
                    this.unlike = 0
                    this.comment.dislikes_count -= 1
                } else {
                    this.unlike = 1;
                    this.comment.dislikes_count += 1
                }
                this.postBlogCommentUnlike();
            }
        },

        cancelReplyComment() {
            this.comment_add = '';
            this.commentInput = 0;
        }
    },
    validations: {
        comment_add: {
            required,
            minLength: minLength(5),
            // maxLength: maxLength(160)
        },
    },
}
</script>

<style scoped lang="scss">
.single-comment::v-deep {
    position: relative;
    padding: 15px 20px;
    margin: 20px 0;
    box-shadow: 0 2px 5px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    .comment-date {
        text-align: left;
        margin-bottom: 10px;
    }

    .comment-body {
        text-align: left;

        p {
            margin-bottom: 5px;
            text-align: justify;
        }

        .reply-content {
            color: $secondary;
            font-style: italic;
            margin-bottom: 10px;

            .reply-title {
                font-weight: 600;

                a {
                    color: $secondary;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .reply-message {
                padding-left: 10px;

                &:before, &:after {
                    content: '"';
                }
            }
        }
    }

    .form-group {
        margin-bottom: 5px;
    }

    .separator-hr {
        background: $secondary;
        height: 8px;
        width: 100%;
        margin: 10px 0;
    }

    .bottom-bar {
        display: flex;
        flex-direction: row;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        &__left {
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
                margin-right: 7px;

                &:hover {
                    cursor: pointer;
                }
            }

            .like {
                color: $secondary;
            }

            span {
                color: rgba(14, 43, 76, .5);
            }

            .separate {
                margin: 0 7px;
            }
        }

        &__right {
            display: flex;
            flex-direction: row;
            margin-left: auto;

            button {
                outline: none;
                background-color: transparent;
                border: 0;
            }
        }

        a {
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }
    }

    &.is-reply {
        .comment-body {
            padding: 3px 10px 5px;
            margin-bottom: 10px;
            border-radius: 10px;
            background: #ca665f24;
        }
    }
}
</style>