<template>
    <div class="single-question">
        <b-row class="type-questions">
            <b-col cols="6" offset="3" sm="3" offset-sm="0" @click="setQuestionFilter(0)" :class="{ active: select_type_question === 0}">Všetky</b-col>
            <b-col cols="6" offset="3" sm="3" offset-sm="0" @click="setQuestionFilter(1)" :class="{ active: select_type_question === 1}">Všeobecné</b-col>
            <b-col cols="6" offset="3" sm="3" offset-sm="0" @click="setQuestionFilter(3)" :class="{ active: select_type_question === 3}">Zákazník</b-col>
            <b-col cols="6" offset="3" sm="3" offset-sm="0" @click="setQuestionFilter(2)" :class="{ active: select_type_question === 2}">Firmy/živnostníci</b-col>
        </b-row>

        <b-spinner v-if="filteredQuestions === null" class="mt-5" type="grow" label="Spinning"></b-spinner>
        <div v-else class="accordion" role="tablist">
            <b-card no-body v-for="(question, index) in filteredQuestions" :key="question.id" class="mb-1" >
                <b-card-header header-tag="header" role="tab">
                    <b-button v-b-toggle="question.slug" block>
                        <font-awesome-icon class="btn-icon" icon="question-circle"></font-awesome-icon>
                        <strong>{{ question.name }}</strong>
                    </b-button>
                </b-card-header>
                <b-collapse :id="question.slug" accordion="my-accordion" role="tabpanel" :visible="index === 0">
                    <b-card-body>
                        <p v-html="question.text"></p>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
    name: "AskedQuestion",
    data() {
        return {
            questions: null,
            select_type_question: 0,
            text_area_container: 'casto-kladene-otazky',
        }
    },
    mounted() {
        this.getQuestions()
    },
    methods: {
        getQuestions() {
            axios.get(config.API_URL + '/textArea/getContainer/' + this.text_area_container, config.HEADERS)
                .then((response) => {
                    console.log(response.data.success.text_areas)
                    this.questions = response.data.success.text_areas
                }, (error) => {
                    console.warn(error);
                });
        },
        setQuestionFilter(value) {
            this.select_type_question = value
        }
    },
    computed: {
        filteredQuestions() {
            if (this.select_type_question === 0) {
                return this.questions
            } else {
                return this.questions.filter(question => {
                    return Number(question.text_area_question_type_id) === this.select_type_question
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.single-question {

    .type-questions {
        cursor: pointer;
        margin-bottom: 50px;

        .active {
            color: white;
            background-color: $primary;
            border-radius: 5px;
            font-weight: bold;
            white-space: nowrap;
            transition: all 0.2s ease-in-out;
        }
    }

    .accordion {
        color: $primary;

        .card {
            border-radius: 6px;
            border: 0;
        }

        .card-header {
            padding: 0;
            border-radius: 6px;

            button {
                background-color: $bg-gray;
                color: $primary;
                outline: none;
                text-align: left;
                border: 0;
                padding: 15px;
                //border-bottom: 1px solid $primary;

                &:focus, &:active, &:hover {
                    outline: none;
                    background-color: transparent;
                }

                &:hover {
                    background-color: $primary;
                    color: white;
                }

                &:focus {
                    background-color: $primary;
                    color: white;
                }

                svg {
                    margin-top: -4px;
                    margin-right: 7px;
                }

                &.not-collapsed {
                    background-color: $primary;
                    color: white;
                }
            }

            .dropdown-toggle:focus {
                box-shadow: none;
            }
        }

        .card-body {
            background-color: $bg-gray;
            text-align: left;

            p {
                margin-bottom: 0;
            }
        }
    }
}
</style>