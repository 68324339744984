<template>
	<div class="component-benefits-slider">
		<a @click="$router.push({ name: 'NewDemand' }, () => {})">
			<div class="container">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div v-for="slide in active_banner" :key="slide.id" class="swiper-slide">
							<b-row class="benefits-slider-item">
								<b-col class="image" cols="12" md="2" offset-md="2">
									<img :src="asset_url + '/' + slide.banner" alt="obrazok">
								</b-col>
								<b-col cols="12" md="8">
									<div class="text">
										<p class="title" v-html="slide.title"></p>
										<p v-html="slide.text"></p>
									</div>
								</b-col>
							</b-row>
						</div>
					</div>
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import config from "@/config";
import market_steps from "@/assets/market_steps.svg"

import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

export default {
    props: {
        banner_slug: {
            default() {
                return ''
            }
        },
    },
	data() {
		return {
			market_steps: market_steps,
            asset_url: '',
            active_banner: '',
            preloadImages: true,
            config_swiper: {
                direction: 'horizontal',
                loop: true,
                autoplay: {
                    delay: 15000,
                },
            },
            main_swiper: '',
		}
	},
    watch: {
        '$store.getters.banner_area': {
            handler() {
                if (this.$store.getters.banner_area) {
                    this.$store.getters.banner_area.forEach(el => {
                        if (el.slug === this.banner_slug) {
                            this.active_banner = el.banners
                            this.$nextTick(() => {
                                this.main_swiper.init();
                            })
                        }
                    })
                }
            }, immediate: true
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        this.$set(this, 'main_swiper', new Swiper('.component-benefits-slider .swiper-container', this.config_swiper));
	}
}
</script>

<style scoped lang="scss">
.component-benefits-slider::v-deep {
	position: relative;
	background: $primary;

    a {
        &:hover {
            cursor: pointer;
        }
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
    }

	.benefits-slider-item {
        display: flex;
        align-items: center;

		.image {
			display: flex;
			justify-content: flex-end;

			img	{
				max-width: 100%;
                max-height: 105px;
			}
		}

		.text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 20px 0;

			p {
				color: white;
				margin-bottom: 0;
				text-align: left;

				&.title {
					font-size: 20px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		display: none;
		//.benefits-slider-item {
		//	.image {
		//		justify-content: center;
		//	}
		//
		//	.text {
		//		padding: 15px;
		//	}
		//}
	}
}
</style>