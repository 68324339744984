<template>
    <b-row class="search-demands-row">
        <b-col cols="auto" class="search-demands">
            <font-awesome-icon class="info" icon="search"></font-awesome-icon>
            <b-form-input :id="'search-' + search_id" v-model="search_term" type="text"></b-form-input>
        </b-col>
    </b-row>
</template>

<script>
import _ from "lodash";

export default {
    name: "ProfileSearchInput",
    props: [ 'search_id' ],
    data() {
        return {
            search_term: ''
        }
    },
    watch: {
        search_term: _.debounce(function () {
            this.$emit('search_term', this.search_term)
        }, 500),
    },
    methods: {
        resetSearchTerm() {
            this.search_term = ''
        },
    }
}
</script>

<style lang="scss" scoped>
.search-demands-row::v-deep {
    display: flex;
    //justify-content: flex-end;

    .search-demands {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-height: 30px;
        margin: 10px 0;

        @media (max-width: 922px) {
            margin: 10px auto;
        }

        svg {
            cursor: pointer;
            margin: 0 10px;
            position: absolute;
            left: 15px;
        }

        input {
            max-height: 30px;
            max-width: 230px;
            border-radius: 20px;
            padding-left: 33px;
        }
    }
}
</style>