<template>
	<div class="to-market-search">
		<div class="container">
			<div class="content">
				<h1 class="text-secondary">Vy sa pýtate, my nájdeme odpoveď.</h1>
				<p>Získajte pre svoj projekt správneho dodávateľa <br> a hodnotné informácie od odborníkov.</p>

                <b-form @submit.prevent="searchTerm()">
                    <b-form-group>
                        <b-form-input type="search" v-model="search_term" placeholder="Nájdeme, čo potrebujete"></b-form-input>
                        <template slot="description">
                            <a @click="$router.push({ name: 'Search', query: { search: 'all' }}, () => {})">
                                Rozšírené vyhľadávanie
                            </a>
                        </template>
                    </b-form-group>
                </b-form>

				<p v-if="$store.getters.user.slug">Nájdite si niekoho pre svoj vysnený projekt, <br> alebo si pozrite, čo práve potrebujú iní.</p>
				<b-row v-if="$store.getters.user.slug" class="buttons">
					<b-col cols="12" lg="3" offset-lg="3" class="right">
						<b-button v-if="page === 'home'" @click="$router.push({ name: 'Demands', params: { demands: 'demands' }})" pill variant="outline-secondary">
                            Prehľad dopytov
                        </b-button>
						<b-button v-if="page === 'demand'" @click="$router.push({ name: 'Search', query: { search: 'supplier', filter: 'amount', sort: 'desc' }}, () => {})" pill variant="outline-secondary">
                            Vyhľadávať dodávateľov
                        </b-button>
					</b-col>
					<b-col cols="12" lg="3" class="left">
						<b-button @click="$router.push({ name: 'NewDemand'})" pill variant="primary">Zadať dopyt</b-button>
					</b-col>
				</b-row>
			</div>

            <b-button v-if="!$store.getters.user.slug" @click="$router.push({ name: 'Sign_up'})" class="mt-3" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="angle-double-right"></font-awesome-icon>
                Pridajte svoj prvý dopyt a nájdite overeného dodávateľa
            </b-button>

			<img class="geometric-3" :src="geometric_3" alt="svg">

			<span class="palicka-right"></span>
			<span class="palicka-center"></span>
			<span class="palicka-center-2"></span>
			<span class="palicka-left"></span>
		</div>
	</div>
</template>

<script>
import geometric_3 from "@/assets/geometric_3.svg"

export default {
    props: [ 'page' ],
	data() {
		return {
			geometric_3: geometric_3,

            search_term: '',
		}
	},
    methods: {
        searchTerm() {
            this.$router.push({ name: 'Search', query: { search: 'all', search_term: this.search_term }}, () => {})
        },
    },
}
</script>

<style scoped lang="scss">
.to-market-search::v-deep {
	padding: 70px 0;

	.container {
		position: relative;

		.content {
			position: relative;
			z-index: 10;
		}
	}

	p {
		margin-bottom: 30px;
	}

	input {
		color: $primary;
		border-radius: 30px;
		background: #DCDEE6;
		border: none;
		width: 500px;
		margin: 0 auto;
		padding: 30px;

		&::placeholder {
			color: $primary;
		}
	}

    a {
        cursor: pointer;
    }

	.buttons {
        button {
            width: 100%;
            max-width: 300px;
            margin-bottom: 20px;
        }

		.left {
			text-align: left;
		}
		.right {
			text-align: right;
		}
        
        @media (max-width: 992px) {
            .left, .right {
                text-align: center;
            }
        }
	}

	.geometric-3 {
		position: absolute;
		left: -520px;
		bottom: -250px;
		height: calc(100% + 400px);
		//z-index: 5;
		z-index: -1;
	}

	.palicka-left {
		position: absolute;
		top: -100px;
		left: 150px;
		width: 2px;
		height: 4000px;
		background: $palicka-color;
		//z-index: 2;
		z-index: -1;
	}

	.palicka-right {
		position: absolute;
		top: 100%;
		right: 150px;
		width: 2px;
		height: 4000px;
		background: $palicka-color;
		//z-index: 1;
		z-index: -1;
	}

	.palicka-center {
		position: absolute;
		top: -20px;
		right: 51.3%;
		width: 2px;
		height: 1500px;
		background: $palicka-color;
		//z-index: 1;
		z-index: -1;
	}

	.palicka-center-2 {
		position: absolute;
		top: 40%;
		right: 48.5%;
		width: 2px;
		height: 1500px;
		background: $palicka-color;
		//z-index: 1;
		z-index: -1;
	}

	@media (max-width: 768px) {
		input {
			width: 100%;
		}

		.buttons {
			.left, .right {
				button {
					width: 100%;
					margin-bottom: 20px;
				}
			}
		}

		.palicka-left, .palicka-right, .palicka-center, .palicka-center-2 {
			display: none;
		}
	}
}
</style>