<template>
    <div class="component-article-head">
        <h1 class="article-head-title" v-html="title"></h1>

        <div class="article-head-separator">
            <span class="vendor-img-overlay">
                <img :src="vendor" alt="dodavatel">
            </span>
        </div>

        <p
            v-if="user"
            @click="$router.push({ name: 'PageProfileAbout', params: { slug: user.slug }})"
            class="article-head-author"
        >
            {{ user.name }} <span v-if="publication_date">| {{ moment(publication_date).format("LL") }}</span>
        </p>

        <!--                        <ShareNetwork-->
        <!--                            v-if="sharing.url"-->
        <!--                            network="facebook"-->
        <!--                            :url="sharing.url"-->
        <!--                            :title="sharing.title"-->
        <!--                            :description="sharing.description"-->
        <!--                            :quote="sharing.quote"-->
        <!--                            :hashtags="sharing.hashtags"-->
        <!--                        >-->
        <!--                            <font-awesome-icon :icon="['fab', 'facebook-square']" class="btn-icon"/>-->
        <!--                            <span>facebook</span>-->
        <!--                        </ShareNetwork>-->

        <!--                        <ShareNetwork-->
        <!--                            v-if="spinner.share_data"-->
        <!--                            :network="'email'"-->
        <!--                            :url="sharing.url"-->
        <!--                            :title="sharing.title"-->
        <!--                            :description="sharing.description"-->
        <!--                        >-->
        <!--                            &lt;!&ndash;                            <font-awesome-icon :icon="['fab', 'facebook-square']" class="btn-icon"/>&ndash;&gt;-->
        <!--                            <span>-->
        <!--                                <font-awesome-icon icon="envelope" class="btn-icon"/>-->
        <!--                                Email-->
        <!--                            </span>-->
        <!--                        </ShareNetwork>-->

        <div class="article-head-category">
            <span class="text-left" v-for="(category, index) in categories" :key="category.id">
                <small @click="goCategory(category.menu_item.id)">
                    {{ category.menu_item.name }}<span v-if="index < categories.length - 1">,</span>
                </small>
            </span>
        </div>
    </div>
</template>

<script>
import vendor from "@/assets/vendor.svg";

export default {
    name: "ArticleHead",
    props: {
        title: {
            type: String,
            default: ''
        },
        publication_date: {
            type: String,
            default: ''
        },
        user: {
            type: Object,
            default() {
                return {}
            }
        },
        categories: {
            type: Array,
            default() {
                return []
            }
        },
        route_name: {
            type: String,
            default: 'Home'
        },
        param: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            vendor: vendor,
            // sharing: {},
        }
    },
    methods: {
        goCategory(category_id) {
            if (this.param === 'blog') {
                this.$router.push({ name: this.route_name, params: { blog: this.param }, query: { category: '[' + category_id + ']' }})
            } else  if (this.param === 'forum') {
                this.$router.push({ name: this.route_name, params: { forum: this.param }, query: { category: '[' + category_id + ']' }})
            }
        },
        // setSharingData(data) {
        //     let sharingData = {}
        //     sharingData.url = config.BASE_URL + '/blog/' + data.slug
        //     let title = data.title.replace(/<[^>]+>/g, '')
        //     sharingData.title = title.substring(0,50)
        //     let text = data.text.replace(/<[^>]+>/g, '')
        //     sharingData.description = text.substring(0,50)
        //     sharingData.hashtags = 'eBrick'
        //     this.sharing = sharingData
        //     this.$nextTick(() => {
        //         this.spinner.share_data = true
        //     })
        // },
    }
}
</script>

<style lang="scss" scoped>
.component-article-head::v-deep {
    margin-bottom: 20px;
    text-align: left;

    .article-head-title {
        text-align: left;
        margin-left: 45px;
        margin-bottom: 15px;
        color: $secondary;
    }

    .article-head-separator {
        position: relative;

        .vendor-img-overlay {
            background: $secondary;
            position: relative;
            display: flex;
            width: 40px;
            height: 40px;
            margin-top: -25px;
            margin-left: -1px;
            margin-right: auto;
            padding: 10px;
            border-radius: 50%;
            box-shadow: 0 6px 14px rgba(155, 65, 58, .2);
            z-index: 2;
        }

        &:before {
            content: "";
            background: $secondary;
            position: absolute;
            top: 17px;
            left: 0;
            height: 8px;
            width: 100%;
        }
    }

    .article-head-author {
        cursor: pointer;
        margin-left: 45px;
        margin-bottom: 0;
        text-align: left;
        font-weight: bold;
    }

    .article-head-category {
        margin-left: 45px;
        display: flex;
        text-align: left;
        flex-direction: row;
        flex-wrap: wrap;

        span {
            > small {
                color: $small-color;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }
}
</style>