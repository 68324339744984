<template>
    <div class="component-vendor-list">
        <h2 class="title text-left"><span>TOP</span> {{ title }}</h2>
        <div class="vendor-list-wrapper">
            <div class="vendor-list">
                <div v-if="users === null"><b-spinner class="mt-5" type="grow" label="Spinning"></b-spinner></div>
                <div v-else-if="users !== null && users.length === 0" class="mt-5">Nenašli sa žiadni TOP remeselníci.</div>
                <div v-else>
                    <b-row v-for="(user, index) in users" :key="user.id" class="vendor-list-item">
                        <b-col cols="12" md="1">
                            <span class="number">{{ index + 1 + '.' }}</span>
                        </b-col>
                        <b-col cols="12" md="11">
                            <Vendor :user="user" :index="index" />
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vendor from "@/components/home/single/Vendor";

export default {
    components: {
        Vendor
    },
    props: {
        topUsers: {
            default: function () {
                return []
            }
        },
        title: {
            default: function () {
                return 'poskytovatelia služieb'
            }
        },
    },
    data() {
        return {
            users: null
        }
    },
    watch: {
        topUsers() {
            this.users = this.topUsers
        }
    },
}
</script>

<style scoped lang="scss">
.component-vendor-list::v-deep {
    position: relative;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 6px 12px rgba(14, 43, 76, .08);
    overflow: hidden;
    z-index: 5;

    .title {
        position: absolute;
        top: 0;
        padding: 20px;
        padding-left: 60px;
        width: calc(100% - 20px);
        background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,.9));
        z-index: 2;

        span {
            color: $secondary;
            font-size: 28px;
            margin-right: 5px;
        }
    }

    .vendor-list-wrapper {
        padding: 73px 60px 10px;
        height: auto;
        overflow-y: auto;
    }

    .vendor-list {
        display: flex;
        flex-direction: column;

        .vendor-list-item {
            padding: 10px 0;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(191, 191, 191, .5);
            }
        }
    }

    .number {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        font-weight: 800;
    }

    @media (max-width: 768px) {

        margin-top: 20px;

        .title {
            position: relative;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center !important;
        }

        .vendor-list-wrapper {
            height: auto;
            padding: 0 30px 10px;
        }

        .vendor-list {
            flex-direction: row;
            align-items: flex-start;

            .vendor-list-item {
                //flex-wrap: nowrap;
                display: flex;
                flex-direction: column;
                //margin-right: 20px;
                min-width: 250px;

                &:not(:last-child) {
                    border-bottom: none;
                }
            }
        }

        .number {
            align-items: flex-start;
            border-bottom: 1px solid rgba(191, 191, 191, .5);
            margin-bottom: 10px;
        }
    }
}
</style>