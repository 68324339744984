<template>
	<div class="page-sign-in">
		<MainMenu />

		<div class="content-wrapper">
			<div class="container">
				<img class="img-doors" :src="doors" alt="svg">

				<b-row class="sign-form">
					<b-col cols="12" md="5" offset-md="6">
						<div class="sign-switcher">
							<div class="left">
								<a class="active" href="#">Prihlásiť sa</a>
							</div>
							<div class="right">
								<a @click="$router.push({ name: 'Sign_up'})">Registrovať</a>
							</div>
						</div>

						<b-form>
							<b-form-group label="Email" label-for="sing_in_email">
								<b-form-input id="sing_in_email" v-model="email" type="email"></b-form-input>
                                <div class="error-message-l" v-show="$v.email.$error">
                                    <div v-show="!$v.email.required || !$v.email.email">Prosím, zadajte Vašu platnú e-mailovú adresu.</div>
                                </div>
							</b-form-group>

							<b-form-group class="mt-4" label="Heslo" label-for="sing_in_password">
								<b-form-input id="sing_in_password" v-model="password" type="password"></b-form-input>
                                <div class="error-message-l" v-show="$v.password.$error">
                                    <div v-show="!$v.password.required">Prosím, zadajte Vaše heslo.</div>
                                </div>
								<div class="error-message-l" v-show="error">{{ error }}</div>
							</b-form-group>

							<b-form-group id="checkbox_remember_wrapper" v-slot="{ ariaDescribedby }">
								<b-form-checkbox-group
									id="checkbox_remember"
									:aria-describedby="ariaDescribedby"
								>
									<b-form-checkbox @change="rememberMe()">Zapamätať si ma</b-form-checkbox>
								</b-form-checkbox-group>
							</b-form-group>

							<b-button @click="signIn()" variant="primary" pill>
<!--                                <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>-->
                                Prihlásiť sa
                            </b-button>
                            <a class="change-pass" @click="$router.push({ name: 'RequestChangePassword' })">Zabudol som heslo</a>
						</b-form>

                        <SocialLogin />
                        <hr>
                        <RulesLink />
					</b-col>
				</b-row>
			</div>
		</div>

		<Footer />
	</div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import doors from "@/assets/doors.svg"
import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import RulesLink from "@/components/login/RulesLink";
import SocialLogin from "@/components/login/SocialLogin";
import { required, email } from 'vuelidate/lib/validators'

export default {
	components: {
        RulesLink,
		MainMenu,
        SocialLogin,
		Footer,
	},
    metaInfo() {
        return {
            title: 'Prihlásenie | ',
        }
    },
	data() {
		return {
			doors: doors,

			email: '',
			password: '',
            remember_me: false,
			error: '',
		}
	},
	beforeCreate() {
		if (this.$store.getters.user) {
			this.$router.push({ name: 'PageProfileAbout', params: { slug: this.$store.getters.user.email }})
		}
	},
    watch: {
        email: function () {
            this.$v.email.$touch()
            this.error = ''
        },
        password: function () {
            this.$v.password.$touch()
            this.error = ''
        },
        remember_me() {
            console.log(this.remember_me)
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.path) {
                console.log('qqq', vm)
                // vm.show = true;
            } else {
                console.log('wwww', vm)
                // vm.show = false;
            }
            next()
        });
    },
	methods: {
        rememberMe() {
            this.remember_me = !this.remember_me
        },
		signIn() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }
            this.error = ''
			axios.post(config.API_URL + '/auth/login', {
				email: this.email,
				password: this.password,
                remember_me: this.remember_me,
			}, config.HEADERS)
			.then((response) => {
                let loginUser = response.data;
				this.$store.dispatch('loginUser', loginUser)
                this.$nextTick(() => {
                    this.$store.dispatch('getCustomer', loginUser.slug)
                    this.$router.push({ name: 'Home' }, () => {})
                });
			}, (error) => {
				// console.warn(error.response.data.error);
                this.error = error.response.data.error;
			});
		},
	},
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        },
    },
}
</script>

<style scoped lang="scss">
.page-sign-in::v-deep {
	.content-wrapper {
		background: $bg-gray;

		.container {
			position: relative;
		}

		.img-doors {
			position: absolute;
			left: 50px;
			bottom: -10px;
		}

		.sign-form {
			padding-top: 100px;
			padding-bottom: 100px;
			margin-bottom: 70px;

			form {
				padding: 40px 0;

				label {
					text-align: left;
					padding-left: 20px;
					font-size: 14px;
					font-weight: 600;

				}

				input {
					color: $primary;
					border-radius: 30px;
					background: $input-bg;
					border: none;
					padding: 30px 20px;
					box-shadow: 0 12px 17px rgba(0,0,0,.2);

					&::placeholder {
						color: $primary;
					}
				}

				#checkbox_remember {
					text-align: left;
					padding-left: 20px;

					label {
						padding-left: 0;
						font-weight: 400;

						&:before {
							top: 2px;
							border-radius: 0.25em;
						}

						&:after {
							top: 2px;
						}
					}
				}

				.btn {
					display: flex;
                    margin: 0 auto;
					padding: 10px 70px;
				}

                .change-pass {
                    font-size: 14px !important;
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
			}
		}

		.sign-switcher {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding-bottom: 4px;
			border-bottom: 1px solid #E6E6E6;

			a {
				color: $secondary;
				padding-bottom: 3px;
				font-weight: 800;
				font-size: 20px;
				letter-spacing: .3px;
				text-decoration: none;
				opacity: .5;

				&:hover {
					opacity: 1;
                    cursor: pointer;
				}

				&.active {
					opacity: 1;
					border-bottom: 5px solid $secondary;
				}
			}
		}

		@media (max-width: 768px) {
			.img-doors {
				opacity: 0;
			}
		}
	}
}
</style>