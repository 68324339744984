<template>
    <b-row class="component-images-uploader">
        <b-col cols="12" class="image-gallery">
            <div v-for="image in old_images" :key="image.id" @click="deleteImage(image.id)">
                <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                <img :src="image.url" alt="image" />
            </div>

            <div v-for="(image, index) in images" :key="index" @click="deleteNewImage(index)">
                <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                <img :src="image" alt="image" />
            </div>
        </b-col>
        <b-col cols="12">
            <div v-show="images_length > 8">
                <span class="gallery-warn">Nemôžte pridať viac ako 8 obrázkov.</span>
            </div>
        </b-col>

        <b-col cols="12" lg="6" offset-lg="3">
            <b-form-group label="Galéria" label-for="blog_gallery_images">
                <b-form-file
                    id="blog_gallery_images"
                    @change="onImagesChange"
                    accept="images/jpeg, images/png"
                    browse-text="Nahrať súbor"
                    drop-placeholder="Nahrať súbor"
                    placeholder=""
                    multiple
                ></b-form-file>
            </b-form-group>
            <template slot="description">
                Podporované súbory: jpg, png v max. velkosti 5 MB
            </template>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "ImagesUploader",
    props: {
        old_images: {
            type: Array,
            default() {
                return []
            }
        },
        images: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            images_length: 0,
        }
    },
    watch: {
        old_images() {
            this.loadAllImagesLength()
        },
        images() {
            this.loadAllImagesLength()
        },
        new_images() {
            this.loadAllImagesLength()
        },
    },
    methods: {
        loadAllImagesLength() {
            if (!this.old_images) {
                this.images_length = this.old_images.length + this.images.length
            } else {
                this.images_length = this.images.length
            }
        },
        deleteImage(image_id) {
            this.$emit('delete-image', image_id)
        },
        deleteNewImage(index) {
            this.$emit('delete-new-image', index)
        },
        onImagesChange(images) {
            this.$emit('upload-images', images)
        },
    },
}
</script>

<style lang="scss" scoped>
.component-images-uploader::v-deep {
    .image-gallery {
        display: flex;
        justify-content: center;
        margin: 30px 0;

        @media (max-width: 992px) {
            flex-wrap: wrap;
        }

        div {
            margin: 7px 7px;
            position: relative;
            max-width: 40%;

            .image-trash {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0) scale(1.2);
                z-index: 1;
                opacity: 0;
            }

            &:hover {
                img {
                    filter: grayscale(100%);
                }

                .image-trash {
                    opacity: 1;
                    cursor: pointer;

                    svg {
                        filter: grayscale(0);
                        color: $secondary;
                    }
                }
            }

            img {
                width: 100%;
                height: 100%;
                max-height: 250px;
                object-fit: cover;
                max-width: 300px;
                border-radius: 10px;
                box-shadow: 0 10px 10px rgba(0, 0, 0, .2);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>