<template>
    <div class="page-blog-create">

        <CreateUpdateHead
            :title="'Napísať článok'"
            :link_text="'Späť na blog'"
            :type="'blog'"
            :page="'PageBlogMain'"
            :slug="'false'"
        />

        <div class="blog-create-container">
            <NotLoginUser v-if="$store.getters.user === ''" :text="'Ak chcete pridať článok, musíte sa najprv prihlásiť.'" />
            <div class="container">
                <b-row>
                    <b-col cols="12" md="10" offset-md="1">
                        <b-form class="create-blog-form">
                            <b-row no-gutters>
                                <b-col cols="12" lg="6" offset-lg="3">
                                    <b-form-group label="Názov článku*" label-for="blog_name" :class="{ 'input--error': $v.title.$error}">
                                        <b-form-input id="blog_name" v-model="title" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.title.$error">
                                            <div v-if="!$v.title.required">Prosím, vyplňte nadpis príspevku.</div>
                                            <div v-else-if="!$v.title.minLength">Nadpis musí mať aspoň {{ $v.title.$params.minLength.min }} znakov.</div>
                                            <div v-else-if="!$v.title.maxLength">Nadpis môže obsahovať maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                                        </div>
                                    </b-form-group>

                                    <CategoryTreeselect
                                        :options_select.sync="category"
                                        :options_data="categories"
                                        ref="category_treeselect_input"
                                    />

                                    <b-form-group label="Úvodný obrázok" label-for="blog_main_image">
                                            <b-form-file
                                                id="blog_main_image"
                                                class="with-append"
                                                accept="images/jpeg, images/png"
                                                placeholder=""
                                                v-model="main_picture"
                                                browse-text="Nahrať súbor"
                                                drop-placeholder="Nahrať súbor"
                                                @change="onMainImageChange"
                                            ></b-form-file>
                                    </b-form-group>
                                    <div v-if="main_picture_show" class="main-picture-show">
                                        <p>Úvodný obrázok:</p>
                                        <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                                        <img :src="main_picture_show" @click="deleteMainPicture()" alt="obrázok">
                                    </div>
                                </b-col>
                            </b-row>

                            <TextEditor
                                :long_text.sync="text"
                                ref="text_editor_component"
                            />

                            <UploadImages
                                class="mt-5 mb-5"
                                @changed="handleImages"
                                :max="images_limit"
                                :maxError="'Maximálny povolený počet obrázkov: ' + images_limit"
                                fileError="Podporované súbory: jpg, png v max. velkosti 5 MB"
                                :uploadMsg="'Maximálny povolený počet obrázkov: ' + images_limit"
                            />

                            <b-row class="send-blog-buttons">
                                <b-col v-if="error !== {}" cols="12">
                                    <div class="error-message-c">
                                        <div v-for="(err, index) in error" :key="index">{{ err }}</div>
                                    </div>
                                </b-col>
                                <b-col v-if="spinner_save" cols="12">
                                    <b-spinner type="grow" label="Spinning"></b-spinner>
                                </b-col>
                                <b-col v-if="!spinner_save" cols="12" lg="4" offset-lg="2" class="text-right">
                                    <b-button pill @click="postBlog('written')" variant="primary">
                                        <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                                        Uložiť článok
                                    </b-button>
                                </b-col>
                                <b-col v-if="!spinner_save" cols="12" lg="4" offset-lg="0" class="text-left">
                                    <b-button pill @click="postBlog('pending')" variant="secondary">
                                        <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                        Uložiť a poslať na kontrolu
                                    </b-button>
                                </b-col>
                            </b-row>

                            <div class="bottom-info">
                                <p><small>Článok Vám skontrolujeme a ak bude všetko v poriadku, príde Vám upozornenie o jeho publikovaní.</small></p>
                                <p><small>Tento proces trvá zvyčajne menej ako dva dni.</small></p>
                            </div>
                        </b-form>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import NotLoginUser from "@/components/NotLoginUser";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import UploadImages from "vue-upload-drop-images"
import CreateUpdateHead from "@/components/form_components/CreateUpdateHead";
import TextEditor from "../../components/form_components/TextEditor";
import CategoryTreeselect from "../../components/form_components/CategoryTreeselect";

export default {
    name: 'PageBlogCreate',
    components: {
        CategoryTreeselect,
        TextEditor,
        CreateUpdateHead,
        NotLoginUser,
        UploadImages,
    },
    metaInfo() {
        return {
            title: 'Vytvoriť blog | ',
        }
    },
    data() {
        return {
            title: '',
            categories: [],
            category: null,
            main_picture: null,
            main_picture_show: null,
            images_limit: 8,
            images: null,
            link: '',
            text: '',

            error: {},
            spinner_save: false,
        };
    },
    mounted() {
        this.getCategory()
    },
    methods: {
        onMainImageChange(event) {
            this.main_picture = event.target.files[0];
            this.main_picture_show = URL.createObjectURL(event.target.files[0]);
        },
        onImagesChange(event) {
            this.images = event.target.files;
        },
        handleImages(files) {
            this.images = files
            console.log(this.images)
        },
        loopCategory(response) {
            let categories = response
            let categoriesLength = Object.keys(categories).length
            let categoriesList = []

            for (let i = 0; i < categoriesLength; i++) {
                let newCategory = {}
                newCategory.label = categories[i].name
                newCategory.id = categories[i].id

                if (categories[i].children.length > 0) {
                    newCategory.children = this.loopCategory(categories[i].children)
                }
                categoriesList.push(newCategory)
            }
            return categoriesList
        },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=1', config.HEADERS)
                .then((response) => {
                    this.categories = this.loopCategory(response.data.success)
                    // this.category_spinner = true
                }, (error) => {
                    console.warn(error)
                });
        },
        postBlog(status) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }

            this.$refs.category_treeselect_input.$v.$touch()
            if (this.$refs.category_treeselect_input.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.category_treeselect_input.$scrollTo('.input--error')
                })
                return false
            }

            this.$refs.text_editor_component.$v.$touch()
            if (this.$refs.text_editor_component.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.text_editor_component.$scrollTo('.input--error')
                })
                return false
            }

            this.spinner_save = true
            this.error = {}

            const myData = new FormData();
            myData.set('title', this.title);
            myData.set('category', '[' + this.category + ']');
            myData.set('status', status);

            if (this.main_picture) myData.set('main_picture', this.main_picture);
            // myData.set('link', this.link);
            myData.set('text', this.text);

            // myData.set('token', this.$store.getters.user.access_token);

            if (this.images) {
                this.images.forEach((element, index) => {
                    myData.set('image' + '[' + index + ']', element)
                });
            }

            for (let pair of myData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            let blogConfig = config.HEADERS;
            blogConfig.HEADERS = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${this.$store.getters.user.access_token.token}`
                }
            };

            axios.post(config.API_URL + '/blog', myData, blogConfig)
                .then((response) => {
                    console.log('save blog', response.data.success.data.blog);
                    this.$router.push({ name: 'PageBlogDetail', params: { slug: response.data.success.data.blog.slug }})
                    this.spinner_save = false
                })
                .catch((error) => {
                    this.spinner_save = false
                    this.error = this.$errorMessage(error.response)
                });
        },
        changeBlogStatus(blog_id) {
            axios.post(config.API_URL + '/blog/mainStatus', {
                blog_id: blog_id,
                status: 'pending'
            }, config.HEADERS)
                .then((response) => {
                    console.log(response);
                    this.blog.status = response.data.success.status
                    this.$nextTick(() => {
                        this.$router.push({ name: 'PageBlogDetail', params: { slug: response.data.success.data.blog.id }})
                    })
                }, (error) => {
                    console.warn(error);
                });
        },
        deleteMainPicture() {
            this.main_picture = null
            this.main_picture_show = null
        },
    },
    validations: {
        title: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(160)
        },
    },
}
</script>

<style scoped lang="scss">
.page-blog-create::v-deep {
    .top-wrapper {
        .container {
            display: flex;
            flex-direction: row;

            .btn-link {
                box-shadow: none;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            .title {
                margin-bottom: 0;
                line-height: 46px;
                font-size: 20px !important;
                color: $secondary;
                display: block;

                @media (min-width: 768px) {
                    margin: 0 44% 0 auto;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        hr {
            border-color: $secondary;
        }
    }

    .blog-create-container {
        position: relative;

        .create-blog-form {

            //.space-between {
            //    @media (min-width: 992px) {
            //        > div {
            //            &:first-child {
            //                .form-group {
            //                    margin-right: 15px;
            //                }
            //            }
            //
            //            &:last-child {
            //                .form-group {
            //                    margin-left: 15px;
            //                }
            //            }
            //        }
            //    }
            //}

            //label {
            //    text-align: left;
            //    padding-left: 20px;
            //    font-size: 14px;
            //    font-weight: 600;
            //}

            .imgsPreview .imageHolder .plus {
                padding-top: 3px;
            }

            small {
                text-align: left;
                padding-left: 20px;
            }

            .main-picture-show {
                width: 80%;
                margin: 0 auto;

                .image-trash {
                    position: absolute;
                    z-index: 1;
                    opacity: 0;
                    left: 50%;
                    transform: translate(-50%, 0) scale(1.2);

                    svg {
                        color: $secondary;
                    }
                }

                img {
                    width: 100%;
                    border-radius: 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                    &:hover {
                        filter: grayscale(100%);
                        cursor: pointer;
                    }
                }

                &:hover {
                    .image-trash {
                        opacity: 1;
                    }
                }
            }

            .send-blog-buttons {
                margin-top: 50px;
                margin-bottom: 20px;

                button {
                    width: 100%;
                    margin-top: 10px;
                }
            }

            .bottom-info {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>