<template>
    <b-row class="component-form-company-data">
        <b-col cols="12" lg="10" offset-lg="1">
            <b-form-group label="Meno*" label-for="name" :class="{ 'input--error': $v.order_data.name.$error}">
                <b-form-input id="name" v-model.trim="order_data.name" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.name.$error">
                    <div v-if="!$v.order_data.name.required">Prosím zadajte Vaše meno.</div>
                </div>
            </b-form-group>

            <b-form-group label="Priezvisko*" label-for="lastname" :class="{ 'input--error': $v.order_data.lastname.$error}">
                <b-form-input id="lastname" v-model.trim="order_data.lastname" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.lastname.$error">
                    <div v-if="!$v.order_data.lastname.required">Prosím zadajte Vaše priezvisko.</div>
                </div>
            </b-form-group>

            <b-form-group label="Názov spoločnosti*" label-for="trade_name" :class="{ 'input--error': $v.order_data.trade_name.$error}">
                <b-form-input id="trade_name" v-model.trim="order_data.trade_name" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.trade_name.$error">
                    <div v-show="!$v.order_data.trade_name.required">Prosím, zadajte názov spoločnosti.</div>
                </div>
            </b-form-group>

            <b-form-group label="IČO*" label-for="ico" :class="{ 'input--error': $v.order_data.ico.$error}">
                <b-form-input id="ico" v-model.trim="order_data.ico" type="number"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.ico.$error">
                    <div v-show="!$v.order_data.ico.required || !$v.order_data.ico.decimal || !$v.order_data.ico.maxLength || !$v.order_data.ico.minLength">Prosím, zadajte platné IČO.</div>
                </div>
            </b-form-group>

            <b-form-group label="DIČ*" label-for="dic" :class="{ 'input--error': $v.order_data.dic.$error}">
                <b-form-input id="dic" v-model.trim="order_data.dic" type="number"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.dic.$error">
                    <div v-show="!$v.order_data.dic.required || !$v.order_data.dic.alphaNum || !$v.order_data.dic.maxLength || !$v.order_data.dic.minLength">Prosím, zadajte platné DIČ.</div>
                </div>
            </b-form-group>

            <b-form-group label="IČ DPH" label-for="ic_dph">
                <b-form-input id="ic_dph" v-model.trim="order_data.ic_dph" type="text"></b-form-input>
            </b-form-group>

            <b-form-group label="Fakturačná adresa – ulica*" label-for="billing_address_street" :class="{ 'input--error': $v.order_data.billing_address_street.$error}">
                <b-form-input id="billing_address_street" v-model.trim="order_data.billing_address_street" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.billing_address_street.$error">
                    <div v-show="!$v.order_data.billing_address_street.required">Prosím, zadajte ulicu.</div>
                    <div v-show="!$v.order_data.billing_address_street.maxLength">Povolených je maximálne {{$v.order_data.billing_address_street.$params.maxLength.max}} znakov.</div>
                </div>
            </b-form-group>

            <b-form-group label="Fakturačná adresa – mesto*" label-for="billing_address_town" :class="{ 'input--error': $v.order_data.billing_address_town.$error}">
                <b-form-input id="billing_address_town" v-model.trim="order_data.billing_address_town" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.billing_address_town.$error">
                    <div v-show="!$v.order_data.billing_address_town.required">Prosím, zadajte mesto.</div>
                    <div v-show="!$v.order_data.billing_address_town.maxLength">Povolených je maximálne {{$v.order_data.billing_address_town.$params.maxLength.max}} znakov.</div>
                </div>
            </b-form-group>

            <b-form-group label="Fakturačná adresa – PSČ*" label-for="billing_address_psc" :class="{ 'input--error': $v.order_data.billing_address_psc.$error}">
                <b-form-input id="billing_address_psc" v-model.trim="order_data.billing_address_psc" type="number"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.billing_address_psc.$error">
                    <div v-show="!$v.order_data.billing_address_psc.required || !$v.order_data.billing_address_psc.numeric  || !$v.order_data.billing_address_psc.maxLength || !$v.order_data.billing_address_psc.minLength">Prosím, zadajte platné PSČ.</div>
                </div>
            </b-form-group>

            <b-form-group label="Fakturačná adresa – Krajina*" label-for="billing_address_country" :class="{ 'input--error': $v.order_data.billing_address_country.$error}">
                <b-form-input id="billing_address_country" v-model.trim="order_data.billing_address_country" type="text"></b-form-input>
                <div class="error-message-l" v-show="$v.order_data.billing_address_country.$error">
                    <div v-show="!$v.order_data.billing_address_country.required">Prosím, zadajte krajinu.</div>
                    <div v-show="!$v.order_data.billing_address_country.maxLength">Povolených je maximálne {{$v.order_data.billing_address_country.$params.maxLength.max}} znakov.</div>
                </div>
            </b-form-group>

            <b-row class="sending-box">
                <b-col cols="12" class="confirmation">
                    <b-form-checkbox id="checkbox-consent-1" v-model="order_data.select_1" :class="{ 'input--error': $v.order_data.select_1.$error}">
                        Súhlasím so
                        <a @click="redirectInformation('casto-kladene-otazky')">
                            Spracovaním osobných údajov</a>
                        a
                        <a @click="redirectInformation('pravidla')">
                            Všeobecnými obchodnými podmienkami
                        </a>
                    </b-form-checkbox>
                </b-col>
                <b-col cols="12" class="error-message-l" v-if="$v.order_data.select_1.$error">
                    <p>Bez súhlasu nie je zakúpenie predplatného možné.</p>
                </b-col>
                <b-col cols="12">
                    <b-button @click="goNextStep()" variant="primary" pill>
                        <font-awesome-icon class="btn-icon mr-1" icon="share"></font-awesome-icon>
                        Prejsť na zhrnutie objednávky
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import { alphaNum, decimal, maxLength, minLength, numeric, required, sameAs } from "vuelidate/lib/validators";

export default {
    name: "FormCompanyData",
    props: {
        order_data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    methods: {
        goNextStep() {
            this.$emit('next-step')
        },
        redirectInformation(slug) {
            let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
            window.open(routeData.href, '_blank');
        },
    },
    validations: {
        order_data: {
            name: {
                required
            },
            lastname: {
                required
            },
            trade_name: {
                required
            },
            ico: {
                required,
                decimal,
                minLength: minLength(8),
                maxLength: maxLength(8),
            },
            dic: {
                required,
                alphaNum,
                maxLength: maxLength(10),
                minLength: minLength(10),
            },
            billing_address_street: {
                required,
                maxLength: maxLength(255),
            },
            billing_address_town: {
                required,
                maxLength: maxLength(255),
            },
            billing_address_psc: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
            billing_address_country: {
                required,
                maxLength: maxLength(255),
            },
            select_1: {
                required,
                sameAs: sameAs(() => true)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.component-form-company-data::v-deep {
    .sending-box {
        display: block;
        margin-top: 60px;
        text-align: left;
        color: $primary;

        .custom-checkbox {
            cursor: pointer;

            input, label {
                cursor: pointer;
            }
        }

        a {
            cursor: pointer;
            text-decoration: underline;
        }

        .btn {
            display: block;
            margin: 50px auto;
            width: 100%;

            @media (max-width: 991px) {
                margin: 40px auto;
            }
        }
    }
}
</style>