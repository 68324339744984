<template>
	<div id="app">
		<router-view/>
        <NotificationsRealTime />
        <CookiesAccept />
	</div>
</template>

<script>
import config from './config'
import ebrick_logo from "@/assets/ebrick_logo.svg";
import NotificationsRealTime from "@/components/NotificationsRealTime";
import CookiesAccept from "@/components/CookiesAccept";
import update from '@/pwa/update'

export default {
    name: 'App',
    components: {
        CookiesAccept,
        NotificationsRealTime,
    },
    mixins: [ update ],
    metaInfo() {
        return {
            titleTemplate: '%s eBrick',
            htmlAttrs: {
                lang: 'sk',
                amp: true
            },
            link: [
                { rel: 'canonical', href: this.current_page }
            ],
            meta: [
                { charset: 'utf-8' },
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_description },
                { vmid: 'robots', name: 'robots', content: 'noindex, nofollow' },
                { vmid: 'author', name: 'author', content: 'wame.sk' },

                { vmid: 'og:url', name:'og:url', content: config.BASE_URL },
                // { vmid: 'og:url', name:'og:url', content: config.BASE_URL + this.current_page },
                { vmid: 'og:title', name:'og:title', content: 'Stavebné dopyty pre firmy i remeselníkov' },
                { vmid: 'og:description', name: 'og:description', content: this.$store.getters.settings.meta_description },
                { vmid: 'og:image', name:'og:image', content: this.$store.getters.settings.meta_logo },
                { vmid: 'og:type', name:'og:type', content: 'website' },
            ],
        }
    },
    data() {
        return {
            current_page: '',
            asset_url: '',
            logo: ebrick_logo,
        }
    },
    watch: {
        '$route': {
            handler(value) {
                this.current_page = value.fullPath
            }, immediate: true
        }
    },
    beforeCreate() {
        if (this.$store.getters.user) {
            config.HEADERS.headers['Authorization'] = `Bearer ${this.$store.getters.user.access_token.token}`;
        }
        this.$store.dispatch('getEbrickSettings')
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        if (this.$store.getters.user.slug) this.$store.dispatch('initAllEchoChannels')
        this.$store.dispatch('getBannerAreas')
        this.refreshApp()
    }
}
</script>

<style lang="scss">
#app {
	font-family: Poppins, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow: hidden;
}
</style>
