<template>
    <div class="component-article-content">
        <img
            v-if="main_image"
            class="article-content-main-image"
            :src="asset_url + '/' + main_image"
            alt="obrazok"
        />

        <div class="article-content-text">
            <p class="content-text" v-html="main_text"></p>
        </div>

        <div v-if="images.length > 0" class="article-content-add-files">
            <h2 class="add-files-title">Priložené súbory</h2>
            <Gallery
                :images="images"
            />
        </div>
    </div>
</template>

<script>
import config from '@/config';
import Gallery from "@/components/detail_components/Gallery";

export default {
    name: "ArticleContent",
    components: {
        Gallery
    },
    props: {
        main_image: {
            type: String,
            default: null
        },
        main_text: {
            type: String,
            default: ''
        },
        images: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            asset_url: ''
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
}
</script>

<style lang="scss" scoped>
.component-article-content::v-deep {
    background: white;
    border-radius: 15px;
    padding: 20px 30px;
    box-shadow: 0 6px 12px #ccc;
    margin-bottom: 20px;

    .article-content-main-image {
        max-height: 400px;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;
        margin-bottom: 20px;
    }

    .article-content-text {
        text-align: justify;

        .content-text {
            ol {
                padding-left: 17px;
            }

            span {
                color: inherit;
                font-family: inherit;
                font-size: inherit;
                background-color: inherit;
            }
        }
    }

    .article-content-add-files {
        margin-top: 30px;

        .add-files-title {
            text-align: left;
            color: $secondary;
        }
    }
}
</style>