<template>
    <div class="page-email-verify">
        <MainMenu />

        <div v-if="spinner.check_verify" class="container">
            <h1>Prebieha overenie emailu...</h1>
            <b-spinner class="mt-5" type="grow" label="Spinning"></b-spinner>
        </div>
        <div v-else-if="spinner.confirm_verify" class="container">
            <h1>
                <font-awesome-icon icon="times-circle"></font-awesome-icon>
                Email nebol úspešne overený.
            </h1>
            <b-button v-if="$store.getters.user" @click="sendVerificationEmail()" class="mt-5" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="redo-alt"></font-awesome-icon>
                Znova požiadať o overenie.
            </b-button>
            <p v-if="spinner.email_send" class="mt-2">Email bol úspešne odoslaný, skontrolujte prosím svoju emailovú schránku.</p>
        </div>
        <div v-else class="container">
            <h1>
                <font-awesome-icon class="success" icon="check-circle"></font-awesome-icon>
                Email bol úspešne overený.
            </h1>
            <b-button @click="$router.push({ name: 'PageProfileAbout', params: { slug: $store.getters.user.slug }})" class="mt-5" variant="primary" pill>
                Prejsť na svoj profil
            </b-button>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";

export default {
    name: "EmailVerify",
    components: {
        MainMenu,
        Footer,
    },
    data() {
        return {
            spinner: {
                check_verify: true,
                confirm_verify: false,
                email_send: false,
            }
        }
    },
    mounted() {
        if (!this.$route.query.token_verify) this.$router.replace({ name: 'Home' })
        this.verifyEmail(this.$route.query.token_verify)
    },
    methods: {
        verifyEmail(token) {
            this.spinner.check_verify = true
            this.spinner.confirm_verify = false

            console.log('verifyEmail(token)', token)
            let postData = new FormData();
            postData.set('token_verify', token);

            axios.post(config.API_URL + '/auth/verifyAccount', postData, config.HEADERS)
                .then(() => {
                    if (this.$store.getters.user) {
                        this.$store.commit('setEmailVerify', true)
                    } else {
                        this.$router.push({ name: 'Sign_in' },() => {})
                    }
                     this.spinner.check_verify = false
                }, (error) => {
                    console.log(error)
                    this.spinner.check_verify = false
                    this.spinner.confirm_verify = true
                });
        },
        sendVerificationEmail() {
            axios.post(config.API_URL + '/auth/createVerifyEmail', {
                token: this.$store.getters.user.access_token.token
            }, config.HEADERS)
                .then(() => {
                    this.email_send = true
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-email-verify::v-deep {
    .container {
        min-height: 20vh;

        h1 {
            svg {
                margin-top: -5px;

                &.success {
                    color: $green;
                }

                &.danger {
                    color: $red;
                }
            }
        }
    }
}
</style>