<template>
    <div class="page-profile-edit">
        <MainMenu />

        <div class="top-wrapper">
            <div class="container">
                <b-button class="btn-back" @click="$router.push({ name: 'PageProfileAbout', params: { slug: $store.getters.user.slug }}, () => {})" variant="link">
                    <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                    Späť na profil
                </b-button>

                <h1 class="title">Úprava profilu</h1>
            </div>
            <hr>
        </div>

        <div class="content-wrapper">
            <div class="container">

                <b-row class="sign-form">
                    <b-col cols="12" md="8" offset-md="2">
                        <b-spinner v-if="spinner.load_data" class="mt-5" type="grow" label="Spinning"></b-spinner>
                        <b-form v-else>
                            <b-row>
                                <b-col cols="12" md="10" offset-md="1">

                                    <div class="profile-photo">
                                        <div class="profile-photo-div">
                                            <label for="customer_image">
                                                <img :src="imagePreview" alt="image">
                                                <span class="add-photo">+</span>
                                            </label>
                                            <input
                                                id="customer_image"
                                                type="file"
                                                accept="images/jpeg, images/png"
                                                placeholder=""
                                                @change="onMainImageChange"
                                            >
                                            <div v-if="main_picture || imagePreview !== default_image" @click="removeMainPicture()" class="delete_customer_image">
                                                Vymazať obrázok
                                            </div>
                                        </div>
                                    </div>

                                    <b-form-group description="Prosím zadajte meno, pod ktorým chcete vystupovať v systéme eBrick." label="Používateľské meno*" label-for="nick" :class="{ 'input--error': $v.user.nick.$error}">
                                        <b-form-input id="nick" v-model="user.nick" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.nick.$error">
                                            <div v-if="!$v.user.nick.required">Prosím, vyplňte Vaše používateľské meno.</div>
                                            <div v-else-if="!$v.user.nick.minLength">Meno musí mať aspoň {{ $v.user.nick.$params.minLength.min }} znakov.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Meno*" label-for="name" :class="{ 'input--error': $v.user.name.$error}">
                                        <b-form-input id="name" v-model="user.name" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.name.$error">
                                            <div v-if="!$v.user.name.required">Prosím, vyplňte Vaše meno.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Priezvisko*" label-for="lastname" :class="{ 'input--error': $v.user.lastname.$error}">
                                        <b-form-input id="lastname" v-model="user.lastname" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.lastname.$error">
                                            <div v-if="!$v.user.lastname.required">Prosím, vyplňte Vaše priezvisko.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Názov spoločnosti*" label-for="trade_name" :class="{ 'input--error': $v.user.trade_name.$error}">
                                        <b-form-input id="trade_name" v-model="user.trade_name" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.trade_name.$error">
                                            <div v-if="!$v.user.trade_name.required">Prosím, vyplňte názov Vašej firmy.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Telefón*" label-for="phone" :class="{ 'input--error': $v.user.phone.$error}">
                                        <font-awesome-icon class="input-custom-prepend" icon="phone"></font-awesome-icon>
                                        <b-form-input id="phone" v-model="user.phone" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.phone.$error">
                                            <div v-if="!$v.user.phone.required || !$v.user.phone.numeric || !$v.user.phone.minLength || !$v.user.phone.maxLength">Prosím, vyplňte Vaše telefónne číslo vo formáte 09xxxxxxxx.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Web" label-for="web" :class="{ 'input--error': $v.user.web_link.$error}">
                                        <font-awesome-icon class="input-custom-prepend" icon="globe"></font-awesome-icon>
                                        <b-form-input id="web" v-model="user.web_link" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.web_link.$error">
                                            <div v-if="!$v.user.web_link.required">Prosím, vyplňte Vaše používateľské meno.</div>
                                            <div v-else-if="!$v.user.web_link.maxLength">Adresa nemôže obsahovať viac ako {{$v.user.web_link.$params.maxLength.max}} znakov.</div>
                                        </div>
                                    </b-form-group>

                                    <div class="select-wrapper" :class="{ 'input--error': $v.user.place.$error}">
                                        <div class="multiselect-title">Miesto pôsobenia* + {{ user.range }} okolie </div>
                                        <font-awesome-icon class="input-custom-prepend" icon="map-marker-alt"></font-awesome-icon>
                                        <treeselect
                                            v-if="placeLoaded"
                                            id="address_form_input"
                                            :multiple="false"
                                            :async="true"
                                            v-model="user.place"
                                            :defaultOptions="options"
                                            :load-options="placeAutoComplete"
                                            placeholder="Mesto.."
                                            searchPromptText="Zadajte mesto..."
                                            loadingText="Načítavanie..."
                                            noResultsText="Žiadna zhoda"
                                            ref="address_input"
                                        />
                                        <div class="error-message-l" v-show="$v.user.place.$error">
                                            <div v-if="!$v.user.place.required">Prosím, vyplňte Vaše miesto pôsobenia.</div>
                                        </div>
                                    </div>
                                    <div v-if="user.range !== 0" class="range-slider-wrapper mt-5">
                                        <b-input-group prepend="10 km" append="50 km">
                                            <vue-range-slider
                                                ref="slider"
                                                v-model="user.range"
                                                :min="10"
                                                :max="50"
                                                :step="10"
                                                :dot-height="25"
                                                :dot-width="25"
                                                formatter='+{value}km'
                                            ></vue-range-slider>
                                        </b-input-group>
                                    </div>

                                    <div class="select-wrapper" :class="{ 'input--error': $v.user.professions.$error}">
                                        <div class="multiselect-title">Profesia</div>
                                        <font-awesome-icon class="input-custom-prepend profession-svg" icon="tools"></font-awesome-icon>
                                        <treeselect
                                            v-model="user.professions"
                                            :multiple="true"
                                            :options="professions"
                                            label="profession"
                                            @input="handleProfessionSelect"
                                            placeholder="Vyberte profesiu v ktorej podnikáte..."
                                        />
                                        <div class="error-message-l" v-show="$v.user.professions.$error">
                                            <div v-if="!$v.user.professions.required">Prosím, zvoľte si profesie v ktorých podnikáte.</div>
                                        </div>
<!--                                        <span class="description">Zvolte si vašu profesiu</span>-->
                                    </div>

                                    <b-form-group label="IČO*" label-for="ico" :class="{ 'input--error': $v.user.ico.$error}">
                                        <b-form-input id="ico" v-model="user.ico" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.ico.$error">
                                            <div v-if="!$v.user.ico.required || !$v.user.ico.minLength || !$v.user.ico.maxLength || !$v.user.ico.numeric">Prosím, zadajte platné IČO.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="DIČ*" label-for="dic" :class="{ 'input--error': $v.user.dic.$error}">
                                        <b-form-input id="dic" v-model="user.dic" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.dic.$error">
                                            <div v-if="!$v.user.dic.required || !$v.user.dic.minLength || !$v.user.dic.maxLength || !$v.user.ico.alphaNum">Prosím, zadajte platné DIČ.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="IČ DPH" label-for="ic_dph">
                                        <b-form-input id="ic_dph" v-model="user.ic_dph" type="text"></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Ulica a číslo domu*" label-for="address" :class="{ 'input--error': $v.user.address.$error}">
                                        <b-form-input id="address" v-model="user.address" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.address.$error">
                                            <div v-if="!$v.user.address.required">Prosím, zadajte ulicu a číslo domu.</div>
                                            <div v-if="!$v.user.address.maxLength">Maximálny povolený počet znakov je {{ $v.user.address.$params.maxLength.max }}.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Mesto*" label-for="city" :class="{ 'input--error': $v.user.city.$error}">
                                        <b-form-input id="city" v-model="user.city" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.city.$error">
                                            <div v-if="!$v.user.city.required">Prosím, zadajte mesto.</div>
                                            <div v-if="!$v.user.city.maxLength">Maximálny povolený počet znakov je {{ $v.user.city.$params.maxLength.max }}.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="PSČ*" label-for="psc" :class="{ 'input--error': $v.user.psc.$error}">
                                        <b-form-input id="psc" v-model="user.psc" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.psc.$error">
                                            <div v-if="!$v.user.psc.required || !$v.user.psc.minLength || !$v.user.psc.maxLength">Prosím, zadajte PSČ.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Krajina*" label-for="country" :class="{ 'input--error': $v.user.country.$error}">
                                        <b-form-input id="country" v-model="user.country" type="text"></b-form-input>
                                        <div class="error-message-l" v-show="$v.user.country.$error">
                                            <div v-if="!$v.user.country.required">Prosím, zadajte krajinu.</div>
                                            <div v-if="!$v.user.country.maxLength">Maximálny povolený počet znakov je {{ $v.user.country.$params.maxLength.max }}.</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group label="Názov e-shopu*" label-for="supplier_web">
                                        <font-awesome-icon class="input-custom-prepend" icon="globe"></font-awesome-icon>
                                        <b-form-input id="shop_link" v-model="user.shop_link_name" type="text"></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Adresa e-shopu s ponukou materiálu*" label-for="link_name">
                                        <font-awesome-icon class="input-custom-prepend" icon="globe"></font-awesome-icon>
                                        <b-form-input id="link_name" v-model="user.shop_link_link" type="text"></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Popis" label-for="link_text">
                                        <font-awesome-icon class="input-custom-prepend" icon="globe"></font-awesome-icon>
                                        <b-form-input id="link_text" v-model="user.shop_link_text" type="text"></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="O mne / O spoločnosti" label-for="about" :class="{ 'input--error': $v.user.about.$error}">
                                        <b-form-textarea
                                            id="about"
                                            placeholder="Mám rád eBrick..."
                                            v-model="user.about"
                                            size="lg"
                                        ></b-form-textarea>
                                        <div class="error-message-l" v-show="$v.user.about.$error">
                                            <div v-if="!$v.user.about.maxLength">Text je príliš dlhý, maximálny povolený počet znakov je {{ $v.user.about.$params.maxLength.max }}.</div>
                                        </div>
                                    </b-form-group>

                                </b-col>
                            </b-row>

                            <ImagesUploader
                                :old_images="old_images"
                                :images="images"
                                @delete-image="deleteImage($event)"
                                @delete-new-image="deleteNewImage($event)"
                                @upload-images="onImagesChange($event)"
                            />

                            <div class="error-message-c" v-for="(error, index) in errors" :key="index">{{ error }}</div>

                            <b-spinner v-if="spinner.save" type="grow" class="mt-3" label="Spinning"></b-spinner>
                            <b-button v-else @click="postUpdatedProfile()" class="btn-save mt-3" pill variant="secondary">
                                <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                                Uložiť zmeny
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import doors from "@/assets/doors.svg"

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import ebrick_logo from "@/assets/ebrick_logo.svg";

import VueRangeSlider from 'vue-range-component'
import 'vue-range-component/dist/vue-range-slider.css'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { required, minLength, maxLength, numeric, alphaNum } from "vuelidate/lib/validators";
import ImagesUploader from "@/components/ImagesUploader";

export default {
    components: {
        ImagesUploader,
        MainMenu,
        Footer,
        Treeselect,
        VueRangeSlider
    },
    metaInfo() {
        return {
            title: 'Upraviť profil | ',
        }
    },
    data() {
        return {
            doors: doors,
            logo: ebrick_logo,

            user: {
                range: 10,
                place: null,
            },

            main_picture: null,
            default_image: ebrick_logo,
            imagePreview: ebrick_logo,

            profession: null,
            professions: [],
            options: [],
            placeLoaded: false,

            new_images: [],
            images: [],
            old_images: [],
            images_length: null,

            supplier: {
                shop_link: '',
                link_name: '',
                link_text: '',
            },

            spinner: {
                load_data: false,
                post_data: false,
            },
            errors: '',
        }
    },
    watch: {
        old_images() {
            this.checkImagesLength()
        },
        images() {
            this.checkImagesLength()
        },
        new_images() {
            this.checkImagesLength()
        },
    },
    mounted() {
        if (!this.$store.getters.permissions('manage own customers')) {
            this.$router.push({ name: 'PageProfileAbout', params: { slug: this.$store.getters.user.slug }})
        }
        this.getProfessions();
        this.getCustomer();
    },
    methods: {
        checkImagesLength() {
            let length = 0
            if (this.old_images) length += this.old_images.length
            if (this.images) length += this.images.length
            this.images_length = length

        },
        onMainImageChange(event) {
            this.main_picture = event.target.files[0];

            let reader  = new FileReader();
            reader.addEventListener("load", function () {
                this.imagePreview = reader.result;
            }.bind(this), false);

            if (this.main_picture) {
                reader.readAsDataURL(this.main_picture);
            }
        },
        removeMainPicture() {
            this.imagePreview = this.default_image
            this.main_picture = null
        },
        handleProfessionSelect(e) {
            if (e.length > 5) {
                this.user.professions.pop()
            }
        },
        setImage(image, func) {
            const reader = new FileReader();
            reader.onload = () => {
                func(reader.result)
            }
            reader.readAsDataURL(image);
        },
        onImagesChange(event) {
            let files = event.target.files
            let self = this;
            files.forEach(img => {
                self.new_images.push(img)
                this.setImage(img, (data) => { self.images.push(data) })
            })
        },
        deleteImage(index) {
            this.old_images = this.old_images.filter(item => item.id !== index)
        },
        deleteNewImage(index) {
            this.images.splice(index, 1)
            this.new_images.splice(index, 1)
        },
        getCustomer() {
            this.spinner.load_data = true
            axios.get(config.API_URL + '/user/customer/get/' + this.$store.getters.user.slug, config.HEADERS)
                .then((response) => {
                    console.log('customer get', response.data.success)
                    let customer = response.data.success
                    console.log(customer)

                    if (customer.customer.image_path) this.imagePreview = config.ASSET_URL + '/' + customer.customer.image_path
                    let oldCustomer = {}
                    oldCustomer.nick = customer.name
                    oldCustomer.role = customer.role
                    oldCustomer.name = customer.customer.name
                    oldCustomer.lastname = customer.customer.lastname
                    oldCustomer.trade_name = customer.customer.trade_name
                    oldCustomer.phone = customer.customer.phone
                    oldCustomer.web_link = customer.customer.web

                    let oldProfessions = []
                    customer.professions.forEach(el => {
                        oldProfessions.push(el.id)
                    })
                    oldCustomer.professions = oldProfessions

                    customer.customer.range ? oldCustomer.range = Number(customer.customer.range) : oldCustomer.range = 10
                    oldCustomer.ico = customer.customer.ico
                    oldCustomer.dic = customer.customer.dic
                    oldCustomer.ic_dph = customer.customer.ic_dph
                    oldCustomer.address = customer.customer.address
                    oldCustomer.city = customer.customer.city
                    oldCustomer.psc = customer.customer.psc
                    oldCustomer.country = customer.customer.country
                    oldCustomer.about = customer.customer.about

                    oldCustomer.shop_link_link = ''
                    oldCustomer.shop_link_name = ''
                    oldCustomer.shop_link_text = ''
                    if (customer.shop_links && customer.shop_links.link) oldCustomer.shop_link_link = customer.shop_links.link
                    if (customer.shop_links && customer.shop_links.name) oldCustomer.shop_link_name = customer.shop_links.name
                    if (customer.shop_links && customer.shop_links.text) oldCustomer.shop_link_text = customer.shop_links.text


                    if (customer.customer.place_id !== null) {
                        let oldPlace = {}
                        oldPlace.id = customer.customer.place_id
                        oldPlace.label = customer.customer.place
                        oldPlace.children = null
                        this.options.push(oldPlace)
                        oldCustomer.place = oldPlace.id
                        this.$store.commit('setUserPlace', oldPlace.id)
                    }

                    console.log('oldcustomer', oldCustomer)
                    this.user = oldCustomer

                    if (response.data.success.images !== 1) this.old_images = response.data.success.images

                    // ked je hodnota null tak da ''
                    let userLength = Object.keys(this.user).length
                    let userKeys = Object.keys(this.user)
                    for (let i = 0; i < userLength; i++) {
                        if (!this.user[userKeys[i]]) {
                            this.user[userKeys[i]] = ''
                        }
                    }

                    this.placeLoaded = true

                    this.$nextTick(() => {
                        this.options = []
                        if (this.$route.params.address_input) {
                            this.$scrollTo('#address_form_input')
                            // this.$refs.address_form_input.focus()
                        }
                    })
                     this.spinner.load_data = false
                }, (error) => {
                    console.warn(error);
                     this.spinner.load_data = false
                });
        },
        getProfessions() {
            axios.get(config.API_URL + '/user/customer/getProfession', config.HEADERS)
                .then((response) => {
                    console.log('profession', response.data.success);
                    let profession = response.data.success;
                    let professionLength = Object.keys(profession).length;
                    let professionList = [];
                    for (let i = 0; i < professionLength; i++) {
                        let newProfesion = {}
                        newProfesion.id = profession[i].id
                        newProfesion.label = profession[i].name
                        professionList.push(newProfesion)
                    }
                    this.professions = professionList
                }, (error) => {
                    console.warn(error);
                });
        },
        placeAutoComplete({ searchQuery, callback  }) {
            axios.get(config.API_URL + '/placeAutoComplite'
                + '?input=' + searchQuery, config.HEADERS)
                .then((response) => {
                    console.log('response place autocompet', response.data.predictions)
                    let towns = response.data.predictions

                    let options = []
                    towns.forEach(el => {
                        let newTown = {}
                        newTown.id = el.place_id
                        newTown.label = el.description
                        options.push(newTown)
                    })
                    callback(null, options)
                }, (error) => {
                    console.warn(error)
                });
        },
        postUpdatedProfile() {
            console.log(this.user)
            if (this.$store.getters.permissions('manage own shop_links')) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$scrollTo('.input--error')
                    })
                    return false
                }
            } else {
                this.$v.user.$touch()
                if (this.$v.user.$invalid) {
                    this.$nextTick(() => {
                        this.$scrollTo('.input--error')
                    })
                    return false
                }
            }

            this.spinner.post_data = true
            let myData = new FormData();
            if (this.user.place) {
                myData.set('place_id', this.user.place);
                this.$store.commit('setUserPlace', this.user.place)
            }
            myData.set('nick', this.user.nick);
            myData.set('name', this.user.name);
            if (this.user.about) myData.set('about', this.user.about);
            if (this.user.web_link) myData.set('web', this.user.web_link);
            myData.set('lastname', this.user.lastname);
            myData.set('trade_name', this.user.trade_name);
            myData.set('phone', this.user.phone);
            myData.set('address', this.user.address);
            myData.set('city', this.user.city);
            myData.set('psc', this.user.psc);
            if (this.user.professions.length > 0) {
                this.user.professions.forEach((profession, index) => {
                    myData.set('profession[' + index + ']', profession);
                })
            } else {
                myData.set('profession[]', '[]');
            }

            myData.set('country', this.user.country);
            myData.set('ico', this.user.ico);
            if (this.user.ic_dph) myData.set('ic_dph', this.user.ic_dph);
            myData.set('dic', this.user.dic);
            if (this.main_picture === null) {
                if (this.imagePreview === this.default_image) {
                    myData.set('main_picture_null', 1);
                } else {
                    myData.set('main_picture_null', 0);
                }
            } else {
                myData.set('main_picture', this.main_picture);
            }
            if (this.new_images) {
                this.new_images.forEach((element, index) => {
                    myData.set('image' + '[' + index + ']', element)
                });
            }
            if (this.old_images.length > 0) {
                let oldImagesID = []
                this.old_images.forEach(element => {
                    oldImagesID.push(element.id)
                });
                myData.set('images', '[' + oldImagesID + ']')
            }
            myData.set('range', this.user.range);

            myData.set('shop_link', this.user.shop_link_link)
            myData.set('link_name', this.user.shop_link_name)
            if (this.user.shop_link_text.length > 0) myData.set('link_text', this.user.shop_link_text)

            for (let pair of myData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }

            axios.post(config.API_URL + '/user/customer/update', myData, config.HEADERS)
                .then((response) => {
                    console.log('update succ', response);
                    this.spinner.post_data = false
                    this.$store.commit('setUserName', response.data.success.name)
                    this.$store.commit('setUserSlug', response.data.success.slug)
                    this.$store.dispatch('getCustomer', response.data.success.slug)
                    this.$nextTick(() => {
                        this.$router.push({ name: 'PageProfileAbout', params: { slug: response.data.success.slug }}, () => {})

                    })
                }, (error) => {
                    console.warn(error);
                    this.errors = this.$errorMessage(error.response)
                    this.spinner.post_data = false
                });
        },
    },
    validations: {
        user: {
            nick: {
                required,
                minLength: minLength(6),
            },
            name: {
                // required,
            },
            lastname: {
                // required,
            },
            trade_name: {
                // required,
            },
            phone: {
                // required,
                numeric,
                maxLength: maxLength(10),
                minLength: minLength(10),
            },
            web_link: {
                maxLength: maxLength(255)
            },
            place: {
                // required,
            },
            professions: {
                // required,
            },
            ico: {
                // required,
                numeric,
                minLength: minLength(8),
                maxLength: maxLength(8),
            },
            dic: {
                // required,
                alphaNum,
                maxLength: maxLength(10),
                minLength: minLength(10),
            },
            address: {
                // required,
                maxLength: maxLength(255),
            },
            city: {
                // required,
                maxLength: maxLength(255),
            },
            psc: {
                // required,
                minLength: minLength(5),
                maxLength: maxLength(5),
            },
            country: {
                // required,
                maxLength: maxLength(255),
            },
            about: {
                maxLength: maxLength(1800),
            },
        },
    }
}
</script>

<style scoped lang="scss">
.page-profile-edit::v-deep {
    .top-wrapper {
        .container {
            display: flex;
            flex-direction: row;

            .btn-link {
                box-shadow: none;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            .title {
                margin-bottom: 0;
                line-height: 46px;
                font-size: 20px !important;
                color: $secondary;
                display: block;

                @media (min-width: 768px) {
                    margin: 0 44% 0 auto;
                }
            }

            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        hr {
            border-color: $secondary;
        }
    }

    .content-wrapper {

        .container {
            position: relative;
        }

        .img-doors {
            position: absolute;
            left: 50px;
            bottom: -10px;
        }

        .sign-form {
            margin-bottom: 70px;

            .profile-photo {
                display: flex;
                justify-content: center;

                .profile-photo-div {
                    position: relative;
                    margin-bottom: 50px;
                    height: 200px;
                    width: 200px;

                    label {
                        cursor: pointer !important;
                        border-radius: 50%;
                        padding-left: 0;
                    }

                    input {
                        display: none;
                        opacity: 1;
                    }

                    img {
                        margin: 0 auto;
                        width: 200px;
                        height: 200px;
                        object-fit: cover;

                        border-radius: 50%;
                        border: 2px solid $primary;

                        @media (max-width: 991px) {
                            margin-right: 0;
                        }
                    }

                    .add-photo {
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        top: 150px;
                        right: 10px;
                        padding-left: 9px;

                        color: white;
                        font-size: 21px;
                        border-radius: 50%;
                        background-color: $primary;
                        font-weight: bold;
                        cursor: pointer !important;
                    }

                    .delete_customer_image {
                        color: $small-color;
                        cursor: pointer;
                        font-size: 13px;
                    }
                }
            }

            form {
                padding: 40px 0;

                .text-muted {
                    margin-top: 5px;
                    margin-left: 20px;
                    padding: 0;
                }

                label {
                    text-align: left;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .form-group {
                    margin-bottom: 40px;

                    input {
                        color: $primary;
                        border-radius: 30px;
                        background: $input-bg;
                        border: none;
                        padding: 30px 20px;
                        box-shadow: 0 12px 17px rgba(0,0,0,.2);

                        &::placeholder {
                            color: $primary;
                        }
                    }

                    .b-form-file {
                        height: auto;
                        max-height: 61px;

                        label {
                            border-radius: 50px;
                            background-color: #dcdee6;
                            border: none;
                            padding: 20px 20px;
                            height: auto;
                            box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
                            white-space: nowrap;

                            .form-file-text {
                                min-height: 21px;
                            }

                            &:after {
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                height: 100%;
                                line-height: unset;
                                background: $primary;
                                color: white;
                                font-weight: 400;
                                padding: 10px 30px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .range-slider-wrapper {
                    position: relative;
                    margin-top: 40px;
                    margin-bottom: 40px;

                    .input-group {
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .vue-range-slider {
                        width: 100% !important;

                        .slider-process {
                            background: $primary;
                        }

                        .slider-dot {
                            border: 2px solid $primary;
                            border-radius: 50%;
                        }

                        .slider-tooltip {
                            color: $primary;
                            background: white;
                            border-color: $primary;
                            font-weight: 600;
                        }
                    }

                    .input-group-prepend, .input-group-append {
                        //width: 10%;

                        .input-group-text {
                            background: none;
                            color: $primary;
                            font-weight: 600;
                        }
                    }

                    //#range_slider {
                    //	position: absolute;
                    //	height: 30px;
                    //	width: 30px;
                    //	top: 50%;
                    //	left: 20px;
                    //	transform: translate(0, -60%);
                    //	z-index: 3;
                    //}
                }

                .form-group > div {
                    position: relative;
                }

                .location-svg {
                    top: 35% !important;
                }

                .input-custom-prepend {
                    position: absolute;
                    left: -25px;
                    top: 50%;
                    font-size: 20px;
                    transform: translate(-50%, -50%);
                }

                .input-group-text {
                    color: white;
                    background: $primary;
                    border: none;
                    border-radius: 0;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }

                .select-wrapper {
                    margin-bottom: 45px;
                    position: relative;

                    .multiselect-title {
                        float: left;
                        margin-left: 20px;
                        font-weight: bold;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }

                    svg.input-custom-prepend {
                        margin-top: 15px;
                    }

                    #multiselect_place {
                        .vue-treeselect__control-arrow-container {
                            display: none;
                        }
                    }

                    .vue-treeselect__control {
                        background-color: #DCDEE6;
                        padding: 15px;
                        border-radius: 30px;
                        outline: none;
                        border: 0;
                        box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
                    }

                    .vue-treeselect__input {
                        font-size: 16px !important;
                    }

                    .vue-treeselect__placeholder, .vue-treeselect__single-value {
                        line-height: inherit;
                        //display: flex;
                        //align-items: center;
                    }

                    .vue-treeselect__multi-value {
                        margin-bottom: 0;
                    }

                    .vue-treeselect__multi-value-item {
                        background-color: $primary;
                        border-radius: 10px;
                        color: white;
                        padding: 2px 0 2px 5px;

                        .vue-treeselect__icon {
                            svg {
                                position: relative;
                                top: 0;
                                right: 0;
                                transform: translate(0, 0);
                                color: white;

                                &:hover {
                                    color: $secondary;
                                }
                            }

                        }
                    }

                    .vue-treeselect__x-container {
                        svg  {
                            color: $primary;

                            &:hover {
                                color: $secondary;
                            }
                        }
                    }

                    .vue-treeselect__control-arrow-container {
                        svg {
                            color: $primary
                        }
                    }

                    span.description {
                        color: #6c757d !important;
                        float: left;
                        padding-left: 20px;
                        font-size: 13px;
                        margin-top: 4px;
                    }
                }

                .select2 .select2-container .select2-container--default {
                    width: 100%;
                }

                .select2-container {
                    width: 100%;

                    .selection {
                        width: 100%;

                        .select2-selection {
                            width: 100%;
                        }
                    }
                }

                .select2-selection {
                    color: $primary;
                    border-radius: 30px;
                    background: $input-bg;
                    border: none;
                    padding: 10px 15px;
                    min-height: 66px;
                    box-shadow: 0 12px 17px rgba(0,0,0,.2);

                    .select2-selection__choice {
                        position: relative;
                        background: $primary;
                        color: white;
                        border: none;
                        padding: 5px 30px 5px 10px;
                        border-radius: 20px;

                        span {
                            position: absolute;
                            background: white;
                            color: $primary;
                            top: 10px;
                            right: 5px;
                            border-radius: 50%;
                            height: 15px;
                            width: 15px;
                            line-height: 15px;
                            font-size: 15px;
                        }
                    }

                    .select2-search {
                        line-height: 35px;
                    }

                    input {
                        background: none;
                        box-shadow: none;
                        padding: 0;
                        border-radius: 0;
                    }
                }

                textarea {
                    position: relative;
                    font-size: 16px;
                    color: $primary;
                    border-radius: 30px;
                    background: $input-bg;
                    border: none;
                    padding: 30px 20px;
                    box-shadow: 0 12px 17px rgba(0,0,0,.2);
                    min-height: 200px;
                    max-height: 500px;

                    &::placeholder {
                        color: $primary;
                    }
                }

                small {
                    text-align: left;
                    padding-top: 10px;
                    padding-left: 20px;
                }

                #checkbox_vop {
                    text-align: left;
                    padding-left: 20px;

                    label {
                        padding-left: 0;
                        font-weight: 400;

                        &:before {
                            top: 2px;
                            border-radius: 0;
                        }

                        &:after {
                            top: 2px;
                        }

                        a {
                            text-decoration: underline;
                        }
                    }
                }

                .warning {
                    margin-top: 100px;

                    h2 {
                        color: $secondary;
                        font-size: 20px !important;
                    }

                    hr {
                        position: relative;
                        width: 200vw;
                        left: -100vw;
                        border-color: #9B413A;
                    }
                }

                .checkbox-warning {
                    margin: 50px 0;

                    > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px
                    };

                    @media (max-width: 443px) {
                        text-align: left;
                        padding-left: 20px;
                    }
                }

                .btn {
                    margin: 0 auto;
                    padding: 10px 50px;

                    svg {
                        margin-top: -3px;
                    }
                }

                .btn-save {
                    padding-top: 10px;
                    width: 100%;
                    max-width: 350px;
                }
            }

            .image-gallery {
                display: flex;
                justify-content: center;
                margin-bottom: 30px;

                @media (max-width: 992px) {
                    flex-wrap: wrap;
                }

                div {
                    margin: 7px 7px;
                    position: relative;
                    max-width: 40%;

                    .image-trash {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 0) scale(1.2);
                        z-index: 1;
                        opacity: 0;
                    }

                    &:hover {
                        img {
                            filter: grayscale(100%);
                        }

                        .image-trash {
                            opacity: 1;
                            cursor: pointer;

                            svg {
                                filter: grayscale(0);
                                color: $secondary;
                            }
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        max-height: 250px;
                        object-fit: cover;
                        max-width: 300px;
                        border-radius: 10px;
                        box-shadow: 0 10px 10px rgba(0, 0, 0, .2);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
            .gallery-warn {
                color: red;
                font-weight: bold;
            }
        }

        .sign-bottom-links {
            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                a {
                    margin-right: 10px;
                }
            }
        }


        @media (max-width: 768px) {
            .img-doors {
                opacity: 0;
            }
        }
    }
}
</style>