<template>
    <div class="component-demand-buttons-group container">
        <b-row class="row-buttons">
            <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
                <b-row v-if="$store.getters.user.slug">
                    <b-col class="mb-4 mb-md-0" cols="12" md="6">
                        <b-button
                            @click="goProfileDemands()"
                            :disabled="$store.getters.user.email_verified_at === null"
                            variant="outline-secondary"
                            pill
                        >
                            Moje dopyty
                        </b-button>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-button
                            @click="sortingReadable()"
                            v-scroll-to="'#demand-list'"
                            :disabled="$store.getters.user.email_verified_at === null"
                            variant="primary"
                            pill
                        >
                            Dopyty pre Vás
<!--                            <span>99+</span>-->
                        </b-button>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col cols="12" md="6" offset-md="3">
                        <b-button @click="$router.push({ name: 'NewDemand' }, () => {})" pill variant="primary">Zadať dopyt</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "DemandButtonsGroup",
    methods: {
        goProfileDemands() {
            this.$router.push({ name: 'PageProfileDemands', params: { slug: this.$store.getters.user.slug } }, () => {})
        },
        sortingReadable() {
            this.$emit('sorting-readable')
        },
    },
}
</script>

<style lang="scss" scoped>
.component-demand-buttons-group::v-deep {
    .row-buttons {
        margin-bottom: 30px;

        .btn {
            width: 100%;

            span {
                color: $primary;
                margin-left: 5px;
                padding: 3px 10px;
                font-weight: 600;
                background-color: white;
                border-radius: 20px;
            }
        }
    }
}
</style>