<template xmlns:div="http://www.w3.org/1999/html">
    <div class="page-search">
        <MainMenu />

        <div class="container search-menu">
            <b-form @submit.prevent="getSearchData()">
                <b-form-input id="search_all" ref="search_all" v-model="search_term" @input="changeSearchTerm" type="search" placeholder="Vyhľadať..."></b-form-input>
            </b-form>

            <div class="wrapper">
                <h1>
                    Výsledky vyhľadávania:
                    <span v-if="search_term">"{{ search_term }}"</span>
                </h1>

                <div class="sorting">
                    <button @click="setActive('all')" :class="{ active: $route.query.search === 'all' }">Všetko</button>
                    <span class="sorting-divide">|</span>
                    <button @click="setActive('supplier')" :class="{ active: $route.query.search === 'supplier' }">Stavebné firmy</button>
                    <span class="sorting-divide">|</span>
                    <button @click="setActive('demand')" :class="{ active: $route.query.search === 'demand' }">Dopyty</button>
                    <span class="sorting-divide">|</span>
                    <button @click="setActive('blog')" :class="{ active: $route.query.search === 'blog' }">Blog</button>
                    <span class="sorting-divide">|</span>
                    <button @click="setActive('forum')" :class="{ active: $route.query.search === 'forum' }">Fórum</button>
                </div>

                <div v-if="$route.query.search !== 'all'" class="sorting sorting-time">
                    <span class="order sorting-divide">Zoradiť podľa:</span>

                    <button v-if="$route.query.search !== 'demand'" @click="setFilter('top_rated')" :class="{active: $route.query.filter === 'top_rated'}">
                        Najviac hodnotené
                        <font-awesome-icon v-if="$route.query.filter === 'top_rated' && $route.query.sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                        <font-awesome-icon v-if="$route.query.filter === 'top_rated' && $route.query.sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                    </button>
                    <span v-if="$route.query.search !== 'demand'" class="sorting-divide">|</span>

                    <button v-if="$route.query.search === 'blog' || $route.query.search === 'forum'" @click="setFilter('newest')" :class="{active: $route.query.filter === 'newest'}">
                        Najnovšie
                        <font-awesome-icon v-if="$route.query.filter === 'newest' && $route.query.sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                        <font-awesome-icon v-if="$route.query.filter === 'newest' && $route.query.sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                    </button>
                    <span v-if="$route.query.search === 'blog' || $route.query.search === 'forum'" class="sorting-divide">|</span>

<!--                    <button v-if="$store.getters.user.user_id && $route.query.search === 'demand'" v-b-modal.modal-distance @click="setFilter('distance')" :class="{active: $route.query.filter === 'distance'}">-->
                    <button v-if="$store.getters.user.user_id && $route.query.search === 'demand'" @click="setFilter('distance')" :class="{active: $route.query.filter === 'distance'}">
                        Najbližšie
                        <font-awesome-icon v-if="$route.query.filter === 'distance' && $route.query.sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                        <font-awesome-icon v-if="$route.query.filter === 'distance' && $route.query.sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                    </button>
                    <span v-if="$store.getters.user.user_id && $route.query.search === 'demand'" class="sorting-divide">|</span>

                    <button v-if="$store.getters.user.user_id && $route.query.search === 'supplier'" @click="setFilter('demands_count')" :class="{active: $route.query.filter === 'demands_count'}">
                        Dokončené projekty
                        <font-awesome-icon v-if="$route.query.filter === 'demands_count' && $route.query.sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                        <font-awesome-icon v-if="$route.query.filter === 'demands_count' && $route.query.sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                    </button>
                    <span v-if="$store.getters.user.user_id && $route.query.search === 'supplier'" class="sorting-divide">|</span>

                    <button @click="setFilter('popular')" :class="{ active: $route.query.filter === 'popular' }">
                        Obľúbené
                        <font-awesome-icon v-if="$route.query.filter === 'popular' && $route.query.sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                        <font-awesome-icon v-if="$route.query.filter === 'popular' && $route.query.sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                    </button>
                </div>

                <div v-if="$route.query.search === 'blog' || $route.query.search === 'forum'" class="date-picker">
                    <div>Dátum publikovania príspevku od - do:</div>
                    <date-picker
                        v-model="range_date"
                        @input="handleRangeDate"
                        value-type="YYYY-MM-DD"
                        format="DD. MM. YYYY"
                        lang="sk"
                        type="date"
                        placeholder="Vyberte dátum"
                        range
                    ></date-picker>
                </div>
            </div>
        </div>

        <CategoryCollapse v-show="$route.query.search !== 'all'" :menu_id="menu_id" :category="category" @update-category="updateCategory" />

        <ModalUpdateCity />

        <div v-show="search_term !== '' && ($route.query.search === 'all' || $route.query.search === 'supplier')" class="suppliers-cards">
            <div class="container">
                <h2>
                    Dodávatelia služieb
                    <span>{{ supplier_total }} výsledkov</span>
                </h2>

                <div class="swiper-container">
                    <b-row class="swiper-wrapper" no-gutters>
                        <b-col class="swiper-slide" cols="auto" md="4" v-for="supplier in all_supplier" :key="supplier.id">
                            <SearchSupplierCard v-if="supplier" :supplier="supplier" />
                        </b-col>
                    </b-row>

                    <div v-show="supplier_total" class="swiper-button swiper-button-prev">
                        <font-awesome-icon class="absolute-left" icon="arrow-left"></font-awesome-icon>
                    </div>
                    <span v-show="supplier_total">{{ component_search_supplier.activeIndex + 1 }}</span>
                    <div v-show="supplier_total" class="swiper-button swiper-button-next">
                        <font-awesome-icon class="absolute-left" icon="arrow-right"></font-awesome-icon>
                    </div>
                </div>
                <b-spinner v-show="spinner.supplier && supplier_total === 0" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <div v-show="$store.getters.user.slug && $store.getters.permissions('view demands') && search_term !== '' && ($route.query.search === 'all' || $route.query.search === 'demand')" class="demand-cards">
            <div class="container">
                <h2>Dopyty<span>{{ demand_total }} výsledkov</span></h2>
                <div class="swiper-container">
                    <b-row class="swiper-wrapper" no-gutters>
                        <b-col class="swiper-slide" cols="auto" md="4" v-for="demand in all_demand" :key="demand.id">
                            <DemandCard v-if="demand" :demand="demand" />
                        </b-col>
                    </b-row>

                    <div v-show="demand_total" class="swiper-button swiper-button-prev">
                        <font-awesome-icon class="absolute-left" icon="arrow-left"></font-awesome-icon>
                    </div>
                    <span v-show="demand_total">{{ component_search_demand.activeIndex + 1 }}</span>
                    <div v-show="demand_total" class="swiper-button swiper-button-next">
                        <font-awesome-icon class="absolute-left" icon="arrow-right"></font-awesome-icon>
                    </div>
                </div>
                <b-spinner v-show="spinner.demand && demand_total === 0" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <div v-show="search_term !== '' && ($route.query.search === 'all' || $route.query.search === 'blog')" class="blog-cards">
            <div class="container">
                <h2>
                    Články od vás
                    <span>{{ blog_total }} výsledkov</span>
                </h2>
                <div class="swiper-container">
                    <b-row class="swiper-wrapper" no-gutters>
                        <b-col class="swiper-slide" cols="auto" md="4" v-for="blog in all_blog" :key="blog.id">
                            <Post v-if="blog" :blog="blog" />
                        </b-col>
                    </b-row>


                    <div v-show="blog_total" class="swiper-button swiper-button-prev">
                        <font-awesome-icon class="absolute-left" icon="arrow-left"></font-awesome-icon>
                    </div>
                    <span v-show="blog_total">{{ component_search_blog.activeIndex + 1 }}</span>
                    <div v-show="blog_total" class="swiper-button swiper-button-next">
                        <font-awesome-icon class="absolute-left" icon="arrow-right"></font-awesome-icon>
                    </div>
                </div>
                <b-spinner v-show="spinner.blog && blog_total === 0" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <div v-show="search_term !== '' && ($route.query.search === 'all' || $route.query.search === 'forum')" class="forum-cards">
            <div class="container">
                <h2>
                    Fórum
                    <span>{{ forum_total }} výsledkov</span>
                </h2>
                <div class="swiper-container">
                    <b-row class="swiper-wrapper" no-gutters>
                        <b-col class="swiper-slide" cols="auto" md="4" v-for="forum in all_forum" :key="forum.id">
                            <ForumPostLight v-if="forum" :forum="forum" />
                        </b-col>
                    </b-row>

                    <div v-show="forum_total" class="swiper-button swiper-button-prev">
                        <font-awesome-icon class="absolute-left" icon="arrow-left"></font-awesome-icon>
                    </div>
                    <span v-show="forum_total">{{ component_search_forum.activeIndex + 1 }}</span>
                    <div v-show="forum_total" class="swiper-button swiper-button-next">
                        <font-awesome-icon class="absolute-left" icon="arrow-right"></font-awesome-icon>
                    </div>
                </div>
                <b-spinner v-show="spinner.forum && forum_total === 0" type="grow" label="Spinning"></b-spinner>
            </div>
        </div>

        <BannerAdvertisingHorizontal :banner_slug="'banner-reklama-pozdlzny'" />

        <Footer />
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import axios from "axios";
import config from "@/config";
import _ from 'lodash';

import MainMenu from "@/components/MainMenu";
import Footer from '@/components/Footer';

import SearchSupplierCard from '@/components/search/single/SearchSupplierCard';
import DemandCard from '@/components/demands/single/DemandCard';
import Post from "@/components/blog/single/Post";
import ForumPostLight from '@/components/forum/single/ForumPostLight';
import CategoryCollapse from '@/components/CategoryCollapse';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModalUpdateCity from "@/components/single/ModalUpdateCity";
import BannerAdvertisingHorizontal from "@/components/BannerAdvertisingHorizontal";

export default {
        components: {
            BannerAdvertisingHorizontal,
            ModalUpdateCity,
            MainMenu,
            Post,
            ForumPostLight,
            DemandCard,
            SearchSupplierCard,
            CategoryCollapse,
            Footer,
            DatePicker,
        },
    metaInfo() {
        return {
            title: 'Vyhľadávanie | ',
        }
    },
    data() {
        return {
            perPage: 12,
            search_term: '',
            searchItems: [ 'all', 'supplier', 'demand', 'blog', 'forum'],
            filterItems: [ 'top_rated', 'newest', 'distance', 'popular', 'demands_count', 'created_at' ],
            sort: '',
            range_date: [],
            spinner: {
                supplier: false,
                demand: false,
                blog: false,
                forum: false,
            },

            blog_lazy_load: true,
            blogCurrentPage: 1,
            all_blog: [],
            blog_total: 0,

            forum_lazy_load: true,
            forumCurrentPage: 1,
            all_forum: [],
            forum_total: 0,

            demand_lazy_load: true,
            demandCurrentPage: 1,
            all_demand: [],
            demand_total: 0,

            supplier_lazy_load: true,
            supplierCurrentPage: 1,
            all_supplier: [],
            supplier_total: 0,

            category: [],
            menu_id: 1,

            config_swiper: {
                init: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                slidesPerView: 'auto',
                initialSlide: 1,
                spaceBetween: 15,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    992: {
                        centeredSlides: true,
                    }
                },
                // on: {
                //     // init: function() { console.log('init') },
                //     // destroy: function() { console.log('delete') },
                //     update: function() { console.log('update') }
                // }
            },
            component_search_blog: '',
            component_search_forum: '',
            component_search_demand: '',
            component_search_supplier: '',
        }
    },
    watch: {
        '$route.query': {
            handler: function (value) {
                console.log('QUERY', value)
                let check_query = this.checkQuery(value)
                console.log('check_query', check_query)
                if (check_query) {
                    // this.resetSwiper()
                    if (value.search_term !== undefined) this.$set(this, 'search_term', decodeURI(value.search_term));
                    // if (this.$refs.search_all !== undefined) this.$scrollTo('#search_all', 750)
                    if (value.search_term !== undefined) this.getSearchData()
                } else {
                    console.log('QUERY ERROR')
                    this.$router.replace({ 'query': null })
                    if (this.search_term.length > 0) {
                        this.$router.replace({
                            query: {
                                ...this.$route.query,
                                search: 'all',
                                search_term: this.search_term
                            }
                        });
                    } else {
                        this.$router.replace({ query: {
                            ...this.$route.query,
                            search: 'all',
                        }});
                    }
                }
            },
            immediate: true
        },
        search_term: function () {
            this.resetAll()
            this.resetSwiper()
            // console.log('watch search_term', this.search_term)
        },
    },
    created() {
            if (this.$route.query.search === 'demand') {
                this.$set(this, 'menu_id', 3);
            } else if (this.$route.query.search === 'blog') {
                this.$set(this, 'menu_id', 1);
            } else if (this.$route.query.search === 'forum') {
                this.$set(this, 'menu_id', 2);
            } else if (this.$route.query.search === 'supplier') {
                this.$set(this, 'menu_id', 4);
            }
    },
    mounted() {
            this.$set(this, 'component_search_blog', new Swiper('.page-search .blog-cards .swiper-container', this.config_swiper));
            this.$set(this, 'component_search_forum', new Swiper('.page-search .forum-cards .swiper-container', this.config_swiper));
            this.$set(this, 'component_search_demand', new Swiper('.page-search .demand-cards .swiper-container', this.config_swiper));
            this.$set(this, 'component_search_supplier', new Swiper('.page-search .suppliers-cards .swiper-container', this.config_swiper));

            let self = this;
            this.component_search_blog.on('slideChange', function() {
                if (
                    self.component_search_blog.activeIndex + 1 === self.all_blog.length ||
                    self.component_search_blog.activeIndex + 2 === self.all_blog.length ||
                    self.component_search_blog.activeIndex + 3 === self.all_blog.length
                ) {
                    if (
                        self.component_search_blog.activeIndex + 1 !== self.blog_total &&
                        self.component_search_blog.activeIndex + 2 !== self.blog_total &&
                        self.component_search_blog.activeIndex + 3 !== self.blog_total
                    ) {
                        if (self.blog_lazy_load) {
                            self.getBlogs();
                            self.blog_lazy_load = false;
                        }
                    }
                }
            });
            this.component_search_forum.on('slideChange', function() {
                if (
                    self.component_search_forum.activeIndex + 1 === self.all_forum.length ||
                    self.component_search_forum.activeIndex + 2 === self.all_forum.length ||
                    self.component_search_forum.activeIndex + 3 === self.all_forum.length
                ) {
                    if (
                        self.component_search_forum.activeIndex + 1 !== self.forum_total &&
                        self.component_search_forum.activeIndex + 2 !== self.forum_total &&
                        self.component_search_forum.activeIndex + 3 !== self.forum_total
                    ) {
                        if (self.forum_lazy_load) {
                            self.getForums();
                            self.forum_lazy_load = false;
                        }
                    }
                }
            });
            this.component_search_demand.on('slideChange', function() {
                if (
                    self.component_search_demand.activeIndex + 1 === self.all_demand.length ||
                    self.component_search_demand.activeIndex + 2 === self.all_demand.length ||
                    self.component_search_demand.activeIndex + 3 === self.all_demand.length
                ) {
                    if (
                        self.component_search_demand.activeIndex + 1 !== self.demand_total &&
                        self.component_search_demand.activeIndex + 2 !== self.demand_total &&
                        self.component_search_demand.activeIndex + 3 !== self.demand_total
                    ) {
                        if (self.demand_lazy_load) {
                            self.getDemands();
                            self.demand_lazy_load = false;
                        }
                    }
                }
            });
            this.component_search_supplier.on('slideChange', function() {

                if (
                    self.component_search_supplier.activeIndex + 1 === self.all_supplier.length ||
                    self.component_search_supplier.activeIndex + 2 === self.all_supplier.length ||
                    self.component_search_supplier.activeIndex + 3 === self.all_supplier.length
                ) {
                    if (
                        self.component_search_supplier.activeIndex + 1 !== self.supplier_total &&
                        self.component_search_supplier.activeIndex + 2 !== self.supplier_total &&
                        self.component_search_supplier.activeIndex + 3 !== self.supplier_total
                    ) {
                        if (self.supplier_lazy_load) {
                            self.getSuppliers();
                            self.supplier_lazy_load = false;
                        }
                    }
                }
            });

            // eslint-disable-next-line no-unused-vars
            // const component_search_blog = new Swiper('.page-search .blog-cards .swiper-container', config_swiper);
            // const component_search_suppliers = new Swiper('.page-search .suppliers-cards .swiper-container', config_swiper);
        },
        methods: {
            checkQueryKey(query) {
                let successQuery = true
                let allowedQuery = ['search', 'filter', 'sort', 'search_term']
                let queryKeys = Object.keys(query)

                for (let i = 0; i < queryKeys.length; i++) {
                    if (!allowedQuery.includes(queryKeys[i])) {
                        successQuery = false
                    }
                }
                return successQuery
            },
            checkQuery(query) {
                if (!this.checkQueryKey(query)) return false
                if (query.search_term && decodeURI(query.search_term).length === 0) return false

                if ('search' in query && this.searchItems.includes(String(query.search))) {
                    console.log('este tu je gut')
                    if ('filter' in query) {
                        return this.filterItems.includes(String(query.filter));
                    } else {
                        return !('sort' in query);
                    }
                } else {
                    return false
                }
            },
            changeSearchTerm: _.debounce(function(e) {
                this.resetAll()
                this.resetSwiper()
                if (e !== '') {
                    this.$router.push({ query: {
                        ...this.$route.query,
                        search_term: encodeURI(e),
                    }});
                } else {
                    if (this.$route.query.search_term) {
                        let query = Object.assign({}, this.$route.query);
                        delete query.search_term;
                        this.$router.replace({ 'query': query });
                    }
                }
            }, 500),
            setActive: function (menuItem) {
                let activeMenuItem
                switch (menuItem) {
                    case 'all':
                        activeMenuItem = ''
                        break;
                    case 'supplier':
                        activeMenuItem = 'top_rated'
                        this.menu_id = 4
                        break;
                    case 'demand':
                        activeMenuItem = 'popular'
                        this.menu_id = 3
                        break;
                    case 'blog':
                        activeMenuItem = 'newest'
                        this.menu_id = 1
                        break;
                    case 'forum':
                        activeMenuItem = 'newest'
                        this.menu_id = 2
                        break;
                }
                this.resetAll()
                this.resetSwiper()

                this.range_date.splice(0)
                let query = Object.assign({}, this.$route.query);
                query.search = menuItem
                if (activeMenuItem === '') {
                    delete query.filter
                    delete query.sort
                } else {
                    query.filter = activeMenuItem
                    query.sort = 'DESC'
                }
                this.$router.push({ 'query': query }, () => {});

                // this.$router.replace({ 'query': null })
                // this.$router.push({ query: {
                //     ...this.$route.query,
                //     search: menuItem,
                //     filter: activeMenuItem,
                //     sort: 'ASC'
                // }}, () => {});

                if (menuItem === 'all') this.resetSwiper()
                if (menuItem === 'blog') this.component_search_blog.slideTo(1, 100, true)
                if (menuItem === 'forum') this.component_search_forum.slideTo(1, 100, true)
                if (menuItem === 'demand') this.component_search_demand.slideTo(1, 100, true)
                if (menuItem === 'supplier') this.component_search_supplier.slideTo(1, 100, true)
            },
            setFilter(filterItem) {
                if (filterItem === 'distance' && this.$store.getters.user.access_token.place_id === null) {
                    this.$bvModal.show('modal-distance')
                    return false
                }

                let sortValue = this.$route.query.sort
                if (filterItem === this.$route.query.filter) {
                    sortValue === 'ASC' ? sortValue = 'DESC' : sortValue = 'ASC'
                } else {
                    sortValue = 'DESC'
                }
                this.resetAll()
                this.resetSwiper()

                let query = Object.assign({}, this.$route.query);
                query.filter = filterItem
                query.sort = sortValue
                this.$router.push({ 'query': query }, () => {});
            },
            handleRangeDate() {
                this.resetAll()
                this.resetSwiper()
                if (String(this.$route.query.search) === 'blog') this.getBlogs();
                if (String(this.$route.query.search) === 'forum') this.getForums();
            },

            resetAll() {
                this.blog_lazy_load = false;
                this.blogCurrentPage = 1;
                this.all_blog.splice(0);
                this.blog_total = 0;

                this.forum_lazy_load = false;
                this.forumCurrentPage = 1;
                this.all_forum.splice(0);
                this.forum_total = 0;

                this.demand_lazy_load = false;
                this.demandCurrentPage = 1;
                this.all_demand.splice(0);
                this.demand_total = 0;

                this.supplier_lazy_load = false;
                this.supplierCurrentPage = 1;
                this.all_supplier.splice(0);
                this.supplier_total = 0;
            },
            resetSwiper() {
                this.component_search_blog.slideTo(1, 100, true);
                this.component_search_forum.slideTo(1, 100, true);
                this.component_search_demand.slideTo(1, 100, true);
                this.component_search_supplier.slideTo(1, 100, true);
            },
            getSearchData() {
                if (String(this.$route.query.search) === 'all' || String(this.$route.query.search) === 'supplier') this.getSuppliers();
                if (String(this.$route.query.search) === 'all' || String(this.$route.query.search) === 'demand') this.getDemands();
                if (String(this.$route.query.search) === 'all' || String(this.$route.query.search) === 'blog') this.getBlogs();
                if (String(this.$route.query.search) === 'all' || String(this.$route.query.search) === 'forum') this.getForums();
            },
            setDateValues(date) {
                let date_from, date_to
                if (date.length !== 0) {
                    if (date[0] !== null || date[1] !== null) {
                        date_from = date[0]
                        date_to = date[1]
                    } else {
                        date_from = ''
                        date_to = ''
                    }
                } else {
                    date_from = ''
                    date_to = ''
                }

                return { date_from, date_to }
            },
            getBlogs() {
                const { date_from, date_to } = this.setDateValues(this.range_date)

                this.spinner.blog = true;
                let orderBy = this.$route.query.filter === undefined ? 'newest' : String(this.$route.query.filter)
                let ordering = this.$route.query.sort === undefined ? 'DESC' : String(this.$route.query.sort)
                axios.get(config.API_URL
                        + '/search/blog'
                        + '?page=' + this.blogCurrentPage
                        + '&perpage=' + this.perPage
                        + '&orderBy=' + orderBy
                        + '&ordering=' + ordering
                        + '&date_from=' + date_from
                        + '&date_to=' + date_to
                        + '&category_ids=' + '[' + this.category + ']'
                        + '&body=' + this.search_term, config.HEADERS)
                    .then((response) => {
                        console.log('search blog:', response.data.success);
                        this.blog_total = response.data.success.total;
                        response.data.success.data.forEach(blog => {
                            // let a = this.all_blog.find(x => x.id === blog.id)
                            this.all_blog.push(blog);
                        })
                        let self = this;
                        this.$nextTick(() => {
                            // if (self.blogCurrentPage === 1) {
                            //     self.component_search_blog.init();
                            // } else {
                            //     self.component_search_blog.update(true);
                            // }
                            self.component_search_blog.update(true);
                            self.blogCurrentPage += 1;
                            self.blog_lazy_load = true;
                            self.spinner.blog = false
                        });
                    }, (error) => {
                        console.warn(error);
                        // this.$errorMessage(error.response.data.error)
                        this.spinner.blog = false;
                    });
            },
            getForums() {
                this.spinner.forum = true;
                let orderBy = this.$route.query.filter === undefined ? 'newest' : String(this.$route.query.filter)
                let ordering = this.$route.query.sort === undefined ? 'DESC' : String(this.$route.query.sort)
                axios.get(config.API_URL
                        + '/search/forum?page=' + this.forumCurrentPage
                        + '&perpage=' + this.perPage
                        + '&orderBy=' + orderBy
                        + '&ordering=' + ordering
                        + '&category_ids=' + '[' + this.category + ']'
                        + '&body=' + this.search_term, config.HEADERS)
                    .then((response) => {
                        console.log('search forum:', response.data.success);
                        this.forum_total = response.data.success.total;
                        response.data.success.data.forEach(forum => {
                            this.all_forum.push(forum);
                        })
                        let self = this;
                        this.$nextTick(() => {
                            // if (self.forumCurrentPage === 1) {
                            //     self.component_search_forum.init();
                            // } else {
                            //     self.component_search_forum.update(true);
                            // }
                            self.component_search_forum.update(true);
                            self.forumCurrentPage += 1;
                            self.forum_lazy_load = true;
                            self.spinner.forum = false;
                        });
                    }, (error) => {
                        console.warn(error);
                        this.spinner.forum = false;
                    });
            },
            getDemands() {
                if (!this.$store.getters.user.slug || !this.$store.getters.permissions('view demands')) return false
                this.spinner.demand = true;
                let orderBy = this.$route.query.filter === undefined ? 'popular' : String(this.$route.query.filter)
                let ordering = this.$route.query.sort === undefined ? 'DESC' : String(this.$route.query.sort)
                axios.get(config.API_URL 
                        + '/search/demand?page=' + this.demandCurrentPage
                        + '&perpage=' + this.perPage
                        + '&orderBy=' + orderBy
                        + '&ordering=' + ordering
                        + '&category_ids=' + '[' + this.category + ']'
                        + '&body=' + this.search_term, config.HEADERS)
                    .then((response) => {
                        console.log('search demand:', response.data);
                        this.demand_total = response.data.success.total;
                        response.data.success.data.forEach(demand => {
                            this.all_demand.push(demand);
                        })
                        let self = this;
                        this.$nextTick(() => {
                            // if (self.demandCurrentPage == 1) {
                            //     self.component_search_demand.init();
                            // } else {
                            //     self.component_search_demand.update(true);
                            // }
                            self.component_search_demand.update(true);
                            self.demandCurrentPage += 1;
                            self.demand_lazy_load = true;
                            self.spinner.demand = false;
                        });
                    }, (error) => {
                        console.warn(error);
                        this.spinner.demand = false;
                    });
            },
            getSuppliers() {
                this.spinner.supplier = true;
                let orderBy = this.$route.query.filter === undefined ? 'top_rated' : String(this.$route.query.filter)
                let ordering = this.$route.query.sort === undefined ? 'DESC' : String(this.$route.query.sort)
                axios.get(config.API_URL
                        + '/search/ProviderAndSupplierUser'
                        + '?page=' + this.supplierCurrentPage
                        + '&perpage=' + this.perPage
                        + '&orderBy=' + orderBy
                        + '&ordering=' + ordering
                        + '&category_ids=' + '[' + this.category + ']'
                        + '&body=' + this.search_term, config.HEADERS)
                    .then((response) => {
                        console.log('search supplier:', response.data);
                        this.supplier_total = response.data.success.total;
                        response.data.success.data.forEach(supplier => {
                            this.all_supplier.push(supplier);
                        })
                        let self = this;
                        this.$nextTick(() => {
                            self.component_search_supplier.update(true);
                            self.supplierCurrentPage += 1;
                            self.supplier_lazy_load = true;
                            self.spinner.supplier = false;
                        });
                    }, (error) => {
                        console.warn(error);
                        this.spinner.supplier = false;
                    });
            },
            updateCategory(category) {
                this.category = category
                this.resetAll()
                this.resetSwiper()
                this.getSearchData()
            },
        }
    }
</script>

<style lang="scss" scoped>
.page-search::v-deep {

    .single-post {
        min-height: 370px;
        height: auto;

        .post-content {
            height: auto;
        }
        
        h3 {
            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .demand-card {
        height: 315px;

        & .demand-note {
            display: none;
        }

        .demand-bookmark {
            right: 0;
        }

        .demand-reaction {
            height: 100%;
        }

        .status-reaction {
            display: none;
        }

        .demand-reaction-status {
            margin-left: 20px;
            left: 0;
            transform: translate(0, 0);
        }

        h1.title {
            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .demand-text {
            .status {
                display: none;
            }
        }

        .demand-info {
            display: flex;
            flex-direction: column;
            margin-left: 35px;
            font-size: 14px !important;

            .offset-lg-1 {
                margin-left: 0;
            }

            > div {
                margin-bottom: 7px;

                &:not(:last-child):after {
                    visibility: hidden;
                }
            }
        }

        @media (max-width: 767px) {
            .demand-info {
                > div {
                    margin-left: 0;
                    font-size: 13px;
                }
            }
        }
    }

    .post-content {
        height: 190px;

        h3 {
            height: 52px;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .spinner-grow {
        position: absolute;
        margin-top: -50px;
        background-color: $secondary;
    }

    .search-menu {

        form {
            margin: 50px auto;
            width: 100%;
            max-width: 450px;

            input {
                color: $primary;
                border-radius: 30px;
                background: #DCDEE6;
                border: none;
                padding: 25px 20px;
                box-shadow: 0 7px 13px -3px rgba(0, 0, 0, .2);

                &::placeholder {
                    color: $primary;
                }
            }
        }

    }

    .wrapper {
        text-align: left;
        margin-bottom: 30px;

        h1 {
            text-align: left;
            font-size: 20px !important;
            color: $secondary;
            margin-bottom: 30px;

            span {
                font-size: 1.2em;
            }
        }

        .sorting {
            text-align: left;

            &:hover {
                cursor: default;
            }

            .order {
                margin-left: 5px;
                color: $primary;

                @media (max-width: 450px) {
                    display: none;
                }
            }

            button {
                outline: none;
                border: none;
                background: transparent;
                color: $primary;

                @media (max-width: 450px) {
                    font-size: 13px !important;
                    background: #eceef1;
                    border-radius: 5px;
                    margin: 0 2px;
                }
            }

            svg {
                color: $primary;
                transform: scale(0.9);

                @media (max-width: 450px) {
                    display: none;
                }
            }

            .active {
                font-weight: bold;
                color: $secondary;

                svg {
                    color: $secondary;
                }
            }

            @media (max-width: 450px) {
                display: flex;
                justify-content: center;

                .sorting-divide {
                    display: none;
                }
            }
        }

        .sorting-time {
            margin-top: 10px;

            button {
                color: $primary;

                @media (max-width: 450px) {
                    padding: 1px 4px;
                }
            }
        }

        .date-picker {
            display: flex;
            align-items: center;
            margin-top: 10px;
            margin-left: 5px;

            .mx-datepicker {
                @media (min-width: 450px) {
                    margin-left: 10px;
                }
            }

            @media (max-width: 450px) {
                flex-direction: column;
                align-items: center;
                font-size: 13px !important;
            }
        }
    }

    .swiper-container {
        overflow: visible;

        .swiper-wrapper {
            padding-bottom: 30px;

            &.row {
                flex-wrap: nowrap;
                margin: 0;
            }
        }
    }

    .swiper-button {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        background-color: $primary;
        color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;

        &::after {
            display: none;
        }

        svg {
            transform: scale(0.9);
        }
    }

    .swiper-button-next {
        left: 30px;

    }
    .swiper-button-prev {
        left: -30px;
    }

    h2 {
        padding-top: 40px;
        color: $secondary;
        font-size: 20px !important;
        text-align: left;
        margin-bottom: 30px;

        span {
            font-size: 0.75em;
            font-weight: normal;
            margin-left: 10px;
        }
    }

    .suppliers-cards, .blog-cards  {
        background-color: $bg-gray;
    }

    .suppliers-cards, .demand-cards, .blog-cards, .forum-cards  {
        padding-bottom: 30px;
    }

    .component-footer {
        margin-top: 50px;
    }
}
</style>