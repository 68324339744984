<template>
    <div class="component-social-login">
        <a :href="google_auth_link" target="_blank" class="google-auth mb-5 mt-3">
            <font-awesome-icon :icon="['fab', 'google']" class="btn-icon"/>
        </a>
        <a :href="facebook_auth_link" target="_blank" class="google-auth mb-5 mt-3">
            <font-awesome-icon :icon="['fab', 'facebook']" class="btn-icon"/>
        </a>
    </div>
</template>

<script>
import config from "@/config";

export default {
    name: "SocialLogin",
    data() {
        return {
            google_auth_link: '',
            facebook_auth_link: '',
        }
    },
    mounted() {
        this.$set(this, 'google_auth_link', config.API_URL + '/auth/socialAuth/google');
        this.$set(this, 'facebook_auth_link', config.API_URL + '/auth/socialAuth/facebook');
    }
}
</script>

<style lang="scss" scoped>
.component-social-login::v-deep {
    svg {
        margin: 0 10px;
        width: 20px;
        height: 20px;
    }
}
</style>