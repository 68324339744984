<template>
    <div class="component-ebrick-description-slider">
        <div class="swiper-container">
			<div class="swiper-wrapper">
				<div v-for="banner in active_banner" :key="banner.id" class="swiper-slide">
					<div class="about-ebrick-card">
                        <span class="top-rectangle"></span>
                        <span class="vendor-img-overlay"><img :src="vendor" alt="dodavatel"></span>
                        <div class="text">{{ banner.text }}</div>
                    </div>
				</div>
			</div>
			<div id="swiper-pagination" class="swiper-pagination"></div>
		</div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"
import vendor from "@/assets/vendor.svg"

export default {
    data() {
        return {
            vendor: vendor,
            active_banner: '',
        }
    },
    watch: {
        '$store.getters.banner_area': {
            handler() {
                if (this.$store.getters.banner_area) {
                    this.$store.getters.banner_area.forEach(el => {
                        if (el.slug === 'ebrick-description-banner') {
                            this.active_banner = el.banners
                        }
                    })
                }
            }, immediate: true
        }
    },
    mounted() {
        const breakpoint = window.matchMedia('(min-width:992px)');
        let mySwiper;

        const breakpointChecker = function() {
            if (breakpoint.matches) {
                if (mySwiper) {
                    mySwiper.destroy( true, true );
                    document.getElementById('swiper-pagination').style.visibility = 'hidden';
                }
            } else if (!breakpoint.matches) {
                document.getElementById('swiper-pagination').style.visibility = 'visible';
                return enableSwiper();
            }
        };

        // eslint-disable-next-line no-unused-vars
        const enableSwiper = function() {
            console.log('init swit')
            mySwiper = new Swiper ('.component-ebrick-description-slider .swiper-container', {
                loop: false,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                initialSlide: 1,
                spaceBetween: 10,
                //a11y: true,
                //keyboardControl: true,
                //grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });
        };
        breakpoint.addEventListener("change", () => {
            breakpointChecker();
        });
        breakpointChecker();
    },
}
</script>

<style lang="scss" scoped>
.component-ebrick-description-slider::v-deep {

    .swiper-container {
		overflow: visible;
        margin-top: -9px;

        .swiper-wrapper {
            width: 90%;
            margin-left: 0;

            .swiper-slide {
                width: 290px;
                height: auto;

                @media (max-width: 992px) {
                    width: 290px;
                }

                .about-ebrick-card {
                    box-shadow: 0 12px 17px rgba(14, 43, 76, 0.2);
                    border-radius: 0 0 20px 20px;
                    padding-bottom: 10px;
                    height: 100%;

                    .top-rectangle {
                        display: block;
                        width: 100%;
                        height: 9px;
                        background-color: $secondary;
                    }

                    .vendor-img-overlay {
                        background: $secondary;
                        position: relative;
                        display: flex;
                        width: 40px;
                        height: 40px;
                        margin-top: -25px;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 10px;
                        border-radius: 50%;
                        box-shadow: 0 6px 14px rgba(155, 65, 58, .2);
                        z-index: 2;

                        img {
                            transform: scale(0.8);
                        }
                    }

                    .text {
                        line-height: 1.6;
                        margin: 10px 25px 15px;
                        font-size: 15px;

                        &-second {
                            margin: 10px 30px 15px;
                        }
                    }
                }
            }


            @media (min-width: 992px) {
                margin: 0 auto;
                display: flex;
                justify-content: space-evenly;
            }
        }

        .swiper-pagination {
            bottom: -50px;

            @media (min-width: 992px) {
                display: none;
            }

            &-bullet {
                background: none;
                border: 1px solid $secondary;
                width: 12px;
                height: 12px;
                opacity: 1;

                &-active {
                    background: $secondary;
                }
            }
        }

        @media (max-width: 992px) {
            padding: 0;
        }
	}
}
</style>