<template>
    <div class="component-profile-user-orders">
        <b-row v-for="order in orders" :key="order.id" class="document-item" @click="openModal(order)" no-gutters>
            <b-col cols="12" lg="2">
                Číslo obj.
                <div class="value">{{ order.order_number }}</div>
            </b-col>
            <b-col cols="12" lg="3">
                Dát. vytvorenia
                <div class="value">{{ moment(order.created_at).format("LL") }}</div>
            </b-col>
            <b-col cols="12" lg="3">
                Dát. splatnosti
                <div class="value">{{ moment(order.created_at).format("LL") }}</div>
            </b-col>
            <b-col cols="12" lg="2">
                Suma
                <div class="value">{{ order.summ_with_tax }} €</div>
            </b-col>
            <b-col cols="12" lg="1">
                Stav
                <div class="value" :class="order.shipped === 1 ? 'paid' : 'unpaid'">
                    {{ order.shipped === 1 ? 'Uhradená' : 'Neuhradená' }}
                </div>
            </b-col>
            <b-col class="icon">
                <font-awesome-icon @click="openModal(order)" icon="info-circle"></font-awesome-icon>
            </b-col>
            <!--                                <b-col cols="12" md="1">-->
            <!--                                    &lt;!&ndash; Stiahnuť PDF &ndash;&gt;-->
            <!--                                    <a href="#" target="_blank" class="download"><img :src="pdf_icon" alt="pdf"></a>-->
            <!--                                </b-col>-->
        </b-row>
        <ModalOrderDetail :order="show_order" />

        <VuePagination
            :per_page="pagination.perPage"
            :current_page="pagination.currentPage"
            :records="pagination.records"
            :load_data="spinners.load_data"
            @handle-current-page="handlePageChange($event)"
        />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import ModalOrderDetail from "@/components/profil/ModalOrderDetail";
import VuePagination from "@/components/VuePagination";

export default {
    name: "ProfileUserOrders",
    components: {
        VuePagination,
        ModalOrderDetail,
    },
    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
                records: 0,
            },

            orders: null,
            show_order: '',
            spinners: {
                load_data: false,
            },
        }
    },
    mounted() {
        this.getUserOrders()
    },
    methods: {
        getUserOrders() {
            this.spinners.load_data = true
            axios.get(config.API_URL + '/orders/user'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage, config.HEADERS)
                .then((response) => {
                    console.log('get user orders', response.data.success)
                    // this.invoices = response.data.success.data
                    let data = response.data.success.data
                    let newOrders = []
                    data.forEach(item => {
                        const { id, order_number, created_at, summ_with_tax, shipped, order_product } = item
                        let order = {}
                        order.id = id
                        order.order_number = order_number
                        order.created_at = created_at
                        order.summ_with_tax = summ_with_tax
                        order.shipped = shipped
                        order.order_product = order_product
                        newOrders.push(order)
                    })
                    this.pagination.records = response.data.success.total
                    this.orders = newOrders
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        openModal(order) {
            this.show_order = order
            this.$bvModal.show('modal-order-detail')
        },
        handlePageChange(page) {
            this.pagination.currentPage = page
            this.getUserOrders();
            this.$scrollTo('.profile-container')
        },
    }
}
</script>

<style lang="scss" scoped>
.component-profile-user-orders::v-deep {
    margin-top: 50px;

    .document-item {
        position: relative;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;

        > div {
            @media (max-width: 767px) {
                display: flex;
                justify-content: space-between;
            }
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;

            background-image: linear-gradient(to right, $primary 0%, rgba(255,255,255,0) 30%);
            background-position: bottom;
            background-size: 8px 2px;
            background-repeat: repeat-x;
        }

        .value {
            font-weight: bold;
        }

        .paid {
            color: $green;
        }
        .unpaid {
            color: $red;
        }

        .download {
            text-align: right;
            width: 100%;
            display: block;

            img {
                height: 32px;
            }
        }

        .icon {
            text-align: right;

            svg {
                transform: scale(1.5);
                margin-right: 10px;
            }
        }
    }
}
</style>