import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/index';

import axios from "axios";
import config from "@/config";

import createPersistedState from "vuex-persistedstate";
import Echo from "laravel-echo";
// import moment from "moment";
// import Pusher from "pusher-js";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: '',
		customer: '',
		order: {},
		notifications: [],
		notification_count: 0,
		new_notification: '',
		chat_messages_count: 0,
		new_chat_message: '',

		banner_areas: '',
		banner_areas_timestamp: '',
		settings: '',
	},
	getters: {
		user: state => {
			return state.user
		},
		customer: state => {
			return state.customer
		},
		order: state => {
			return state.order
		},
		permissions: state => (permission) => {
			const allPermissions = state.user.access_token.permissions
			return allPermissions.includes(permission)
		},
		notifications: state => {
			return state.notifications
		},
		notification_count: state => {
			return state.notification_count
		},
		new_notification: state => {
			return state.new_notification
		},
		chat_messages_count: state => {
			return state.chat_messages_count
		},
		new_chat_message: state => {
			return state.new_chat_message
		},
		banner_area: state => {
			return state.banner_areas
		},
		banner_areas_timestamp: state => {
			return state.banner_areas_timestamp
		},
		settings: state => {
			return state.settings
		},
	},
	mutations: {
		setUser(state, data) {
			state.user = data;
		},
		setCustomer(state, data) {
			state.customer = data;
		},
		setUserNewsletter(state, data) {
			state.user.access_token.newsLetter = data;
		},
		setUserPlace(state, data) {
			state.user.access_token.place_id = data
		},
		setUserEmail(state, data) {
			state.user.email = data
		},
		setUserName(state, data) {
			state.user.name = data
		},
		setUserSlug(state, data) {
			state.user.slug = data
		},
		setEmailVerify(state, data) {
			state.user.email_verified_at = data
		},
		setOrderData(state, data) {
			state.order = data
		},
		clearLocalStorage(state) {
			state.user = ''
			state.customer = ''
			state.order = {}
			state.notifications = []
			state.notification_count = 0
			state.chat_messages_count = 0
		},
		setNotifications(state, data) {
			state.notifications = data
		},
		setNewNotification(state, data) {
			state.new_notification = data
		},
		setNotificationCount(state, data) {
			data ? state.notification_count += 1 : state.notification_count = 0
		},
		setChatMessagesCount(state, data) {
			data ? state.chat_messages_count += 1 : state.chat_messages_count = 0
		},
		setNewChatMessages(state, data) {
			state.new_chat_message = data
		},
		setBannerAreas(state, data) {
			state.banner_areas = data
		},
		setBannerAreasTimestamp(state, data) {
			state.banner_areas_timestamp = data
		},
		settings(state, data) {
			state.settings = data
		},
	},
	actions: {
		getEbrickSettings({ commit }) {
			axios.get(config.API_URL + '/mainInfo', config.HEADERS)
				.then((response) => {
					commit('settings', response.data.success)
				}, (error) => {
					console.warn(error);
				});
		},
		getBannerAreas({ state, commit }) {
			let timeDifference = 0
			timeDifference = (Date.now() - state.banner_areas_timestamp) / 3600000
			if (timeDifference > 1) {
				axios.get(config.API_URL + '/banners/getBanners', config.HEADERS)
					.then((response) => {
						commit('setBannerAreas', response.data.success)
						commit('setBannerAreasTimestamp', Date.now())
					}, (error) => {
						console.warn(error);
					});
			}
		},
		async loginUser({ commit, dispatch }, data) {
			let userData = data;
            config.HEADERS.headers['Authorization'] = `Bearer ${ userData.access_token.token }`;
            userData.access_token.permissions = await dispatch('restructurePermissions', userData.access_token.permissions)
			commit('setUser', userData)
			dispatch('getNewNotification', true)
			dispatch('initAllEchoChannels')
		},
		restructurePermissions(_, permissions) {
			return new Promise((resolve) => {
				let permissionsArray = [];
				permissions.forEach(permission => {
					permission.permissions.forEach(permission_name => {
						permissionsArray.push(permission_name.name);
					});
				});
				let uniqueSet = new Set(permissionsArray);
				resolve([...uniqueSet])
			})
		},
		logoutUser({ state, commit, dispatch }) {
			if (state.user.slug) dispatch('destroyAllEchoChannels')
			commit('clearLocalStorage')
			delete config.HEADERS.headers['Authorization'];
			router.push({ name: 'Sign_in' });
		},
		getCustomer({ commit }, user_slug) {
			return new Promise((resolve, reject) => {
				axios.get(config.API_URL + '/user/customer/get/' + user_slug, config.HEADERS)
					.then((response) => {
						console.log('vuex customer', response.data.success)
						if (response.data.success) {
							let customer = response.data.success
							commit('setCustomer', customer)
							resolve(true);
						} else {
							resolve(false)
						}
					}, (error) => {
						console.warn(error);
						reject(error);
					});
			})
		},
		getNewNotification({ state, commit, dispatch }, data) {
			if (!state.user.slug) return
			axios.get(config.API_URL + '/user/notifications', config.HEADERS)
				.then((response) => {
					commit('setNotifications', response.data.success.data)
					if (data) {
						commit('setNotificationCount', false)
						response.data.success.data.forEach(el => {
							if (el.saw === 0) commit('setNotificationCount', true)
						})
					}
				}, (error) => {
					if (Number(error.response.status) === 401) {
						dispatch('logoutUser')
					}
				});
		},
		initializationEcho({ state }) {
			return new Promise( resolve => {
				window.Pusher = require("pusher-js");
				window.Echo = new Echo({
					cluster: 'eu',
                    app_id: '1282707',
					encrypted: true,
					broadcaster: 'pusher',
					key: '6e875356e8e5a6b612ca',
                    secret: 'ac532415a25f60f01b5b',
					authEndpoint: config.API_URL + '/broadcasting/auth',
					auth: {
						headers: {
							Authorization: 'Bearer ' + state.user.access_token.token
						},
					},
				});
				resolve('EchoInitialized');
			})
		},
		echoChannelNotifications({ state, commit }) {
			window.Echo.private("notification." + state.user.user_id).listen(
				"NotificationEvent",
				(e) => {
					if (router.history.current.name !== 'Chat' && String(e.notification.notification_type.type) !== 'new_chat_message') {
						commit('setNotificationCount', true)
					}
					if (router.history.current.name !== 'Chat')	commit('setNewNotification', e.notification)
				}
			);
		},
		echoChannelChat({ state, commit }) {
			window.Echo.private("privatechat." + state.user.user_id).listen(
				"PrivateMessageSent",
				(e) => {
					router.history.current.name === 'Chat' ? commit('setChatMessagesCount', false) : commit('setChatMessagesCount', true)
					commit('setNewChatMessages', e)
				}
			);
		},
		async initAllEchoChannels({ dispatch }) {
			await dispatch('initializationEcho')
			dispatch('echoChannelNotifications')
			dispatch('echoChannelChat')
		},
		destroyAllEchoChannels({ state }) {
            window.Echo.leave('private-notification.' + state.user.user_id)
            window.Echo.leave('private-privatechat.' + state.user.user_id)
		},
	},
	modules: {
	},
	plugins: [createPersistedState()],
})
