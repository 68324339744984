<template>
    <div class="page-new-demand">
        <MainMenu />

        <CreateUpdateHead
            :title="'Zadanie dopytu'"
            :page="'Demands'"
            :slug="'false'"
            :link_text="'Späť na dopyty'"
        />

        <UnverifiedEmail v-if="$store.getters.user.email_verified_at === null" />
        <div v-else class="new-demand-body">
            <NotLoginUser v-if="!$store.getters.user" :text="'Ak chcete zadávať dopyty, musíte sa najprv prihlásiť.'" />
            <div class="container">
                <b-form>
                    <b-row no-gutters>
                        <b-col cols="12" md="6" offset-md="3">

                            <b-form-group label="Nadpis projektu*" label-for="title" description="Zadajte názov projektu." :class="{ 'input--error': $v.title.$error}">
                                <b-form-input id="title" v-model.trim="title" type="text"></b-form-input>
                                <div class="error-message-l" v-show="$v.title.$error">
                                    <div v-if="!$v.title.required">Prosím, vyplňte názov projektu.</div>
                                    <div v-else-if="!$v.title.minLength">Zadajte minimálne {{ $v.title.$params.minLength.min }} znakov.</div>
                                    <div v-else-if="!$v.title.maxLength">Zadajte maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                                </div>
                            </b-form-group>

                            <div class="select-wrapper">
                                <div class="multiselect-title" :class="{ 'input--error': $v.place.$error}">Miesto realizácie projektu*</div>
                                    <treeselect
                                        id="multiselect_place"
                                        :multiple="false"
                                        :async="true"
                                        :limit="1"
                                        v-model="place"
                                        :load-options="placeAutoComplete"
                                        placeholder="Mesto.."
                                        searchPromptText="Zadajte mesto..."
                                        loadingText="Načítavanie..."
                                        noResultsText="Žiadna zhoda"
                                    />
                                    <div class="error-message-l" v-show="$v.place.$error">
                                        <div v-if="!$v.place.required">Prosím, vyplňte miesto realizácie projektu.</div>
                                    </div>
                                <span class="description">Zadajte miesto realizácie projektu.</span>
                            </div>

                            <div class="select-wrapper">
                                <div class="multiselect-title" :class="{ 'input--error': $v.category.$error}">Kategória*</div>
                                <treeselect
                                    id="multiselect_category"
                                    v-model="category"
                                    :multiple="false"
                                    :options="categories"
                                    :show-count="true"
                                    :default-expand-level="1"
                                    placeholder="Vyberte kategóriu"
                                />
                                <div class="error-message-l" v-show="$v.category.$error">
                                    <div v-if="!$v.category.required">Prosím, zvoľte si kategóriu.</div>
                                </div>
                                <span class="description">Zadajte kategóriu projektu.</span>
                            </div>

                            <b-row v-show="parameters.length > 0" class="category-parameters">
                                <b-col cols="10" offset="1" class="parameter-info">
                                    <strong>Doplnkové parametre dopytu:</strong>
                                    <hr>
                                </b-col>
                                <b-col cols="12" v-for="param in parameters" :key="param.id">
                                    <b-row class="parameter">
                                        <b-col cols="4" class="label">
                                            {{ param.label }}
                                            <span v-if="param.required === 1">(povinný parameter)</span>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-form-input
                                                :id="'input-parameter-' + param.id"
                                                @input="setParameter($event, param.id)"
                                                type="text"
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12">
                                    <b-spinner v-if="spinner_parameter" type="grow" label="Spinning"></b-spinner>
                                </b-col>
                            </b-row>

                            <b-form-group
                                label="Realizovať do*"
                                label-for="date"
                                description="Termín dokončenia projektu."
                                :class="{ 'input--error': $v.expected_date.$error}"
                            >
                                <b-form-input id="date" v-model="expected_date" type="date"></b-form-input>
                                <div class="error-message-l" v-show="$v.expected_date.$error">
                                    <div v-if="!$v.expected_date.required">Prosím zadajte očakávaný dátum realizácie projektu.</div>
                                </div>
                                <div class="error-message-l" v-show="error['expected_date']">{{ error['expected_date'] }}</div>
                            </b-form-group>

                            <b-form-group label="Cena" label-for="price" description="Celková odhadovaná cena projektu v €." :class="{ 'input--error': $v.amount.$error}">
                                <b-form-input id="price" v-model="amount" type="number"></b-form-input>
                                <div class="error-message-l" v-show="$v.amount.$error">
                                    <div v-if="!$v.amount.decimal || !$v.amount.maxLength">Prosím zadajte iba číslice.</div>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="change-published-demand">
                        <b-col cols="12" class="change-published-demand__text-editor">
                            <TextEditor
                                :long_text.sync="project_description"
                                ref="text_editor_component"
                            />
                        </b-col>

                        <b-col cols="12" class="change-published-demand__image-gallery">
                            <p class="image-gallery-title">Galéria obrázkov</p>
                            <UploadImages
                                @changed="handleImages"
                                :max="images_limit"
                                :maxError="'Maximálny povolený počet obrázkov: ' + images_limit"
                                fileError="Podporované súbory: jpg, png v max. velkosti 5 MB"
                            />
                        </b-col>

                        <b-col cols="12" md="6" offset-md="3" class="change-published-demand__attachments">
                            <b-form-group
                                label="Prílohy"
                                label-for="input_attachments_files"
                                description='Maximálna povolená veľkosť súborov je 5MB vo formáte PDF.'
                            >
                                <b-input-group>
                                    <b-form-file
                                        id="input_attachments_files"
                                        class="with-append"
                                        placeholder=""
                                        drop-placeholder="Nahrať súbor"
                                        browse-text="Nahrať súbor"
                                        multiple
                                        accept=".pdf"
                                        @change="onFileChange"
                                    ></b-form-file>
                                </b-input-group>
                            </b-form-group>

                            <div class="attachment-list">
                                <div class="attachment-card" v-for="(file, index) in files" :key="index">
                                    <font-awesome-icon class="attachment-card__paperclip" icon="paperclip"></font-awesome-icon>
                                    <span class="attachment-card__name">{{ file.name }}</span>
                                    <font-awesome-icon class="attachment-card__delete" @click="deleteAttachment(index)" icon="trash-alt"></font-awesome-icon>
                                </div>
                                <div v-if="files.length > max_files" class="error-message">Maximálny počet príloh: <strong>{{ max_files }}</strong>. Povolené súbory: .pdf.</div>
                            </div>
                        </b-col>

                        <b-col cols="12" md="6" offset-md="3" class="change-published-demand__video-links">
                            <div v-for="(link, index) in video_links" :key="index">
                                <DemandVideoLink :link.sync="link.link" :index="index" :ref="'input_link_' + index" />
                                <font-awesome-icon v-if="index < 2" @click="addVideoLink()" class="add-button" icon="plus-circle"></font-awesome-icon>
                                <font-awesome-icon v-if="index > 0" @click="deleteVideoLink(index)" icon="minus-circle"></font-awesome-icon>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="send-demand-buttons">
                        <b-col v-if="error" class="error-message-c" cols="12">
                            <div v-for="(err, index) in error" :key="index">{{ err }}</div>
                        </b-col>
                        <b-col v-if="spinners.post_data" cols="12">
                            <b-spinner type="grow" label="Spinning"></b-spinner>
                        </b-col>
                        <b-col v-if="!spinners.post_data" cols="10" offset="1" lg="4" offset-lg="2">
                            <b-button @click="sendNewDemand(1)" pill variant="primary">
                                <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                                Uložiť dopyt
                            </b-button>
                        </b-col>
                        <b-col v-if="!spinners.post_data" cols="10" offset="1" lg="4" offset-lg="0">
                            <b-button v-b-modal.modal-to-active pill variant="secondary">
                                <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                Zverejniť dopyt
                            </b-button>
                        </b-col>
                    </b-row>

                    <ModalDemandInactiveToActive
                        @confirm-activ-demand="sendNewDemand(3)"
                        ref="modal_to_active"
                    />
                </b-form>
            </div>
        </div>

        <img class="geometric-1" :src="geometric_1" alt="svg">
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import geometric_1 from "@/assets/geometric_1.svg";

import MainMenu from "@/components/MainMenu";
import NotLoginUser from "@/components/NotLoginUser";
import DemandVideoLink from "@/components/demands/single/DemandVideoLink";
import Footer from "@/components/Footer";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ModalDemandInactiveToActive from "@/components/demands/modal/ModalDemandInactiveToActive";
import UnverifiedEmail from "@/components/UnverifiedEmail";
import UploadImages from "vue-upload-drop-images";
import TextEditor from "@/components/form_components/TextEditor";
import { required, minLength, maxLength, decimal } from 'vuelidate/lib/validators'
import CreateUpdateHead from "../../components/form_components/CreateUpdateHead";

    export default {
        components: {
            CreateUpdateHead,
            TextEditor,
            UnverifiedEmail,
            ModalDemandInactiveToActive,
            MainMenu,
            NotLoginUser,
            Footer,
            Treeselect,
            DemandVideoLink,
            UploadImages,
        },
        data() {
            return {
                geometric_1: geometric_1,

                title: '',
                place: null,
                categories: [],
                category: null,
                parameters: [],
                parameter: [],
                parameterID: [],
                spinner_parameter: false,
                expected_date: '',
                amount: '',

                project_description: '',
                images: null,
                images_limit: 6,
                files: [],
                max_files: 6,
                video_links: [
                    { link: '' },
                ],

                spinners: {
                    post_data: false,
                },
                error: {},
            }
        },
        watch: {
            category: function () {
                this.category ? this.getParameters() : this.parameters = []
            },
            files: function () {
                console.log(this.files)
            },
            parameter: function () {
                console.log('this.parameter', this.parameter)
            },
        },
        mounted() {
            this.getCategory();
        },
        methods: {
            handleImages(files) {
                this.images = files
                console.log('idem images')
            },
            addVideoLink() {
                this.video_links.push({ link: '' })
            },
            deleteVideoLink(index) {
                this.video_links.splice(index, 1)
            },
            loopCategory(response) {
                let categories = response
                let categoriesLength = Object.keys(categories).length
                let categoriesList = []

                for (let i = 0; i < categoriesLength; i++) {
                    let newCategory = {}
                    newCategory.label = categories[i].name
                    newCategory.id = categories[i].id

                    if (categories[i].children.length > 0) {
                        newCategory.children = this.loopCategory(categories[i].children)
                    }
                    categoriesList.push(newCategory)
                }
                return categoriesList
            },
            getCategory() {
                axios.get(config.API_URL + '/allCategory?menu_id=3', config.HEADERS)
                    .then((response) => {
                        this.categories = this.loopCategory(response.data.success)
                        this.category_spinner = true
                    }, (error) => {
                        console.warn(error)
                    });
            },
            getParameters() {
                if (this.category.length === 0) {
                    this.parameters = []
                    this.parameter = []
                } else {
                    this.spinner_parameter = true;
                    axios.get(config.API_URL + '/demand/getParamList'
                        + '?category_id=' + '[' + this.category + ']', config.HEADERS)
                        .then((response) => {
                            console.log('parametre', response.data.success)

                            let params = response.data.success
                            let paramsLength = Object.keys(params).length
                            let paramsID = Object.keys(params)
                            this.parameterID = paramsID
                            let parametersNew = []

                            for (let i = 0; i < paramsLength; i++) {
                                let newParam = {}
                                newParam.id = params[parseInt(paramsID[i])].id
                                newParam.label = params[parseInt(paramsID[i])].param
                                newParam.required = params[parseInt(paramsID[i])].required

                                parametersNew.push(newParam)
                            }

                            this.spinner_parameter = false;
                            this.parameters = parametersNew

                        }, (error) => {
                            console.warn(error)
                        });
                }
            },
            setParameter($event, id) {
                this.parameter['value_' + id] = $event
            },
            onFileChange(event) {
                let newFiles = event.target.files
                newFiles.forEach(file => {
                    this.files.push(file);
                })
            },
            deleteAttachment(index) {
                this.files.splice(index, 1)
            },
            placeAutoComplete({ searchQuery, callback  }) {
                axios.get(config.API_URL + '/placeAutoComplite'
                    + '?input=' + searchQuery, config.HEADERS)
                    .then((response) => {
                        // console.log(response.data.predictions)
                        let towns = response.data.predictions

                        let options = []
                        towns.forEach(el => {
                            let newTown = {}
                            newTown.id = el.place_id
                            newTown.label = el.description
                            options.push(newTown)
                        })
                        callback(null, options)
                    }, (error) => {
                        console.warn(error)
                    });
            },
            // checkValidation() {
            //     this.$v.$touch()
            //     if (this.$v.$invalid || video_link_error) {
            //         this.$nextTick(() => {
            //             this.$scrollTo('.input--error')
            //         })
            //         return false
            //     }
            // },
            sendNewDemand(status) {
                console.log('sendNewDemand', status)
                this.$refs.modal_to_active.hideConfirmModal()
                let video_link_error = false
                this.error = {}
                if (this.files.length > this.max_files) return false

                this.$v.$touch()
                if (this.$v.$invalid || video_link_error) {
                    this.$nextTick(() => {
                        this.$scrollTo('.input--error')
                    })
                    return false
                }

                this.video_links.forEach((el, index) => {
                    let ref = 'input_link_' + index
                    this.$refs[ref][0].$v.$touch()
                    if (this.$refs[ref][0].$v.$invalid) {
                        video_link_error = true
                    }
                })

                this.$refs.text_editor_component.$v.$touch()
                if (this.$refs.text_editor_component.$v.$invalid) {
                    this.$nextTick(() => {
                        this.$refs.text_editor_component.$scrollTo('.input--error')
                    })
                    return false
                }

                this.spinners.post_data = true

                const postData = new FormData();
                postData.set('title', this.title);
                postData.set('place_id', this.place);
                postData.set('state_id', status);
                postData.set('category', '[' + this.category + ']');
                postData.set('expected_date', this.expected_date);
                if(this.amount) postData.set('amount', this.amount);
                postData.set('project_description', this.project_description);

                let paramLength = Object.keys(this.parameter).length
                let paramKeys = Object.keys(this.parameter)
                this.parameterID.forEach(el => {
                    let newPar = 'value_' + el
                    for (let i = 0; i < paramLength; i++) {
                        if (newPar === paramKeys[i] && this.parameter[paramKeys[i]] !== '') {
                            postData.set(paramKeys[i], this.parameter[paramKeys[i]]);
                        }
                    }
                })

                // if (this.images) {
                //     this.images.forEach((el, index) => {
                //         postData.set('image[' + [index] + ']', el);
                //         postData.set('image[' + [index] + ']', el.name);
                //     })
                // }

                if (this.images) {
                    this.images.forEach((element, index) => {
                        postData.set('image' + '[' + index + ']', element)
                    });
                }

                if (this.files.length > 0) {
                    this.files.forEach((el, index) => {
                        postData.set('file[' + [index] + ']', el);
                        postData.set('attachment_name[' + [index] + ']', el.name);
                    })
                }

                this.video_links.forEach((el, index) => {
                    if (el.link.length > 0) postData.set('link[' + [index] + ']', el.link);
                })

                for (let pair of postData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]);
                }

                this.postNewDemand(postData)
            },
            postNewDemand(data) {
                let localConfig = config.HEADERS;
                localConfig.HEADERS = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "application/json",
                        "Authorization": `Bearer ${this.$store.getters.user.access_token.token}`
                    }
                };
                axios.post(config.API_URL + '/demand/store', data, localConfig)
                    .then((response) => {
                        console.log('postNewDemand', response.data.success.demand);
                        this.$nextTick(() => {
                            this.$router.push({ name: 'DemandDetail', params: { slug: response.data.success.demand.slug }})
                        })
                        this.spinners.post_data = false
                    }, (error) => {
                        this.spinners.post_data = false
                        this.error = this.$errorMessage(error.response)
                    });
            }
        },
        validations: {
            title: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(255)
            },
            place: {
                required,
            },
            category: {
                required,
            },
            amount: {
                decimal,
                maxLength: maxLength(255)
            },
            expected_date: {
                required,
            },
        },
}
</script>

<style lang="scss" scoped>
.page-new-demand::v-deep {
    margin: 0 auto;

    .new-demand-head {
        display: flex;
        flex-direction: row;

        .btn-link {
            box-shadow: none;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }

        &__title {
            margin-bottom: 0;
            line-height: 46px;
            font-size: 20px !important;
            color: $secondary;
            display: block;

            @media (min-width: 768px) {
                margin: 0 44% 0 auto;
            }
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
	}

    .new-demand-body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .select-wrapper {
            margin-bottom: 45px;

            .multiselect-title {
                float: left;
                margin-left: 20px;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 8px;
            }

            #multiselect_place {
                .vue-treeselect__control-arrow-container {
                    display: none;
                }
            }

            .vue-treeselect__control {
                background-color: #DCDEE6;
                padding: 15px;
                border-radius: 30px;
                outline: none;
                border: 0;
                box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
            }

            .vue-treeselect__input {
                font-size: 16px !important;
            }

            .vue-treeselect__placeholder, .vue-treeselect__single-value {
                line-height: inherit;
                //display: flex;
                //align-items: center;
            }

            .vue-treeselect__multi-value {
                margin-bottom: 0px;
            }

            .vue-treeselect__multi-value-item {
                background-color: $primary;
                border-radius: 10px;
                color: white;
                padding: 2px 0px 2px 5px;

                .vue-treeselect__icon {
                    svg {
                        position: relative;
                        top: 0;
                        right: 0;
                        transform: translate(0, 0);
                        color: white;

                        &:hover {
                            color: $secondary;
                        }
                    }

                }
            }

            .vue-treeselect__x-container {
                svg  {
                    color: $primary;

                    &:hover {
                        color: $secondary;
                    }
                }
            }

            .vue-treeselect__control-arrow-container {
                svg {
                    color: $primary
                }
            }

            span.description {
                color: #6c757d !important;
                float: left;
                padding-left: 20px;
                font-size: 13px;
                margin-top: 4px;
            }
        }

        .category-parameters {
            margin-bottom: 20px;

            .parameter-info {
                text-align: left;
                strong {
                    font-size: 14px;
                }

                hr {
                    margin-top: 0;
                }
            }

            .parameter {
                margin-bottom: 10px;

                .label {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    span {
                        position: absolute;
                        display: block;
                        color: red;
                        font-size: 12px;
                        margin-top: 31px;
                    }
                }

                input {
                    border-radius: 15px;
                }
            }
        }

        form {
            margin-top: 40px;
            color: $primary;

            .form-group {
                margin-bottom: 30px;
                text-align: left;

                label {
                    text-align: left;
                    padding-left: 20px;
                    font-size: 14px;
                    font-weight: 600;
                }

                input {
                    color: $primary;
                    border-radius: 30px;
                    background: $input-bg;
                    border: none;
                    padding: 30px 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                    &::placeholder {
                        color: $primary;
                    }

                    &.with-append {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                small {
                    text-align: left;
                    padding-left: 20px;
                }

                textarea {
                    position: relative;
                    font-size: 14px;
                    border-radius: 20px;
                    background: $input-bg;
                    border: none;
                    padding: 15px 15px;
                    box-shadow: 0 7px 20px rgba(0,0,0,.2);
                    min-height: 200px;
                    max-height: 500px;

                    &::placeholder {
                        color: $primary;
                    }

                    &:focus {
                        &::placeholder {
                            opacity: 0;
                        }
                    }
                }

                .b-form-file {
                    height: auto;

                    label {
                        border-radius: 50px;
                        background-color: #dcdee6;
                        border: none;
                        padding: 20px 20px;
                        height: auto;
                        box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                        .form-file-text {
                            min-height: 21px;
                            white-space: nowrap;
                        }

                        &:after {
                            display: flex;
                            align-items: center;
                            flex-direction: row;
                            height: 100%;
                            line-height: unset;
                            background: $primary;
                            color: white;
                            font-weight: 400;
                            padding: 10px 30px;
                        }
                    }
                }
            }
        }

        .change-published-demand {
            &____text-editor {
                //
            }

            &__image-gallery {
                position: relative;
                height: 100%;
                margin: 50px 0;

                .image-gallery-title {
                    font-weight: bold;
                    margin-bottom: 0;
                }

                div.plus {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__attachments {
                .attachment-list {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-bottom: 30px;
                    position: relative;

                    .attachment-card {
                        display: flex;

                        &__paperclip {
                            margin-right: 5px;
                            margin-top: 2px;
                            float: left;
                        }

                        &__name {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                        }

                        &__delete {
                            margin-top: 2px;
                            float: right;
                            cursor: pointer;
                        }
                    }
                }
            }

            &__video-links {
                > :nth-child(2) {
                    .add-button {
                        margin-right: 10px;
                    }
                }
                .form-group {
                    margin-bottom: 0;
                }

                svg {
                    cursor: pointer;
                }
            }
        }

        .send-demand-buttons {
            margin-top: 50px;

            button {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    .geometric-1 {
        position: absolute;
        top: 400px;
        left: 20px;
        transform: scaleX(-1);
        width: 760px;
        max-width: 90vw;
        z-index: -1;
        opacity: 0.5;
    }
}
</style>