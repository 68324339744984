var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-vue-pagination mt-5"},[(_vm.load_data)?_c('b-spinner',{attrs:{"type":"grow","label":"Spinning"}}):_c('pagination',{attrs:{"per-page":_vm.per_page,"records":_vm.records,"options":{
            theme: 'bootstrap4',
            edgeNavigation: false,
            texts: {
                count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                first: 'Prvá',
                last: 'Posledná'
            }
        }},on:{"paginate":_vm.handlePageChange},model:{value:(_vm.current_page_copy),callback:function ($$v) {_vm.current_page_copy=$$v},expression:"current_page_copy"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }