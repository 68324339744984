<template>
    <div class="page-forum">
        <MainMenu />
        <div class="container">
            <b-row class="row-buttons">
                <b-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
                    <b-row v-if="$store.getters.user.slug">
                        <b-col class="mb-4 mb-md-0" cols="12" md="6">
                            <b-button
                                @click="$router.push({ name: 'PageProfileForum', params: { slug: $store.getters.user.slug } }, () => {})"
                                variant="outline-secondary"
                                pill
                            >
                                Moje otázky
                            </b-button>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-button @click="$router.push({ name: 'ForumNew' }, () => {})" variant="primary" pill>Pridať otázku</b-button>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col cols="12" md="6" offset-md="3">
                            <b-button @click="$router.push({ name: 'ForumNew' }, () => {})" variant="primary" pill>Pridať otázku</b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>

        <BenefitsSlider :banner_slug="'benefits-slider'" />

        <SearchPost :search_type="'forum'"/>

        <div id="category-select" class="choose-category">
            <div class="container">
                <h2 class="title text-left">Vyberte si kategóriu</h2>
            </div>

            <CategoryCollapse :menu_id="2" :category="category" @update-category="updateCategory" />
        </div>

        <div id="forum-main" class="main-content">
            <div class="container">
                <b-row>
                    <b-col class="left" cols="12" lg="8">
                        <div class="top-wrapper">
                            <h2 class="title text-left">Témy</h2>
<!--                            <p class="breadcumb">Pred stavbou > Projekty</p>-->
                        </div>

                        <div class="sort">
                            <button @click="setFilter('created_at')" :class="{ 'sorting-filter': filter === 'created_at' }">
                                Najnovšie
                                <font-awesome-icon v-show="filter === 'created_at' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'created_at' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button> |
                            <button @click="setFilter('forum_likes_count')" :class="{ 'sorting-filter': filter === 'forum_likes_count' }">
                                Najčítanejšie
                                <font-awesome-icon v-show="filter === 'forum_likes_count' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'forum_likes_count' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button> |
                            <button @click="setFilter('comments_count')" :class="{ 'sorting-filter': filter === 'comments_count' }">
                                Najkomentovanejšie
                                <font-awesome-icon v-show="filter === 'comments_count' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                <font-awesome-icon v-show="filter === 'comments_count' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                            </button>
                        </div>

                        <b-row v-if="all_forums.length > 0" class="list">
<!--                            <b-spinner v-if="all_forums.length < 1" type="grow" label="Spinning"></b-spinner>-->
                            <b-col cols="12" v-for="forum in all_forums" :key="forum.id">
                                <ForumPostLight v-if="forum" :forum="forum" />
                            </b-col>
                        </b-row>
                        <div v-else-if="all_forums.length === 0 && spinner.forum" class="mt-5 mb-5">
                            <b-spinner type="grow" label="Spinning"></b-spinner>
                        </div>
                        <div v-else class="forums-not-found">
                            <p>Nenašli sa žiadne relevantné príspevky</p>
                            <b-button @click="$router.push({ name: 'ForumNew' }, () => {})" variant="primary" pill>
                                Pridať článok
                            </b-button>
                        </div>

                        <div v-if="!spinner.forum" class="paginator">
                            <pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :records="records"
                                :options="{
                                    theme: 'bootstrap4',
                                    edgeNavigation: false,
                                    texts: {
                                        count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                                        first: 'Prvá',
                                        last: 'Posledná'
                                    }
                                }"
                                @paginate="handlePageChange"
                            ></pagination>
                        </div>

                    </b-col>

                    <b-col class="sidebar" cols="12" lg="4" xl="3" offset-xl="1">
                        <BannerAdvertisingVertical :banner_slug="'TPagFbT03nrHAhO'" />
                        <TopPostContributor v-if="topForumUsers.length > 0" :topUsers="topForumUsers" />
                    </b-col>
                </b-row>
                <!--        <img class="geometric-5" :src="geometric_4" alt="svg">-->
            </div>
        </div>

        <PostList />
        <Newsletter />
        <Footer />

    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import BenefitsSlider from "@/components/home/BenefitsSlider";

import ForumPostLight from "@/components/forum/single/ForumPostLight";
import CategoryCollapse from "@/components/CategoryCollapse";
import TopPostContributor from "@/components/TopPostContributor";
import BannerAdvertisingVertical from "@/components/BannerAdvertisingVertical";

import PostList from "@/components/forum/PostList";
import Newsletter from "@/components/home/Newsletter";
import Footer from "@/components/Footer";
import SearchPost from "@/components/SearchPost";

export default {
    name: 'Forum',
    components: {
        SearchPost,
        MainMenu,
        BenefitsSlider,
        ForumPostLight,
        CategoryCollapse,
        TopPostContributor,
        BannerAdvertisingVertical,
        PostList,
        Newsletter,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Fórum | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_description_forum },
                { vmid: 'og:type', name:'og:type', content: 'forum' },
            ]
        }
    },
    data() {
        return {
            category: [],

            search_term: '',

            allowed_query: ['filter', 'currentPage', 'sort', 'category'],
            allowed_filters: ['created_at', 'forum_likes_count', 'comments_count'],
            default_filter: 'created_at',
            filter: 'created_at',
            sort: 'DESC',

            perPage: 6,
            currentPage: 1,
            all_forums: [],
            records: 0,

            topForumUsersCount: 10,
            topForumUsers: [],

            spinner: {
                forum: false,
            }
        }
    },
    watch: {
        '$route.query': {
            handler: function (query) {
                if (this.checkQuery(query)) {
                    // console.log('QUERY Y')
                    if (query.sort) {
                        query.sort === 'ASC' ? this.sort = 'ASC' : this.sort = 'DESC'
                    } else {
                        this.sort = 'DESC'
                    }
                    if (query.currentPage) {
                        if (Number.isInteger(Number(query.currentPage))) this.currentPage = Number(query.currentPage)
                    } else {
                        this.currentPage = 1
                    }
                    query.category ? this.category = JSON.parse(query.category) : this.category = []

                    this.getForums()
                } else {
                    this.resetFilter(this.default_filter)
                }
            },
            immediate: true
        },
    },
    mounted() {
        if (this.$route.params.forum) {
            this.$scrollTo('#category-select')
        }

        this.getTopForumUsers();
    },
    methods: {
        checkQuery(query) {
            let querySuccess = true
            let queryKeys = Object.keys(query)
            if (queryKeys.length > 0) {
                queryKeys.forEach(key => {
                    if (!this.allowed_query.includes(key)) querySuccess = false
                })
            }
            if (querySuccess) {
                if (query.filter) {
                    if (this.allowed_filters.includes(String(query.filter))) {
                        this.filter = String(query.filter)
                    } else {
                        querySuccess = false
                    }
                } else {
                    this.filter = this.default_filter
                    this.currentPage = 1
                    this.sort = 'DESC'
                }
            }
            return querySuccess
        },
        handlePageChange(page) {
            console.log('handlePageChange', page)
            let query = Object.assign({}, this.$route.query);
            if (page === 1) {
                this.currentPage = 1
                delete query.currentPage
            } else {
                if (Number(this.$route.query.currentPage) !== page) query.currentPage = this.currentPage
            }
            this.$router.push({ 'query': query }, () => {});
            this.$scrollTo('#forum-main')
        },
        changeSort(actualFilter) {
            let query = Object.assign({}, this.$route.query);
            if (actualFilter === this.filter) {
                if (this.sort === 'ASC') {
                    delete query.sort
                    this.sort = 'DESC'
                } else {
                    query.sort = 'ASC'
                    this.sort = 'ASC'
                }
            } else {
                if (this.sort !== 'DESC') {
                    delete query.sort
                    this.sort = 'DESC'
                }
            }
            this.currentPage = 1
            delete query.currentPage
            this.$router.push({ 'query': query }, () => {});
        },
        setFilter(filterItem) {
            this.changeSort(filterItem)
            if (filterItem === 'created_at') {
                if (this.filter !== 'created_at') this.$router.replace({ 'query': null })
            } else {
                let query = Object.assign({}, this.$route.query);
                query.filter = filterItem
                if (this.filter !== filterItem) {
                    delete query.currentPage
                    this.currentPage = 1
                }
                this.$router.push({ 'query': query }, () => {});
            }
            this.filter = filterItem
            this.$scrollTo('#forum-main')
        },
        updateCategory(category) {
            this.category = category
            this.currentPage = 1
            let query = Object.assign({}, this.$route.query);
            delete query.currentPage
            category.length > 0 ? query.category = JSON.stringify(category) : delete query.category
            this.$router.push({ 'query': query }, () => {});
        },
        getForums() {
            this.spinner.forum = true
            this.all_forums.splice(0)
            console.log('URL', this.filter, this.sort, this.category, this.currentPage)
            axios.get(config.API_URL + '/forum'
                + '?page=' + this.currentPage
                + '&perpage=' + this.perPage
                + '&category_ids=' + '[' + this.category + ']'
                + '&orderBy=' + this.filter
                + '&ordering=' + this.sort, config.HEADERS)
            .then((response) => {
                // console.log('forums', response)
                this.records = response.data.success.total;
                this.all_forums = response.data.success.data;
                this.spinner.forum = false
            }, (error) => {
                console.warn(error);
                this.spinner.forum = false
            });
        },
        searchTerm() {
            this.$router.push({ name: 'Search', params: { search_term: this.search_term, search: 'forum' }}, () => {})
        },
        getTopForumUsers() {
            axios.get(config.API_URL + '/user/getTopForumUsers'
                + '?quantity=' + this.topForumUsersCount, config.HEADERS)
                .then((response) => {
                    console.log('getTopForumUsers:', response.data.success);
                    this.topForumUsers = response.data.success
                })
                .catch(error => {
                    console.log(error.response)
                });
        },
    },
}
</script>

<style scoped lang="scss">
.page-forum::v-deep {
    .row-buttons {
        margin-bottom: 30px;

        .btn {
            width: 100%;
        }
    }

    .choose-category {
        .title {
            color: $secondary;
            margin: 50px 0 30px;
        }

        #collapse-categories {
            background: $bg-gray;

            .list {
                padding: 30px 0 10px;

                .card {
                    border: none;
                    background-color: $bg-gray;

                    header {
                        border: none;
                        border-radius: 10px;

                        button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 10px 15px;
                            box-shadow: none;

                            .icon-start {
                                margin-right: 10px;
                            }

                            .icon-end {
                                margin-left: auto;
                                font-size: 16px;
                                transition: transform .2s ease-in-out;
                            }

                            &:focus {
                                background-color: $primary;
                            }

                            &:hover {
                                background-color: #E1E3E6;
                                border-color: #E1E3E6;
                                color: $primary;
                            }

                            &.not-collapsed {
                                background-color: #E1E3E6;
                                border-color: #E1E3E6;
                                color: $primary;

                                .icon-end {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }

                    .card-body {
                        text-align: left;

                        .custom-checkbox .custom-control-label::before {
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .category-toggle {
            background: $bg-gray;
            padding: 30px 0;
            outline: none;

            svg {
                transition: transform .4s ease-in-out;
            }

            &.not-collapsed {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .main-content {
        position: relative;
        padding: 50px 0;

        .geometric-5 {
            position: absolute;
            right: -550px;
            bottom: -270px;
            z-index: -1;
        }

        .left {
            .top-wrapper {
                display: flex;
                flex-direction: row;

                .title {
                    color: $secondary;
                    margin-bottom: 15px;
                }

                .breadcumb {
                    color: $secondary;
                    line-height: 33px;
                    margin-bottom: 0;
                    margin-left: auto;
                }
            }

            .sort {
                text-align: left;

                @media (max-width: 450px) {
                    text-align: center;
                }

                button {
                    outline: none;
                    border: none;
                    background: transparent;
                    color: $primary;

                    @media (max-width: 450px) {
                        font-size: 13px !important;
                    }
                }

                svg {
                    color: $primary;
                    transform: scale(0.9);
                }

                .sorting-filter {
                    font-weight: bold;
                    color: $secondary;

                    svg {
                        color: $secondary;
                    }
                }
            }

            .list {
                margin-top: 30px;

                .single-forum-post-light {
                    margin-bottom: 30px;
                }
            }

            .paginator {
                display: flex;
                justify-content: center;
                width: 100%;

                .VuePagination {
                    //width: inherit;
                    overflow-x: auto;
                }

                .page-link {
                    user-select: none;
                }

                .VuePagination__pagination-item-prev-chunk .page-link {
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                }

                .VuePagination__pagination-item-next-chunk .page-link {
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }

        .sidebar {
            >.title {
                font-size: 16px !important;
                text-align: center;
                margin-bottom: 40px;
            }

            .top-blog-vendor-list {
                .single-vendor-vertical {
                    margin-bottom: 30px;
                }

                @media (max-width: 992px) {
                    display: flex;
                    flex-direction: row;
                    margin-left: -15px;
                    margin-right: -15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    overflow-x: auto;

                    .single-vendor-vertical {
                        margin-right: 15px;
                        width: 290px;
                    }
                }
            }
        }
    }
}

</style>