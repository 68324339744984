<template>
    <div class="component-category-treeselect" :class="{ 'input--error': $v.options_select_child.$error}">
        <div class="category-treeselect-input">
            <div class="category-title">Kategória*</div>
            <treeselect
                v-model="options_select_child"
                :multiple="false"
                :options="options_data"
                :show-count="true"
                :default-expand-level="1"
                placeholder="Vyberte kategóriu"
            />
            <div class="error-message-l" v-show="$v.options_select_child.$error">
                <div v-if="!$v.options_select_child.required">Prosím, zvoľte kategóriu.</div>
            </div>
            <span class="description">Ak pre danú kategóriu a podkategóriu existujú</span>
        </div>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { required } from 'vuelidate/lib/validators'

export default {
    name: "CategoryTreeselect",
    components: {
        Treeselect,
    },
    props: {
        options_select: {
            type: Number,
            default: 0
        },
        options_data: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            options_select_child: this.options_select,
        }
    },
    watch: {
        options_select_child(value) {
            this.$emit('update:options_select', value)
        },
    },
    validations: {
        options_select_child: {
            required,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-category-treeselect::v-deep {
    .category-treeselect-input {
        margin-bottom: 50px;
        font-weight: normal !important;

        .category-title {
            float: left;
            margin-left: 20px;
            font-weight: bold;
            font-size: 14px;
        }

        label {
            padding-left: 0;
            font-weight: normal;
        }

        .vue-treeselect__control {
            background-color: #DCDEE6;
            min-height: 60px;
            padding: 11px;
            border-radius: 30px;
            outline: none;
            border: 0;
            box-shadow: 0 12px 17px rgba(0, 0, 0, .2);
        }

        .vue-treeselect__value-container {
            .vue-treeselect__single-value {
                margin-top: 3px;
            }
        }

        .vue-treeselect__placeholder {
            margin-top: 3px;
        }

        .vue-treeselect__x-container {
            svg  {
                color: $primary;

                &:hover {
                    color: $secondary;
                }
            }
        }

        .vue-treeselect__control-arrow-container {
            svg {
                color: $primary
            }
        }

        span.description {
            color: #6c757d !important;
            float: left;
            padding-left: 20px;
            font-size: 13px;
            margin-top: 4px;
        }
    }
}
</style>