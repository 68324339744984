<template>
    <div class="notifications">
        <notifications group="notify" />
    </div>
</template>

<script>

export default {
    name: "NotificationsRealTime",
    watch: {
        '$store.getters.new_notification': function(value) {
            this.$notify({
                group: 'notify',
                title: value.notification_type.title,
                text: value.notification_type.message,
            });
        },
    },
    mounted() {
        this.$store.dispatch('getNewNotification', true)
    },
}
</script>

<style lang="scss" scoped>
::v-deep .vue-notification-group {
    margin-top: 10px;
    margin-right: 10px;
}

::v-deep .vue-notification {
    background-color: $secondary;
    border-left: 5px solid $primary;
    border-radius: 10px;
    box-shadow: 1px 1px 1px white;
}
</style>