<template>
    <div class="page-change-password">
        <MainMenu />

        <div class="container">
            <b-form>
                <b-row v-if="change_1 && change_2" class="mt-3 mb-5">
                    <b-col class="12" lg="6" offset-lg="3">
                        <b-form-group label="Nové heslo*" label-for="new_password">
                            <b-form-input id="new_password" v-model="new_password" type="password"></b-form-input>
                            <div class="error-message-l" v-show="$v.new_password.$error">
                                <div v-show="!$v.new_password.required">Prosím, zvoľte si nové heslo.</div>
                                <div v-show="!$v.new_password.minLength">Heslo musí mať aspoň {{$v.new_password.$params.minLength.min}} znakov.</div>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col class="12" lg="6" offset-lg="3">
                        <b-form-group label="Potvrdenie nového hesla*" label-for="new_password_confirmation">
                            <b-form-input id="new_password_confirmation" v-model="new_password_confirmation" type="password"></b-form-input>
                            <div class="error-message-l" v-show="$v.new_password_confirmation.$error">
                                <div v-show="!$v.new_password_confirmation.required || !$v.new_password_confirmation.sameAsPassword">Zadané heslá sa nezhodujú.</div>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" offset-md="3">
                        <b-button @click="changePassword()" pill variant="primary">Zmeniť heslo</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="!change_1">
                    <b-col cols="12" lg="6" offset-lg="3">
                        Vaše heslo bolo úspešne zmenené.
                    </b-col>

                    <b-col cols="10" offset="1" lg="6" offset-lg="3">
                        <b-button @click="$router.push({ name: 'Sign_in' })" pill variant="primary">Naspäť na prihlásenie</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="!change_2">
                    <b-col cols="12" lg="6" offset-lg="3">
                        Vaše heslo bolo úspešne zmenené.
                    </b-col>

                    <b-col cols="10" offset="1" lg="6" offset-lg="3">
                        <b-button @click="$router.push({ name: 'Sign_in' })" pill variant="primary">
                            <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                            Prihlásiť sa
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";

import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
    name: "ChangePassword",
    components: {
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Zmena hesla | ',
        }
    },
    data() {
        return {
            new_password: '',
            new_password_confirmation: '',

            change_1: true,
            change_2: true,
        }
    },
    methods: {
        changePassword() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            let myData = new FormData();
            myData.set('password', this.new_password);
            myData.set('password_confirmation', this.new_password_confirmation);

            // for (let pair of myData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            if (this.$store.getters.user.slug) {
                axios.post(config.API_URL
                    + '/auth/change-password-conto', myData, config.HEADERS)
                    .then((response) => {
                        console.log('change_1', response)
                        this.$store.dispatch('logoutUser');
                        this.change_1 = false
                    }, (error) => {
                        console.warn(error);
                        this.errors = this.$errorMessage(error.response.data.error)
                    });
            } else {
                myData.set('email', this.$route.query.email);
                myData.set('token', this.$route.query.token);

                axios.post(config.API_URL
                    + '/auth/change-password', myData, config.HEADERS)
                    .then((response) => {
                        console.log('change_2', response)
                        this.change_2 = false
                    }, (error) => {
                        console.warn(error);
                    });
            }
        }
    },
    validations: {
        new_password: {
            required,
            minLength: minLength(6),
        },
        new_password_confirmation: {
            required,
            sameAsPassword: sameAs('new_password')
        },
    }
}
</script>

<style scoped lang="scss">
.page-change-password::v-deep {
    form {
        padding: 40px 0;

        .row > div {
            margin-bottom: 20px;
        }

        label {
            text-align: left;
            padding-left: 20px;
            font-size: 14px;
            font-weight: 600;
        }

        .form-group {

            input {
                color: $primary;
                border-radius: 30px;
                background: $input-bg;
                border: none;
                padding: 30px 20px;
                box-shadow: 0 12px 17px rgba(0,0,0,.2);

                &::placeholder {
                    color: $primary;
                }
            }
        }

        // ikonka pred emailom
        .input-custom-prepend {
            display: none;
            position: absolute;
            left: -25px;
            top: 50%;
            font-size: 20px;
            transform: translate(-50%, -50%);
        }

        #checkbox_vop_wrapper_1,
        #checkbox_vop_wrapper_2 {
            .custom-control-label::after {
                //margin-top: -10px;
            }

            label {
                font-weight: normal;
                padding: 0;
                margin-left: 10px;
            }
        }

        .btn {
            margin: 0 auto;
            padding: 10px 70px;
        }
    }
}
</style>