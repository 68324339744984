<template>
    <div class="page-after-pay">
        <MainMenu />

        <div class="container main-content">
            <h1>Ďakujeme za zakúpenie predplatného.</h1>
            <h2>Odteraz si môžete prezerať dopyty.</h2>

            <b-row>
                <b-col cols="12" lg="3" offset-lg="3" class="mb-3">
                    <b-button @click="$router.push({ name: 'PageProfileSubscriptions', params: { slug: $store.getters.user.slug } })" variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="file-alt"></font-awesome-icon>
                        Prejsť na objednávky
                    </b-button>
                </b-col>
                <b-col cols="12" lg="3" class="mb-3">
                    <b-button @click="$router.push({ name: 'Demands', params: { demands: 'demands' }})" variant="secondary" pill>
                        <font-awesome-icon class="btn-icon" icon="arrow-circle-right"></font-awesome-icon>
                        Prejsť na dopyty
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";

export default {
    name: "AfterPay",
    components: {
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Ďakujeme | ',
        }
    },
    mounted() {
        this.checkAfterPay(this.$route.fullPath)
    },
    methods: {
        checkAfterPay(orderData) {
            let indexQuestionMark = orderData.indexOf("?")
            let token = orderData.slice(indexQuestionMark + 1)
            console.log('after pay', token)
            axios.post(config.API_URL + '/chekout/afterPay?' + token,{}, config.HEADERS)
                .then((response) => {
                    console.log('AFTER PAY', response.data)
                    this.$store.dispatch('loginUser', response.data)
                }, (error) => {
                    console.warn(error);
                });
        }
    },
    
}
</script>

<style lang="scss" scoped>
.page-after-pay::v-deep {

    .main-content {
        margin-top: 100px;
        margin-bottom: 100px;

        h2 {
            font-size: 20px !important;
        }

        .row {
            margin-top: 100px;
        }

        button {
            width: 100%;
            max-width: 300px;
        }
    }
}
</style>