<template>
    <b-modal id="modal-to-active" title="Zverejniť dopyt?">
        <b-container fluid>
            <p class="my-4">Chystáte sa zverejniť dopyt. Po jeho zverejnení, nebude možné meniť niektoré polia. Pokračovať?</p>
        </b-container>
        <template #modal-footer>
            <div class="w-100">
                <b-button
                    variant="primary"
                    size="sm"
                    class=" mr-3"
                    @click="hideConfirmModal()"
                >
                    <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                    Zrušiť
                </b-button>

                <b-button
                    variant="secondary"
                    size="sm"
                    class=""
                    @click="confirmNewDemand()"
                >
                    <font-awesome-icon icon="share-square"></font-awesome-icon>
                    Zverejniť dopyt
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalDemandInactiveToActive",
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-to-active')
        },
        confirmNewDemand() {
            this.$emit('confirm-activ-demand')
        }
    }
}
</script>

<style lang="scss" scoped>

#modal-to-active {
    .modal-dialog {
        svg {
            margin-top: -3px;
        }

        button {
            border-radius: 20px;
        }
    }

    .modal-footer div {
        text-align: right;
    }
}
</style>