<template>
    <div class="component-create-update-head">
        <h1 class="update-head-title">{{ title }}</h1>

        <hr class="head-hr">

        <b-button
            class="btn-back"
            @click="redirectToPage()"
            variant="link"
        >
            <font-awesome-icon icon="chevron-left"></font-awesome-icon>
            {{ link_text }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: "CreateUpdateHead",
    props: {
        title: {
            type: String,
            default: ''
        },
        link_text: {
            type: String,
            default: 'Späť'
        },
        type: {
            type: String,
            default: ''
        },
        page: {
            type: String,
            default: ''
        },
        slug: {
            type: String,
            default: ''
        },
    },
    methods: {
        redirectToPage() {
            if (this.slug === 'false') {
                this.$router.push({ name: this.page }, () => {})
            } else {
                this.$router.push({ name: this.page, params: { slug: this.slug } }, () => {})
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.component-create-update-head::v-deep {
    position: relative;

    .update-head-title {
        color: $secondary;
    }

    .head-hr {
        margin: 0;
        border-color: $secondary;
    }

    .btn-back {
        margin: 0 auto;
    }
}
</style>