<template>
    <div class="page-profile-demands">

        <div>
            <div v-if="$route.params.slug === $store.getters.user.slug">
                <div class="demands-info">
                    <b-row no-gutters>
                        <b-col cols="12" lg="5" class="demands-info__description">
                            <font-awesome-icon class="info" icon="exclamation-circle"></font-awesome-icon>
                            Počet projektov na ktorých pracujete:
                        </b-col>
                        <b-col cols="2" lg="2" class="demands-info__value">
                            <strong>{{ user_demands_count }}</strong>
                        </b-col>
                        <b-col cols="10" lg="5" class="demands-info__link">
                            <a v-b-modal="'modal-1'" class="btn-view">Zobraziť vybrané projekty</a>
                        </b-col>
                    </b-row>
                    <DemandProjectsModal
                        :modal_id="'modal-1'"
                        :title="'Projekty, na ktorých pracujete'"
                        :demands="user_demands_process"
                    />

                    <b-row no-gutters>
                        <b-col cols="12" lg="5" class="demands-info__description">
                            <font-awesome-icon class="info" icon="exclamation-circle"></font-awesome-icon>
                            Počet projektov, ktoré ste neohodotili:
                        </b-col>
                        <b-col cols="2" lg="2" class="demands-info__value">
                            <strong>{{ user_demands_not_rate_count }}</strong>
                        </b-col>
                        <b-col cols="10" lg="5" class="demands-info__link">
                            <a v-b-modal="'modal-2'" class="btn-view">Zobraziť vybrané projekty</a>
                        </b-col>
                    </b-row>
                    <DemandProjectsModal
                        :modal_id="'modal-2'"
                        :title="'Projekty, ktoré čakajú na uzavretie'"
                        :demands="user_not_rate_demands"
                    />
                    <hr />
                </div>

                <b-row class="demand-sort-table">
                    <b-col cols="6">
                        <div class="demand-sort-desktop">
                            <a @click="setActive('all')" :class="{ active: isActive('all') }">Všetky</a> |
                            <a @click="setActive('favorites')" :class="{ active: isActive('favorites') }">Obľúbené</a> |
                            <a @click="setActive('mydemands')" :class="{ active: isActive('mydemands') }">Moje dopyty</a>
                        </div>

                        <b-nav-item-dropdown id="demand-sort-mobile">
                            <template #button-content>
                                <span v-if="activeItem === 'all'">Všetky</span>
                                <span v-else-if="activeItem === 'favorites'">Obľúbené</span>
                                <span v-else>Moje dopyty</span>
                            </template>
                            <b-dropdown-item @click="setActive('all')" :class="{ active: isActive('all') }">Všetky</b-dropdown-item>
                            <b-dropdown-item @click="setActive('favorites')" :class="{ active: isActive('favorites') }">Obľúbené</b-dropdown-item>
                            <b-dropdown-item @click="setActive('mydemands')" :class="{ active: isActive('mydemands') }">Moje dopyty</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-col>

                    <b-col cols="6" v-if="activeItem === 'mydemands'" class="demand-status">
                        <div class="demand-status-desktop">
                            <a @click="filterDemands(0)" :class="{ active: status === 0 }">Všetky</a> |
                            <a @click="filterDemands(1)" :class="{ active: status === 1 }">Aktívne</a> |
                            <a @click="filterDemands(2)" :class="{ active: status === 2 }">Neaktívne</a>
                        </div>

                        <b-nav-item-dropdown id="demand-status-mobile">
                            <template #button-content>
                                <span v-if="status === 0">Všetky</span>
                                <span v-else-if="status === 1">Aktívne</span>
                                <span v-else>Neaktívne</span>
                            </template>
                            <b-dropdown-item @click="filterDemands(0)" :class="{ active: status === 0 }">Všetky</b-dropdown-item>
                            <b-dropdown-item @click="filterDemands(1)" :class="{ active: status === 1 }">Aktívne</b-dropdown-item>
                            <b-dropdown-item @click="filterDemands(2)" :class="{ active: status === 2 }">Neaktívne</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-col>
                </b-row>

                <ProfileSearchInput :search_id="'demands'" @search_term="searchDemand($event)" ref="profile_search" />
    <!--            <b-row v-if="!spinners.load_data && pagination.records === 0" class="mt-5">-->
    <!--                <b-col cols="12" class="text-center">-->
    <!--                    <p>Žiadne dopyty</p>-->
    <!--                    <b-button @click="$router.push({ name: 'NewDemand' }, () => {})" variant="primary" pill>Zadať dopyt</b-button>-->
    <!--                </b-col>-->
    <!--            </b-row>-->

            </div>

            <div v-if="demands_data && demands_data.length > 0" class="demands-body">
                <DemandCard v-for="demand in demands_data" :key="demand.id" :demand="demand" />
            </div>
            <p v-if="!spinners.load_data && demands_data && demands_data.length === 0" class="mt-5">Žiadne príspevky</p>
            <VuePagination
                v-if="check_route"
                :per_page="pagination.perPage"
                :current_page="pagination.currentPage"
                :records="pagination.records"
                :load_data="spinners.load_data"
                @handle-current-page="handlePageChange($event)"
            />
        </div>
    </div>
</template>

<script>
import config from "@/config";
import axios from "axios";
import DemandProjectsModal from "@/components/demands/modal/DemandProjectsModal";
import ProfileSearchInput from "@/components/profil/ProfileSearchInput";
import DemandCard from "@/components/demands/single/DemandCard";
import VuePagination from "@/components/VuePagination";

export default {
    name: "PageProfileDemands",
    components: {
        VuePagination,
        DemandCard,
        ProfileSearchInput,
        DemandProjectsModal,
    },
    data() {
        return {
            check_route: false,

            pagination: {
                perPage: 6,
                currentPage: 1,
                records: 0,
            },

            demands_data: null,
            activeItem: 'all',
            status: 0,
            search_demand_term: '',

            user_demands_count: 0,
            user_demands_process: null,
            user_demands_not_rate_count: 0,
            user_not_rate_demands: null,

            spinners: {
                load_data: false,
            },

            activeItems: [ 'all', 'favorites', 'mydemands' ],
            statuses: [ '', '3,4,5,6', '1,2' ], // demands - all: '', active: '3,4,5,6', no active: '1,2'
        }
    },
    watch: {
        //
    },
    mounted() {
        this.checkRoute()
        if (this.$route.params.slug === this.$store.getters.user.slug) {
            this.getDemandsData()
        } else {
            this.selectGetApi()
        }
    },
    methods: {
        checkRoute() {
            let query = Object.assign({}, this.$route.query);
            if (this.$route.params.slug === this.$store.getters.user.slug) {
                if (query.filter) {
                    this.activeItem = String(query.filter)
                    if (String(query.filter) === 'mydemands') {
                        delete query.page
                    }
                } else {
                    this.activeItem = 'all'
                }
            } else {
                delete query.filter
                this.activeItem = 'all'
            }
            if (query.page) {
                this.pagination.currentPage = Number(query.page)
            } else {
                this.pagination.currentPage = 1
            }

            this.$router.push({ 'query': query }, () => {});
            this.status = 0
            this.check_route = true
            this.selectGetApi()
        },
        selectGetApi() {
            if (this.$route.params.slug === this.$store.getters.user.slug) {
                if (this.activeItem === 'favorites') {
                    this.getUserDemandsLike()
                } else {
                    this.getMyDemands();
                }
            } else {
                this.getOtherDemands()
            }
        },
        searchDemand(search_term) {
            this.search_demand_term = search_term
            this.selectGetApi()
        },
        handlePageChange(page) {
            this.pagination.currentPage = page
            let query = Object.assign({}, this.$route.query);
            if (page !== 1) {
                if (Number(this.$route.query.page) !== page) query.page = page
            } else {
                delete query.page
            }
            this.$router.push({ 'query': query }, () => {});
            this.selectGetApi();
            this.$scrollTo('.profile-container')
        },
        setActive(menuItem) {
            if (this.spinners.load_data) return false
            this.activeItem = menuItem
            this.status = 0
            let query = Object.assign({}, this.$route.query);
            if (menuItem === 'all') {
                delete query.filter
            } else {
                if (Number(this.$route.query.filter) !== menuItem) query.filter = menuItem
            }
            this.pagination.currentPage = 1
            delete query.page
            this.$router.push({ 'query': query }, () => {});
            this.selectGetApi();
        },
        isActive(menuItem) {
            return this.activeItem === menuItem
        },
        filterDemands(status) {
            if (this.spinners.load_data) return false
            this.status = status
            if (this.activeItem === 'mydemands') this.selectGetApi();
        },
        getMyDemands() {
            if (!this.$store.getters.user.slug) return false
            this.spinners.load_data = true
            let filter
            this.activeItem === 'all' ? filter = 'favourite' : filter = ''
            console.log('getUserDemands filter', this.activeItem, filter)
            axios.get(config.API_URL + '/demand/userCreated'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&filter=' + filter
                + '&body=' + this.search_demand_term
                + '&state=' + this.statuses[Number(this.status)]
                + '&slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('user demands', response)
                    this.pagination.records = response.data.success.total;
                    this.demands_data = response.data.success.data;
                    this.spinners.load_data = false
                }, (error) => {
                    this.$errorMessage(error.response)
                    this.spinners.load_data = false
                });
        },
        getUserDemandsLike() {
            if (!this.$store.getters.user.slug) return false
            this.spinners.load_data = true
            axios.get(config.API_URL + '/demand/list'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&body=' + this.search_demand_term, config.HEADERS)
                .then((response) => {
                    console.log('oblubene dopyty', response)
                    this.pagination.records = response.data.success.total;
                    this.demands_data = response.data.success.data;
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        getOtherDemands() {
            console.log('getOtherDemands')
            if (!this.$store.getters.user.slug) return false
            this.spinners.load_data = true
            axios.get(config.API_URL + '/demand/userCreated'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&filter=' + ''
                + '&body=' + this.search_demand_term
                + '&slug=' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('user OTHER demands', response)
                    this.pagination.records = response.data.success.total;
                    this.demands_data = response.data.success.data;
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },

        getDemandsData() {
            if (!this.$store.getters.user.slug) return false
            this.getUserDemandsCount()
            this.getUserDemandsProcess()
            this.getNotRateDemandsCount()
            this.getNotRateDemands()
        },
        getUserDemandsCount() {
            axios.get(config.API_URL + '/demand/user/count', config.HEADERS)
                .then((response) => {
                    // console.log('countProcessingDemands', response.data.success)
                    this.user_demands_count = response.data.success
                }, (error) => {
                    this.$errorMessage(error.response)
                });
        },
        getUserDemandsProcess() {
            axios.get(config.API_URL + '/demand/user/getProcessedDemands', config.HEADERS)
                .then((response) => {
                    // console.log('getProcessedDemands', response.data.success)
                    this.user_demands_process = response.data.success.data
                }, (error) => {
                    console.warn(error);
                });
        },
        getNotRateDemandsCount() {
            axios.get(config.API_URL + '/demand/user/getCountUnraitedDemands', config.HEADERS)
                .then((response) => {
                    // console.log('getNotRateDemandsCount', response.data.success)
                    this.user_demands_not_rate_count = response.data.success
                }, (error) => {
                    console.warn(error);
                });
        },
        getNotRateDemands() {
            axios.get(config.API_URL + '/demand/user/getUnraitedDemandsList', config.HEADERS)
                .then((response) => {
                    // console.log('getNotRateDemands', response.data.success)
                    this.user_not_rate_demands = response.data.success.data
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-demands::v-deep {

    .demands-info {
        color: $secondary;
        font-size: 14px;

        .row {
            margin-bottom: 5px;
        }

        &__description {
            text-align: left;

            svg {
                margin-top: -3px;
                margin-right: 5px;
            }
        }

        &__value {
            text-align: center;
        }

        &__link {
            text-align: right;

            .btn-view {
                color: $secondary;
                margin-left: auto;
                white-space: nowrap;
                font-weight: 600;
            }
        }
    }

    .demand-sort-table {
        font-size: 14px;

        &:hover {
            cursor: default;
        }

        a {
            margin: 0 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            font-weight: bold;
            color: $secondary;
        }

        .demand-sort-desktop {
            text-align: left;
        }

        .demand-status-desktop {
            text-align: right;
        }

        .demand-sort-desktop, .demand-status-desktop {
            @media (max-width: 992px) {
                display: none;
            }
        }

        #demand-sort-mobile {
            text-align: left;

            ul {
                transform: none !important;
                top: 30px !important;
                left: 20px !important;
            }
        }

        #demand-status-mobile {
            text-align: right;

            ul {
                transform: none !important;
                top: 30px !important;
                right: 20px;
                left: auto !important;
            }
        }

        #demand-sort-mobile, #demand-status-mobile {
            outline: none;
            list-style-type: none;

            > * {
                outline: none;
            }

            ul {
                li {
                    margin: 0 10px;
                    border-radius: 5px;

                    &:hover, &:focus {
                        background: #9999;
                    }

                    .dropdown-item {
                        width: auto !important;

                        &:hover, &:focus {
                            background-color: transparent;
                        }

                    }
                }
                .active {
                    background-color: $primary;

                    a {
                        color: white;
                    }
                }
            }

            a {
                span {
                    margin-right: 5px;
                }
            }

            @media (min-width: 992px) {
                display: none;
            }

        }
    }

    .component-vue-pagination {
        margin-top: 40px;
    }
}
</style>