<template>
    <div class="component-wrote-about-slider">
        <div class="container">

            <h1>Napísali o nás</h1>

            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="blog in all_blogs" :key="blog.id" class="swiper-slide">
                        <WroteAbout :blog="blog" />
                    </div>
                </div>

                <!-- Add Pagination -->
                <div class="swiper-button-prev"><font-awesome-icon class="absolute-left" icon="arrow-left"></font-awesome-icon></div>
                <div class="swiper-button-next"><font-awesome-icon class="absolute-left" icon="arrow-right"></font-awesome-icon></div>

                <!-- <div class="swiper-pagination"></div> -->
            </div>

        </div>
    </div>
</template>

<script>
import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

import WroteAbout from "@/components/my_ebrick/about_project/WroteAbout";
import axios from "axios";
import config from "@/config";

    export default {
        components: {
            WroteAbout,
        },
        data() {
            return {
                config_swiper: {
                    init: false,
                    observer: true,
                    observeParents: true,
                    observeSlideChildren: true,

                    slidesPerView: 3,
                    centeredSlides: true,
                    initialSlide: 2,
                    pagination: {
                        el: '.swiper-pagination',
                        dynamicBullets: true,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        320: {
                            slidesPerView: 1,
                            //spaceBetween: 20
                        },
                        // when window width is >= 480px
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }
                },
                main_swiper: '',

                all_blogs: [],
                spinner: {
                    blogs: false
                }
            }
        },
        mounted() {
            this.$set(this, 'main_swiper', new Swiper('.component-wrote-about-slider .container .swiper-container', this.config_swiper));
            this.getBlogs()
        },
        methods: {
            getBlogs() {
                this.spinner.blogs = true
                this.all_blogs.splice(0)
                axios.get(config.API_URL + '/blog'
                    + '?page=' + '1'
                    + '&perpage=' + '20'
                    + '&category_ids=' + '[126]'
                    + '&orderBy=' + 'publication_date'
                    + '&ordering=' + 'DESC', config.HEADERS)
                    .then((response) => {
                        console.log('bloggg:', response.data.success.data);
                        this.all_blogs = response.data.success.data;
                        this.$nextTick(() => {
                            this.main_swiper.init();
                        })
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.spinner.blog = false
                    });
            },
        }
    }
</script>

<style lang="scss" scoped>
.component-wrote-about-slider::v-deep {

    .container {
        padding: 0 30px;

        h1 {
            color: $secondary;
            text-align: left;
            font-size: 20px !important;
            margin-bottom: 0;
        }
    }

    .swiper-container {
      width: 100%;
      height: auto;
      padding-top: 40px;
      padding-bottom: 100px;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: $body-bg;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 88%;
        background-color: $primary;
        color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;

        &::after {
            display: none;
        }

        svg {
            transform: scale(0.9);
        }
    }

    .swiper-button-next {
        right: 45%;

        @media (max-width: 991px) {
            right: 35%;
        }
    }
    .swiper-button-prev {
        left: 45%;

        @media (max-width: 991px) {
            left: 35%;
        }
    }

    /* .swiper-pagination {
        bottom: 0;

        &-bullet {
            background: none;
            border: 1px solid $secondary;
            width: 12px;
            height: 12px;
            opacity: 1;

            &-active {
                background: $secondary;
            }
        }
    } */
}
</style>