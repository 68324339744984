<template>
    <cookie-law theme="dark-lime" class="component-cookies-accept">
        <div slot-scope="props" class="cookies-container">
            <p class="cookies-text">
                Používame cookies, ktoré nám umožňujú poskytovať pre vás lepšie služby.
                <a @click="$router.push({ name: 'Informations', params: { slug: 'vseobecne-obchodne-podmienky' }}, () => {})" class="cookies-link">
                    Viac info
                </a>
            </p>
            <b-button @click="props.accept" variant="primary" pill><span>Súhlasím</span></b-button>
        </div>
    </cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
    name: "CookiesAccept",
    components: {
        CookieLaw,
    }
}
</script>

<style lang="scss" scoped>
.component-cookies-accept::v-deep {
    .cookies-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;

        .cookies-text {
            margin: 0;

            .cookies-link {
                color: white;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .btn {
            background-color: $green;
            border: 0;
        }

        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>