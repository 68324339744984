<template>
    <div @click="$router.push({ name: 'PageProfileAbout', params: { slug: user.user.slug }})" class="single-vendor-vertical">
        <div class="single-img">
			<span class="vendor-img-overlay">
				<img :src="vendor" alt="dodavatel">
			</span>
        </div>

        <div class="single-content">
            <p class="title text-bold">{{ user.user.name }}</p>
            <p class="text-center text-truncate">
                <small v-for="(profession, index) in user.user.professions" :key="profession.id">
                    {{ profession.name }}<span v-if="index < user.user.professions.length - 1"> | </span>
                </small>
            </p>
        </div>
    </div>
</template>

<script>
import vendor from "@/assets/vendor.svg"

export default {
    props: {
        user: {
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
            vendor: vendor,
        }
    },
}
</script>

<style scoped lang="scss">
.single-vendor-vertical::v-deep {
    position: relative;
    background: white;
    //box-shadow: 0 5px 10px rgba(8, 25, 45, .25);
    box-shadow: 0 6px 12px rgba(14, 43, 76, .08);
    margin-top: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;

    img.post-image {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .single-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        .vendor-img-overlay {
            background: $primary;
            position: relative;
            display: flex;
            width: 40px;
            height: 40px;
            margin-top: -20px;
            margin-left: auto;
            margin-right: auto;
            padding: 10px;
            border-radius: 50%;
            box-shadow: 0 6px 14px rgba(14,43,76,.2);
            z-index: 2;
        }

        &:before {
            content: "";
            background: $primary;
            position: absolute;
            top: 0;
            left: 0;
            //height: 7px;
            height: 9px;
            width: 100%;
        }
    }

    .single-content {
        padding: 20px;

        .title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 10px;
        }

        p {
            margin-bottom: 0;

            > small {
                color: $small-color;
            }
        }
    }
}
</style>