<template>
    <div class="component-text-editor" :class="{ 'input--error': $v.project_description_child.$error}">
        <p class="text-editor-title">{{ editor_title }}</p>

        <editor
            api-key="4h6h7c0v3wauejx0go7l16ht7hchy9th3ieu1dgmkk9vwhl7"
            v-model.trim="project_description_child"
            :init="{
                height: 500,
                language: 'sk',
                menubar: false,
                plugins: [
                    'fullscreen lists charmap print preview wordcount help'
                ],
                toolbar:
                    'undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                selector: 'textarea',
                invalid_styles: 'color font-size font-family background-color'
            }"
        />

        <div class="error-message-l" v-show="$v.project_description_child.$error">
            <div v-if="!$v.project_description_child.required || !$v.project_description_child.minLength || !$v.project_description_child.stripHtmlTags">
                Zadajte minimálne {{ $v.project_description_child.$params.minLength.min }} znakov.
            </div>
            <div v-else-if="!$v.project_description_child.maxLength">
                Zadajte maximálne {{ $v.project_description_child.$params.maxLength.max }} znakov.
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import { required, maxLength, minLength } from "vuelidate/lib/validators";
const stripHtmlTags = (value) => {
    if (value) {
        let valueStrip = value.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi, '')
        return valueStrip.length > 20;
    } else {
        return true
    }
}

export default {
    name: "TextEditor",
    components: {
        Editor,
    },
    props: {
        editor_title: {
            type: String,
            default: 'Popis'
        },
        long_text: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            project_description_child: this.long_text
        }
    },
    watch: {
        project_description_child() {
            this.$emit('update:long_text', this.project_description_child)
        },
    },
    validations: {
        project_description_child: {
            required,
            minLength: minLength(20),
            maxLength: maxLength(20000),
            stripHtmlTags: stripHtmlTags,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-text-editor::v-deep {

    .text-editor-title {
        text-align: left;
        margin-left: 30px;
        margin-bottom: 0;
        font-weight: bold;
    }

    .tox-tinymce {
        border-radius: 20px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }
}
</style>