<template>
    <b-modal id="modal-demand-status" title="Dôvod uzatvorenia dopytu">
        <b-container v-if="problemText.length === 0" class="text-center">
            <b-spinner type="grow" label="Spinning"></b-spinner>
        </b-container>
        <b-container v-else>
            <b-form-group v-for="issue in problemText" :key="issue.id" class="termination-reason" v-slot="{ ariaDescribedby }">
                <b-form-radio :aria-describedby="ariaDescribedby" name="cause-radios" v-model="noFinished" :value="issue.id">{{ issue.text }}</b-form-radio>
                <b-form-textarea v-if="issue.input == 1 && noFinished == issue.id" placeholder="Uveďte dôvod..." v-model="noFinishedReason" type="text"></b-form-textarea>
            </b-form-group>
            <div class="error-message-l" v-show="$v.noFinished.$error">
                <div v-if="!$v.noFinished.checkProblem">Prosím, zadajte dôvod uzatvorenia dopytu.</div>
            </div>

        </b-container>

        <template #modal-footer>
            <div class="text-right w-100">
                <b-button
                    @click="hideConfirmModal()"
                    variant="primary"
                    class="mr-3"
                    pill
                >
                    <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                    Zrušiť
                </b-button>
                <b-button
                    @click="changeStatus()"
                    variant="secondary"
                    pill
                >
                    <font-awesome-icon class="btn-icon" icon="window-close"></font-awesome-icon>
                    Uzavrieť dopyt
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";
import config from "@/config";
// import { required } from "vuelidate/lib/validators";

const checkProblem = (value, vm) => {
    if (value) {
        if (vm.problemId.indexOf(value) != -1) {
            // vm.noFinishedReason.length == 0 ? false : true
            if (vm.noFinishedReason.length == 0) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    } else {
        return false
    }
}

export default {
    name: "ModalStatusFinish",
    props: [ 'demandStatus' ],
    data() {
        return {
            problemText: [],
            problemId: [],

            noFinished: null,
            noFinishedReason: '',
        }
    },
    watch: {
        demandStatus: function() {
            this.getProblemText()
        },
        noFinished: function () {
            this.noFinishedReason = ''
        },
    },
    methods: {
        hideConfirmModal() {
            this.noFinished = null;
            this.noFinishedReason ='';
            this.$v.$reset()
            this.$bvModal.hide('modal-demand-status')
        },
        getProblemText() {
            let type_id
            if (this.demandStatus == 2) {
                type_id = 1
            } else if (this.demandStatus == 5) {
                type_id = 2
            }

            axios.get(config.API_URL + '/demand/finishedWithProblemText'
                + '?type_id=' + type_id, config.HEADERS)
                .then((response) => {
                    // console.log('get text problem', response.data.success)
                    this.problemText = response.data.success

                    let inputId = []
                    response.data.success.forEach(el => {
                        if (el.input == 1) inputId.push(el.id)
                    })
                    this.problemId = inputId
                }, (error) => {
                    console.warn(error)
                });
        },
        changeStatus() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            let cancel_info = {}
            cancel_info.text_id = this.noFinished
            cancel_info.text = this.noFinishedReason

            this.$emit('modal-change-status', cancel_info)
            this.hideConfirmModal()
        }
    },
    validations: {
        noFinished: {
            checkProblem: checkProblem,
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep #modal-demand-status {
    .container {
        color: $primary;

        label {
            cursor: pointer;
        }

        textarea {
            width: 90%;
            margin: 10px auto 0;

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .modal-footer {
        //
    }
}
</style>