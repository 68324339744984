<template>
    <div class="component-demand-control-panel">
        <p class="title-demand-status">Stav dopytu:</p>
        <div @click="categoriesCollapse = !categoriesCollapse" class="active-status">
            <span v-show="demand_status === 1">Neaktívny / Rozpísaný</span>
            <span v-show="demand_status === 2">Zrušený</span>
            <span v-show="demand_status === 3">Aktívny</span>
            <span v-show="demand_status === 4">Ukončený, služby boli dodané</span>
            <span v-show="demand_status === 5">Ukončený, služby neboli dodané</span>
            <span v-show="demand_status === 6">Dopyt je v procese</span>
        </div>
        
        <p v-if="demand_changed" class="status-changed">Stav dopytu bol úspešne zmenený.</p>
        <b-collapse v-if="demand_status !== 2 && demand_status !== 4 && demand_status !== 5" id="collapse-demand-status" v-model="categoriesCollapse">
            <div class="list">
                <div class="accordion-status-list" role="tablist">
                    <b-form-group class="text-left" v-slot="{ ariaDescribedby }">
                        <b-form-radio v-if="demand_status === 1 || demand_status === 3" v-model="demandStatus" :aria-describedby="ariaDescribedby" name="some-radios" :value="2">
                            Zrušený
                        </b-form-radio>
                        <b-form-radio v-if="demand_status === 1" v-model="demandStatus" :aria-describedby="ariaDescribedby" name="some-radios" :value="3">
                            Aktívny
                        </b-form-radio>
                        <b-form-radio v-if="demand_status === 6" v-model="demandStatus" :aria-describedby="ariaDescribedby" name="some-radios" :value="4">
                            Ukončený, služby boli dodané
                        </b-form-radio>
                        <b-form-radio v-if="demand_status === 6" v-model="demandStatus" :aria-describedby="ariaDescribedby" name="some-radios" :value="5">
                            Ukončený, služby neboli dodané
                        </b-form-radio>
                    </b-form-group>
                </div>
                <b-button @click="checkStatus()" class="mt-3 btn-change-status" variant="primary" pill :disabled="demandStatus === 0">
                    <font-awesome-icon class="change-icon" icon="history"></font-awesome-icon>
                    Zmeniť stav dopytu
                </b-button>
            </div>
        </b-collapse>

        <div v-if="demand_status !== 2 && demand_status !== 4 && demand_status !== 5" class="category-toggle" v-b-toggle="'collapse-demand-status'" >
            <div class="category-toggle__change-button">
                {{ categoriesCollapse ? 'Skryť status' : 'Zmena statusu' }}
                <font-awesome-icon icon="arrow-down" size="xs"></font-awesome-icon>
            </div>
        </div>
        <ModalStatusFinish :demandStatus="demandStatus" @modal-change-status="changeStatus" />
        <ModalDemandInactiveToActive @confirm-activ-demand="changeStatus()" ref="modal_to_active" />

        <span v-if="demand_status === 2 || demand_status === 5">
            <b-spinner v-if="spinners.post_recover" class="mt-5" type="grow" label="Spinning"></b-spinner>
            <b-button
                v-else
                @click="demandRestore(demand.slug)"
                class="mt-3"
                variant="secondary"
                pill
            >
                <font-awesome-icon class="btn-icon" icon="sync-alt"></font-awesome-icon>
                Obnoviť dopyt
            </b-button>
        </span>

        <div v-if="demand_status === 4" class="demand-status-rating">
            <b-button
                v-if="demand.provider_rating === null"
                @click="goDemandRating(demand.slug, demand.user.slug)"
                class="mt-2"
                variant="secondary"
                pill
            >
                <font-awesome-icon class="btn-icon" icon="star"></font-awesome-icon>
                Ohodnotiť dopyt
            </b-button>

            <DemandRating
                :provider_rating="demand.provider_rating"
                :user_rating="demand.user_rating"
            />
        </div>

<!--        <div v-if="$store.getters.user.slug && demand.demand_in_procces_user && $store.getters.user.slug === demand.demand_in_procces_user.reacted_user.slug" class="demand-status">-->
<!--            <p>Stav dopytu:</p>-->
<!--            <div class="active-status">-->
<!--                <span v-show="demand_status === 4">Ukončený, služby boli dodané</span>-->
<!--                <span v-show="demand_status === 5">Ukončený, služby neboli dodané</span>-->
<!--                <span v-show="demand_status === 6">Dopyt je v procese</span>-->
<!--            </div>-->

<!--            <b-button-->
<!--                v-if="demand.user_rating === null"-->
<!--                @click="$router.push({ name: 'DemandRating', query: { demand: demand.slug, user: demand.user.slug } })"-->
<!--                class="mt-2 mb-1"-->
<!--                variant="secondary"-->
<!--                pill-->
<!--                :disabled="demand.state_id !== 4"-->
<!--            >-->
<!--                <font-awesome-icon class="btn-icon" icon="star"></font-awesome-icon>-->
<!--                Ohodnotiť dopyt-->
<!--            </b-button>-->

<!--            <DemandRating-->
<!--                v-if="demand_status === 4"-->
<!--                :provider_rating="demand.provider_rating"-->
<!--                :user_rating="demand.user_rating"-->
<!--            />-->
<!--        </div>-->
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import ModalStatusFinish from "@/components/demands/modal/ModalStatusFinish";
import DemandRating from "@/components/demands/DemandRating";
import ModalDemandInactiveToActive from "@/components/demands/modal/ModalDemandInactiveToActive";

export default {
    name: "DemandControlPanel",
    components: {
        ModalStatusFinish,
        DemandRating,
        ModalDemandInactiveToActive,
    },
    props: {
        demand: {
            type: Object,
            default() {
                return {}
            }
        },
        demandStatusActive: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            demand_status: this.demandStatusActive,
            categoriesCollapse: false,
            demandStatus: 0,
            demand_changed: false,

            spinners: {
                post_recover: false,
            }
        }
    },
    methods: {
        goDemandRating(demand_slug, user_slug) {
            this.$router.push({ name: 'DemandRating', query: { demand: demand_slug, user: user_slug } })
        },
        demandRestore(demand_slug) {
            this.post_recover = true
            const postData = new FormData();
            postData.set('slug', demand_slug);
            axios.post(config.API_URL + '/demand/copy', postData, config.HEADERS)
                .then((response) => {
                    // this.demand_status = 1
                    this.$router.push({ name: 'DemandDetail', params: { slug: response.data.success.slug } }, () => {})
                    this.$emit('refresh-demand')
                }, (error) => {
                    console.warn(error);
                })
            .finally(() => {
                this.post_recover = false
            });
        },
        checkStatus() {
            this.demand_changed = false
            if (this.demandStatus === 5 || this.demandStatus === 2) {
                this.$bvModal.show('modal-demand-status')
            } else if (this.demandStatus === 3) {
                this.$bvModal.show('modal-to-active')
            } else {
                this.changeStatus()
            }
        },
        changeStatus(cancel_info) {
            this.$refs.modal_to_active.hideConfirmModal()
            this.categoriesCollapse = false
            this.demand_changed = false

            const myData = new FormData();
            myData.set('demand_id', this.demand.id);
            myData.set('state', this.demandStatus);
            if (cancel_info) {
                myData.set('text_id', cancel_info.text_id);
                if (cancel_info.text) myData.set('text', cancel_info.text);
            }
            // for (let pair of myData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }
            axios.post(config.API_URL + '/demand/mainState', myData, config.HEADERS)
                .then((response) => {
                    this.demandStatus = 0
                    this.$emit('refresh-demand')
                    this.$bvModal.hide('modal-demand-status')
                    if (response.data.success.state_id === 4) {
                        this.$router.push({ name: 'DemandRating', query: { demand: this.demand.slug, user: this.demand.user.slug } })
                    }
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.component-demand-control-panel::v-deep {
    position: relative;
    background-color: $bg-gray;
    text-align: center;
    padding: 20px 0;

    .title-demand-status {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        background: $bg-gray;
        height: 100%;
        width: 200vw;
        z-index: -1;
    }

    .active-status {
        background-color: $primary;
        padding: 10px 30px;
        color: white;
        border-radius: 10px;
        cursor: pointer;
        width: 70%;
        margin: 0 auto;
        font-weight: bold;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .status-changed {
        color: $secondary;
    }

    #collapse-demand-status {
        background: $bg-gray;

        .list {
            padding: 30px 0 10px;

            .accordion-status-list {
                display: flex;
                justify-content: center;

                .custom-control {
                    input {
                        cursor: pointer;
                    }
                    label {
                        cursor: pointer;
                    }
                }
            }
        }

        .btn-change-status {
            background-color: $primary;
            border: 0;
            padding: 10px 50px;
            box-shadow: none;

            svg.change-icon {
                margin: -3px 0 0;
                color: white;
                transform: scale(1);
                position: relative;
            }
        }
    }

    .category-toggle {
        padding-top: 10px;
        background: $bg-gray;
        outline: none;

        &__change-button {
            svg {
                color: $secondary;
                margin: 0 0 0 5px;
                transition: all .4s ease-in-out;
            }
        }

        &.not-collapsed {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
</style>