<template>
	<div class="component-newsletter">
		<div class="content-wrapper">
			<div class="container">
				<img :src="newsletter" alt="newsletter">

				<h2 class="text-secondary">Staviate?</h2>
				<h3>Vždy aktuálne informácie z eBrick.</h3>
				<p>Najlepšie články, novinky z fóra, akcie...</p>

                <div v-if="$store.getters.user.slug">
                    <div v-if="$store.getters.user.access_token.newsLetter !== null" class="newsletter-active">
                        <strong>Odber už máte aktívny.</strong>
                    </div>
                    <b-button v-if="$store.getters.user.access_token.newsLetter === null" @click="postNewsletter()" pill variant="primary">
                        <font-awesome-icon class="btn-icon" icon="paper-plane"></font-awesome-icon>
                        Začať odoberať
                    </b-button>
                    <b-button v-else v-b-modal.modal-cancel-newsletter variant="primary" pill>
                        <font-awesome-icon class="btn-icon" icon="power-off"></font-awesome-icon>
                        Zrušiť odober
                    </b-button>
                </div>
                <div v-else>
                    <b-form-input v-model="email" type="email" placeholder="Zadajte svoj email"></b-form-input>
                    <div class="error-message-c" v-show="$v.email.$error">
                        <div v-show="!$v.email.required || !$v.email.email">
                            Prosím, zadajte Vašu platnú e-mailovú adresu.
                        </div>
                    </div>
                    <div v-if="email">
                        <b-form-checkbox
                            id="checkbox-newsletter"
                            v-model="newsletter_consent"
                            name="checkbox-newsletter"
                            class="custom-checkbox mt-2"
                            :value="1"
                            :unchecked-value="0"
                        >
                            Súhlasím so
                            <a @click="redirectInformation('ochrana-osobnych-udajov')">
                                Spracovaním osobných údajov</a>
                            a
                            <a @click="redirectInformation('vseobecne-obchodne-podmienky')">
                                Všeobecnými obchodnými podmienkami*
                            </a>
                        </b-form-checkbox>
                        <div class="error-message-c" v-show="$v.newsletter_consent.$error">
                            <div v-show="!$v.newsletter_consent.newsletter_check">Najprv musíte súhlasiť s podmienkami.</div>
                        </div>
                    </div>
                    <div v-if="success_newsletter && email === null" class="success-newsletter">Úspešne ste sa prihlásili na odber Newslettera.</div>
                    <b-button @click="postNewsletter()" pill variant="primary">
                        <font-awesome-icon class="btn-icon" icon="paper-plane"></font-awesome-icon>
                        Začať odoberať
                    </b-button>
                </div>
				<p class="info-muted">Newsletter posielame pravidelne</p>

                <b-modal id="modal-cancel-newsletter" title="Zrušenie odberu">
                    <p class="my-4">Naozaj chcete zrušiť odber?</p>
                    <template #modal-footer>
                        <div class="w-100">
                            <b-button variant="primary" size="sm" class=" mr-3" @click="hideConfirmModal()">
                                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                                Zrušiť
                            </b-button>

                            <b-button variant="secondary" size="sm" class="" @click="newsLetterUnfollow()">
                                <font-awesome-icon class="btn-icon" icon="power-off"></font-awesome-icon>
                                Zrušiť odber
                            </b-button>
                        </div>
                    </template>
                </b-modal>
			</div>
		</div>
		<img class="geometric-8" :src="newsletter_bg" alt="svg">
	</div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import newsletter from "@/assets/newsletter_white.svg"
import newsletter_bg from "@/assets/newsletter_bg.svg"

import { email, required } from "vuelidate/lib/validators";
const newsletter_check = (value) => {
    if (value) {
        return value === 1;
    } else {
        return false
    }
}

export default {
	data() {
		return {
			newsletter: newsletter,
			newsletter_bg: newsletter_bg,

            email: null,
            newsletter_consent: null,
            success_newsletter: false,
		}
	},
    mounted() {
        if (this.$store.getters.user.user_id) this.$set(this, 'newsletter_consent', 1)
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-cancel-newsletter')
        },
        newsLetterUnfollow() {
            let myData = new FormData();
            myData.set('email', this.$store.getters.user.email);
            myData.set('token_news', this.$store.getters.user.access_token.newsLetter.token);

            axios.post(config.API_URL + '/newsLetterUnfollow', myData, config.HEADERS)
                .then((response) => {
                    console.log(response.data);
                    this.$store.commit('setUserNewsletter', null)
                    this.hideConfirmModal()
                }, (error) => {
                    console.warn(error);
                });
        },
        postNewsletter() {
            if (!this.$store.getters.user.slug) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return false
                }
            }

            const myData = new FormData();
            myData.set('email', this.$store.getters.user.email ? this.$store.getters.user.email : this.email);
            if(this.newsletter_consent === 1 || this.$store.getters.user.slug) myData.set('conditions_data', '1');

            axios.post(config.API_URL + '/newsLetterFollow', myData, config.HEADERS)
                .then((response) => {
                    console.log(response.data.success)
                    if (this.$store.getters.user.slug) this.$store.commit('setUserNewsletter', response.data.success)
                    this.email = null
                    this.newsletter_consent = null
                    this.success_newsletter = true
                    this.$v.$reset()
                }, (error) => {
                    console.warn(error)
                });
        },
        redirectInformation(slug) {
            let routeData = this.$router.resolve({ name: 'Informations', params: { slug: slug } })
            window.open(routeData.href, '_blank');
        },
    },
    validations: {
        email: {
            required,
            email,
        },
        newsletter_consent: {
            newsletter_check: newsletter_check,
        },
    }
}
</script>

<style scoped lang="scss">
.component-newsletter::v-deep {
	position: relative;
	background: $body-bg;
	padding-top: 70px;
	overflow: hidden;

	.content-wrapper {
		position: relative;
		z-index: 10;

        .error-message-c {
            margin-top: 0;
        }
	}

    .newsletter-active {
        color: $secondary;
    }

	img {
		margin-bottom: 20px;
	}

	p {
		font-size: 14px;
	}

	input {
		color: $primary;
		border-radius: 30px;
		background: #DCDEE6;
		border: none;
		width: 500px;
		margin: 0 auto;
		padding: 30px;

		&::placeholder {
			color: $primary;
		}
	}

    .custom-checkbox {
        cursor: pointer;

        label {
            cursor: pointer;
        }

        a {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .success-newsletter {
        margin-top: 5px;
        font-size: 14px !important;
        color: $secondary;
        font-weight: bold;
    }

	.btn {
        margin-top: 15px;
		padding: 10px 70px;
	}

	.info-muted {
		margin-top: 5px;
		font-size: 13px;
		color: rgba(14, 43, 76, .5);
	}

	.geometric-8 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media (max-width: 768px) {
		input {
			width: 100%;
		}

		.btn {
			width: 100%;
		}
	}
}

::v-deep #modal-cancel-newsletter {
    .modal-footer {
        text-align: right;

        button {
            border-radius: 20px;

            svg {
                margin-top: -3px;
                margin-right: 3px;
            }
        }
    }
}
</style>