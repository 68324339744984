var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-text-editor",class:{ 'input--error': _vm.$v.project_description_child.$error}},[_c('p',{staticClass:"text-editor-title"},[_vm._v(_vm._s(_vm.editor_title))]),_c('editor',{attrs:{"api-key":"4h6h7c0v3wauejx0go7l16ht7hchy9th3ieu1dgmkk9vwhl7","init":{
            height: 500,
            language: 'sk',
            menubar: false,
            plugins: [
                'fullscreen lists charmap print preview wordcount help'
            ],
            toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help',
            selector: 'textarea',
            invalid_styles: 'color font-size font-family background-color'
        }},model:{value:(_vm.project_description_child),callback:function ($$v) {_vm.project_description_child=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"project_description_child"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$v.project_description_child.$error),expression:"$v.project_description_child.$error"}],staticClass:"error-message-l"},[(!_vm.$v.project_description_child.required || !_vm.$v.project_description_child.minLength || !_vm.$v.project_description_child.stripHtmlTags)?_c('div',[_vm._v(" Zadajte minimálne "+_vm._s(_vm.$v.project_description_child.$params.minLength.min)+" znakov. ")]):(!_vm.$v.project_description_child.maxLength)?_c('div',[_vm._v(" Zadajte maximálne "+_vm._s(_vm.$v.project_description_child.$params.maxLength.max)+" znakov. ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }