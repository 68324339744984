<template>
    <b-modal id="modal-clear-cart">
        <template #modal-title>
            Zrušenie košíka
        </template>

        <b-container>
            <p class="my-4">
                Chystáte sa odísť z obchodu.
                <strong>Prajete si uložiť obsah košíka?</strong>
            </p>
        </b-container>

        <template #modal-footer>
            <b-button @click="holdStorageData()" size="sm" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="check"></font-awesome-icon>
                Ponechať údaje
            </b-button>

            <b-button @click="clearStorageData()" size="sm" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="trash-alt"></font-awesome-icon>
                Vymazať údaje
            </b-button>

            <b-button @click="hideConfirmModal()" size="sm" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalClearCart",
    props: {
        next_path: {
            default() {
                return {}
            }
        },
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-clear-cart')
        },
        confirmRedirect(value) {
            this.$emit('continue', value)
            this.hideConfirmModal()
        },

        holdStorageData() {
            this.confirmRedirect('continue')
        },
        clearStorageData() {
            this.$store.commit('setOrderData', '')
            this.confirmRedirect('delete')
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep #modal-clear-cart {
    button {
        padding: 10px 20px;
    }
}
</style>