<template>
    <div class="page-profile-about">
        <h2 class="profile-name">{{ customer.name }}</h2>

        <div>
            <div v-if="$route.params.slug === $store.getters.user.slug" class="profile-email">
                <strong>Môj email:</strong>
                {{ customer.email }}

                <div v-if="customer.role === 'default'" class="profile-data-no-packages">
                    <h3 class="no-package-title">Chcete sa prezentovať s detailnejším profilom ako firma?</h3>
                    <p class="text-center">Vyberte si jedno z našich predplatných a môžete o sebe uviesť omnoho viac detailov vďaka ktorým si Vás môže zvoliť zákazník ako svojho dodávateľa.</p>
                    <b-button @click="$router.push({ name: 'Subscription' })" variant="secondary" pill>
                        <font-awesome-icon class="btn-icon" icon="angle-double-right"></font-awesome-icon>
                        Predplatné a plány
                    </b-button>
                </div>
            </div>

            <div v-if="customer.role !== 'default'" class="profile-data">
                <b-row no-gutters>
                    <b-col cols="12" v-if="customer.customer && customer.customer.trade_name">
                        <font-awesome-icon class="company-icon" icon="building"></font-awesome-icon>
                        <p class="company-info"><strong>{{ customer.customer.trade_name }}</strong></p>
                    </b-col>

                    <b-col cols="12" v-if="customer.customer && customer.customer.address" class="profile-data__address">
                        <font-awesome-icon class="address-icon" icon="map-marker-alt"></font-awesome-icon>
                        <p class="address-info">
                            <span v-if="customer.customer.address">{{ customer.customer.address }}</span><br>
                            <span v-if="customer.customer.psc">{{ customer.customer.psc }},</span>
                            <span v-if="customer.customer.city"> {{ customer.customer.city }}</span><br>
                            <span v-if="customer.customer.country">{{ customer.customer.country }}</span>
                        </p>
                    </b-col>

                    <b-col cols="12" v-if="customer.customer && customer.customer.email" class="profile-data__email">
                        <font-awesome-icon class="email-icon" icon="envelope" size="sm"></font-awesome-icon>
                        <p class="email-info">{{ customer.customer.email }}</p>
                    </b-col>

                    <b-col cols="12" v-if="customer.customer && customer.customer.phone" class="profile-data__phone">
                        <font-awesome-icon class="phone-icon" icon="phone" size="sm"></font-awesome-icon>
                        <p class="phone-info">{{ customer.customer.phone }}</p>
                    </b-col>

                    <b-col cols="12" v-if="customer.customer && customer.customer.place" class="profile-data__place">
                        <font-awesome-icon class="place-icon" icon="map-marked-alt" size="sm"></font-awesome-icon>
                        <p class="phone-info">
                            <span>
                                {{ customer.customer.place }}
                                <span v-if="customer.customer.range">+ {{ customer.customer.range }} km okolie</span>
                            </span>
                        </p>
                    </b-col>

                    <b-col cols="12" v-if="customer.professions === [] && customer.professions.length > 0" class="profile-data__professions">
                        <font-awesome-icon class="professions-icon" icon="tools"></font-awesome-icon>
                        <p class="professions-info">
                            <span v-for="(profession, index) in customer.professions" :key="profession.id">
                                {{ profession.name }}<span v-if="index < customer.professions.length - 1"> | </span>
                            </span>
                        </p>
                    </b-col>

                    <b-col cols="12" v-if="customer.customer && customer.customer.web" class="profile-data__web">
                        <font-awesome-icon class="globe-icon" icon="globe"></font-awesome-icon>
                        <a :href="customer.customer.web" target="_blank"><span>{{ customer.customer.web }}</span></a>
                    </b-col>
                </b-row>

                <div v-if="customer.customer && customer.customer.about" class="profile-data-about">
                    <h3 class="profile-data-about__title">O mne</h3>
                    <p class="profile-data-about__text" v-html="customer.customer.about"></p>
                </div>

                <Gallery
                    v-if="images.length > 0"
                    :images="images"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Gallery from "@/components/detail_components/Gallery";

export default {
    name: "PageProfileAbout",
    components: {
        Gallery
    },
    data() {
        return {
            customer: {},
            images: [],
        }
    },
    watch: {
        customer(value) {
            this.selectImageUrl(value.images)
        },
    },
    mounted() {
        this.customer = this.$store.getters.customer
    },
    methods: {
        selectImageUrl(images) {
            if (images !== 1) {
                let new_images = []
                images.forEach(image => {
                    if (image.url) new_images.push(image.url)
                })
                this.images = new_images
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.page-profile-about::v-deep {
    text-align: left;

    .profile-name {
        color: $secondary;
        text-align: left;
        margin-top: 20px;
        border-bottom: 1px solid;
    }

    .profile-email {
        margin-bottom: 20px;
    }

    .profile-data {
        .row {
            margin-bottom: 20px;

            > div {
                display: flex;

                svg {
                    margin-top: 5px;
                }

                p {
                    margin-left: 20px;
                }
            }
        }

        .profile-data-about {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 20px 0;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: -100vw;
                background: $bg-gray;
                height: 100%;
                width: 200vw;
                z-index: -1;
            }

            &__title {
                font-size: 20px !important;
            }

            &__text {
                text-align: justify;
            }

            p {
                margin: 0;
            }
        }

        &__web {
            .globe-icon {
                margin-top: -3px;
            }

            a {
                margin-left: 20px;
            }
        }
    }

    .profile-data-no-packages {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        .no-package-title {
            font-size: 18px !important;
        }
    }

    .component-gallery {
        margin-top: 20px;

        h3 {
            font-size: 18px !important;
        }
    }
}
</style>