<template>
    <div class="component-top-blog-slider">
        <div class="container">
            <h2 class="title text-left">Top články</h2>
            <b-spinner v-show="topBlogs.length === 0" type="grow" label="Spinning"></b-spinner>
        </div>


        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="blog in topBlogs" :key="blog.id">
                    <PostHorizontal class="d-none d-lg-flex" :blog="blog" />
                    <Post class="d-lg-none" :blog="blog" />
                </div>
            </div>

            <div v-show="topBlogs.length !== 0" class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

import PostHorizontal from "@/components/blog/single/PostHorizontal";
import Post from "@/components/blog/single/Post";

export default {
    components: {
        PostHorizontal,
        Post
    },
    data() {
        return {
            topBlogs: [],
        }
    },
    mounted() {
        this.getTopBlogs();
        // eslint-disable-next-line no-unused-vars
        const top_post_swiper = new Swiper('.component-top-blog-slider .swiper-container', {
            observer: true,
            observeParents: true,
            direction: 'horizontal',
            loop: false,
            centeredSlides: true,
            initialSlide: 1,
            slidesPerView: 'auto',
            spaceBetween: 40,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    },
    methods: {
        getTopBlogs() {
            axios.get(config.API_URL + '/blog/top/getTopPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    this.topBlogs = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style scoped lang="scss">
.component-top-blog-slider::v-deep {
    padding-top: 50px;
    padding-bottom: 80px;
    background: $bg-gray;

    .title {
        color: $secondary;
        margin-bottom: 50px;
    }

    .swiper-container {
        overflow: visible;

        .swiper-slide {
            width: 290px;
            cursor: pointer;

            @media (min-width: 992px) {
                width: 550px;
            }
        }

        .swiper-pagination {
            bottom: -40px;

            &-bullet {
                background: none;
                border: 1px solid $secondary;
                width: 12px;
                height: 12px;
                opacity: 1;

                &-active {
                    background: $secondary;
                }
            }
        }
    }
}
</style>