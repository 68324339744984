<template>
    <div class="page-request-change-password">
        <MainMenu />

        <div class="container mt-5 mb-5">
            <b-form>
                <b-row v-if="formInput">
                    <b-col class="12" lg="6" offset-lg="3">
                        <b-form-group label="Email*" label-for="email">
                            <b-form-input id="email" v-model="email" type="email"></b-form-input>
                            <div class="error-message-c" v-show="$v.email.$error">
                                <div v-show="!$v.email.required || !$v.email.email">Prosím, zadajte Vašu platnú e-mailovú adresu.</div>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" offset-md="3">
                        <b-button @click="requestChangePassword()" pill variant="primary">Požiadať o zmenu hesla</b-button>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col cols="12" lg="6" offset-lg="3">
                        Ak ste zadali platný registračný e-mail, na Vašu e-mailovú adresu sme poslali postup na zmenu hesla.
                    </b-col>
                    <b-col cols="12" lg="6" offset-lg="3">
                        <b-button @click="$router.push({ name: 'Sign_in' })" pill variant="primary">Naspäť na prihlásenie</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";

import { email, required } from "vuelidate/lib/validators";

export default {
    name: "RequestChangePassword",
    components: {
        MainMenu,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Zmena hesla | ',
        }
    },
    data() {
        return {
            email: '',
            formInput: true,
        }
    },
    methods: {
        requestChangePassword() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return false
            }

            const myData = new FormData();
            myData.set('email', this.email);

            axios.post(config.API_URL + '/auth/reset-password', myData, config.HEADERS)
                .then(() => {
                    // console.log('reseet password', response)
                    this.formInput = false
                }, (error) => {
                    if (error.response.status === 404) {
                        this.formInput = false
                    }
                });
        }
    },
    validations: {
        email: {
            required,
            email,
        },
    }
}
</script>

<style lang="scss" scoped>
.page-request-change-password::v-deep {
    form {
        padding: 40px 0;

        .row > div {
            margin-bottom: 20px;
        }

        label {
            text-align: left;
            padding-left: 20px;
            font-size: 14px;
            font-weight: 600;
        }

        .form-group {

            input {
                color: $primary;
                border-radius: 30px;
                background: $input-bg;
                border: none;
                padding: 30px 20px;
                box-shadow: 0 12px 17px rgba(0,0,0,.2);

                &::placeholder {
                    color: $primary;
                }
            }
        }

        // ikonka pred emailom
        .input-custom-prepend {
            display: none;
            position: absolute;
            left: -25px;
            top: 50%;
            font-size: 20px;
            transform: translate(-50%, -50%);
        }

        #checkbox_vop_wrapper_1,
        #checkbox_vop_wrapper_2 {
            .custom-control-label::after {
                //margin-top: -10px;
            }

            label {
                font-weight: normal;
                padding: 0;
                margin-left: 10px;
            }
        }

        .btn {
            margin: 0 auto;
            padding: 10px 70px;
        }
    }
}
</style>