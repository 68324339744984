<template>
    <div class="component-banner-advertising-vertical">
        <a v-if="banner.banner" :href="banner.url" target="_blank">
            <img v-if="banner.banner" :src="asset_url + '/' + banner.banner" alt="obrazok" />
        </a>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
    name: "BannerAdvertising",
    props: {
        banner_slug: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            asset_url: '',
            banner: {},
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
    mounted() {
        this.getBannerAdvertising()
    },
    methods: {
        getBannerAdvertising() {
            axios.get(config.API_URL + '/banners/getRandomBanner/' + this.banner_slug, config.HEADERS)
                .then((response) => {
                    console.log('component-banner-advertising-vertical', response)
                    this.banner = response.data.success
                }, (error) => {
                    console.warn(error)
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.component-banner-advertising-vertical::v-deep {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100%;
    max-width: 250px;
    height: auto;
    margin: 0 auto 20px;

    img {
        width: 100%;
        max-width: 300px;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
        border-radius: 10px;
    }
}
</style>