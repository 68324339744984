<template>
    <div class="page-profile-edit-default">
        <MainMenu />

        <div class="profile-edit-default-container container">
            <b-spinner v-if="spinners.load_data" class="mt-5" type="grow" label="Spinning"></b-spinner>
            <b-form v-else>
                <b-row>
                    <b-col cols="12" md="6" offset-md="3">

                        <b-form-group
                            description="Prosím zadajte meno, pod ktorým chcete vystupovať v systéme eBrick."
                            label="Používateľské meno*"
                            label-for="nick"
                            :class="{ 'input--error': $v.nick.$error}"
                        >
                            <b-form-input id="nick" v-model="nick" type="text"></b-form-input>
                            <div class="error-message-l" v-show="$v.nick.$error">
                                <div v-if="!$v.nick.required">Prosím, vyplňte Vaše používateľské meno.</div>
                                <div v-else-if="!$v.nick.minLength">Meno musí mať aspoň {{ $v.nick.$params.minLength.min }} znakov.</div>
                            </div>
                        </b-form-group>

                    </b-col>
                </b-row>

                <div v-if="errors" class="mt-2 error-back">
                    <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
                </div>

                <b-spinner v-if="spinners.post_data" type="grow" class="mt-3" label="Spinning"></b-spinner>
                <b-button v-else @click="postUpdatedProfile()" class="btn-save mt-3" pill variant="secondary">
                    <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                    Uložiť zmeny
                </b-button>
            </b-form>
        </div>

        <Footer />
    </div>
</template>

<script>
import config from "@/config";
import MainMenu from "@/components/MainMenu";
import Footer from "@/components/Footer";
import { minLength, required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
    name: "ProfileEditDefault",
    components: {
        MainMenu,
        Footer,
    },
    data() {
        return {
            nick: '',
            spinners: {
                load_data: false,
                post_data: false,
            },
            errors: {},
        }
    },
    mounted() {
        this.getCustomer()
    },
    methods: {
        getCustomer() {
            this.spinners.load_data = true
            axios.get(config.API_URL + '/user/customer/get/' + this.$store.getters.user.slug, config.HEADERS)
                .then((response) => {
                    console.log('customer get', response.data.success)
                    this.nick = response.data.success.name
                    this.spinners.load_data = false
                }, (error) => {
                    console.warn(error);
                    this.spinners.load_data = false
                });
        },
        postUpdatedProfile() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }

            this.spinners.post_data = true
            let postData = new FormData();
            postData.set('nick', this.nick);

            // for (let pair of postData.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]);
            // }

            axios.post(config.API_URL + '/user/customer/update', postData, config.HEADERS)
                .then((response) => {
                    console.log('update succ', response);
                    this.$store.commit('setUserName', response.data.success.name)
                    this.$store.commit('setUserSlug', response.data.success.slug)
                    this.$store.dispatch('getCustomer', response.data.success.slug)
                    this.$nextTick(() => {
                        this.$router.push({ name: 'PageProfileAbout', params: { slug: response.data.success.slug }}, () => {})

                    })
                    this.spinners.post_data = false
                }, (error) => {
                    console.warn(error);
                    this.errors = this.$errorMessage(error.response)
                    this.spinner.save = false
                    this.spinners.post_data = false
                });
        },
    },
    validations: {
        nick: {
            required,
            minLength: minLength(6),
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-edit-default::v-deep {
    .profile-edit-default-container {
        padding-top: 100px;
    }
}
</style>