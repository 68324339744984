<template>
    <div class="page-demands">
        <MainMenu />

        <DemandButtonsGroup @sorting-readable="sortingReadable()" />

        <BenefitsSlider :banner_slug="'benefits-slider'" />

        <div class="container">
            <Search :page="'demand'" :search_type="'demand'" />
        </div>

        <div id="demand-list">
            <div class="demand-filter container">
                <b-row>
                    <b-col cols="10" offset="1" lg="5" class="title">
                        <h2>Dopyty od zákazníkov</h2>
                    </b-col>
                    <b-col v-if="$store.getters.user.slug &&$store.getters.user.email_verified_at !== null" cols="10" offset="1" lg="5" class="second_col">
                        <b-row>
                            <b-col cols="12" lg="auto" class="order-type">Zoradiť podľa:</b-col>
                            <b-col cols="12" lg="auto" offset-md="0">
                                <span class="sort-by">
                                    <button @click="setFilter('recommended')" :class="{ active: filter === 'recommended'}">
                                        Vybrali sme
                                    </button> |
                                    <button @click="setFilter('publication_date')" :class="{ active: filter === 'publication_date'}">
                                        Najnovšie
                                        <font-awesome-icon v-show="filter === 'publication_date' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                        <font-awesome-icon v-show="filter === 'publication_date' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                                    </button> |
                                    <button @click="setFilter('distance')" :class="{ active: filter === 'distance'}">
                                        Najbližšie
                                        <font-awesome-icon v-show="filter === 'distance' && sort === 'ASC'" icon="arrow-up"></font-awesome-icon>
                                        <font-awesome-icon v-show="filter === 'distance' && sort === 'DESC'" icon="arrow-down"></font-awesome-icon>
                                    </button>
                                </span>
                            </b-col>
                        </b-row>

                        <ModalUpdateCity />
                    </b-col>
                </b-row>
            </div>

            <CategoryCollapse :menu_id="3" :category="category" @update-category="updateCategory" />

            <div class="container" v-if="$store.getters.user.slug && $store.getters.permissions('view demands')">
                <b-button @click="$router.push({ name: 'PageProfileDemands', params: { slug: $store.getters.user.slug }, query: { filter: 'favorites' } }, () => {})" pill variant="outline-secondary" class="favorite-demands-button">
                    Obľúbené dopyty
                </b-button>
                <p class="strong-text mt-4">
                    <strong>Zvýraznili</strong> sme pre Vás projekty, na ktoré ste už reagovali.
                </p>
            </div>
            <hr>

            <div v-if="!$store.getters.user.slug" class="container">
                <b-row class="no-permission">
                    <b-col cols="12" md="8" offset-md="2">
                        <h3>Ak chcete vidieť podrobnosti dopytu a reagovať na dopyty zákazníkov, musíte sa najprv prihlásiť</h3>
                        <b-row>
                            <b-col cols="12" lg="4" offset-lg="2" class="mt-1">
                                <b-button @click="$router.push({ name: 'Sign_in' })" variant="primary" pill>
                                    <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                                    Prihlásiť sa
                                </b-button>
                            </b-col>
                            <b-col cols="12" lg="4" class="mt-1">
                                <b-button @click="$router.push({ name: 'Sign_up' })" variant="secondary" pill>
                                    <font-awesome-icon class="btn-icon" icon="sign-in-alt"></font-awesome-icon>
                                    Registrovať
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <div v-else class="container">
                <b-row v-if="$store.getters.user.email_verified_at === null">
                    <b-col><UnverifiedEmail /></b-col>
                </b-row>
                <b-row v-if="$store.getters.user.email_verified_at && !$store.getters.permissions('view demands')" class="no-permission">
                    <b-col cols="12" md="5" offset-md="1">
                        <div class="customer-type company">Pre firmy</div>
                        <h3>Ak chcete reagovať na dopyty zákazníkov, musíte si aktivovať predplatné</h3>
                        <b-button @click="$router.push({ name: 'Subscription' })" variant="secondary" pill>
                            <font-awesome-icon class="btn-icon mr-1" icon="share"></font-awesome-icon>
                            Prejsť na predplatné a plány
                        </b-button>
                    </b-col>
                    <b-col cols="12" md="5">
                        <div class="customer-type customer">Pre zákazníkov</div>
                        <h3>Pridajte svoj prvý dopyt a nájdite overeného dodávateľa</h3>
                        <b-button @click="$router.push({ name: 'NewDemand' }, () => {})" variant="primary" pill>
                            <font-awesome-icon class="btn-icon mr-1" icon="plus-circle"></font-awesome-icon>
                            Zadať dopyt
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <DemandsList
                :demands="all_demands"
                :pagination="pagination"
                :load_data="spinners.load_data"
                @handle-current-page="handlePageChange($event)"
            />

            <div v-if="all_demands === [] && records === 0" class="mt-5">
                <strong>Nenašli sa žiadne dopyty...</strong>
            </div>

            <DemandButtonsGroup class="mt-5" @sorting-readable="sortingReadable()" />
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

import MainMenu from "@/components/MainMenu";
import BenefitsSlider from "@/components/home/BenefitsSlider";
import Search from "@/components/toMarket";
import Footer from "@/components/Footer";
import CategoryCollapse from "@/components/CategoryCollapse";
import ModalUpdateCity from "@/components/single/ModalUpdateCity";
import UnverifiedEmail from "@/components/UnverifiedEmail";
import DemandButtonsGroup from "@/components/demands/DemandButtonsGroup";
import DemandsList from "@/components/demands/DemandsList";

export default {
    name: 'Demands',
    components: {
        DemandsList,
        DemandButtonsGroup,
        UnverifiedEmail,
        MainMenu,
        BenefitsSlider,
        Search,
        ModalUpdateCity,
        CategoryCollapse,
        Footer,
    },
    metaInfo() {
        return {
            title: 'Dopyty | ',
            meta: [
                { vmid: 'description', name: 'description', content: this.$store.getters.settings.meta_description_demand },
            ]
        }
    },
    data() {
        return {
            allowed_query: ['filter', 'page', 'sort', 'category'],
            allowed_filters: ['recommended', 'publication_date', 'distance'],
            default_filter: 'recommended',
            filter: 'recommended',
            sort: 'DESC',
            category: [],
            perPage: 5,
            records: 0,
            all_demands: [],

            pagination: {
                perPage: 10,
                currentPage: 1,
                records: 0,
            },

            spinners: {
                load_data: false,
            }
        }
    },
    watch: {
        '$route.query': {
            handler: function (query) {
                if (this.checkQuery(query)) {
                    console.log('QUERY Y')
                    if (query.sort) {
                        query.sort === 'ASC' ? this.sort = 'ASC' : this.sort = 'DESC'
                    } else {
                        this.sort = 'DESC'
                    }
                    if (query.page) {
                        if (Number.isInteger(Number(query.page))) this.pagination.currentPage = Number(query.page)
                    } else {
                        this.pagination.currentPage = 1
                    }
                    query.category ? this.category = JSON.parse(query.category) : this.category = []

                    this.getDemands()
                } else {
                    this.resetFilter(this.default_filter)
                }
            },
            immediate: true
        },
    },
    mounted() {
        if (this.$route.params.demands) {
            this.$scrollTo('#demand-list')
        }
    },
    methods: {
        checkQuery(query) {
            let querySuccess = true
            let queryKeys = Object.keys(query)
            if (queryKeys.length > 0) {
                queryKeys.forEach(key => {
                    if (!this.allowed_query.includes(key)) querySuccess = false
                })
            }
            if (querySuccess) {
                if (query.filter) {
                    if (this.allowed_filters.includes(String(query.filter))) {
                        this.filter = String(query.filter)
                    } else {
                        querySuccess = false
                    }
                } else {
                    this.filter = this.default_filter
                    this.pagination.currentPage = 1
                    this.sort = 'DESC'
                }
            }
            return querySuccess
        },
        changeSort(actualFilter) {
            let query = Object.assign({}, this.$route.query);
            if (actualFilter === this.$route.query.filter && actualFilter !== 'recommended') {
                if (this.sort === 'ASC') {
                    delete query.sort
                    this.sort = 'DESC'
                } else {
                    query.sort = 'ASC'
                    this.sort = 'ASC'
                }
            } else {
                if (this.sort !== 'DESC') {
                    delete query.sort
                    this.sort = 'DESC'
                }
            }
            this.$router.push({ 'query': query }, () => {});
        },
        setFilter(filterItem) {
            if (filterItem === 'distance' && this.$store.getters.user.access_token.place_id === null) {
                this.$bvModal.show('modal-distance')
                return false
            }

            this.changeSort(filterItem)
            if (filterItem === 'recommended') {
                if (this.filter !== 'recommended') this.$router.replace({ 'query': null })
            } else {
                let query = Object.assign({}, this.$route.query);
                query.filter = filterItem
                if (this.filter !== filterItem) {
                    delete query.page
                    this.pagination.currentPage = 1
                }
                this.$router.push({ 'query': query }, () => {});
            }
            this.filter = filterItem
            this.$scrollTo('#demand-list')
        },
        updateCategory(category) {
            this.category = category
            this.pagination.currentPage = 1
            let query = Object.assign({}, this.$route.query);
            delete query.page
            category.length > 0 ? query.category = JSON.stringify(category) : delete query.category
            this.$router.push({ 'query': query }, () => {});
        },
        handlePageChange(page) {
            console.log('handlePageChange', page)
            this.pagination.currentPage =  page
            let query = Object.assign({}, this.$route.query);
            if (page === 1) {
                this.pagination.currentPage = 1
                delete query.page
            } else {
                if (Number(this.$route.query.page) !== page) query.page = page
            }
            this.$router.push({ 'query': query }, () => {});
            console.log('route qyeru', this.$route.query)
            this.$scrollTo('#demand-list')
        },
        getDemands() {
            // if (!this.$store.getters.user.slug || this.$store.getters.user.email_verified_at === null) {
            //     this.spinners.load_data = false
            //     return false
            // }
            this.spinners.load_data = true

            let place_id
            this.filter === 'distance' ? place_id = this.$store.getters.user.access_token.place_id : place_id = ''
            axios.get(config.API_URL + '/demand/index'
                + '?page=' + this.pagination.currentPage
                + '&perpage=' + this.pagination.perPage
                + '&orderBy=' + this.filter
                + '&place_id=' + place_id
                + '&category_ids=' + '[' + this.category + ']'
                + '&ordering=' + this.sort, config.HEADERS)
            .then((response) => {
                console.log('demands:', response.data.success);
                this.pagination.records = response.data.success.total;
                this.all_demands = response.data.success.data;
                this.spinners.load_data = false
            }, (error) => {
                console.warn(error);
                this.all_demands = []
                this.spinners.load_data = false
                this.$errorMessage(error.response)
            });
        },
        resetFilter(filter) {
            this.filter = filter
            this.sort = 'DESC'
            this.pagination.currentPage = 1
            this.category = []
            this.$router.push({ 'query': null }, () => {});
        },

        sortingReadable() {
            this.resetFilter(this.default_filter)
        },
    },
}
</script>

<style lang="scss" scoped>
.page-demands::v-deep {

    .demand-filter {
        margin-bottom: 50px;

        .row .second_col {
            @media (max-width: 992px) {
                margin-top: 10px;
            }

            .order-type {
                display: flex;
                align-items: center;
                    justify-content: center;
            }
        }

        .title {
            display: flex;
            align-content: center;

            @media (max-width: 992px) {
                justify-content: center
            }

            h2 {
                display: flex;
                align-items: center;
                font-size: 20px !important;
                color: $secondary;
                margin: 0;
            }
        }

        .sort-by {
            color: $primary;
            cursor: pointer;

            button {
                outline: none;
                border: none;
                background: transparent;
                color: $primary;

                @media (max-width: 450px) {
                    font-size: 13px !important;
                }
            }

            svg {
            color: $primary;
            transform: scale(0.9);
            }

            .active {
                font-weight: bold;
                color: $secondary;

                svg {
                    color: $secondary;
                }
            }
        }

        .filter-select {

            select {
                margin-top: -5px;
                margin-bottom: 0 !important;
                @media (max-width: 992px) {
                    margin-top: 0;
                }
            }
            
            @media (min-width: 1200px) {
                display: none;
                float: right;
            }
        }
    }

    .strong-text {
        color: $secondary;
        font-size: 12px;
    }

    .favorite-demands-button {
        background-color: white;

        &:hover {
            background-color: $secondary;
        }
    }

    .demand-list hr {
        border-color: $secondary;
    }

    .no-entered-demands {
        margin-top: 100px;
        margin-bottom: 100px;
        button {
            width: 100%;
        }
    }

    .no-permission {
        margin-top: 50px;

        h3 {
            margin: 15px;
            font-weight: bold !important;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 30px;

            button {
                width: 100%;
                max-width: 400px;
                margin: 0 auto;
            }
        }

        .customer-type {
            background-color: $bg-gray;
            border-radius: 20px;
            font-weight: bold;
            padding: 5px 0;
        }
    }

    .demand-cards {
        display: flex;
        flex-direction: column;
        align-items: center;

        .demand-card {
            @media (min-width: 991px) {
                width: 80%;
            }
        }
    }

    .paginator {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;

        .VuePagination {
            overflow-x: auto;
        }

        .page-link {
            user-select: none;
        }

        .VuePagination__pagination-item-prev-chunk .page-link {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }

        .VuePagination__pagination-item-next-chunk .page-link {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        @media (max-width: 700px) {
            transform: scale(0.85);
        }
    }
}
</style>