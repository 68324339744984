<template>
	<div class="component-stats">
		<div @click="runNumbers()" class="container">
			<b-row class="stats-wrapper">
				<b-col class="stats-col" cols="3">
					<div class="stats-item">
						<span class="icon-wrapper">
							<font-awesome-icon icon="address-card"></font-awesome-icon>
						</span>
						<span id="stat-1" class="number">0</span>
						<span class="title">počet overených dodávateľov</span>
					</div>
				</b-col>
				<b-col class="stats-col" cols="3">
					<div class="stats-item">
						<span class="icon-wrapper">
							<font-awesome-icon icon="file-invoice" class="icon-demand-count"></font-awesome-icon>
						</span>
						<span id="stat-2" class="number">0</span>
						<span class="title">počet ukončených dopytov</span>
					</div>
				</b-col>
				<b-col class="stats-col" cols="3">
					<div class="stats-item">
						<span class="icon-wrapper">
							<font-awesome-icon icon="file-signature" class="icon-blog-count"></font-awesome-icon>
						</span>
						<span id="stat-3" class="number">0</span>
						<span class="title">počet článkov</span>
					</div>
				</b-col>
				<b-col class="stats-col" cols="3">
					<div class="stats-item">
						<span class="icon-wrapper">
							<font-awesome-icon icon="users"></font-awesome-icon>
						</span>
						<span id="stat-4" class="number">0</span>
						<span class="title">počet zákazníkov</span>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import { CountUp } from 'countup.js';
import axios from "axios";
import config from "@/config";
import ScrollMagic from 'scrollmagic'

export default {
	data() {
		return {
            countUp1: '',
            countUp2: '',
            countUp3: '',
            countUp4: '',

            controllerScrollMagic: '',
            sceneScrollMagic: '',

            statistics: '',
		}
	},
    mounted() {
        axios.get(config.API_URL + '/user/getStatisticCounts', config.HEADERS)
            .then((response) => {
                // console.log('statsdata', response)
                this.initCountUp(response.data.success)

                this.$set(this, 'controllerScrollMagic', new ScrollMagic.Controller());
                this.$set(this, 'sceneScrollMagic', new ScrollMagic.Scene({
                    offset: 300,
                    duration: 5000,
                    triggerHook: "onCenter",
                    reverse: false
                }).addTo(this.controllerScrollMagic));

                let self = this;
                this.sceneScrollMagic.on("start", function () {
                    self.runNumbers();
                });
            }, (error) => {
                console.warn(error);
            });
    },
    methods: {
        initCountUp(response) {
            this.$set(this, 'countUp1', new CountUp('stat-1', response.provider));
            this.$set(this, 'countUp2', new CountUp('stat-2', response.demands));
            this.$set(this, 'countUp3', new CountUp('stat-3', response.blogs));
            this.$set(this, 'countUp4', new CountUp('stat-4', response.users));
        },
        runNumbers() {
            this.countUp1.start();
            this.countUp2.start();
            this.countUp3.start();
            this.countUp4.start();
        }
    }
}
</script>

<style scoped lang="scss">
.component-stats::v-deep {
	position: relative;
	background: $bg-gray;
	padding: 50px 0;
	z-index: 10;
	overflow-y: auto;

	.stats-wrapper {
		min-width: 960px;

		.stats-item {
			position: relative;
			background: white;
			padding: 25px 20px 20px;
			height: 100%;
			display: flex;
			flex-direction: column;
			border-top: 7px solid $secondary;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			box-shadow: 0 6px 12px rgba(14, 43, 76, .08);

			.icon-wrapper {
				position: absolute;
				top: -25px;
				left: 50%;
				background: $secondary;
				padding: 5px;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				transform: translate(-50%, 0);
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				box-shadow: 0 6px 14px rgba(155, 65, 58, .2);

				svg {
					color: white;
					font-size: 25px;
				}

                .icon-demand-count {
                    font-size: 26px;
                }

                .icon-blog-count {
                    margin-left: 6px;
                }
			}

			.number {
				font-size: 20px;
				color: $secondary;
				font-weight: 800;
				margin-top: 10px;
			}
		}
	}
}
</style>