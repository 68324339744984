<template>
	<div class="component-forum-list">
		<div class="container">
			<div class="content">
				<h2 class="text-left mb-3">Príspevky vo Fóre</h2>
                <b-spinner v-show="topForums.length === 0" type="grow" label="Spinning"></b-spinner>
			</div>
			<img class="geometric-6" :src="rollers" alt="svg">
			<img class="geometric-7" :src="rollers_outline" alt="svg">
		</div>

		<div class="forum-list-wrapper">
			<div class="container">
				<b-row class="content forum-list">
                    <b-col cols="4" v-for="forum in topForums" :key="forum.id">
                        <ForumPost :forum="forum" />
                    </b-col>
				</b-row>
			</div>
		</div>

		<div class="container">
			<div class="content">
				<b-button @click="$router.push({ name: 'Forum' }, () => {})" class="all-forum-posts secondary-lighter" pill variant="secondary">
                    <font-awesome-icon class="btn-icon" icon="align-left"></font-awesome-icon>
                    Všetky otázky vo fóre
                </b-button>
			</div>
		</div>
	</div>
</template>

<script>
import ForumPost from "@/components/forum/single/ForumPost";

import rollers from "@/assets/rollers.svg"
import rollers_outline from "@/assets/rollers_outline.svg"
import axios from "axios";
import config from "@/config";

export default {
	components: {
		ForumPost
	},
	data() {
		return {
			rollers: rollers,
			rollers_outline: rollers_outline,

            topForums: []
		}
	},
    mounted() {
        this.getTopForums()
    },
    methods: {
        getTopForums() {
            axios.get(config.API_URL + '/forum/last/getLastPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    console.log(response.data)
                    this.topForums = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
    },
}
</script>

<style scoped lang="scss">
.component-forum-list::v-deep {
	position: relative;
	padding: 70px 0;
	background: $primary;
	z-index: 5;
    min-height: 200px;

	.container {
		position: relative;

		.content {
			position: relative;
			z-index: 10;

            .spinner-grow {
                color: white;
            }
		}
	}

	h2 {
		color: white;
	}

	.forum-list-wrapper {
		overflow-y: auto;
		padding-bottom: 20px;

		.forum-list {
			min-width: 960px;
		}
	}

	.all-forum-posts {
		margin-top: 70px;
		padding: 10px 50px;
        width: 100%;
        max-width: 300px;
	}

	.geometric-6 {
		position: absolute;
		left: -400px;
		top: -20px;
		z-index: 2;
	}

	.geometric-7 {
		position: absolute;
		right: -400px;
		top: -100px;
		z-index: 2;
	}

	@media (max-width: 768px) {
		.all-forum-posts {
			margin-top: 20px;
			width: 100%;
		}
	}
}
</style>