<template>
    <b-modal id="modal-notification" v-if="$store.getters.user" size="lg">
        <template #modal-title>Upozornenia</template>
        <div v-if="$store.getters.notifications.length > 0">
            <b-row v-for="(notify, index) in $store.getters.notifications" :key="index" class="one-notification" :class="{ 'no-read': notify.saw === 0}" no-gutters>
                <b-col cols="12" md="2" class="time">{{ moment(notify.created_at).format("lll") }}</b-col>
                <b-col cols="12" md="7"><strong>{{ notify.notification_type.message }}</strong></b-col>
                <b-col cols="9" md="2" class="user-profile">
                    <span v-if="notify.senders !== null" @click="goProfile(notify.senders.slug)">{{ notify.senders.name }}</span>
                    <span v-else><strong><em>admin</em></strong></span>
                </b-col>
                <b-col cols="3" md="1" @click="goTarget(notify.notification_type, notify.slug)" class="notify-buttons">
                    <div class="toolest">
                        <font-awesome-icon icon="arrow-alt-circle-right"></font-awesome-icon>
                        <span class="tooltiptext">Prejsť na upozornenie</span>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12">Nemáte žiadne upozornenia.</b-col>
            </b-row>
        </div>

        <div class="notification-info">*Upozornenia sa budú zobrazovať 7 dní od prečítania, po tomto čase budú automaticky vymazané.</div>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zavrieť
            </b-button>
        </template>
    </b-modal>
</template>

<script>

export default {
    name: "ModalNotifications",
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-notification')
        },
        goTarget(link, slug) {
            this.$router.push({ name: link.type_link_name, params: { slug: slug }}, () => {})
            this.hideConfirmModal()
        },
        goProfile(slug) {
            this.$router.push({ name: 'PageProfileAbout', params: { slug: slug } }, () => {})
            this.hideConfirmModal()
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep #modal-notification {
    padding-left: 0 !important;
    color: $primary;

    .modal-header {
        color: $secondary;
        font-weight: bold;
    }

    .no-read {
        background-color: rgba($small-color, 0.2);
        border-radius: 10px;
    }

    .one-notification {
        display: flex;
        align-items: center;
        padding: 10px;

        &:after {
            content: "";
            position: relative;
            //left: 50%;
            //transform: translate(-50%, 0);
            width: 100%;
            height: 1px;
            bottom: -10px;

            background-image: linear-gradient(to right, $primary 0%, rgba(255,255,255,0) 30%);
            background-position: bottom;
            background-size: 5px 1px;
            background-repeat: repeat-x;
        }

        .time {
            font-size: 14px;
        }

        .user-profile {
            cursor: pointer;
            color: $secondary;
        }

        .notify-buttons {
            display: flex;
            justify-content: space-around;
            align-items: center;
            cursor: pointer;

            .toolest {
                svg {
                    transform: scale(1.1);

                    &:hover {
                        color: $secondary;
                    }
                }

                &:hover .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .toolest .tooltiptext {
                visibility: hidden;
                opacity: 0;
                display: table;
                position: absolute;
                padding: 5px 10px;
                width: max-content;
                z-index: 1;
                transition: 0.3s;
                margin-top: 3px;

                font-size: 14px;
                border-radius: 0 30px 30px 30px;
                background-color: #8b8b8b;
                color: #fff;

                & {
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

        }
    }

    .notification-info {
        font-size: 11px;
        color: #9e9e9e;
        margin-left: 10px;
    }
}

</style>