<template>
    <b-modal id="modal-confirm-blog-pending">
        <template #modal-title>
            Požiadať o schválenie článku
        </template>

        <b-container >
            <p class="my-4">
                Chystáte sa odoslať článok na kontrolu.
                <strong>Po odoslaní už NEBUDE možné upravovať článok.</strong>
                Pokračovať?
            </p>
        </b-container>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>

            <b-button @click="deleteBlog()" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="check"></font-awesome-icon>
                Požiadať o schválenie
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalBlogDelete",
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-confirm-blog-pending')
        },
        deleteBlog() {
            this.$emit('pending-blog')
            this.hideConfirmModal()
        },
    }
}
</script>

<style lang="scss" scoped>

</style>