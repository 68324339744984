<template>
    <b-modal id="modal-new-forum" title="Zverejniť otázku?">
        <p class="my-4">
            <strong class="warning">POZOR!</strong>
            Po zverejnení otázky ju už nebude možné upraviť!
        </p>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zrušiť
            </b-button>
            <b-button @click="postNewForum()" variant="secondary" pill>
                <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                Zverejniť otázku
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ModalUpdateCity",
    data() {
        return {
            //
        }
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide('modal-new-forum')
        },
        postNewForum() {
            // this.hideConfirmModal()
            this.$emit('post-new-forum')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep #modal-new-forum {
    //
}
</style>