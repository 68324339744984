<template>
    <div class="component-gallery">
        <h3 class="gallery-title">Galéria</h3>

        <CoolLightBox
            :items="images"
            :index="indexImage"
            @close="indexImage = null">
        </CoolLightBox>

        <b-row class="image-row">
            <b-col
                cols="6"
                sm="4"
                v-for="(image, index) in images"
                :key="index"
            >
                <img
                    class="post-image"
                    @click="indexImage = index"
                    :src="image"
                    alt="obrazok"
                    width="auto"
                    height="auto"
                >
<!--                :src="asset_url + '/' + image"-->
            </b-col>
        </b-row>
    </div>
</template>

<script>
import config from "@/config";

export default {
    name: "Gallery",
    props: {
        images: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            asset_url: '',
            indexImage: null,
        }
    },
    created() {
        this.$set(this, 'asset_url', config.ASSET_URL);
    },
}
</script>

<style lang="scss" scoped>
.component-gallery::v-deep {
    .gallery-title {
        text-align: left;
        font-size: 18px !important;
    }

    .image-row {
        margin: 0;

        > div {
            margin: 5px 0;
            padding: 0 5px;
            max-height: 200px;
        }

        .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
            cursor: pointer;
            box-shadow: 1px 1px 5px 1px #00000069;
        }
    }
}
</style>