<template>
	<div class="component-footer">
		<div class="container">
			<div class="content">
				<b-button v-scroll-to="'body'" class="btn-back-up" pill variant="secondary">
					<font-awesome-icon icon="arrow-up"></font-awesome-icon>
				</b-button>

				<b-row class="footer-menu">
					<b-col cols="4" md="3" offset="1">
						<ul>
							<li><a @click="$router.push({ name: 'Home'}, () => { $scrollTo('.main-menu') })">Domov</a></li>
							<li><a @click="$router.push({ name: 'PageBlogMain' }, () => { $scrollTo('.main-menu') })">Blog</a></li>
							<li><a @click="$router.push({ name: 'Forum' }, () => { $scrollTo('.main-menu') })">Fórum</a></li>
							<li><a @click="$router.push({ name: 'Demands' }, () => { $scrollTo('.main-menu') })">Dopyty</a></li>
							<li><a @click="$router.push({ name: 'AboutProject'}, () => { $scrollTo('.main-menu') })">Môj eBrick</a></li>
							<li><a @click="$router.push({ name: 'Contact'}, () => { $scrollTo('.main-menu') })">Kontakt</a></li>
						</ul>
					</b-col>

					<b-col cols="7" md="3" offset-md="1">
						<ul>
							<li>
								<a @click="$router.push({ name: 'Subscription'}, () => {})">Predplatné a plány</a>
							</li>
							<li>
								<a @click="$router.push({ name: 'Informations', params: { slug: 'pravidla' }}, () => { $scrollTo('.main-menu') })">Pravidlá portálu</a>
							</li>
<!--							<li>-->
<!--								<a @click="$router.push({ name: 'Informations', params: { slug: 'navody1' }}, () => { $scrollTo('.main-menu') })">Postupy a návody</a>-->
<!--							</li>-->
							<li>
								<a @click="$router.push({ name: 'Informations', params: { slug: 'casto-kladene-otazky' }}, () => { $scrollTo('.main-menu') })">F&Q</a>
							</li>
							<li>
								<a @click="$router.push({ name: 'Informations', params: { slug: 'ochrana-osobnych-udajov' }}, () => { $scrollTo('.main-menu') })">GPDR</a>
							</li>
							<li>
								<a @click="$router.push({ name: 'Informations', params: { slug: 'vseobecne-obchodne-podmienky' }}, () => { $scrollTo('.main-menu') })">Obchodné podmienky</a>
							</li>
						</ul>
					</b-col>
					<b-col cols="5" md="3" offset="1">
						<ul>
<!--							<li><a @click="$router.push({ name: 'AboutProject', params: { wrote_about: 'wrote_about'} }, () => {})">Napísali o nás</a></li>-->
							<li><a @click="$router.push({ name: 'Home', params: { newsletter: 'newsletter' } }, () => {})">Newsletter</a></li>
						</ul>
					</b-col>
				</b-row>

				<hr>

				<b-row>
					<b-col cols="11" md="3" offset="1">
						<ul>
							<li v-if="$store.getters.settings">
								<font-awesome-icon class="absolute-left" icon="map-marker-alt" color="white"></font-awesome-icon>
								<a href="#">
                                    {{ $store.getters.settings.adress }} <br>
                                    {{ $store.getters.settings.psc }} {{ $store.getters.settings.city }},
                                    {{ $store.getters.settings.country }}
								</a>
							</li>
						</ul>
					</b-col>
					<b-col cols="12" md="4" class="socials">
						<ul>
							<li v-if="$store.getters.settings">
								<a :href="$store.getters.settings.facebook_link" target="_blank">
									<font-awesome-icon :icon="['fab', 'facebook-f']"></font-awesome-icon>
								</a>
								<a :href="$store.getters.settings.instagram_link" target="_blank">
									<font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon>
								</a>
								<a :href="$store.getters.settings.linkedin_link" target="_blank">
									<font-awesome-icon :icon="['fab', 'linkedin-in']"></font-awesome-icon>
								</a>
							</li>
						</ul>
					</b-col>
					<b-col cols="11" md="3" offset="1">
						<ul>
							<li v-if="$store.getters.settings">
								<font-awesome-icon class="absolute-left" icon="envelope" color="white"></font-awesome-icon>
                                <a :href="`mailto:${ $store.getters.settings.email }`">{{ $store.getters.settings.email }}</a>
								<br>
								<font-awesome-icon class="absolute-left-2" icon="phone" color="white"></font-awesome-icon>
                                <a :href="`tel:${ $store.getters.settings.tel }`">{{ $store.getters.settings.tel }}</a>
							</li>
						</ul>
					</b-col>
				</b-row>

				<div class="copyright">
					Copyright © {{ currentYear }} eBrick | created by <a href="https://wame.sk/vyvoj-webovych-aplikacii" target="_blank">
                    <img :src="wame_logo" class="wame-logo" alt="wame-logo">
                </a>
				</div>
			</div>
			<img class="footer_geometric" :src="footer_geometric" alt="svg">
		</div>
	</div>
</template>

<script>
import footer_geometric from "@/assets/footer_geometric.svg"
import wame_logo_white from "@/assets/wame_logo_white.png"

export default {
	data() {
		return {
			currentYear: new Date().getFullYear(),
			footer_geometric: footer_geometric,
			wame_logo: wame_logo_white,
		}
	},
}
</script>

<style scoped lang="scss">
.component-footer::v-deep {
	position: relative;
	background: $primary;
    margin-top: 150px;
	padding: 70px 0 20px;
	z-index: 10;

	.container {
		position: relative;
	}

	.content {
		position: relative;
		z-index: 3;
	}

	.btn-back-up {
		position: absolute;
		left: 20px;
		top: -100px;
		width: 60px;
		height: 60px;
		font-size: 24px;
		padding: 0;
	}

	a {
		color: white;
		font-size: 14px;
	}

	ul {
		list-style: none;
		padding: 0;
		text-align: left;

		li {
			line-height: 2;
		}
	}

	.footer-menu {
        color: white;

		ul {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
		}
        
        a {
            cursor: pointer;
        }
	}

	hr {
		border-color: rgba(220, 222, 230, .1);
	}

	.absolute-left {
		position: absolute;
		margin-left: -20px;
		font-size: 12px;
		top: 10px;

		&-2 {
			position: absolute;
			margin-left: -20px;
			font-size: 12px;
			top: 40px;
		}
	}

	.socials {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		li {
			display: flex;
			flex-direction: row;
			justify-content: center;

			a {
				margin: 0 7px;
			}
		}
	}

    .copyright {
        font-size: 14px;
        color: rgba(255, 255, 255, .5);

        a {
          color: white;
        }

        .wame-logo {
            width: 80px;
        }
	}

	.footer_geometric {
		position: absolute;
		top: -90px;
		left: -400px;
		z-index: 2;
	}

	@media (max-width: 768px) {
		.footer-menu {
			ul {
				justify-content: flex-start;
			}
		}
	}
}
</style>