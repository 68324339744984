<template>
    <div class="page-profile-subscription">
        <div v-if="activePlan" class="bg-wrapper plan-info">
            <b-row v-if="activePlan.main_package" class="active-plan">
                <b-col cols="12" sm="6" lg="3"><strong>Aktívny plán:</strong></b-col>
                <b-col cols="12" sm="6" lg="9">
                    <img :src="worker" alt="image">
                    <span class="active-plan__name">
                        <strong>{{ activePlan.main_package.order_product_name }}</strong>
                    </span>
                </b-col>
                <b-col cols="12" sm="6" lg="3"><strong>Dátum zakúpenia:</strong></b-col>
                <b-col cols="12" sm="6" lg="9">{{ moment(activePlan.main_package.from_date).format("LL") }}</b-col>
                <b-col cols="12" sm="6" lg="3"><strong>Dátum skončenia:</strong></b-col>
                <b-col cols="12" sm="6" lg="9">{{ moment(activePlan.main_package.to_date).format("LL") }}</b-col>
                <b-col cols="12" class="mt-3">
                    <b-button @click="$router.push({ name: 'Subscription' })" variant="primary" pill>
                        <font-awesome-icon class="btn-icon mr-1" icon="share"></font-awesome-icon>
                        Prejsť na predplatné
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <div v-else class="mt-5">
            <p><em>Zatiaľ nemáte aktivovaný žiadny balík</em></p>
            <b-button @click="$router.push({ name: 'Subscription' }, () => {})" variant="secondary" pill>
                Prejsť na predplatné
            </b-button>
        </div>

        <ProfileUserOrders />

        <ProfileUserInvoices />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import worker from "@/assets/worker_icon.png";
import ProfileUserOrders from "@/components/profil/ProfileUserOrders";
import ProfileUserInvoices from "@/components/profil/ProfileUserInvoices";

export default {
    name: "PageProfileSubscriptions",
    components: {
        ProfileUserInvoices,
        ProfileUserOrders,
    },
    data() {
        return {
            worker: worker,
            activePlan: '',
        }
    },
    mounted() {
        this.getUserActivePlan()
    },
    methods: {
        getUserActivePlan() {
            axios.get(config.API_URL + '/user/activePackage', config.HEADERS)
                .then((response) => {
                    console.log('activeplan', response.data.success)
                    this.activePlan = response.data.success
                }, (error) => {
                    console.warn(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.page-profile-subscription::v-deep {
    .bg-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -100vw;
            background: $bg-gray;
            height: 100%;
            width: 200vw;
            z-index: -1;
        }
    }

    .active-plan {
        text-align: left;
        margin: 30px 0;

        > div:not(:last-child) {
            margin-bottom: 5px;
        }

        img {
            margin-top: -5px;
        }

        &__name {
            margin-left: 10px;
        }
    }
}
</style>