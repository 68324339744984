<template>
    <b-modal :id="modal_id" size="lg" :title="title + ': ' + (demands ? demands.length : 0)">
        <b-row v-if="demands">
            <b-col v-for="demand in demands" :key="demand.id" cols="12" :class="{'my-demand': $store.getters.user.slug === demand.user.slug}">
                <div class="demand-title">
                    <div @click="$router.push({ name: 'DemandDetail', params: { slug: demand.slug }}, () => {})" class="title">
                        {{ demand.title }}
                    </div>
                    <div @click="$router.push({ name: 'PageProfileAbout', params: { slug: demand.user.slug }}, () => {})" class="author">
                        Autor:
                        <span>{{ demand.user.name }}</span>
                    </div>
                </div>
                <b-button v-if="demand.state_id === 6" @click="$router.push({ name: 'DemandDetail', params: { slug: demand.slug }})" class="primary" pill>
                    <font-awesome-icon class="btn-icon" icon="info-circle"></font-awesome-icon>
                    Detail projektu
                </b-button>
                <b-button v-if="demand.state_id === 4" @click="$router.push({ name: 'DemandRating', query: { demand: demand.slug, user: demand.user.slug } })" class="primary" pill>
                    <font-awesome-icon class="btn-icon" icon="star"></font-awesome-icon>
                    Ohodnotiť projekt
                </b-button>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <span class="demand-title">Žiadne dopyty.</span>
                <b-button @click="$router.push({ name: 'NewDemand' })" class="primary" pill>
                    <font-awesome-icon class="btn-icon" icon="marker"></font-awesome-icon>
                    Zadať dopyt
                </b-button>
            </b-col>
        </b-row>

        <template #modal-footer>
            <b-button @click="hideConfirmModal()" variant="primary" pill>
                <font-awesome-icon class="btn-icon" icon="times-circle"></font-awesome-icon>
                Zavrieť
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "DemandProjectsModal",
    props: {
        modal_id: {
            type: String,
        },
        title: {
            type: String,
        },
        demands: {
            default: function () {
                return {}
            }
        },
    },
    methods: {
        hideConfirmModal() {
            this.$bvModal.hide(this.modal_id)
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {

    .modal-content {
        padding: 0 10px;

        .modal-body {
            margin: 0 10px;

            .my-demand {
                background-color: rgba($secondary-lighter, 0.2);
                border-radius: 20px;
            }

            .demand-title {
                margin-right: 60px;

                .title {
                    font-weight: bold;
                    cursor: pointer;

                    @supports (-webkit-line-clamp: 1) {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: initial;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }

                .author {
                    cursor: pointer;

                    span {
                        font-weight: bold;
                        color: $secondary;
                    }
                }

                @media (max-width: 768px) {
                    margin-right: 0;

                    .title {
                        @supports (-webkit-line-clamp: 2) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: initial;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }

            button {
                padding: 7px 20px;
                min-width: 180px;
            }
        }

        .row {
            > div {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px 30px;

                @media (max-width: 768px) {
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    text-align: center;
                }

                &:not(:last-child):before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%,0);
                    bottom: 0;
                    height: 2px;
                    width: 96%;
                    background-size: 8px 2px;
                    background-repeat: repeat-x;
                    background-image: linear-gradient( 90deg, #0e2b4c 0, rgba(255, 255, 255, 0) 30%);
                    background-position: bottom;
                }
            }
        }
    }
}
</style>