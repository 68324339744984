<template>
    <div class="component-demand-rating">
        <b-row class="demand-user-rating" no-gutters>
            <b-col cols="12" md="6">
                <h3>Hodnotenie dodávateľa</h3>
                <div v-if="provider_rating === null" class="mt-2">
                    <p><em>Čaká sa na hodnotenie...</em></p>
                </div>
                <b-row v-else class="user-rating" no-gutters>
                    <b-col cols="12" md="6">Kvalita práce</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="provider_rating.quality"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Pomer cena / výkon</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="provider_rating.summ_quality"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Komunikácia</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="provider_rating.comunication"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Čistota</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="provider_rating.cleanliness"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Rýchlosť dodania</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="provider_rating.deliver_speed"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" md="6">
                <h3>Hodnotenie používateľa</h3>
                <div v-if="user_rating === null" class="mt-2">
                    <p><em>Čaká sa na hodnotenie...</em></p>
                </div>
                <b-row v-else class="user-rating" no-gutters>
                    <b-col cols="12" md="6">Uhradenie sumy</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="user_rating.pay_summ"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Rýchlosť platby</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="user_rating.pay_speed"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Komunikatívnosť</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="user_rating.comunication"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">Ústretovosť</b-col>
                    <b-col cols="12" md="6">
                        <div class="star-rating-container">
                            <star-rating
                                :rating="user_rating.friendliness"
                                :round-start-rating="false"
                                :show-rating="false"
                                :read-only="true"
                                :star-size="star_size"
                                :active-color="active_star_color"
                                :inactive-color="inactive_star_color"
                                :padding="star_padding"
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
    name: "DemandRating",
    components: {
        StarRating,
    },
    props: {
        provider_rating: {
            default() {
                return {}
            }
        },
        user_rating: {
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            active_star_color: '#ffa600',
            inactive_star_color: '#fff',
            star_padding: 10,
            star_size: 15,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-demand-rating::v-deep {
    .demand-user-rating {
        margin-top: 20px;

        > div {
            position: relative;
        }

        h3 {
            font-size: 18px !important;
        }

        .user-rating > div:nth-child(2n - 1) {
            text-align: right;

            @media (max-width: 767px) {
                text-align: center;
            }
        }

        .star-rating-container {
            display: flex;
            justify-content: center;
            position: relative;

            .vue-star-rating {
                span {
                    svg {
                        position: relative;
                        margin: 0;
                        vertical-align: unset;
                    }
                }

                span:last-of-type {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
</style>