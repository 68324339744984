<template>
    <div class="page-new-forum">
        <MainMenu />

        <CreateUpdateHead
            :title="'Pridať otázku'"
            :page="'Forum'"
            :slug="'false'"
            :link_text="'Späť na fórum'"
        />

        <div class="new-forum-container container">
            <NotLoginUser v-if="$store.getters.user === ''" :text="'Ak chcete pridať otázku, musíte sa prihlásiť.'" />

            <b-form>
                <b-row>
                    <b-col cols="12" lg="6" offset-lg="3">
                        <b-form-group label="Nadpis*" label-for="title" :class="{ 'input--error': $v.title.$error}">
                            <b-form-input id="title" v-model.trim="title" type="text"></b-form-input>
                            <div class="error-message-l" v-show="$v.title.$error">
                                <div v-if="!$v.title.required">Prosím, vyplňte nadpis príspevku.</div>
                                <div v-else-if="!$v.title.minLength">Nadpis musí mať aspoň {{ $v.title.$params.minLength.min }} znakov.</div>
                                <div v-else-if="!$v.title.maxLength">Nadpis môže obsahovať maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                            </div>
                        </b-form-group>

                        <CategoryTreeselect
                            :options_select.sync="category"
                            :options_data="categories"
                            ref="category_treeselect_input"
                        />
                    </b-col>

                    <b-col cols="12" lg="10" offset-lg="1">
                        <TextEditor
                            :long_text.sync="text"
                            ref="text_editor_component"
                        />

                        <UploadImages
                            class="mt-5 mb-5"
                            @changed="handleImages"
                            :max="images_limit"
                            :maxError="'Maximálny povolený počet obrázkov: ' + images_limit"
                            fileError="Podporované súbory: jpg, png v max. velkosti 5 MB"
                        />

                        <b-row class="send-forum-buttons">
                            <b-col v-if="spinner.save" cols="12">
                                <b-spinner type="grow" label="Spinning"></b-spinner>
                            </b-col>
                            <b-col v-if="!spinner.save" cols="10" offset="1" md="4" offset-md="4">
                                <b-button @click="checkNewForum()" variant="primary" pill>
                                    <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                    Zverejniť otázku
                                </b-button>
                            </b-col>
                        </b-row>

                        <ModalNewForum @post-new-forum="postForum()" ref="modal_new_forum" />

                        <div class="error-message-c" v-if="error">
                            <span v-for="(err, index) in error" :key="index">{{ err }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </div>

        <img class="geometric-1" :src="geometric_1" alt="svg">

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import geometric_1 from "@/assets/geometric_1.svg";
import MainMenu from "@/components/MainMenu";
import NotLoginUser from "@/components/NotLoginUser";
import ModalNewForum from '@/components/forum/ModalNewForum'
import CreateUpdateHead from "@/components/form_components/CreateUpdateHead";
import CategoryTreeselect from "@/components/form_components/CategoryTreeselect";
import TextEditor from "@/components/form_components/TextEditor";
import UploadImages from "vue-upload-drop-images"
import Footer from "@/components/Footer";
import { required, minLength, maxLength } from 'vuelidate/lib/validators'


export default {
    components: {
        TextEditor,
        CategoryTreeselect,
        CreateUpdateHead,
        MainMenu,
        NotLoginUser,
        Footer,
        ModalNewForum,
        UploadImages,
    },
    metaInfo() {
        return {
            title: 'Vytvoriť otázku | ',
        }
    },
    data() {
        return {
            geometric_1: geometric_1,

            title: '',
            categories: [],
            category: null,
            images: null,
            images_limit: 5,
            text: '',

            spinner: {
                save: false,
            },

            error: {}
        }
    },
    mounted() {
        this.getCategory()
    },
	methods: {
        handleImages(files) {
            this.images = files
        },
        loopCategory(response) {
            let categories = response
            let categoriesLength = Object.keys(categories).length
            let categoriesList = []

            for (let i = 0; i < categoriesLength; i++) {
                let newCategory = {}
                newCategory.label = categories[i].name
                newCategory.id = categories[i].id

                if (categories[i].children.length > 0) {
                    newCategory.children = this.loopCategory(categories[i].children)
                }
                categoriesList.push(newCategory)
            }
            return categoriesList
        },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=2', config.HEADERS)
                .then((response) => {
                    this.categories = this.loopCategory(response.data.success)
                    // this.category_spinner = true
                }, (error) => {
                    console.warn(error)
                });
        },
        checkNewForum() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    this.$scrollTo('.input--error')
                })
                return false
            }

            this.$refs.category_treeselect_input.$v.$touch()
            if (this.$refs.category_treeselect_input.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.category_treeselect_input.$scrollTo('.input--error')
                })
                return false
            }

            this.$refs.text_editor_component.$v.$touch()
            if (this.$refs.text_editor_component.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.text_editor_component.$scrollTo('.input--error')
                })
                return false
            }

            this.$bvModal.show('modal-new-forum')
        },
        postForum() {
            this.$refs.modal_new_forum.hideConfirmModal()

            this.spinner.save = true

			const myData = new FormData();
			myData.set('text', this.text);
			myData.set('title', this.title);
			myData.set('category', '[' + this.category + ']');
			myData.set('status', '1');

            if (this.images) {
                this.images.forEach((element, index) => {
                    myData.set('image' + '[' + index + ']', element)
                });
            }

			for (let pair of myData.entries()) {
				console.log(pair[0]+ ', ' + pair[1]); 
			}

            let forumConfig = config.HEADERS;
            forumConfig.HEADERS = {
				headers: {
					"Content-Type": "multipart/form-data",
					"Accept": "application/json",
                    "Authorization": `Bearer ${this.$store.getters.user.access_token.token}`
				}
			};

			axios.post(config.API_URL + '/forum', myData, forumConfig)
                .then((response) => {
                    console.log(response.data.success);
                    this.spinner.save = false
                    this.$router.push({ name: 'ForumDetail', params: { slug: response.data.success.data[1].slug }})
                }, (error) => {
                    console.warn(error)
                    this.error = this.$errorMessage(error.response)
                    this.spinner.save = false
                });
		},
	},
    validations: {
        title: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(160)
        },
    },
}
</script>

<style lang="scss" scoped>
.page-new-forum::v-deep {
    margin: 0 auto;

    .new-forum-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container {
        height: auto;
    }

    .geometric-1 {
        position: absolute;
        top: 400px;
        left: 20px;
        transform: scaleX(-1);
        width: 760px;
        z-index: -1;
        opacity: 0.5;
    }
}
</style>