<template>
	<div class="component-post-list">
		<div class="container">
			<div class="content">
				<h2 class="text-left mb-3">Články od vás</h2>

			</div>
			<img class="geometric-6" :src="rollers" alt="svg">
			<img class="geometric-7" :src="rollers_outline" alt="svg">
		</div>

		<div class="post-list-wrapper">
			<div class="container">
				<b-row class="content post-list">
					<b-col cols="4" v-for="blog in topBlogs" :key="blog.id">
						<a @click="$router.push({ name: 'PageBlogDetail', params: { slug: blog.slug } }, () => {})" class="post-link">
                            <PostDark :blog="blog" />
						</a>
					</b-col>
				</b-row>
			</div>
		</div>

		<div class="container">
			<div class="content">

				<b-button @click="$router.push({ name: 'Blog' })" class="all-posts secondary-lighter" pill variant="secondary">
                    Všetky články
                </b-button>

			</div>

		</div>
	</div>
</template>

<script>
import PostDark from "@/components/forum/single/PostDark";

import rollers from "@/assets/rollers.svg"
import rollers_outline from "@/assets/rollers_outline.svg"
import axios from "axios";
import config from "@/config";

export default {
	components: {
		PostDark
	},
	data() {
		return {
			rollers: rollers,
			rollers_outline: rollers_outline,

            topBlogs: [],
		}
	},
    mounted() {
        this.getTopBlogs()
    },
    methods: {
        getTopBlogs() {
            axios.get(config.API_URL + '/blog/top/getTopPosts?quantity=3', config.HEADERS)
                .then((response) => {
                    this.topBlogs = response.data.success;
                }, (error) => {
                    console.warn(error);
                });
        },
    },
}
</script>

<style scoped lang="scss">
.component-post-list::v-deep {
	position: relative;
	padding: 70px 0;
	background: $primary;
	z-index: 5;

	.container {
		position: relative;

		.content {
			position: relative;
			z-index: 10;
		}
	}

	h2 {
		color: $secondary-lighter;
	}

	.post-list-wrapper {
		overflow-y: auto;
    padding-top: 20px;
		padding-bottom: 20px;

		.post-list {
			min-width: 960px;

            .post-link {
                cursor: pointer;
            }
		}
	}

	.all-posts {
		margin-top: 40px;
		padding: 10px 50px;
	}

	.geometric-7 {
		position: absolute;
		left: -400px;
		top: -20px;
		z-index: 2;
	}

	.geometric-6 {
		position: absolute;
		right: -400px;
		top: -100px;
		z-index: 2;
	}

	@media (max-width: 768px) {
		.all-posts {
			margin-top: 20px;
			width: 100%;
		}
	}
}
</style>