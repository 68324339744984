<template>
    <div class="component-vue-pagination mt-5">
        <b-spinner v-if="load_data" type="grow" label="Spinning"></b-spinner>
        <pagination
            v-else
            v-model="current_page_copy"
            :per-page="per_page"
            :records="records"
            :options="{
                theme: 'bootstrap4',
                edgeNavigation: false,
                texts: {
                    count: 'Zobrazené {from} - {to} z {count} položiek|Počet položiek: {count}|',
                    first: 'Prvá',
                    last: 'Posledná'
                }
            }"
            @paginate="handlePageChange"
        ></pagination>
    </div>
</template>

<script>
export default {
    name: "VuePagination",
    props: {
        per_page: {
            type: Number,
            default: 20
        },
        current_page: {
            type: Number,
            default: 1
        },
        records: {
            type: Number,
            default: 0
        },
        load_data: {
            type: Boolean,
            default: false
        },
        query_name: {
            type: String,
            default: 'page'
        },
        query_change: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            current_page_copy: this.current_page
        }
    },
    watch: {
        current_page(value) {
            this.current_page_copy = value
        }
    },
    methods: {
        handlePageChange(page) {
            this.$emit('handle-current-page', page)
        },
    }
}
</script>

<style lang="scss" scoped>
.component-vue-pagination::v-deep {
    min-height: 80px;

    .VuePagination__pagination {
        justify-content: center;

        @media (max-width: 700px) {
            transform: scale(0.85);
        }
    }

    .VuePagination__pagination-item-prev-chunk .page-link {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .VuePagination__pagination-item-next-chunk .page-link {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}
</style>