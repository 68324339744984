<template>
    <div class="page-blog-update">
        <CreateUpdateHead
            :title="'Upraviť článok'"
            :page="'PageBlogDetail'"
            :type="'blog'"
            :slug="$route.params.slug"
            :link_text="'Späť na blog'"
        />

        <div class="update-blog-container container">
            <b-spinner v-if="spinners.load_data" class="mt-5" type="grow" label="Spinning"></b-spinner>
            <b-form v-else class="blog-form">
                <b-row>

                    <b-col v-if="!blog_publication" cols="12" lg="6" offset-lg="3">
                        <b-form-group label="Názov článku*" label-for="blog_name">
                            <b-form-input id="blog_name" v-model="title" type="text"></b-form-input>
                            <div class="error-message-l" v-show="$v.title.$error">
                                <div v-if="!$v.title.required">Prosím, vyplňte nadpis príspevku.</div>
                                <div v-else-if="!$v.title.minLength">Nadpis musí mať aspoň {{ $v.title.$params.minLength.min }} znakov.</div>
                                <div v-else-if="!$v.title.maxLength">Nadpis môže obsahovať maximálne {{ $v.title.$params.maxLength.max }} znakov.</div>
                            </div>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" lg="6" offset-lg="3">
                        <CategoryTreeselect
                            :options_select.sync="category"
                            :options_data="categories"
                            ref="category_treeselect_input"
                        />

                        <b-form-group label="Úvodný obrázok" label-for="blog_main_image">
                            <b-form-file
                                id="blog_main_image"
                                v-model="main_picture"
                                @change="onMainImageChange"
                                accept="images/jpeg, images/png"
                                browse-text="Nahrať súbor"
                                drop-placeholder="Nahrať súbor"
                                placeholder=""
                            ></b-form-file>
                        </b-form-group>
                        <div v-if="main_picture_show" class="main-picture-show">
                            <p>Úvodný obrázok:</p>
                            <span class="image-trash"><font-awesome-icon icon="trash-alt"></font-awesome-icon></span>
                            <img :src="main_picture_show" @click="deleteMainPicture()" alt="obrazok">
                        </div>
                    </b-col>

                    <b-col cols="12" lg="10" offset-lg="1">
                        <TextEditor
                            :long_text.sync="text"
                            ref="text_editor_component"
                        />

                        <ImagesUploader
                            :old_images="old_images"
                            :images="images"
                            @delete-image="deleteImage($event)"
                            @delete-new-image="deleteNewImage($event)"
                            @upload-images="onImagesChange($event)"
                        />
                    </b-col>

                    <b-col cols="12" lg="8" offset-lg="2" class="send-blog-buttons">
                        <b-row>
                            <b-col v-if="error" cols="12">
                                <div class="error-message-c mb-0" v-if="error">
                                    <span v-for="(err, index) in error" :key="index">{{ err }}</span>
                                </div>
                            </b-col>
                            <b-col v-if="!spinners.post_data" cols="12" lg="6" class="mt-2">
                                <b-button pill @click="updateBlog('written')" variant="primary">
                                    <font-awesome-icon class="btn-icon" icon="save"></font-awesome-icon>
                                    Uložiť článok
                                </b-button>
                            </b-col>
                            <b-col v-if="!spinners.post_data" cols="12" lg="6" class="mt-2">
                                <b-button pill @click="updateBlog('pending')" variant="secondary">
                                    <font-awesome-icon class="btn-icon" icon="share-square"></font-awesome-icon>
                                    Uložiť a poslať na kontrolu
                                </b-button>
                            </b-col>
                            <b-col cols="12">
                                <b-spinner v-if="spinners.post_data" type="grow" label="Spinning"></b-spinner>
                            </b-col>
                            <b-col cols="12" class="bottom-info">
                                <p><small>Článok Vám skontrolujeme a ak bude všetko v poriadku, príde Vám upozornenie o jeho publikovaní.</small></p>
                                <p><small>Tento proces trvá zvyčajne menej ako dva dni.</small></p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-form>
        </div>

        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import ImagesUploader from "@/components/ImagesUploader";
import CreateUpdateHead from "@/components/form_components/CreateUpdateHead";
import CategoryTreeselect from "@/components/form_components/CategoryTreeselect";
import TextEditor from "@/components/form_components/TextEditor";

export default {
    name: 'PageBlogUpdate',
    components: {
        TextEditor,
        CategoryTreeselect,
        CreateUpdateHead,
        ImagesUploader,
    },
    metaInfo() {
        return {
            title: 'Upraviť blog | ',
        }
    },
    data() {
        return {
            blog_id: '',
            title: '',
            categories: [],
            category: null,
            main_picture: null,
            main_picture_show: null,
            blog_publication: false,

            old_images: null,
            images: [],
            new_images: [],
            images_length: null,

            text: '',

            error: null,

            spinners: {
                load_data: false,
                post_data: false,
            }
        };
    },
    mounted() {
        this.getBlog()
        this.getCategory()
    },
    watch: {
        old_images: function () {
            this.loadAllImagesLength()
        },
        images: function () {
            this.loadAllImagesLength()
        },
        new_images: function () {
            this.loadAllImagesLength()
        },
    },
    methods: {
        loadAllImagesLength() {
            if (this.old_images !== null) {
                this.images_length = this.old_images.length + this.images.length
            } else {
                this.images_length = this.images.length
            }
        },
        onMainImageChange(event) {
            this.main_picture = event.target.files[0];
            this.main_picture_show = URL.createObjectURL(event.target.files[0]);
        },
        setImage(image, func) {
            const reader = new FileReader();
            reader.onload = () => {
                func(reader.result)
            }
            reader.readAsDataURL(image);
        },
        onImagesChange(event) {
            let files = event.target.files
            let self = this;
            files.forEach(img => {
                self.new_images.push(img)
                this.setImage(img, (data) => { self.images.push(data) })
            })
        },
        getBlog() {
            this.spinners.load_data = true
            axios.get(config.API_URL + '/blog/' + this.$route.params.slug, config.HEADERS)
                .then((response) => {
                    console.log('toto je upravit blog:', response.data.success);
                    let response_data = response.data.success
                    this.blog_id = response_data[0].id
                    this.title = response_data[0].title
                    // const myArr = data[0].category
                    // this.category = myArr.replace(/\D+/g, ' ').trim().split(' ').map(e => parseInt(e));
                    // this.category.push(response_data[0].categories[0].category_id)
                    this.category = response_data[0].categories[0].category_id
                    if (response_data[0].publication_date) this.blog_publication = true
                    console.log(this.blog_publication)
                    if (response_data[0].main_picture_path) this.main_picture_show = config.ASSET_URL + '/' + response_data[0].main_picture_path
                    this.text = response_data[0].text
                    if (response_data[1] !== 1) {
                        this.old_images = response_data[1]
                    }
                    this.spinners.load_data = false
                }, (error) => {
                    this.$errorMessage(error.response)
                    this.spinners.load_data = false
                });
        },
        getCategory() {
            axios.get(config.API_URL + '/allCategory?menu_id=1', config.HEADERS)
                .then((response) => {
                    // console.log('cate', response.data.success)
                    let categories = response.data.success
                    let categoriesLength = Object.keys(categories).length
                    let categoriesList = []

                    for (let i = 0; i < categoriesLength; i++) {
                        const newCategory = {}
                        newCategory.label = categories[i].name
                        newCategory.id = categories[i].id

                        if (categories[i].children[0]) {
                            let categoriesChildren = categories[i].children
                            let categoriesChildrenLength = Object.keys(categoriesChildren).length
                            let categoriesChildList = []

                            for (let i = 0; i < categoriesChildrenLength; i++) {
                                const newCategoryChild = {}
                                newCategoryChild.id = categoriesChildren[i].id
                                newCategoryChild.label = categoriesChildren[i].name

                                categoriesChildList.push(newCategoryChild)
                            }
                            newCategory.children = categoriesChildList
                        }
                        categoriesList.push(newCategory)
                    }
                    // console.log('cateLISTI', categoriesList)
                    this.categories = categoriesList
                }, (error) => {
                    this.$errorMessage(error.response)
                });
        },
        blogDataValidation() {
            let return_value = true
            if (this.blog_publication) {
                this.$v.category.$touch()
                this.$v.text.$touch()
                if (this.$v.category.$invalid) return_value = false
                if (this.$v.text.$invalid) return_value = false
            } else {
                this.$v.$touch()
                if (this.$v.$invalid) return_value = false
            }

            this.$refs.category_treeselect_input.$v.$touch()
            if (this.$refs.category_treeselect_input.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.category_treeselect_input.$scrollTo('.input--error')
                })
                return false
            }

            this.$refs.text_editor_component.$v.$touch()
            if (this.$refs.text_editor_component.$v.$invalid) {
                this.$nextTick(() => {
                    this.$refs.text_editor_component.$scrollTo('.input--error')
                })
                return_value = false
            }
            return return_value
        },
        updateBlog(status) {
            if (this.images_length > 8) return false
            if (!this.blogDataValidation()) return false

            this.spinners.post_data = true
            this.error = null

            const postData = new FormData();
            postData.set('status', status);
            postData.set('title', this.title);
            postData.set('category', '[' + this.category + ']');
            if (!this.main_picture) {
                if (this.main_picture_show) {
                    postData.set('main_picture_null', 0);
                } else {
                    postData.set('main_picture_null', 1);
                }
            } else {
                postData.set('main_picture', this.main_picture);
            }

            postData.set('text', this.text);
            //.replace(/(<([^>]+)>)/gi, "")

            if (this.old_images !== null && this.old_images.length > 0) {
                let oldImagesID = []
                this.old_images.forEach(element => {
                    oldImagesID.push(element.id)
                });
                // myData.set('image' + '[' + index + ']', element)
                postData.set('images', '[' + oldImagesID + ']')
            }
            if (this.new_images) {
                this.new_images.forEach((element, index) => {
                    postData.set('image' + '[' + index + ']', element)
                });
            }
            for (let pair of postData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]);
            }
            this.postBlogUpdatedData(postData);
        },
        postBlogUpdatedData(postData) {
            let customerConfig = config.HEADERS;
            customerConfig.HEADERS = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    'Authorization': `Bearer ${this.$store.getters.user.access_token.token}`
                }
            };
            axios.post(config.API_URL + '/blog/update/' + this.$route.params.slug, postData, customerConfig)
                .then((response) => {
                    console.log('save blog', response.data);
                    this.$router.push({ name: 'PageBlogDetail', params: { slug: response.data.success.slug }})
                    this.spinners.post_data = false
                })
                .catch((error) => {
                    this.spinners.post_data = false
                    this.error = this.$errorMessage(error.response)
                });
        },


        deleteMainPicture() {
            this.main_picture = null
            this.main_picture_show = null
        },
        deleteImage(image_id) {
            this.old_images = this.old_images.filter(item => item.id !== image_id)
        },
        deleteNewImage(index) {
            this.images.splice(index, 1)
            this.new_images.splice(index, 1)
        },
    },
    validations: {
        title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(160)
        },
        category: {
            required,
        },
        text: {
            required,
            minLength: minLength(20),
            maxLength: maxLength(20000)
        },
    },
}
</script>

<style scoped lang="scss">
.page-blog-update::v-deep {

    .update-blog-container {
        position: relative;

        .blog-form {
            margin: 40px 0 80px;

            .main-picture-show {
                margin: 20px auto;

                p {
                    margin-bottom: 0;
                }

                .image-trash {
                    position: absolute;
                    z-index: 1;
                    opacity: 0;
                    left: 50%;
                    transform: translate(-50%, 0) scale(1.2);

                    svg {
                        color: $secondary;
                    }
                }

                img {
                    width: auto;
                    max-width: 100%;
                    max-height: 300px;
                    border-radius: 20px;
                    box-shadow: 0 12px 17px rgba(0, 0, 0, .2);

                    &:hover {
                        filter: grayscale(100%);
                        cursor: pointer;
                    }
                }

                &:hover {
                    .image-trash {
                        opacity: 1;
                    }
                }
            }

            .send-blog-buttons {
                margin-top: 50px;

                .btn {
                    width: 90%;
                }

                .bottom-info {
                    margin-top: 30px;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}
</style>