export default {
    API_URL: "https://ebrick.wame.sk/api",
    ASSET_URL: "https://ebrick.wame.sk/storage",
    BASE_URL: "https://app.ebrick.wame.sk",
    ADMIN_URL: "https://ebrick.wame.sk",
    // API_URL: "https://admin.ebrick.sk/api",
    // ASSET_URL: "https://admin.ebrick.sk/storage",
    // BASE_URL: "https://ebrick.sk",
    // ADMIN_URL: "https://admin.ebrick.sk",
    HEADERS: {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    }
}